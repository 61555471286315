import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  Link,
  useColorMode,
  Checkbox,
  list,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import { Link as RouteLink } from "react-router-dom";
import { statusSwitch, validationSwitch } from "../../data/taskData";
import { FaFile, FaFileVideo } from "react-icons/fa";
import { BsFillPlayBtnFill } from "react-icons/bs";

// import Upload from "./Upload";
import Card from "../../components/card";
import { downloadFile } from "./api";
import TableRow from "../../components/table/TableRow";
import CardTitle from "../../components/card/CardTitle";
import { VideoPlayer } from "./VideoPlayer";

export default function Player({
  timecode,
  setTimecode,
  index,
  file,
  clientId,
  // setComments,
  // reloadComments,
  // setReloadComments,
  isLoading,
  setIsLoading,
  currentFile,
}) {
  const { colorMode } = useColorMode();
  //   console.log("taskData", taskData);
  // console.log("user", user);
  let history = useHistory();
  const [listUrl, setListUrl] = useState([]);
  const [playing, setPlaying] = useState(false);

  return (
    <Box key={index}>
      {
        file?.muxPlaybackId?.length > 0 ? (
          <Box key={index}>
            <VideoPlayer
              muxPlaybackId={file?.muxPlaybackId}
              muxId={file?.muxId}
              clientId={clientId}
              fileId={file?._id}
              timecode={timecode}
              setTimecode={setTimecode}
              currentFile={currentFile}
            />

            <Flex
              pt='10px'
              fontSize='xs'
              paddingRight='10px'
              wordBreak='break-word'>
              {file.originalName}
            </Flex>
          </Box>
        ) : (
          <></>
        )
        //   <TableRow
        //   key={index}
        //   gridCol="1fr  12fr 4fr 2fr"
        //   color={colorMode === "light" ? "gray.500" : "white"}
        //   click={() => handleDownload(file)}
        // >
        //   <Box color={colorMode === "light" ? "gray.500" : "white"}>
        //     {file.type === "video/mp4" ? (
        //       <FaFileVideo size={24} />
        //     ) : (
        //       <FaFile size={24} />
        //     )}
        //   </Box>
        //   {/* <Flex color={colorMode === "light" ? "gray.500" : "white"}>
        //     {file.muxPlaybackId ? <BsFillPlayBtnFill/> : ""}
        //   </Flex> */}
        //   <Flex color={colorMode === "light" ? "gray.500" : "white"}>
        //     {file.displayName ? file.displayName : ""} - {index + 1}
        //   </Flex>
        //   <Flex color={colorMode === "light" ? "gray.500" : "white"}>
        //     {file.createdAt
        //       ? dayjs(file.createdAt).format("DD/MM/YYYY")
        //       : ""}
        //   </Flex>
        //   <Flex color={colorMode === "light" ? "gray.500" : "white"}>
        //     {file.size ? (file.size / 1000000).toFixed() + " Mo" : ""}
        //   </Flex>
        // </TableRow>
      }
    </Box>
  );
}

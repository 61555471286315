import React, { useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import OrderSummary from "./OrderSummary";
import Team from "./Team";
import CardTitle from "../../components/card/CardTitle";
import RightColumn from "../../components/column/RightColumn";
import { MiniHeader } from "../../components/header/MiniHeader";

export default function RightBar({
  team,
  projectName,
  projectData,
  projectId,
  reloadData,
  setReloadData,
  admin,
  isLoading,
  clientId,
}) {
  const [active, setActive] = useState("Order");

  const selectDisplay = (title) => {
    setActive(title);
  };

  return (
    <RightColumn>
      <Flex px='20px' pt='20px'>
        <Box
          onClick={() => selectDisplay("Order")}
          mr='15px'
          cursor={active === "Order" ? "unset" : "pointer"}>
          <CardTitle title='Order Summary' isActive={active === "Order"} />
        </Box>
        <Box
          onClick={() => selectDisplay("Team")}
          cursor={active === "Team" ? "unset" : "pointer"}>
          <CardTitle title='Team' isActive={active === "Team"} />
        </Box>
      </Flex>
      {active === "Order" && (
        <OrderSummary
          projectData={projectData}
          admin={admin}
          projectId={projectId}
          setReloadData={setReloadData}
          isLoading={isLoading}
        />
      )}
      {active === "Team" && (
        <Team
          team={team}
          projectName={projectName}
          projectId={projectId}
          reloadData={reloadData}
          setReloadData={setReloadData}
          admin={admin}
          isLoading={isLoading}
          clientId={clientId}
        />
      )}
    </RightColumn>
  );
}

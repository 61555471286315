import React from "react";

import {
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Flex,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import { statusOptions } from "../../data/projectData";
import { MdAddBox } from "react-icons/md";

export default function NavBar(props) {
  const history = useHistory();
  const {
    clients,
    admin,
    freelance,
    queryClient,
    queryDisplay,
    queryCategory,
    queryTag,
    isLoading,
  } = props;

  const categoryList = [
    "Interview",
    "Shooting",
    "Prémontage",
    "Montage",
    "Motion design",
  ];

  const reverseCategory = (cat) => {
    switch (cat) {
      case "interview":
        return "Interview";
      case "shooting":
        return "Shooting";
      case "preProduction":
        return "Prémontage";
      case "production":
        return "Montage";
      case "motionDesign":
        return "Motion design";
      default:
        return "All category";
    }
  };

  return (
    <Flex my='5' justifyContent='space-between'>
      {/* Display filter */}
      <Flex>
        {admin && (
          <Menu>
            <MenuButton
              as={Button}
              variant='outline'
              rounded='full'
              rightIcon={<ChevronDownIcon />}>
              {queryDisplay === "all" ? "All projects" : "My projects"}
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?client=${queryClient?.toLowerCase()}&display=all`
                  );
                }}>
                All projects
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?client=${queryClient?.toLowerCase()}&display=me`
                  );
                }}>
                My projects
              </MenuItem>
            </MenuList>
          </Menu>
        )}

        {admin && (
          <Menu>
            <MenuButton
              as={Button}
              variant='outline'
              rounded='full'
              rightIcon={<ChevronDownIcon />}>
              {queryCategory === "all"
                ? "All category"
                : reverseCategory(queryCategory)}
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?client=${queryClient?.toLowerCase()}&display=${queryDisplay}&category=all`
                  );
                }}>
                All category
              </MenuItem>
              {categoryList.map((item) => {
                const metaCategory = (it) => {
                  switch (it) {
                    case "Interview":
                      return "interview";
                    case "Shooting":
                      return "shooting";
                    case "Prémontage":
                      return "preProduction";
                    case "Montage":
                      return "production";
                    case "Motion design":
                      return "motionDesign";
                    default:
                      return "all";
                  }
                };
                return (
                  <MenuItem
                    onClick={() => {
                      history.push(
                        `?client=${queryClient?.toLowerCase()}&display=${queryDisplay}&category=${metaCategory(
                          item
                        )}`
                      );
                    }}>
                    {item}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        )}

        {/* Client filter */}
        {(admin || freelance) && (
          <Menu>
            <MenuButton
              as={Button}
              variant='outline'
              rounded='full'
              rightIcon={<ChevronDownIcon />}
              textTransform='capitalize'>
              {queryClient === "all"
                ? "All clients"
                : clients &&
                  clients.filter((item) => item._id === queryClient)[0]?.name}
            </MenuButton>
            <MenuList maxH='300px' overflow='auto'>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?client=all&display=${queryDisplay}&category=${queryCategory}`
                  );
                }}>
                All
              </MenuItem>

              {clients &&
                clients
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          history.push(
                            `?client=${item._id}&display=${queryDisplay}&category=${queryCategory}`
                          );
                        }}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
            </MenuList>
          </Menu>
        )}
      </Flex>
    </Flex>
  );
}

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";

import { differenceInBusinessDays } from "date-fns";

import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import { fetchTasks } from "./api";

import SideBar from "./SideBar";
import RightBar from "./RightBar";
import { Updating } from "../../components/notification/Updating";
import Main from "./Main";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";
import { FaClock } from "react-icons/fa";
import { HiPencilAlt } from "react-icons/hi";
import EditVideo from "../../components/video/EditVideo";
import MainColumn from "../../components/column/MainColumn";
dayjs.locale("fr");

export default function Video() {
  const [isLoading, setIsLoading] = useState(false);

  const { colorMode } = useColorMode();
  let { projectId, videoId } = useParams();
  const { admin, user, freelance, client } = useUser();
  const [videoData, setVideoData] = useState("");
  const [adminProjectId, setAdminProjectId] = useState("");
  const [monthTask, setMonthTask] = useState([]);
  const [month, setMonth] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reloadData, setReloadData] = useState(false);

  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();

  useEffect(() => {
    // console.log("user from index", user)
    setIsLoading(true);
    fetchTasks(projectId, videoId, admin, onClose).then((response) => {
      // const tasks = response.tasks;

      setVideoData(response);
      setAdminProjectId(response?.video?._id);
      setIsLoading(false);
      // console.log("videoData", response);
      // console.log("reloadData");
    });
  }, [projectId, videoId, reloadData]);

  const lastTask = videoData?.tasks?.filter(
    (task) => task.dueDate !== null
  ).length;

  const date1 =
    videoData &&
    new Date(
      videoData?.tasks.filter((task) => task.dueDate !== null)[
        lastTask - 1
      ]?.dueDate
    );

  const date2 =
    videoData &&
    new Date(
      videoData?.tasks.filter((task) => task.dueDate !== null)[0]?.dueDate
    );

  const stageTime = differenceInBusinessDays(date1, date2) + 1;

  return (
    <Box w='100%'>
      <MainColumn>
        <MiniHeader>
          <Flex alignItems='center'>
            <CardTitle title={videoData?.video?.title} isActive='false' />

            {stageTime > 0 && (
              <Flex
                color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}
                fontSize='xs'
                alignItems='center'
                ml='10px'>
                <FaClock />
                <Box ml='5px'>{stageTime}J</Box>
              </Flex>
            )}
            {admin && !isLoading && (
              <Button
                aria-label='Edit'
                variant='outline'
                rounded='full'
                size='sm'
                onClick={() => onOpenEdit()}
                mx='10px'>
                <HiPencilAlt />
              </Button>
            )}
          </Flex>
        </MiniHeader>

        <Flex justifyContent='space-between'>
          <SideBar
            projectName={videoData?.project?.name}
            clientName={videoData?.project?.client.name}
            videoName={videoData?.video?.title}
            projectLink={`/quotes/${videoData?.project?._id}`}
            clientLink={`/clients/${videoData?.project?.client?._id}`}
            isLoading={isLoading}
            admin={admin}
            client={client}
            freelance={freelance}
            videoStatus={videoData?.video?.newStatus?.value}
            projectStatus={videoData?.project?.status?.value}
            clientLogo={videoData?.project?.client?.logo}
          />

          <Main
            videoData={videoData}
            reloadData={reloadData}
            setReloadData={setReloadData}
          />

          <RightBar
            videoData={videoData}
            adminProjectId={adminProjectId}
            projectData={videoData?.project}
            projectId={projectId}
            reloadData={reloadData}
            setReloadData={setReloadData}
            admin={admin}
            projectName={videoData && videoData.project.name}
            team={videoData.admins}
            videoId={videoData?.video?._id}
            isLoading={isLoading}
            clientId={videoData?.project?.client?._id}
            user={user}
            clientName={videoData?.project?.client?.name}
          />

          {isLoading && <Updating />}
        </Flex>

        <EditVideo
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          reloadData={reloadData}
          setReloadData={setReloadData}
          projectData={videoData.project}
          projectName={videoData && videoData.project.name}
          projectId={projectId}
          videoId={videoData?.video?._id}
          videoData={videoData.video}
          clientId={videoData?.project?.client?._id}
        />
      </MainColumn>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormHelperText,
  Textarea,
  useColorMode,
  Switch,
} from "@chakra-ui/react";
import Select from "react-select";
import { useUser } from "../../libs/UserContext";
import { customStyles, darkStyles } from "../../styles/customStyles";
import { roleOptions } from "../../data/adminData";
import { createCollab, createSuperCollab, fetchClients } from "./api";
import { teamOptions } from "../../data/kronikData";
export default function NewCollab(props) {
  const { colorMode } = useColorMode();
  const { admin } = useUser();
  const {
    projectId,
    projectName,
    videoId,
    handleCloseAdmin,
    adminProjectId,
    reloadData,
    setReloadData,
    admins,
    isOpen,
    onClose,
    clientId,
  } = props;

  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [isLoadingOne, setIsLoadingOne] = useState(false);
  const [clients, setClients] = useState("")
  const [people, setPeople] = useState("")
  const [isClient, setIsClient] = useState(false)
  // console.log("clientId", clientId);

  const adminsEmailList = admins && admins.filter((admin) => admin.email);


  useEffect(() => {
    fetchClients(clientId).then((response)=> {
      setClients(
         
        response && response.sort(function (a, b) {
        if (a.family_name?.toLowerCase() < b.family_name?.toLowerCase()) {
          return -1;
        }
        if (a.family_name?.toLowerCase() > b.family_name?.toLowerCase()) {
          return 1;
        }
        return 0;
      }).map((item)=> {
        return {
          label:item.given_name + "  " + item.family_name,
          value:item.email
        }
      })
        
      )
      // console.log(`clients list de ${clientId}`, response)
    })
  },[clientId])



  function changeEmail(e) {
    setEmail(e.target.value);
  }

  async function newSuperCollab() {
    setIsLoadingOne(true);
    // console.log("projectId", projectId);
    // console.log("admin", admin);
    // console.log("email", email);
    // console.log("role", role);
    // console.log("projectName", projectName);
    createSuperCollab(
      projectId,
      admin,
      email,
      role,
      projectName,
      clientId
    ).then((response) => {
      setIsLoadingOne(false);
      onClose();
      setReloadData(!reloadData);
    });
  }

  async function newCollab() {
    setIsLoadingOne(true);
    // console.log("projectId", projectId);
    // console.log("admin", admin);
    // console.log("email", email);
    // console.log("role", role);
    // console.log("videoId", videoId);
    // console.log("projectName", projectName);
    createCollab(
      projectId,
      admin,
      email,
      role,
      videoId,
      projectName,
      clientId
    ).then((response) => {
      setIsLoadingOne(false);
      onClose();
      setReloadData(!reloadData);
    });
  }


  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add people</ModalHeader>
        <ModalCloseButton />
        <ModalBody>


          {/* Client or team */}
        
            <Box my="4" mb="4">
              <Switch
                isChecked={isClient}
                onChange={(e) => {
                  setIsClient(!isClient)
                  }}
              >
                {isClient ? "Client" : "kronik team"}
              </Switch>
            </Box>
          

           {/* Clients */}
             {clients && isClient &&
                <Box my="4" >
                  <FormLabel>Client</FormLabel>
                  <Select
                    styles={colorMode === "light" ? customStyles : darkStyles}
                    isClearable
                    placeholder="Select a freelance"
                    value={people}
                    onChange={(e) => {
                      setPeople(e)
                      setEmail(e ? e.value : "")    
                      console.log(e) 
                    }}
                    options={clients}
                  />
                </Box>}

          {/* Team */}
          {!isClient &&
                <Box my="4">
                  <FormLabel>Team</FormLabel>
                  <Select
                    styles={colorMode === "light" ? customStyles : darkStyles}
                    isClearable
                    placeholder="Select a team member"
                    value={people}
                    onChange={(e) => {
                      setPeople(e)
                      setEmail(e ? e.value : "")    
                      console.log(e) 
                    }}
                    options={teamOptions}
                  />
                </Box>}



          {/* email */}
          <Box my="4">
            <FormControl id="subtitle">
              <FormLabel>email</FormLabel>
              <Input
                variant="filled"
                isDisabled
                placeholder="Add a email"
                value={people && people.value.length > 0 ? people.value : ""}
                type="email"
                // onChange={() => console.log("yo")}
              />
            </FormControl>
          </Box>

          {/* Role */}
          <Box my="4">
            <FormControl id="role">
              <FormLabel>Role</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                placeholder="Select a role "
                value={role ? role : ""}
                onChange={setRole}
                options={roleOptions}
              />
              {((adminProjectId && role && role.value === "accountManager") ||
                (adminProjectId && role && role.value === "administrator") ||
                (adminProjectId  && role&& role.value === "client")) && (
                <FormHelperText color="red.500">
                  You can't add a "{role.label}" on a video (but on the quote)
                </FormHelperText>
              )}

              {((!adminProjectId  && role&& role.value === "interviewer") ||
                (!adminProjectId && role && role.value === "storyteller") ||
                (!adminProjectId && role && role.value === "cadreur") ||
                (!adminProjectId && role && role.value === "monteur") ||
                (!adminProjectId && role && role.value === "motionDesigner")) && (
                <FormHelperText color="red.500">
                  You can't add a "{role.label}" on a quote (but on a video)
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={
              !email ||
              !role ||
              isLoadingOne ||
              (adminProjectId && role && role.value === "accountManager") ||
              (adminProjectId && role && role.value === "administrator") ||
              (adminProjectId && role && role.value === "client") ||
              (!adminProjectId && role && role.value === "interviewer") ||
              (!adminProjectId && role && role.value === "storyteller") ||
              (!adminProjectId && role && role.value === "cadreur") ||
              (!adminProjectId && role && role.value === "monteur") ||
              (!adminProjectId && role && role.value === "motionDesigner")
            }
            bgColor="primary"
            color="white"
            mr={3}
            onClick={
              // () => newSuperCollab()
              () => (adminProjectId ? newCollab() : newSuperCollab())
            }
            isLoading={isLoadingOne}
            loadingText="Loading"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import React, { useState, useEffect } from "react";
import { Box, Heading, Flex } from "@chakra-ui/react";

import fetchVideos, { fetchVideosByUser } from "./api";
import Kanban from "./Kanban";
import NavBar from "./NavBar";
import { useUser } from "../../libs/UserContext";
import { Updating } from "../../components/notification/Updating";
import { useLocation, useParams } from "react-router-dom";
// import VideosList from "./VideosList";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";
import MainColumn from "../../components/column/MainColumn";
import ProductionHeader from "../../components/header/ProjectHeader";

export default function Production() {
  const { user, admin, freelance } = useUser();
  const [dateSelected, setDateSelected] = useState("all");
  const [data, setData] = useState("");
  const [videos, setVideos] = useState("");
  const [clients, setClients] = useState("");
  const [reloadData, setReloadData] = useState(false);
  const { videoStatus } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [cognitoId, setCognitoId] = useState("");

  const [filteredVideos, setFilteredVideos] = useState("");
  const [notStarted, setNotStarted] = useState("");
  const [production, setProduction] = useState("");
  const [validated, setValidated] = useState("");
  const [delivered, setDelivered] = useState("");
  const [deliveredQty, setDeliveredQty] = useState(0);
  const [productionQty, setProductionQty] = useState(0);
  const [preparationQty, setPreparationQty] = useState(0);
  const [filteredVideosQty, setFilteredVideosQty] = useState("");
  const [queryStatus, SetQueryStatus] = useState("");
  const [queryClient, SetQueryClient] = useState("");
  const [queryDisplay, SetQueryDisplay] = useState("");
  const [queryManager, SetQueryManager] = useState("");

  const location = useLocation();

  function useQuery() {
    // const { search } = useLocation();
    // return React.useMemo(() => new URLSearchParams(search), [search]);
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  useEffect(() => {
    const arrayCognito = {
      géraldinemillion: "us-east-2:1be77bef-8ae5-449c-9e6e-be1c8cc7d1af",
      // géraldinemillion:"60a525b33ac1c70008ed421a",
      maxencelebreux: "us-east-2:24bf8f7c-684c-46f7-86f7-7d632be13e87",
      geoffroylasne: "us-east-2:d5c1e7f1-bc8d-483c-8380-e43f467e126e",
      mariongauffroy: "us-east-2:0bbe5b02-3b82-4bba-9af3-77829c144316",
      claracolleoni: "us-east-2:292faf1c-b190-46d4-b6fd-bb7103cefe0b",
    };

    const manager = query.get("manager") ? query.get("manager") : "all";
    switch (manager) {
      case "géraldinemillion":
        setCognitoId(arrayCognito["géraldinemillion"]);
        break;
      case "maxencelebreux":
        setCognitoId(arrayCognito["maxencelebreux"]);
        break;
      case "geoffroylasne":
        setCognitoId(arrayCognito["geoffroylasne"]);
        break;
      case "mariongauffroy":
        setCognitoId(arrayCognito["mariongauffroy"]);
        break;
      case "claracolleoni":
        setCognitoId(arrayCognito["claracolleoni"]);
        break;
      default:
        setCognitoId(null);
    }

    SetQueryStatus(query.get("status") ? query.get("status") : "all");
    SetQueryClient(query.get("client") ? query.get("client") : "all");
    SetQueryDisplay(
      query.get("display")
        ? query.get("display")
        : user.username === "florian@kronik.com" ||
          user.username === "christophe@kronik.com" ||
          user.username === "mathieu@kronik.com"
        ? "all"
        : "me"
    );
    SetQueryManager(manager);
  }, [
    location,
    // , videos
  ]);

  // useEffect(() =>{
  //   const manager = queryManager
  //     switch(manager) {
  //       case 'géraldinemillion' : setCognitoId(arrayCognito["géraldinemillion"]);
  //       break
  //       case 'maxencelebreux' : setCognitoId(arrayCognito["maxencelebreux"]);
  //       break
  //       case 'geoffroylasne' : setCognitoId(arrayCognito["geoffroylasne"]);
  //       break
  //       case 'mariongauffroy' : setCognitoId(arrayCognito["mariongauffroy"]);
  //       break
  //       case 'claracolleoni' : setCognitoId(arrayCognito["claracolleoni"]);
  //       break
  //       default : setCognitoId(null)
  //     }
  //   },[queryManager])

  useEffect(() => {
    if (cognitoId) {
      setIsLoading(true);
      fetchVideosByUser(dateSelected, cognitoId).then((response) => {
        // console.log("data2", response)
        // console.log("tasks by cognito", response);
        setData(response);

        setIsLoading(false);
        // console.log("cognito", cognitoId);
        // console.log("response cognito", response);
      });
    } else if (cognitoId === null) {
      setIsLoading(true);
      fetchVideos(dateSelected, queryDisplay).then((response) => {
        setData(response);
        // console.log("cognitoId", cognitoId)
        // console.log("queryDisplay", queryDisplay)
        // console.log("data1", response)
        setIsLoading(false);
      });
    }
  }, [queryDisplay, reloadData, cognitoId]);

  useEffect(() => {
    // setIsLoading(true);
    // console.log("useEffect data")
    const production =
      data &&
      data
        ?.filter(
          (item) =>
            // item?.cancel !== true &&
            // item?.newStatus?.value !== "delivered"
            item?.newStatus?.value === "production" ||
            item?.newStatus?.value === "interview" ||
            item?.newStatus?.value === "preProduction"
        )
        .sort(function (a, b) {
          return new Date(a.month) - new Date(b.month);
        });

    const validated =
      data &&
      data
        .filter(
          (item) =>
            item?.cancel !== true && item?.newStatus?.value === "approve"
        )
        .sort(function (a, b) {
          return new Date(a.month) - new Date(b.month);
        });

    const preparation =
      data &&
      data
        .filter(
          (item) =>
            item?.cancel !== true &&
            // item?.newStatus?.value !== "delivered"
            item?.newStatus?.value === "preparation" &&
            item?.newStatus?.value !== "validated"
        )
        .sort(function (a, b) {
          return new Date(a.month) - new Date(b.month);
        });

    const finished =
      data &&
      data
        .filter(
          (item) =>
            item?.cancel !== true && item?.newStatus?.value === "delivered"
        )
        .sort(function (a, b) {
          return new Date(a.month) - new Date(b.month);
        });

    setProduction(production);
    setValidated(validated);
    setDelivered(finished);
    setNotStarted(preparation);
    setPreparationQty(preparation.length);
    setProductionQty(production.length);
    setDeliveredQty(finished.length);
    setFilteredVideosQty(filteredVideos.length);

    setVideos(
      data &&
        data.filter(
          (video) =>
            queryClient === "all" ||
            video.client?.toLowerCase() === queryClient.toLowerCase()
          // &&
          // (
          //   video.accountManager.includes(queryDisplay.toLowerCase()) ||
          // queryDisplay === 'all') ||
          //  queryManager === "all"
        )
    );
  }, [data]);

  useEffect(() => {
    // console.log("useEffect 2")
    switch (true) {
      case queryStatus === "all":
        setFilteredVideos(
          videos &&
            videos.filter(
              (video) =>
                (video.cancel !== true && queryClient === "all") ||
                (video.cancel !== true &&
                  video.client?.toLowerCase() === queryClient?.toLowerCase())
              // &&
              // (video.accountManager.includes(queryManager?.toLowerCase()) ||
              // queryManager === "all")
            )
        );
        break;
      case queryStatus === "notstarted":
        setFilteredVideos(
          notStarted &&
            notStarted.filter(
              (video) => (video) =>
                (video.cancel !== true && queryClient === "all") ||
                (video.cancel !== true &&
                  video.client?.toLowerCase() === queryClient?.toLowerCase())
              // &&
              // (video.accountManager.includes(queryManager?.toLowerCase()) ||
              // queryManager === "all")
            )
        );
        // console.log("filteredVideos not started", filteredVideos)
        // console.log("filteredVideos client", queryClient)
        break;
      case queryStatus === "production":
        // console.log("queryStatus", queryStatus)
        // console.log("filteredVideos production", filteredVideos)
        setFilteredVideos(
          production &&
            production.filter(
              (video) => (video) =>
                (video.cancel !== true && queryClient === "all") ||
                (video.cancel !== true &&
                  video.client?.toLowerCase() === queryClient?.toLowerCase())
              // &&
              // (video.accountManager.includes(queryManager?.toLowerCase()) ||
              // queryManager === "all")
            )
        );
        break;
      case queryStatus === "validated":
        // console.log("queryStatus", queryStatus)
        // console.log("filteredVideos production", filteredVideos)
        setFilteredVideos(
          validated &&
            validated.filter(
              (video) => (video) =>
                (video.cancel !== true && queryClient === "all") ||
                (video.cancel !== true &&
                  video.client?.toLowerCase() === queryClient?.toLowerCase())
              // &&
              // (video.accountManager.includes(queryManager?.toLowerCase()) ||
              // queryManager === "all")
            )
        );
        break;
      case queryStatus === "delivered":
        setFilteredVideos(
          delivered &&
            delivered.filter(
              (video) => (video) =>
                (video.cancel !== true && queryClient === "all") ||
                (video.cancel !== true &&
                  video.client?.toLowerCase() === queryClient?.toLowerCase())
              // &&
              // (video.accountManager.includes(queryManager?.toLowerCase()) ||
              // queryManager === "all")
            )
        );
        // console.log("filteredVideos delivered", filteredVideos)
        break;
      default:
        setFilteredVideos(
          videos &&
            videos.filter(
              (video) =>
                (video.cancel !== true && queryClient === "all") ||
                (video.cancel !== true &&
                  video.client?.toLowerCase() === queryClient?.toLowerCase())
              // &&
              // (video.accountManager.includes(queryManager?.toLowerCase()) ||
              // queryManager === "all")
            )
        );
    }
  }, [
    isLoading,
    queryStatus,
    queryClient,
    cognitoId,
    // , queryManager
  ]);

  useEffect(() => {
    // console.log("setclient")
    // console.log("videos", videos)
    const clientsArray = videos && videos.map((item) => item.client);

    setClients(videos && [...new Set(clientsArray.sort())]);
  }, [videos]);

  return (
    <MainColumn>
      {/* <MiniHeader>
        <CardTitle title='Videos' isActive={true} />
      </MiniHeader> */}
      <ProductionHeader />
      <MainColumn>
        <Box px='20px'>
          <NavBar
            clients={clients}
            admin={admin}
            freelance={freelance}
            filteredVideos={filteredVideos}
            queryStatus={queryStatus}
            queryClient={queryClient}
            queryDisplay={queryDisplay}
            queryManager={queryManager}
          />

          {/* {!isLoading && (
            <VideosList
              videoStatus={videoStatus}
              filteredVideos={filteredVideos}
            />
          )} */}
          <Kanban
            videos={filteredVideos}
            admin={admin}
            setReloadData={setReloadData}
          />
        </Box>
      </MainColumn>

      {isLoading && <Updating />}
    </MainColumn>
  );
}

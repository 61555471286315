import React from "react";
import { API } from "aws-amplify";

export async function fetchTasks(projectId, videoId, admin) {
  let url = `/task/get/${projectId}/${videoId}?admin=${admin}`;

  return API.get("kronik", url, {});
}


export async function fetchJobs( videoId) {
  let url = `/job/list/${videoId}`;

  return API.get("kronik", url, {});
}




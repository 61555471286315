import React, { useState, useEffect } from "react";
import fetchProject from "./api";
import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import { Box, Button, Flex, useDisclosure } from "@chakra-ui/react";

import SideBar from "./SideBar";
import RightBar from "./RightBar";
import { Updating } from "../../components/notification/Updating";
import Main from "./Main";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";
import { HiPencilAlt } from "react-icons/hi";
import { EditProject } from "../../components/project/EditProject";
import MainColumn from "../../components/column/MainColumn";

export default function Project() {
  let { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { admin, freelance, client } = useUser();
  const [project, setProject] = useState("");
  const [videos, setVideos] = useState("");
  const [shooting, setShooting] = useState("");
  const [reloadData, setReloadData] = useState(false);

  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  // const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (admin || client) {
      setIsLoading(true);
      fetchProject(projectId, admin).then((response) => {
        setProject(response);
        console.log("project", response);
        setIsLoading(false);
      });
    }
  }, [reloadData]);

  useEffect(() => {
    setVideos(project && project.videos);
    setShooting(
      project &&
        project.videos.filter(
          (video) =>
            video.category &&
            video.category &&
            video.category.value === "shooting"
        )
    );
  }, [project]);
  return (
    <Box w='100%'>
      {(admin || client) && (
        <MainColumn>
          <MiniHeader>
            <CardTitle
              title={project && project.project.name}
              isActive='false'
            />

            {admin && !isLoading && (
              <Button
                aria-label='Edit'
                variant='outline'
                rounded='full'
                size='sm'
                onClick={() => onOpenEdit()}
                mx='10px'>
                <HiPencilAlt />
              </Button>
            )}
          </MiniHeader>
          <Flex justifyContent='space-between'>
            <SideBar
              projectName={project?.project?.name}
              clientName={project?.project?.client?.name}
              clientLink={`/clients/${project?.project?.client?._id}`}
              isLoading={isLoading}
              admin={admin}
              client={client}
              freelance={freelance}
              projectStatus={project?.project?.status?.value}
              clientLogo={project?.project?.client?.logo}
            />
            <Main
              project={project}
              videos={videos}
              reloadData={reloadData}
              setReloadData={setReloadData}
            />
            {(admin || client) && (
              <RightBar
                projectData={project.project}
                team={project.admins}
                projectName={project && project.project.name}
                projectId={projectId}
                reloadData={reloadData}
                setReloadData={setReloadData}
                admin={admin}
                isLoading={isLoading}
                clientId={project?.project?.client?._id}
              />
            )}
            {isLoading && <Updating />}
          </Flex>
          <EditProject
            isOpen={isOpenEdit}
            onClose={onCloseEdit}
            reloadData={reloadData}
            setReloadData={setReloadData}
            projectData={project?.project}
            client={project?.project?.client}
          />
        </MainColumn>
      )}
    </Box>
  );
}

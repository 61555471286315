import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  useColorMode,
  Button,
  Image,
  Flex,
  Grid,
} from "@chakra-ui/react";
import TableRow from "../../components/table/TableRow";
import { Updating } from "../../components/notification/Updating";
import { FaBullseye } from "react-icons/fa";
import { fetchClientsAll, fetchClientsMe } from "./api";

export default function ClientsList(props) {
  const history = useHistory();

  const {
    clients,
    isLoading,
    setIsLoading,
    setClients,
    reloadData,
    admin,
    queryDisplay,
    setClientList,
    queryClient,
    setFilteredClients,
    filteredClients,
  } = props;

  const { colorMode } = useColorMode();
  // console.log("clients", clients);

  useEffect(() => {
    setIsLoading(true);
    if (admin && queryDisplay === "all") {
      fetchClientsAll().then((response) => {
        setClients(response);
        setIsLoading(false);
        const clientsArray = response.map((item) => item?.name);
        // console.log("clients item", response);
        setClientList(response && [...new Set(clientsArray.sort())]);
      });
    } else {
      fetchClientsMe().then((response) => {
        // console.log("response me", response);
        setClients(response);
        setIsLoading(false);
      });
    }
  }, [reloadData, queryDisplay]);

  useEffect(() => {
    clients &&
      setFilteredClients(
        clients.filter(
          (item) =>
            queryClient === "all" ||
            item.name.toLowerCase() === queryClient.toLowerCase()
        )
      );
  }, [clients, queryClient]);

  function OpenClient(e, link) {
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  // console.log("clients test", clients);
  return (
    <Box>
      <Grid templateColumns='1fr 1fr 1fr 1fr ' gap='20px' mt='20px'>
        {filteredClients &&
          filteredClients
            .sort(function (a, b) {
              if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              }
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              }
              return 0;
            })
            .map((item, index) => {
              const Link = `/clients/${item._id}`;

              return (
                // <TableRow
                //   key={index}
                //   gridCol='4fr 4fr 1fr'
                //   click={(e) => OpenClient(e, Link)}>
                <>
                  <Flex
                    onClick={(e) => OpenClient(e, Link)}
                    justifyContent='space-between'
                    boxShadow='sm'
                    rounded='xl'
                    minH='180px'
                    m='5px'
                    p='20px'
                    direction='column'
                    _hover={{
                      cursor: "pointer",
                      bgColor:
                        colorMode === "light" ? "gray.50" : "whiteAlpha.100",
                      shadow: "lg",
                    }}
                    bg={colorMode === "light" ? "white" : "#232323"}>
                    <Flex alignItems='center'>
                      {item?.logo && (
                        <Image
                          mr='10px'
                          borderRadius='lg'
                          boxSize='42px'
                          alt={`Logo of {client?.name}`}
                          src={`https://aaliz317.twic.pics/${item?.logo}`}
                        />
                      )}
                      {item.name}
                    </Flex>
                    <Box>
                      {admin && item.projects && (
                        <Badge
                          mt='5px'
                          fontSize='xs'
                          variant='solid'
                          borderRadius='full'
                          px='10px'
                          py='5px'
                          bgColor='whiteAlpha.100'>
                          <Box>
                            {admin && ` ${item.projects?.length} quotes`}
                          </Box>
                        </Badge>
                      )}
                    </Box>

                    {/* {admin && <Box>Edit</Box>} */}
                    {/* </TableRow> */}
                  </Flex>
                </>
              );
            })}
        {isLoading && <Updating />}
      </Grid>
    </Box>
  );
}

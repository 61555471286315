import { Flex } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import CardTitle from "../card/CardTitle";
import { MiniHeader } from "./MiniHeader";

export default function Taskheader(props) {
  const {
    queryStatus,
    queryClient,
    queryDisplay,
    queryManager,
    queryCategory,
    queryPeople,
    queryList,
  } = props;
  const { admin } = useUser();
  const location = useLocation();

  // console.log("location", location.pathname);
  return (
    <MiniHeader>
      <Flex gap='20px'>
        {admin && (
          <Link
            //   as={NavLink}
            to='/shooting'
            // activeClassName='selected'
            _hover={{
              textDecor: "none",
            }}>
            <CardTitle
              title='Shooting'
              isActive={location.pathname === "/shooting"}
            />
          </Link>
        )}

        {/* {admin && ( */}
        <Link
          //   as={NavLink}
          to={`/tasks?list=todo&status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}&category=${queryCategory}&people=${queryPeople}`}
          // activeClassName='selected'
          _hover={{
            textDecor: "none",
          }}>
          <CardTitle
            title='Todo'
            isActive={
              location.pathname === "/tasks" &&
              location.search.search("list=todo") > 0
            }
          />
        </Link>
        {/* )} */}
        {/* {admin && ( */}
        <Link
          //   as={NavLink}
          to={`/tasks?list=watchlist&status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}&category=${queryCategory}&people=${queryPeople}`}
          // activeClassName='selected'
          _hover={{
            textDecor: "none",
          }}>
          <CardTitle
            title='Validation'
            isActive={
              location.pathname === "/tasks" &&
              location.search.search("list=watchlist") > 0
            }
          />
        </Link>
        {/* )} */}
      </Flex>
    </MiniHeader>
  );
}

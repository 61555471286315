import { Box, Button, Flex, FormLabel, Grid, Text, useColorMode } from "@chakra-ui/react"
import ButtonIcon from "../../components/button/ButtonIcon"
import ButtonIconV2 from "../../components/button/ButtonIconV2"

export function SelectNumber(props) {

    const {arrayOptions, category, state,dispatch} = props

    const {colorMode} = useColorMode()
    // console.log("arrayOptions", arrayOptions)
    // console.log("category", category)
    // console.log("arrayOptionscategory", arrayOptions)
    // console.log("state", state && state[category])


    return (
  
    <Box mt="40px">
        <FormLabel>{category}</FormLabel>
                          
            {arrayOptions.map((item, index) => {
                // console.log("item", item)
                    const step = item.step ? item.step : 1

                    const stateInclude = state[category]?.find(it => it.name === item.name)?.include 
                    const stateQty = state[category]?.find(it => it.name === item.name)?.qty 
                function newState() {
                    if ( state[category]?.find(it => it.name === item.name)?.include ) {
                        return state[category]

                    }
                    else if(
                        state[category].filter(it => it.name === item.name).length === 1)  {
                            
                        const newArray = state[category].filter(it => it.name !== item.name)
                        
                        item.qty = stateInclude > step ? stateInclude : step
                        item.include = stateInclude
                        return newArray
                    } else {             
                        console.log("2",stateInclude )                             
                        // item.include = stateInclude
                        // item.qty = stateInclude > step ? stateInclude : step
                        return  (
                            // console.log("new stateitem", [...state[category], item]),
                        [...state[category], item])
                    }
                } 

                function addQty() {
                    
                        const newArray = state[category].filter(it => it.name !== item.name)
                        if( (state[category] &&  state[category].filter(it => it.name === item.name).length > 0 )) {

                            item.qty =  stateQty + step
                            item.include = stateInclude
                            console.log("include +",    )
                            console.log("qty", stateQty)
                            
                        } else {
                            item.qty = step
                            item.include = stateInclude
                            

                        }
                        return [...newArray, item]
                    
                } 
                function removeQty() {
                    
                const newArray = state[category].filter(it => it.name !== item.name)
                if (!stateInclude){
                    item.qty =  item.qty > 0 ? stateQty - step : item.qty
                    item.include = stateInclude
                    console.log("stateInclude", stateInclude   )
                    console.log("qty", stateQty)
                    return [...newArray, item]
                }
                else if(stateInclude && stateQty >= stateInclude) {
                    item.qty = item.qty > stateInclude ? stateQty-step : stateInclude
                    item.include = stateInclude
                    console.log("item", item)
                    console.log("stateInclude -", stateInclude)
                    console.log("stateQty -", stateQty)


                    return [...newArray, item]
                } else {
                    
                        const newArray = state[category].filter(it => it.name !== item.name)
                        // console.log("initalqty -2", initalqty)
                        console.log("item", item)
                        console.log("stateQty (remove)", stateQty)
                        console.log("stateInclude (remove)", stateInclude)
                        return newArray

                }
            
        } 
                return (
                    <Box    
                        // display="inline-grid"
                        paddingX="10px"
                        paddingY="10px"
                        marginY="10px"
                        rounded="xl"
                        boxShadow="sm"
                        // width="120px"
                        bg={
                            colorMode === "light"
                            ? "white"
                            : "blackAlpha.400"
                        }
                        _hover={{
                            bgColor:
                            colorMode === "light"
                                ? "gray.50"
                                : "blackAlpha.600",
                            shadow: "lg",
                        }}
                    >
                         <Grid templateColumns="1fr 2fr 1fr 1fr" gap="20px" >

                            <ButtonIconV2
                            title={item.name}
                            // variant="outline"
                            click={() =>{
                                    dispatch({
                                        type:"field",
                                        payload:{
                                            fieldName:category,
                                            value: newState()
                                        }
                                        })
                                    }
                                }
                                checked={state[category] && state[category].filter(it => it.name === item.name).length > 0 ? true : false}
                                // icon={item.icon}
                            />
                            <Flex alignItems="center">
                                <Button onClick={() =>{
                                        dispatch({
                                            type:"field",
                                            payload:{
                                                fieldName:category,
                                                value: removeQty()
                                            }
                                        })
                                    }
                                }
                            
                                >
                                    -
                                </Button>
                                <Text>{state[category] &&  state[category].filter(it => it.name === item.name).length > 0 ? state[category].filter(it => it.name === item.name)[0].qty : 0}</Text>
                                <Button onClick={() =>{
                                        dispatch({
                                            type:"field",
                                            payload:{
                                                fieldName:category,
                                                value: addQty()
                                            }
                                            })
                                        }
                                    }
                                
                                >
                                    +
                                </Button>
                            </Flex>
                            <Flex>
                                <Text>{item.price}€</Text>
                            </Flex>
                            <Flex>
                                <Text>{item.qty * item.price}€</Text>
                            </Flex>
                        </Grid>
                        
                    </Box>
                )

                }
                
                )}
                                    
            </Box>
            
    )
}
export const roleOptions = [
  { value: "administrator", label: "Administrateur" },
  { value: "accountManager", label: "Account manager" },
  { value: "client", label: "Client" },
  { value: "clientProjectManager", label: "Client Project Manager" },
  { value: "interviewer", label: "Interviewer" },
  { value: "storyteller", label: "Storyteller" },
  { value: "cadreur", label: "Cadreur" },
  { value: "monteur", label: "Monteur" },
  { value: "motionDesigner", label: "Motion designer" },
];

export const displayRole = (people) => {
  const role =
    people.videos && people.videos.length > 0
      ? people.videos[0].role
      : people.role;
  switch (role) {
    case "owner":
      return "kronik";
    case "administrator":
      return "kronik";
    case "accountManager":
      return "Project manager";
    case "client":
      return "Client";
    case "interviewer":
      return "Interviewer";
    case "storyteller":
      return "Storyteller";
    case "cadreur":
      return "Cameraman";
    case "monteur":
      return "Filmmaker";
    case "motionDesigner":
      return "Motion Designer";
      case "freelance":
        return "kroniker";
    default:
      return "NC";
  }
};

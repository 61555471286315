import React, { useState } from "react";
import { API } from "aws-amplify";
import { getHours } from "date-fns";
import dayjs from "dayjs";
import "dayjs/locale/fr";

export default async function fetchVideos(dateSelected, display) {
  // console.log("dateSelected", dateSelected);
  // console.log("display", display);
  //   if (dateSelected && dateSelected.lenght > 0) {

  return API.get(
    "kronik",
    `/video/get/videos/?year=${dateSelected}&display=${display}`
  ).then((response) => {
    // console.log("videos avec tout", response);
    const videosArray = response
      .map((item) => {
        const array = [];
        item.admins &&
          item.admins.map((admin) => {
            if (admin.role === "accountManager") {
              array.push(
                (admin.given_name + admin.family_name)
                  .toString()
                  .toLocaleLowerCase()
              );
            }
            return array;
          });

        return {
          title: item.title,
          organization: item.clientName,
          accountManager: array,
          date: dayjs(item.dueDate).format("MMMM YYYY"),
          dateJs: item.dueDate,
          dueDate: item.dueDate,
          month: dayjs(item.dueDate).format("MMMM YYYY"),
          project: item.projectName,
          projectId: item.project,
          client: item.clientName,
          clientLogo: item.clientLogo,
          id: item._id,
          brief: item.brief,
          status: item.status,
          newStatus: item.newStatus,
          category: item.category,
          format: item.format,
          subtitle: item.subtitle,
          duration: item.duration,
          people: item.people,
          places: item.places,
          pricing: item.pricing,
          cancel: item.cancel,
          monthRevenue: item.month ? item.month : null,
          totalJobs: item.totalJobs,
        };
      })
      .filter(
        (item) =>
          item.monthRevenue !== null &&
          (item?.newStatus?.value === "delivered" ||
            item?.newStatus?.value === "approve" ||
            item?.newStatus?.value === "production")
      )
      .sort(function (a, b) {
        return new Date(b.monthRevenue) - new Date(a.monthRevenue);
      });
    return videosArray;
  });
}

import React, { useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import CardTitle from "../../components/card/CardTitle";
import OrderSummary from "./OrderSummary";
import RightColumn from "../../components/column/RightColumn";
import { MiniHeader } from "../../components/header/MiniHeader";

export default function RightBar({
  jobData,
  freelanceId,
  setReloadData,
  admin,
  isLoading,
}) {
  const [active, setActive] = useState("Order");

  const selectDisplay = (title) => {
    setActive(title);
  };

  return (
    <RightColumn>
      <Flex mx='20px' pt='20px'>
        <CardTitle title='Order Summary' isActive={active === "Order"} />
      </Flex>

      {/* <Box
          onClick={() => selectDisplay("Material")}
          cursor={active === "Material" ? "unset" : "pointer"}
        >
          <CardTitle title="Material" isActive={active === "Material"} />
        </Box> */}

      {active === "Order" && (
        <OrderSummary
          jobData={jobData}
          admin={admin}
          freelanceId={freelanceId}
          setReloadData={setReloadData}
          isLoading={isLoading}
        />
      )}
      {/* {active === "Material" && (
        <Material
        freelanceData={freelanceData}
        admin={admin}
        freelanceId={freelanceId}
        setReloadData={setReloadData}
        isLoading={isLoading}
        />
      )} */}
    </RightColumn>
  );
}

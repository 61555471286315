import React, { useEffect, useState, useRef } from "react";
import videojs from "@mux/videojs-kit";
import "@mux/videojs-kit/dist/index.css";
import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Img,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import ButtonColor from "../../components/button/ButtonColor";
// import {fetchComments, postCommment } from "./api";
import "@videojs/themes/dist/sea/index.css";
import { TimeIcon } from "@chakra-ui/icons";

import { format } from "date-fns";

export function VideoPlayer({
  muxPlaybackId,
  muxId,
  clientId,
  fileId,
  setComments,
  reloadComments,
  setReloadComments,
  isLoading,
  setIsLoading,
  timecode,
  setTimecode,
  currentFile,
}) {
  const [currentTime, setCurrentTime] = useState(null);
  const [comment, setComment] = useState("");
  const [player, setPlayer] = useState("");
  const [previousThumbnail, setPreviousThumbnail] = useState("");
  const [currentThumbnail, setCurrentThumbnail] = useState("");
  const [nextThumbnail, setNextThumbnail] = useState("");
  const [breakPoint, setBreakPoint] = useState("");
  const [isChecked, setIsCheked] = useState(true);

  const { user, admin } = useUser();
  let { projectId, videoId, taskId } = useParams();

  const videoPlayerRef = useRef(null); // Instead of ID

  // useEffect(() => {
  //   // console.log("isLoading status", isLoading)
  //   // console.log("setIsLoading", true)
  //   // setIsLoading(true)
  //   fetchComments(projectId, fileId).then(
  //     (response) =>
  //   setComments(response)

  //   // console.log("setIsLoading", false)
  //   ).then( () => setIsLoading(false),)

  // },[reloadComments, currentFile])

  useEffect(() => {
    if (videoPlayerRef) {
      const player = videojs(
        videoPlayerRef.current,
        {
          userActions: {
            hotkeys: true,
          },
          timelineHoverPreviews: true,
          plugins: {
            mux: {
              debug: false,
              data: {
                env_key: "v8suvj8uf38aqv7fqqht07t0b", // pass your own personal key in an `.env` file
                video_title: "Example Title",
              },
            },
          },
        },
        () => {
          player.src({
            // this is the public playback ID without the 'https://stream.mux.com/' prefix
            src: muxPlaybackId,
            type: "video/mux",
          });

          player.on("timeupdate", () => {
            setCurrentTime(player.currentTime());
          });
        }
      );
      setPlayer(player);
    }

    return () => {};
  }, [muxPlaybackId]);

  useEffect(() => {
    if (player) {
      player.currentTime(timecode);
      // player.play()
      // setTimecode("")
      // player.controlBar.progressControl.show();
    }
  }, [timecode]);

  function addComment(value) {
    setComment(value);
    player.pause();
    // player.currentTime(4);
  }

  // useEffect(() => {

  //   setPreviousThumbnail(`https://image.mux.com/${muxPlaybackId}/thumbnail.jpg?time=${parseInt(currentTime)-1}`)
  //   setCurrentThumbnail(`https://image.mux.com/${muxPlaybackId}/thumbnail.jpg?time=${parseInt(currentTime)}`)
  //   setNextThumbnail(`https://image.mux.com/${muxPlaybackId}/thumbnail.jpg?time=${parseInt(currentTime)+1}`)

  // },[comment, currentTime])

  // function createComment() {
  //   // console.log("newComment",comment)
  //   setComment("")
  //   setIsLoading(true)
  //    postCommment(
  //     isChecked && currentTime ? `https://image.mux.com/${muxPlaybackId}/thumbnail.jpg?time=${parseInt(currentTime)}` : null,
  //      isChecked ? currentTime : null,
  //      comment,
  //      null,
  //      null,
  //      null,
  //      muxId,
  //      muxPlaybackId,
  //      fileId,
  //      taskId,
  //      videoId,
  //      projectId,
  //      clientId,).then((response) => setReloadComments(Math.random())
  //      ).then((response) =>    setIsLoading(false))
  // }

  const formatted = format(
    parseFloat(currentTime ? currentTime : 0) * 1000,
    "mm:ss"
  );

  return (
    <Box className='App'>
      {/* {muxPlaybackId && <div>{muxPlaybackId}</div>} */}
      {muxPlaybackId && (
        <video
          ref={videoPlayerRef}
          className='video-js vjs-16-9 vjs-big-play-centered '
          controls
          preload='auto'
          width='100%'></video>
      )}

      {/* <Flex>
          <Img   boxSize='100px' objectFit='cover' src={previousThumbnail} onClick={() => setCurrentTime(currentTime-1)}/>
          <Img   boxSize='100px' objectFit='cover' src={currentThumbnail}/>
          <Img   boxSize='100px' objectFit='cover' src={nextThumbnail} onClick={()=>setCurrentTime(currentTime+1)}/>
        </Flex> */}
      {/* <Textarea mt="20px" placeholder='Write your comment here...' value={comment} onFocus={() =>  player.pause()} onChange={(e) => addComment (e.target.value)}  /> */}
      {/* <Flex textAlign="center" mt="20px">
          
                        {currentTime > 0 && 
           <Flex textAlign="center" ml="10px"> 
                <Flex pl="10px" textAlign="center">
                  <Checkbox   colorScheme="gray" isChecked={isChecked} onChange={e => setIsCheked(!isChecked)}></Checkbox>
                </Flex>
                <Flex  ml="15px">
                       <HStack fontSize="sm"  color="gray.600" >
                         <TimeIcon/> <Text ml="0"> {formatted && formatted}</Text>
                         </HStack>
                </Flex>
              </Flex>
                        }
          </Flex> */}
    </Box>
  );
}

import { Box, Flex, Grid, Heading, Text, useColorMode } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { getFreelancetasks } from "./api";
import { TaskRow } from "./TaskRow";
import "dayjs/locale/fr";
import fetchFreelanceJobs from "../jobs/api";
import Card from "../../components/card";
import { Updating } from "../../components/notification/Updating";
export function FreelanceDashboard() {
  dayjs.locale("fr");
  const { colorMode } = useColorMode();
  const [tasks, setTasks] = useState("");
  const [lateTasks, setLateTasks] = useState("");
  const [currentTasks, setCurrentTasks] = useState("");
  const [futurTasks, setFuturTasks] = useState("");
  const [jobs, setJobs] = useState("");
  const [totalJobs, setTotalJobs] = useState("");
  const [totalPricing, setTotalPricing] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function week(dateItem) {
    switch (dayjs(dateItem).format("DD MMMM YYYY")) {
      case dayjs(new Date()).subtract(1, "day").format("DD MMMM YYYY"):
        return "Yesterday";
      case dayjs().format("DD MMMM YYYY"):
        return "Today";
      case dayjs().add(1, "day").format("DD MMMM YYYY"):
        return "Tomorrow";

      default:
        return dayjs(dateItem).format("DD MMMM YYYY");
    }
  }

  function groupBy(objectArray, property) {
    return (
      objectArray &&
      objectArray.reduce((acc, obj) => {
        const key = obj[property];

        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {})
    );
  }

  const groupByDate = groupBy(currentTasks, "dateJs");

  useEffect(() => {
    setIsLoading(true);
    getFreelancetasks().then((response) => {
      setIsLoading(false);
      setTasks(response);
      // setLateTasks(response.filter((task) =>  dayjs(task.dateJs) < dayjs().startOf("day")))
      setLateTasks(
        response.filter((task) => task.validation?.value === "validation")
      );
      //   setCurrentTasks(
      //     response.filter(
      //       (task) =>
      //         dayjs(task.dateJs) >= dayjs().startOf("day") &&
      //         dayjs(task.dateJs) < dayjs().add(15, "day").startOf("day")
      //     )
      //   );
      setCurrentTasks(
        response.filter((task) => task.validation?.value !== "validation")
      );
      setFuturTasks(
        response.filter(
          (task) => dayjs(task.dateJs) > dayjs().add(15, "day").startOf("day")
        )
      );
      // console.log("response", response)
      // console.log("Now", dayjs(new Date()).format("DD/MM/YYYY"))
      // console.log("late task", response.filter((task) => dayjs(task.dateJs).format("YYYY/MM/DD") < dayjs(new Date()).format("YYYY/MM/DD")))
      // console.log("today", dayjs().format("DD MMMM YYYY"))
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchFreelanceJobs().then((response) => {
      setIsLoading(false);
      const pricingArray =
        response &&
        response.jobs
          ?.filter((job) => job.status?.value !== "refused")
          .map((job) => {
            // console.log("jobs", response.jobs)
            return job.pricing;
          });
      setJobs(response?.jobs ? response.jobs : []);
      setTotalJobs(
        response?.jobs
          ? response?.jobs?.filter((job) => job.status?.value !== "refused")
              .length
          : 0
      );
      setTotalPricing(
        response?.jobs
          ? pricingArray.reduce((previousValue, currentValue) => {
              return previousValue + currentValue;
            })
          : 0
      );
    });
  }, []);

  return (
    <>
      <Box
        as='main'
        py={{ base: "80px", sm: "0px" }}
        // px={{ base: "20px", sm: "40px" }}
      >
        <Grid templateColumns='1fr 1fr ' gap='20px' mt='10px' mb='40px'>
          {jobs && (
            <>
              <Card>
                <Flex
                  justifyContent='center'
                  alignItems='center'
                  flexDirection='column'
                  my='40px'>
                  <Heading size='3xl' mb='20px'>
                    {totalJobs ? totalJobs : 0}
                  </Heading>
                  <Text color={colorMode === "light" ? "gray.500" : "white"}>
                    Total jobs{" "}
                  </Text>
                </Flex>
              </Card>
              <Card>
                <Flex
                  justifyContent='center'
                  alignItems='center'
                  flexDirection='column'
                  my='40px'>
                  <Heading size='3xl' mb='20px'>
                    {" "}
                    {totalPricing ? totalPricing + " €" : ""}
                  </Heading>
                  <Text color={colorMode === "light" ? "gray.500" : "white"}>
                    Total invoiced{" "}
                  </Text>
                </Flex>
              </Card>
            </>
          )}
        </Grid>

        {currentTasks && (
          <Heading
            as='h2'
            fontSize='2xl'
            mb='2'
            textTransform='capitalize'
            pb='10px'>
            My tasks
          </Heading>
        )}
        {currentTasks &&
          Object.keys(groupByDate).map((item, index) => {
            const tasksFilter = currentTasks.filter(
              (task) => task.dateJs === item
            );
            return (
              <>
                <Heading
                  as='h2'
                  fontSize='lg'
                  mb='2'
                  textTransform='capitalize'>
                  {week(item)}
                </Heading>

                <TaskRow tasksList={tasksFilter} title='my tasks' key={index} />
              </>
            );
          })}

        {lateTasks && (
          <Box mb='0px'>
            <Heading as='h2' fontSize='2xl' mb='2' textTransform='capitalize'>
              Tasks in Validation
            </Heading>
            <TaskRow tasksList={lateTasks} />
          </Box>
        )}
        {/* {futurTasks && (
          <Box mb='40px'>
            <Heading as='h2' fontSize='xl' mb='2' textTransform='capitalize'>
              Futur tasks
            </Heading>
            <TaskRow tasksList={futurTasks} />
          </Box>
        )} */}
        {/* {tasks && tasks.map((task, index) => {

                return (
                    <>
                    <Box key={index}>{task.title}</Box>
                    </>
                )
            })} */}
      </Box>
      {isLoading && <Updating />}
    </>
  );
}

import { Box, Button, Flex } from "@chakra-ui/react";
import Axios from "axios";

import { useEffect, useState } from "react";
import { BsController } from "react-icons/bs";
import ButtonIcon from "../button/ButtonIcon";

export default function Downloader({ files = [], remove }) {
  // console.log("files", files);
  return (
    <Flex
      direction='column'
      p='20px'
      bg='#232323'
      minH='60px'
      maxH='300px'
      borderRadius='lg'
      width='300px'
      color='white'
      position='fixed'
      overflow='scroll'
      bottom='20px'
      left='auto'
      right='24px'
      display='block'
      zIndex={10000}>
      Download files
      {files &&
        files.map((file, index) => (
          <DownnloadItem
            key={file.id}
            file={file}
            downloadName={file.originalName}
            index={index}
            removeFile={() => remove(file.id)}
            {...file}
          />
        ))}
    </Flex>
  );
}

const DownnloadItem = ({ file, index, removeFile }) => {
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });

  useEffect(() => {
    const controller = new AbortController();

    // let isCancelled = false;

    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };
    // if(!isCancelled){
    Axios.get(file.url, {
      responseType: "blob",
      ...options,
      signal: controller.signal,
    }).then(function (response) {
      // console.log(response);

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"],
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file?.originalName}`);
      document.body.appendChild(link);
      // link.saveAs = false;
      link.click();

      setDownloadInfo((info) => ({
        ...info,
        completed: true,
      }));
      removeFile();

      //   if(!isCancelled){
      //   removeFile();
      //   }
      //   timmy = setTimeout(() => {
      //     removeFile();
      //   }, 4000);

      //    return () => {
      //    clearTimeout(timmy)
      // };
    });
    // }

    return () => {
      // isCancelled = true;
      controller.abort();
    };
  }, []);

  const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;

  return (
    <Box key={index}>
      {downloadInfo.loaded > 0 && (
        <>
          <span className='text-success'>
            {formatBytes(downloadInfo.loaded)}
          </span>
          / {formatBytes(downloadInfo.total)}
        </>
      )}
      {downloadInfo.loaded === 0 && <>Initializing...</>}
      {/* File-{index + 1}{" "} */}
      {file?.originalName?.toString().substr(0, 7)}...
      <Button size='xs' onClick={() => removeFile()}>
        x
      </Button>
      {/* {file} */}
    </Box>
  );
};

import { Box, Flex, Grid, Heading, Text, useColorMode } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import fetchVideos, { fetchTasks, getFreelancetasks } from "./api";
import { TaskRow } from "./TaskRow";
import "dayjs/locale/fr";
import fetchFreelanceJobs from "../jobs/api";
import Card from "../../components/card";
import { Updating } from "../../components/notification/Updating";
import { useHistory } from "react-router-dom";
export function ClientDashboard() {
  dayjs.locale("fr");
  const { colorMode } = useColorMode();
  const [tasks, setTasks] = useState("");
  const [lateTasks, setLateTasks] = useState("");
  const [currentTasks, setCurrentTasks] = useState("");
  const [futurTasks, setFuturTasks] = useState("");
  const [validationTasks, setValidationTasks] = useState("");
  const [shootingTasks, setShootingTasks] = useState("");
  const [jobs, setJobs] = useState("");
  const [totalJobs, setTotalJobs] = useState("");
  const [totalPricing, setTotalPricing] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dateSelected, setDateSelected] = useState("all");
  const [display, setDisplay] = useState("");
  const [videos, setVideos] = useState("");
  const [notStartedQty, setNotStartedQty] = useState("");
  const [productionQty, setProductionQty] = useState(0);
  const [deliveredQty, setDeliveredQty] = useState(0);
  const history = useHistory();

  function cardLink(e, link) {
    // console.log("metaKey", e.metaKey);
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  useEffect(() => {
    setIsLoading(true);
    fetchTasks(false).then((response) => {
      setIsLoading(false);
      setTasks(response);
      setValidationTasks(
        response.filter((task) => task?.validation?.value === "validation")
      );
      setShootingTasks(
        response.filter(
          (task) =>
            task?.category?.value === "shooting" &&
            task?.status?.value !== "done" &&
            task?.status?.value !== "blocked"
        )
      );
      setLateTasks(
        response.filter((task) => dayjs(task.dateJs) < dayjs().startOf("day"))
      );
      setCurrentTasks(
        response.filter(
          (task) =>
            dayjs(task.dateJs) >= dayjs().startOf("day") &&
            dayjs(task.dateJs) < dayjs().add(15, "day").startOf("day") &&
            task?.validation?.value === "validation"
        )
      );
      setFuturTasks(
        response.filter(
          (task) =>
            dayjs(task.dateJs) > dayjs().add(15, "day").startOf("day") &&
            task?.validation?.value === "validation"
        )
      );
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchVideos(dateSelected, display).then((response) => {
      const production = response
        .filter(
          (item) =>
            item?.cancel !== true &&
            (item?.newStatus?.value === "production" ||
              item?.newStatus?.value === "interview" ||
              item?.newStatus?.value === "preProduction" ||
              item?.newStatus?.value === "approve")
        )
        .sort(function (a, b) {
          return new Date(a.month) - new Date(b.month);
        });

      const preparation = response
        .filter(
          (item) =>
            item?.cancel !== true &&
            item?.newStatus?.value === "preparation" &&
            item?.newStatus?.value !== "validated"
        )
        .sort(function (a, b) {
          return new Date(a.month) - new Date(b.month);
        });

      const delivered = response
        .filter(
          (item) =>
            item?.cancel !== true &&
            item?.newStatus?.value === "delivered" &&
            item?.newStatus?.value !== "finished"
        )
        .sort(function (a, b) {
          return new Date(a.month) - new Date(b.month);
        });

      setVideos(production);
      setNotStartedQty(preparation.length);
      setProductionQty(production.length);
      setDeliveredQty(delivered.length);
      setIsLoading(false);
    });
  }, [display]);

  return (
    <>
      <Box
        as='main'
        py={{ base: "80px", sm: "0px" }}
        // px={{ base: "20px", sm: "40px" }}
      >
        <Grid templateColumns='1fr 1fr 1fr ' gap='20px' mt='10px' mb='40px'>
          {videos && (
            <>
              <Card p='0px'>
                <Box
                  p='20px'
                  onClick={(e) => cardLink(e, "/projects?status=notstarted")}
                  _hover={{
                    rounded: "xl",
                    cursor: "pointer",
                    bgColor:
                      colorMode === "light" ? "gray.50" : "blackAlpha.600",
                    shadow: "lg",
                  }}>
                  <Flex
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                    my='40px'>
                    <Heading size='xl' mb='20px'>
                      {notStartedQty
                        ? notStartedQty + " projects"
                        : "0 project"}
                    </Heading>
                    <Text color={colorMode === "light" ? "gray.500" : "white"}>
                      Not started{" "}
                    </Text>
                  </Flex>
                </Box>
              </Card>
              <Card p='0px'>
                <Box
                  p='20px'
                  onClick={(e) => cardLink(e, "/projects?status=production")}
                  _hover={{
                    rounded: "xl",
                    cursor: "pointer",
                    bgColor:
                      colorMode === "light" ? "gray.50" : "blackAlpha.600",
                    shadow: "lg",
                  }}>
                  <Flex
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                    my='40px'>
                    <Heading size='xl' mb='20px'>
                      {" "}
                      {productionQty
                        ? productionQty + " projects"
                        : "0 project"}
                    </Heading>
                    <Text color={colorMode === "light" ? "gray.500" : "white"}>
                      In production{" "}
                    </Text>
                  </Flex>
                </Box>
              </Card>
              <Card p='0px'>
                <Box
                  p='20px'
                  onClick={(e) => cardLink(e, "/projects?status=delivered")}
                  _hover={{
                    rounded: "xl",
                    cursor: "pointer",
                    bgColor:
                      colorMode === "light" ? "gray.50" : "blackAlpha.600",
                    shadow: "lg",
                  }}>
                  <Flex
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                    my='40px'>
                    <Heading size='xl' mb='20px'>
                      {" "}
                      {deliveredQty ? deliveredQty + " projects" : "0 project"}
                    </Heading>
                    <Text color={colorMode === "light" ? "gray.500" : "white"}>
                      Delivered{" "}
                    </Text>
                  </Flex>
                </Box>
              </Card>
            </>
          )}
        </Grid>
        {validationTasks && (
          <Box mb='40px'>
            <Heading as='h2' fontSize='xl' mb='2'>
              To validate
            </Heading>
            <TaskRow tasksList={validationTasks} />
          </Box>
        )}

        {shootingTasks && (
          <Box mb='0px'>
            <Heading as='h2' fontSize='xl' mb='2'>
              Next shooting
            </Heading>
            <TaskRow tasksList={shootingTasks} />
          </Box>
        )}
      </Box>
      {isLoading && <Updating />}
    </>
  );
}

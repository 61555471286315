export const initialState = {
    _id:"",
    email: "",
    projectId:"",
    clientId:"",
    clientName:"",
    video:"",
    videoId:"",
    status: { label: "", value: "" },
    title:"",
    brief:"",
    pricing:0,
    ipAdress:"",
    note: "",
    category: "",
    rating:"",
    deliverables:"",
    paymentStatus:{ label: "", value: "" },
    internalFeedback:"",
    clientFeedback:"",
    name:"",
    videoName:"",
    sendEmail:true,

    // freelanceId:"",
    // dateStamp:"",
    // reviews:"",
    isLoadingOne: false,
    isLoadingTwo: false,
    error: "",
    role: { value: "freelance", label: "Freelance" },
  };
  
  export function reducer(state, action) {
    switch (action.type) {
      case "previousData": {
        return {
          ...state,
          _id:action.payload._id,
          email: action.payload.email,
          projectId:action.payload.projectId,
          clientId:action.payload.clientId,
          clientName:action.payload.clientName,
          video:action.payload.video,
          videoId:action.payload.videoId,
          status: action.payload.status,
          title:action.payload.title,
          brief:action.payload.brief,
          pricing:action.payload.pricing,
          ipAdress:action.payload.ipAdress,
          note: action.payload.note,
          category: action.payload.category,
          rating:action.payload.rating,
          deliverables:"",
          paymentStatus:action.payload.paymentStatus,
          internalFeedback:"",
          clientFeedback:action.payload.clientFeedback,
          name:action.payload.name,
          videoName:action.payload.videoName,
          sendEmail:action.payload.sendEmail,
        };
      }
      case "field": {
        return {
          ...state,
          [action.payload.fieldName]: action.payload.value,
        };
      }
      case "save": {
        return {
          ...state,
          isLoadingOne: true,
        };
      }
      case "delete": {
        return {
          ...state,
          isLoadingTwo: true,
        };
      }
      case "error": {
        return { ...state, error: "there is an error" };
      }
      case "success": {
        return initialState;
      }
  
      default:
        return state;
    }
  }
  
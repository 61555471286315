import { API } from "aws-amplify";

export default function getFiles(
  display,
  limit = 40,
  skip = 1,
  admin,
  clientId,
  category
) {
  const filterCategory = category === "all" ? "" : `&category=${category}`;
  // const url = `/file/get/files?display=${display}&limit=${limit}&skip=${skip}&category=${category}`;
  const url = `/file/get/files?display=${display}&limit=${limit}&skip=${skip}${filterCategory}`;
  // const clientUrl = `/file/get/files?display=${display}&limit=${limit}&skip=${skip}&client=${clientId}&category=${category}`;
  const clientUrl = `/file/get/files?display=${display}&limit=${limit}&skip=${skip}&client=${clientId}${filterCategory}`;

  if (clientId.length > 0 && clientId !== "all") {
    console.log("useEffect client id", clientUrl);
    return API.get("kronik", clientUrl, {});
  } else {
    console.log("useEffect client all", url);
    return API.get("kronik", url, {});
  }
}

export async function getClients() {
  try {
    return await API.get("kronik", "/client/all", {});
    // setClients(data);
  } catch (e) {
    console.log(e.message);
  }
}

export async function getClientsMe() {
  // console.log("fetchClientsMe");
  return API.get("kronik", "/client/get/list", {});
}

import { AiFillVideoCamera, AiFillCheckCircle } from "react-icons/ai";
import { BsFillInboxFill } from "react-icons/bs";

import { FaCut } from "react-icons/fa";
import { MdMovieFilter } from "react-icons/md";
import { GiScooter } from "react-icons/gi";

export const statusOptions = [
  { value: "warmUp", label: "Préparation" },
  { value: "interview", label: "Interview" },
  { value: "preProduction", label: "Prémontage" },
  { value: "production", label: "Montage" },
  { value: "approval", label: "Validé" },
  { value: "broadcast", label: "Diffusé" },
];

export const newStatusOptions = [
  {
    value: "preparation",
    label: "Préparation",
  },
  {
    value: "interview",
    label: "Shooting",
  },
  {
    value: "preProduction",
    label: "Prémontage",
  },
  {
    value: "production",
    label: "Montage",
  },
  { value: "approve", label: "Validé" },
  {
    value: "delivered",
    label: "Délivré",
  },
];

export const newStatusOptionsWithIcon = [
  {
    value: "preparation",
    label: "Préparation",
    icon: "🕔",
    // <BsFillInboxFill size={32} />
  },
  {
    value: "interview",
    label: "Shooting",
    icon:
      // <AiFillVideoCamera size={32} />
      "🚧",
  },
  {
    value: "preProduction",
    label: "Prémontage",
    icon:
      // <FaCut size={32} />
      "🚧",
  },
  {
    value: "production",
    label: "Montage",
    icon:
      // <MdMovieFilter size={32} />
      "🚧",
  },
  {
    value: "approve",
    label: "Validé",
    icon: "✅",
    // <AiFillCheckCircle size={32} />
  },
  {
    value: "delivered",
    label: "Délivré",
    icon: "✅",
    // <GiScooter size={32} />
  },
];

export const categoryOptions = [
  { value: "temoignage", label: "Témoignage" },
  { value: "story", label: "Story" },
  { value: "interview", label: "Interview" },
  { value: "portrait", label: "Portrait" },
  { value: "backstage", label: "Backstage" },
  { value: "vLog", label: "Blog video" },
  { value: "masterclass", label: "Masterclass" },
  { value: "event", label: "Event" },
  { value: "live", label: "Live" },
  { value: "shooting", label: "Shooting" },
  { value: "capsule", label: "Capsule" },
  { value: "podcast", label: "Podcast" },
  { value: "news", label: "News" },
  { value: "pitch", label: "Pitch" },
  { value: "demo", label: "Demo" },
  { value: "job", label: "Job" },
];

export const durationOptions = [
  { value: "15s", label: "15 s" },
  { value: "30s", label: "30 s" },
  { value: "1min", label: "1 min" },
  { value: "1min30s", label: "1 min 30 s" },
  { value: "2min", label: "2 min" },
  { value: "2min30s", label: "2 min 30 s" },
  { value: "3min", label: "3 min" },
  { value: "5min", label: "5 min" },
  { value: "10min", label: "10 min" },
  { value: ">10min", label: ">10 min" },
];

export const subtitleOptions = [
  { value: "fr", label: "Français" },
  { value: "eng", label: "Anglais" },
  { value: "span", label: "Espagnol" },
  { value: "ger", label: "Allemand" },
  { value: "ita", label: "Italien" },
  { value: "dut", label: "Néérlandais" },
  { value: "swe", label: "Suédois" },
  { value: "dan", label: "Danois" },
  { value: "nor", label: "Norvégien" },
  { value: "fin", label: "Finlandais" },
  { value: "pol", label: "Polonais" },
];

export const speakerLanguageOptions = [
  { value: "fr", label: "Français" },
  { value: "eng", label: "Anglais" },
  { value: "span", label: "Espagnol" },
  { value: "ger", label: "Allemand" },
  { value: "ita", label: "Italien" },
  { value: "dut", label: "Néérlandais" },
  { value: "swe", label: "Suédois" },
  { value: "dan", label: "Danois" },
  { value: "nor", label: "Norvégien" },
  { value: "fin", label: "Finlandais" },
  { value: "pol", label: "Polonais" },
];

export const formatOptions = [
  { value: "16/9", label: "16/9" },
  { value: "1/1", label: "1/1" },
  { value: "4/5", label: "4/5" },
  { value: "9/16", label: "9/16" },
  { value: "5/4", label: "5/4" },
];

export const peopleOptions = [
  { value: "client", label: "Client" },
  { value: "user", label: "Utilisateur" },
  { value: "team", label: "Équipe" },
  { value: "director", label: "Dirigeant" },
  { value: "manager", label: "Manager" },
  { value: "associate", label: "Associé" },
  { value: "partner", label: "Partenaire" },
  { value: "ambassador", label: "Ambassadeur" },
  { value: "fan", label: "Fan" },
  { value: "personality", label: "Personnalité" },
  { value: "supplier", label: "Fournisseur" },
  { value: "subContracor", label: "Sous-traitant" },
  { value: "sponsor", label: "Sponsor" },
  { value: "journalist", label: "Journaliste" },
  { value: "expert", label: "Expert" },
  { value: "entrepreuneur", label: "Entrepreuneur" },
  { value: "investissor", label: "Investisseur" },
];

export const placesOptions = [
  { value: "remote", label: "En remote" },
  { value: "office", label: "Au bureau" },
  { value: "client", label: "Chez le client" },
  { value: "outSide", label: "En extérieur" },
  { value: "studio", label: "En studio" },
];

export function statusSwitch(param) {
  switch (param) {
    case "approval":
      return "#377D50";
    case "broadcast":
      return "#377D50";
    case "production":
      return "#6799F5";
    case "preProduction":
      return "#6799F5";
    case "interview":
      return "#6799F5";
    default:
      return "gray";
  }
}

export function newStatusSwitch(param) {
  switch (param) {
    case "approve":
      return "#377D50";
    case "delivered":
      return "#377D50";
    case "production":
      return "#6799F5";
    case "preProduction":
      return "#6799F5";
    case "interview":
      return "#6799F5";
    default:
      return "gray";
  }
}

export function newStatusEmoji(param) {
  switch (param) {
    case "approve":
      return "✅";
    case "preparation":
      return "🕔";
    case "delivered":
      return "✅";
    case "production":
      return "🚧";
    case "preProduction":
      return "🚧";
    case "interview":
      return "🚧";

    default:
      return "";
  }
}

import React, { useState, useRef } from "react";
import {
  Flex,
  Box,
  Heading,
  Text,
  VStack,
  Link,
  Tooltip,
  Avatar,
  AvatarGroup,
  Spacer,
  IconButton,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import NavBar from "./NavBar";
import { IoFlash } from "react-icons/io5";
import UpdateStatus from "../../components/video/UpdateStatus";

export default function Kanban(props) {
  const { colorMode } = useColorMode();

  const { videos, admin, setReloadData } = props;
  const [currentVideo, setCurrentVideo] = useState("");

  const {
    isOpen: isOpenStatus,
    onOpen: onOpenStatus,
    onClose: onCloseStatus,
  } = useDisclosure();

  const firstRenderStatus = useRef(true);
  const firstRenderValidation = useRef(true);

  // console.log("videos", videos);
  function clickStatus(e, video) {
    e.preventDefault();

    firstRenderStatus.current = true;
    firstRenderValidation.current = true;
    setCurrentVideo(video);
    // console.log("currentVideo", currentVideo);
    onOpenStatus();
    e.stopPropagation();
  }
  // console.log("videos", videos);
  // const columns = [
  //   { status: "warmUp", title: "Preparation", order: 1 },
  //   { status: "interview", title: "Interview", order: 1 },
  //   { status: "preProduction", title: "Pre-production", order: 1 },
  //   { status: "production", title: "Production", order: 1 },
  //   { status: "approval", title: "Validated", order: 1 },
  //   { status: "broadcast", title: "Delivered", order: -1 },
  //   { status: "noStatus", title: "No status", order: 1 },
  // ];

  const columns = [
    // { status: "noStarted", title: "Not started", order: 1 },
    { status: "preparation", title: "Preparation", order: 1 },
    { status: "interview", title: "Shooting", order: 1 },
    { status: "preProduction", title: "Pre-production", order: 1 },
    { status: "production", title: "Production", order: 1 },
    { status: "approve", title: "Validated", order: 1 },
    { status: "delivered", title: "Delivered", order: -1 },
    { status: "noStatus", title: "No status", order: 1 },
  ];

  // const columns = [
  //   { status: "warmUp", title: "✏️ Préparation", order: 1 },
  //   { status: "interview", title: "🎬 Interview", order: 1 },
  //   { status: "preProduction", title: "🚧 Prémontage", order: 1 },
  //   { status: "production", title: "🌈 Montage", order: 1 },
  //   { status: "approval", title: "✅ Validé", order: 1 },
  //   { status: "broadcast", title: "📫 Diffusé", order: -1 },
  //   { status: "noStatus", title: "⚠️ Sans status", order: 1 },
  // ];
  const columnStatus = columns.map((item, index) => {
    return item.status;
  });
  return (
    <Flex overflow='auto' grow='1' pl={{ base: "10px", lg: "0px" }}>
      <Flex>
        {/* Column */}
        {columns &&
          columns.map((column, index) => {
            const cards =
              videos &&
              videos
                .filter((element) =>
                  // if status
                  element.newStatus
                    ? // if col "sans status"
                      column.status === "noStatus"
                      ? // true if status is not listed in col array
                        columnStatus.indexOf(element.newStatus.value) < 0
                      : //if not col "sans status" true if title col = card status
                        element.newStatus.value === column.status
                    : // if no status
                      column.status === "noStatus"
                )
                .sort(
                  column.order === 1
                    ? (a, b) => new Date(a.dateJs) - new Date(b.dateJs)
                    : (a, b) => new Date(b.dateJs) - new Date(a.dateJs)
                );

            return (
              <Flex mx='2' key={index} direction='column'>
                <Heading
                  fontSize='md'
                  // p="20px"
                  // bg="gray.100"

                  // bg={colorMode === "light" ? "#F6F6F6" : "gray.900"}
                  // px="20px"
                  pt='20px'
                  pb='10px'
                  roundedTop='xl'>
                  {column.title}
                </Heading>
                <Flex
                  direction='column'
                  w='250px'
                  // mx="10px"
                  pr='0px'
                  // py="5px"
                  overflow='auto'
                  // bg="gray.100"
                  // bg={colorMode === "light" ? "#F6F6F6" : "gray.900"}
                  // bg="blackAlpha.100"
                  // border="1px"
                  // borderColor="gray.200"
                  // borderRadius="5"
                  roundedBottom='xl'
                  // bg="white"
                  // boxShadow="sm"
                >
                  <Flex direction='column' px='0px'>
                    {/* Cards */}
                    {cards &&
                      cards.map((card, index) => {
                        if (card.role) {
                          return (
                            <Link
                              key={index}
                              as={RouteLink}
                              to={`/projects/${card.id}/quotes/${card.projectId}`}
                              _hover={{ textDecor: "none" }}>
                              {" "}
                              <Box
                                shadow='sm'
                                borderWidth={
                                  colorMode === "light" ? "0px" : "0px"
                                }
                                _hover={{
                                  bgColor:
                                    colorMode === "light"
                                      ? "gray.50"
                                      : "blackAlpha.600",
                                  shadow: "lg",
                                }}
                                bg={colorMode === "light" ? "white" : "#232323"}
                                my='4px'
                                rounded='xl'
                                // px="15px"
                                // py="25px"
                                p='3'
                                mx='5px'
                                // minH="120px"
                              >
                                <Text
                                  color={
                                    colorMode === "light"
                                      ? "primary"
                                      : "whiteAlpha.500"
                                  }
                                  fontSize='sm'>
                                  {card.client}
                                </Text>
                                <Text
                                  color={
                                    colorMode === "light" ? "gray.500" : "white"
                                  }>
                                  {card.title}
                                </Text>
                              </Box>
                            </Link>
                          );
                        } else {
                          return (
                            <Link
                              key={index}
                              as={RouteLink}
                              to={`/projects/${card.id}/quotes/${card.projectId}`}
                              _hover={{ textDecor: "none" }}>
                              <Flex
                                // shadow="sm"
                                boxShadow='sm'
                                borderColor='#F0F0F0'
                                borderWidth={
                                  colorMode === "light" ? "1px" : "0px"
                                }
                                _hover={{
                                  bgColor:
                                    colorMode === "light"
                                      ? "gray.50"
                                      : "blackAlpha.600",
                                  shadow: "md",
                                }}
                                bg={colorMode === "light" ? "white" : "#232323"}
                                my='4px'
                                rounded='xl'
                                // px="15px"
                                py='25px'
                                p='3'
                                mx='5px'
                                // minH="120px"
                              >
                                <Flex grow='1'>
                                  <Flex
                                    direction='column'
                                    justifyContent='space-between'
                                    grow='1'>
                                    <Flex direction='column'>
                                      <Flex justifyContent='space-between'>
                                        <Text
                                          color={
                                            colorMode === "light"
                                              ? "primary"
                                              : "whiteAlpha.500"
                                          }
                                          fontSize='sm'>
                                          {card.client}
                                        </Text>
                                        {admin && (
                                          <IconButton
                                            aria-label='Edit'
                                            icon={<IoFlash />}
                                            rounded='full'
                                            size='xs'
                                            onClick={(e) =>
                                              clickStatus(e, card)
                                            }
                                          />
                                        )}
                                      </Flex>
                                      <Text
                                        color={
                                          colorMode === "light"
                                            ? "gray.500"
                                            : "white"
                                        }>
                                        {card.title}
                                      </Text>
                                    </Flex>

                                    <Flex mt='10px'>
                                      <AvatarGroup
                                        size='xs'
                                        max={2}
                                        spacing={-1.5}>
                                        {card.accountManager &&
                                          card.accountManager.map(
                                            (item, index) => {
                                              return (
                                                <Avatar
                                                  // bg="gray.300"
                                                  // color="white"
                                                  key={index}
                                                  name={item}
                                                />
                                              );
                                            }
                                          )}
                                      </AvatarGroup>
                                    </Flex>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Link>
                          );
                        }
                      })}
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
      </Flex>
      <UpdateStatus
        isOpen={isOpenStatus}
        onClose={onCloseStatus}
        videoData={currentVideo}
        admin={admin}
        projectId={currentVideo?.projectId}
        videoId={currentVideo?.id}
        projectName={currentVideo?.project}
        videoName={currentVideo?.title}
        setReloadData={setReloadData}
        clientId={currentVideo?.clientId}
      />
    </Flex>
  );
}

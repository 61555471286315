export const initialState = {
  title: "",
  brief: "",
  status: "",
  validation: "",
  category: "",
  leader: "",
  medias: "",
  subtitle: "",
  date: "",
  time: "",
  isLoadingOne: false,
  isLoadingTwo: false,
  error: "",
  link: "",
  private: false,
  statusDate: "",
  validationDate: "",
  sharing:false,
  sendEmail:true
};

export function reducer(state, action) {
  switch (action.type) {
    case "previousData": {
      // console.log("sharing state:", action.payload.sharing);
      // console.log("private state:", action.payload.private);

      return {
        ...state,
        category: action.payload.category,
        title: action.payload.title,
        brief: action.payload.brief,
        date: action.payload.date,
        time: action.payload.date,
        status: action.payload.status,
        validation: action.payload.validation,
        leader: action.payload.leader,
        subtitle: action.payload.subtitle,
        link: action.payload.link,
        private: action.payload.private,
        statusDate: action.payload.statusDate,
        validationDate: action.payload.validationDate,
        sharing:action.payload.sharing,
        sendEmail:action.payload.sendEmail
      };
    }
    case "field": {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      };
    }
    case "save": {
      return {
        ...state,
        isLoadingOne: true,
        // isLoadingTwo: false,
      };
    }
    case "success": {
      // console.log("state private", state.private);
      return initialState;
    }
    case "delete": {
      return {
        ...state,
        isLoadingTwo: true,
        // isLoadingOne: false,
      };
    }
    case "error": {
      return {
        ...state,
        error: "there is an error",
      };
    }
    default: {
      return state;
    }
  }
}

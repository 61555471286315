import { Flex } from "@chakra-ui/react";
import React from "react";

export default function MainColumn({ children }) {
  return (
    <Flex w='100%' overflowY='auto' height='100vh' flexDir='column' mt='0px'>
      {children}
    </Flex>
  );
}

import { API } from "aws-amplify";
import axios from "axios";

export async function fetchUser() {
  const url = `/people/me`;
  return API.get("kronik", url, {});
}

export async function fetchJobs() {
  const url = `/freelance/me`;

  return API.get("kronik", url, {});
}

export async function updatePicture(file) {
  const fileName = file.name;
  const reader = new FileReader();
  const url = `https://api.kronik.com/file/putObject`;

  const config = {
    onUploadProgress: (progressEvent) =>
      console.log(
        parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
      ),
  };

  reader.onloadend = () => {
    const base64String = reader.result.split(`base64,`)[1];
    const dataInfo = {
      fileName: fileName,
      base64String: base64String,
    };

    axios
      .post(url, dataInfo, config)
      .then((r) => {
        console.log(r);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  reader.readAsDataURL(file);
}

export async function updateUser(user, fileName, admin) {
  const url = `/people/update/${user._id}?admin=${admin}`;
  return await API.put("kronik", url, {
    body: {
      given_name: user.given_name,
      //   given_name: "Michelle7",
      family_name: user.family_name,
      profile_picture: fileName ? fileName : null,
    },
  }).then((response) => {
    // console.log("response user", response);
    return response;
  });
}

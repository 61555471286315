import { API } from "aws-amplify";

export async function createProject(state, admin, preSelectedClient) {
  try {
    await API.post("kronik", `/project/create?admin=${admin}`, {
      body: {
        name: state.name,
        pricing: parseInt(state.pricing),
        quantity: parseInt(state.quantity),
        status: state.status,
        description: state.description,
        attachmentId: null,
        type: state.category,
        client: preSelectedClient
          ? preSelectedClient
          : state.currentClient.value,
        goal: state.currentGoals,
        wonDate: state.wonDate,
        cancel: state.cancel,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function updateProject(projectId, admin, state) {
  try {
    await API.put("kronik", `/project/${projectId}?admin=${admin}`, {
      body: {
        name: state.name,
        description: state.description,
        pricing: parseInt(state.pricing),
        quantity: parseInt(state.quantity),
        status: state.status,
        attachmentId: null,
        type: state.category,
        projectName: state.projectName,
        client: state.currentClient && state.currentClient.value,
        goal: state.currentGoals,
        wonDate: state.wonDate,
        cancel: state.cancel,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function updateStatus(projectId, admin, projectData, status) {
  try {
    await API.put("kronik", `/project/${projectId}?admin=${admin}`, {
      body: {
        name: projectData.name,
        description: projectData.description,
        pricing: projectData.pricing,
        quantity: projectData.quantity,
        status: status ? status : projectData.status,
        attachmentId: null,
        type: projectData.type,
        projectName: projectData.projectName,
        client: projectData.client,
        goal: projectData.goal,
        wonDate: projectData.wonDate,
        cancel: projectData.cancel,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function deleteProject(projectId, clientId) {
  try {
    return await API.put("kronik", `/project/delete `, {
      body: {
        projectId: projectId,
        clientId: clientId,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function getClients() {
  try {
    return await API.get("kronik", "/client/all", {});
    // setClients(data);
  } catch (e) {
    console.log(e.message);
  }
}

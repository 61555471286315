import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  useColorMode,
  Button,
  useDisclosure,
  Link,
  admins,
  isOpen,
  onClose,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { displayRole } from "../../data/adminData";

export default function CollabLists(props) {
  const {
    team,
    isOpen,
    onOpen,
    onClose,
    isOpenEdit,
    onOpenEdit,
    onCloseEdit,
    setCurrentAdmin,
    onOpenNew,
    // displayRole,
  } = props;
  const { colorMode } = useColorMode();
  //   const { isOpen, onOpen, onClose } = useDisclosure();
  // console.log("team", team);

  function testModal(item) {
    setCurrentAdmin(item);
    onClose();
    onOpenEdit();
  }

  function addPeople() {
    onClose();
    onOpenNew();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Team</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            // borderWidth="1px"
            boxShadow="sm"
            rounded="md"
            bg={colorMode === "light" ? "white" : "gray.900"}
            mb="6"
          >
            <Table variant="unstyled" colorScheme="blackAlpha">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>email</Th>
                  <Th> Role</Th>
                </Tr>
              </Thead>
              {team &&
                team.map((item, index) => {
                  return (
                    <Tbody
                      _hover={{ bg: "#edf2f7", cursor: "pointer" }}
                      key={index}
                      onClick={(e) => testModal(item)}
                    >
                      <Tr>
                        <Td>
                          {item.user && item.user.given_name}{" "}
                          {item.user && item.user.family_name}
                        </Td>
                        <Td>{item.user && item.user.email}</Td>
                        <Td>
                          {/* <Badge>{item.role ? item.role : ""}</Badge> */}
                          <Badge>{displayRole(item)}</Badge>
                        </Td>
                      </Tr>
                    </Tbody>
                  );
                })}
            </Table>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button onClick={() => addPeople()}>Add a person</Button>

          {/* <Button
            // isDisabled={!state.currentClient || !state.name}
            colorScheme="blue"
            mr={3}
            onClick={
              () => newSuperCollab()
              // (adminProjectId ? newCollab() : newSuperCollab())
            }
            isLoading={isLoadingOne}
            loadingText="Loading"
          >
            Save
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

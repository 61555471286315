import { Flex, useColorMode } from "@chakra-ui/react";
import { Children } from "react";

export function MiniHeader({ children }) {
  const { colorMode } = useColorMode();
  return (
    <Flex
      mb='0px'
      borderBottom='1px solid'
      borderColor={colorMode === "light" ? "darkBorder" : "lightBorder"}
      minH='60px'
      px='20px'
      alignItems='center'>
      {children}
    </Flex>
  );
}

import { Flex } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import CardTitle from "../../components/card/CardTitle";
import { MiniHeader } from "../../components/header/MiniHeader";
import { useUser } from "../../libs/UserContext";

export default function Header() {
  const { admin } = useUser();
  const location = useLocation();

  // console.log("location", location.pathname);
  return (
    <MiniHeader>
      <Flex gap='20px'>
        {/* {admin && ( */}
        <Link
          //   as={NavLink}
          to='/library'
          // activeClassName='selected'
          _hover={{
            textDecor: "none",
          }}>
          <CardTitle
            title='Library'
            isActive={location.pathname === "/library"}
          />
        </Link>
        {/* )} */}
      </Flex>
    </MiniHeader>
  );
}

import React, { useState, useEffect, useReducer, useRef } from "react";
import { useUser } from "../../libs/UserContext";
import Select from "react-select";
import fr from "date-fns/locale/fr";
import { setHours, setMinutes, getHours } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { initialState, reducer } from "./reducer";
import { registerLocale } from "react-datepicker";
import { customStyles, darkStyles } from "../../styles/customStyles";
import {
  categoryOptions,
  statusOptions,
  validationOptions,
} from "../../data/taskData";
import {
  Button,
  Grid,
  Text,
  Box,
  Checkbox,
  Flex,
  Input,
  Switch,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormHelperText,
  useColorMode,
} from "@chakra-ui/react";
import { deleteTask, updateTask } from "./api";

export default function EditTask(props) {
  const { colorMode } = useColorMode();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [editLink, setEditLink] = useState(false);
  const [allDay, setAllday] = useState(true);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [deleteButton, setDeleteButton] = useState(false);

  const { admin } = useUser();

  registerLocale("fr", fr);

  const {
    projectId,
    videoId,
    task,
    projectName,
    videoName,
    admins,
    finalRef,
    isOpen,
    onClose,
    reloadData,
    setReloadData,
    firstRenderStatus,
    firstRenderValidation,
    taskId,
  } = props;

  // console.log('admins list', admins)
  const adminList =
    admins &&
    admins.map((item) => {
      return {
        label:
          item.user && item.user.given_name
            ? item.user.given_name + " " + item.user.family_name
            : item.user && item.user.email,
        value: item.user && item.user._id,
      };
    });

  const leaderOptions = adminList ? adminList : [];

  useEffect(() => {
    // console.log("edit task", task);
    // console.log("projectId", projectId);
    // console.log("videoId", videoId);
    dispatch({
      type: "previousData",
      payload: {
        category: task && task.category,
        subtitle: task && task.subtitle,
        link: task && task.link,
        title: task && task.title,
        brief: task && task.brief,
        date: Date.parse(task && task.dueDate),
        statusDate: Date.parse(task && task.statusDate),
        validationDate: Date.parse(task && task.validationDate),
        status: task && task.status,
        validation: task && task.validation,
        private: task && task.private,
        sharing: task && task.sharing,
        sendEmail: task ? task.sendEmail : false,
        leader:
          adminList &&
          task?.taskLeader &&
          adminList.find((element) => element.value === task.taskLeader._id),
      },
    });
  }, [task, admins]);

  useEffect(() => {
    // console.log("state.date", state.date)
    if (getHours(state.date) > 0) {
      setAllday(false);
    }
  }, [state.date]);

  // const firstRenderStatus = useRef(true);
  // const firstRenderValidation = useRef(true);

  useEffect(() => {
    if (firstRenderStatus.current) {
      firstRenderStatus.current = false;
    } else {
      if (state.status && state.status.value === "done") {
        dispatch({
          type: "field",
          payload: {
            fieldName: "statusDate",
            value: new Date(),
          },
        });
      }
    }
  }, [firstRenderStatus, state.status]);

  useEffect(() => {
    if (firstRenderValidation.current) {
      firstRenderValidation.current = false;
    } else {
      if (
        (state.validation && state.validation.value === "validated") ||
        (state.validation && state.validation.value === "corection")
      ) {
        dispatch({
          type: "field",
          payload: {
            fieldName: "validationDate",
            value: new Date(),
          },
        });
      }
    }
  }, [firstRenderValidation, state.validation]);

  async function save(e) {
    dispatch({ type: "save" });
    // console.log("task id 1", task);
    // console.log("state 1", state);
    updateTask(
      task,
      state,
      admin,
      projectId,
      videoId,
      projectName,
      videoName,
      taskId
    ).then((response) => {
      dispatch({ type: "success" });

      firstRenderStatus.current = true;
      firstRenderValidation.current = true;
      onClose();
      setReloadData(!reloadData);
    });
  }

  async function remove(e) {
    dispatch({ type: "delete" });
    deleteTask(projectId, task, admin).then((response) => {
      dispatch({ type: "success" });
      setDeleteButton(false);
      setConfirmMessage("");
      onClose();
      setReloadData(!reloadData);
    });
  }

  const DateForm = () => {
    return (
      <DatePicker
        // className="date-picker"
        wrapperClassName={
          colorMode === "light"
            ? "date-picker-wrapper-light"
            : "date-picker-wrapper-dark"
        }
        // calendarClassName="dark-test"
        popperClassName={colorMode === "light" ? "ligth-picker" : "dark-picker"}
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        dateFormat='dd/MM/yyyy'
        placeholderText='Choisir une date'
        isClearable
        calendarStartDay={1}
        selected={state.date}
        onChange={(date) =>
          dispatch({
            type: "field",
            payload: {
              fieldName: "date",
              value: date,
            },
          })
        }
        // locale="fr"
      />
    );
  };

  const StatusDateForm = () => {
    return (
      <DatePicker
        // className="date-picker"
        wrapperClassName={
          colorMode === "light"
            ? "date-picker-wrapper-light"
            : "date-picker-wrapper-dark"
        }
        // calendarClassName="dark-test"
        popperClassName={colorMode === "light" ? "ligth-picker" : "dark-picker"}
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        dateFormat='dd/MM/yyyy'
        calendarStartDay={1}
        placeholderText='Choisir une date'
        isClearable
        selected={state.statusDate}
        onChange={(date) =>
          dispatch({
            type: "field",
            payload: {
              fieldName: "statusDate",
              value: date,
            },
          })
        }
        // locale="fr"
      />
    );
  };

  const ValidationDateForm = () => {
    return (
      <DatePicker
        // className="date-picker"
        wrapperClassName={
          colorMode === "light"
            ? "date-picker-wrapper-light"
            : "date-picker-wrapper-dark"
        }
        // calendarClassName="dark-test"
        popperClassName={colorMode === "light" ? "ligth-picker" : "dark-picker"}
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        dateFormat='dd/MM/yyyy'
        placeholderText='Choisir une date'
        isClearable
        calendarStartDay={1}
        selected={state.validationDate}
        onChange={(date) =>
          dispatch({
            type: "field",
            payload: {
              fieldName: "validationDate",
              value: date,
            },
          })
        }
        // locale="fr"
      />
    );
  };

  const TimeForm = () => {
    return (
      <DatePicker
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        // calendarClassName="dark-test"
        popperClassName={colorMode === "light" ? "ligth-picker" : "dark-picker"}
        placeholderText='Choisir une heure'
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption='Heure'
        dateFormat='HH:mm'
        locale='fr'
        selected={state.date}
        onChange={(date) => {
          dispatch({
            type: "field",
            payload: {
              fieldName: "date",
              value: date,
            },
          });
        }}
        // locale="fr"
        // showMonthYearPicker
      />
    );
  };

  function displayHour() {
    setAllday(!allDay);
    !allDay &&
      dispatch({
        type: "field",
        payload: {
          fieldName: "date",
          value: Date.parse(setHours(setMinutes(state.date, 0), 0)),
        },
      });
  }

  return (
    <Modal
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior='outside'
      size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize='3xl'>Edit task</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Category */}
          <Box my='4'>
            <FormLabel>Category</FormLabel>

            <Select
              styles={colorMode === "light" ? customStyles : darkStyles}
              // isClearable
              placeholder='Select a category'
              value={state.category && state.category}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "category",
                    value: e,
                  },
                });
              }}
              options={categoryOptions}
            />
          </Box>

          {/* Public or private */}
          {(state.category?.label == "Prémontage" ||
            state.category?.label == "Montage" ||
            state.category?.label === "Motion design") && (
            <Box my='4'>
              <Switch
                isChecked={state.private}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "private",
                      value: e.currentTarget.checked,
                    },
                  });
                }}>
                {state.private ? "Interne" : "Publique"}
              </Switch>
            </Box>
          )}

          {/* Subtitle */}
          <Box my='4'>
            <FormControl id='subtitle'>
              <FormLabel>Subtitle</FormLabel>
              <Input
                variant='filled'
                placeholder='Add a subtitle'
                value={state.subtitle}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "subtitle",
                      value: e.target.value,
                    },
                  })
                }
              />
            </FormControl>
          </Box>

          {/* Brief*/}
          <Box my='4'>
            <FormLabel>Brief</FormLabel>
            <Textarea
              variant='filled'
              placeholder='Write a quick brief'
              size='md'
              resize='vertical'
              value={state.brief}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "brief",
                    value: e.target.value,
                  },
                });
              }}
            />
          </Box>

          {/* Date */}
          <Box my='4'>
            <FormLabel>Date</FormLabel>
            <Flex>
              <Box maxW='200'>
                <DateForm />
              </Box>
              <Box maxW='100'>{!allDay && !allDay && <TimeForm />}</Box>
              <Checkbox
                onChange={() => displayHour()}
                defaultIsChecked={allDay}>
                All day
              </Checkbox>
            </Flex>
          </Box>

          <Grid templateColumns='1fr 1fr'>
            {/* Status */}
            <Box my='4' mr='20px'>
              <FormLabel>Status</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                placeholder='Select a status'
                isClearable
                value={state.status}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "status",
                      value: e,
                    },
                  });
                }}
                options={statusOptions}
              />
            </Box>

            {/* StatusDate */}
            <Box my='4'>
              <FormLabel>Status Date</FormLabel>
              <Flex>
                <Box maxW='200'>
                  <StatusDateForm />
                </Box>
              </Flex>
            </Box>
          </Grid>

          <Grid templateColumns='1fr 1fr'>
            {/* Validation status */}
            <Box my='4' mr='20px'>
              <FormLabel>Validation</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                placeholder='Select a validation status'
                isClearable
                value={state.validation}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "validation",
                      value: e,
                    },
                  });
                }}
                options={validationOptions}
              />
            </Box>

            {/* ValidationDate */}
            <Box my='4'>
              <FormLabel>Validation Date</FormLabel>
              <Flex>
                <Box maxW='200'>
                  <ValidationDateForm />
                </Box>
              </Flex>
            </Box>
          </Grid>

          {/* Link */}

          <Box my='4'>
            <FormControl id='link'>
              <FormLabel>Link</FormLabel>
              <Input
                variant='filled'
                placeholder='Add a link (vimeo, Google drive, etc)'
                value={state.link}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "link",
                      value: e.target.value,
                    },
                  })
                }
              />
            </FormControl>
          </Box>

          {/* People */}
          <Box my='4'>
            <FormLabel>Responsable</FormLabel>
            <Select
              styles={colorMode === "light" ? customStyles : darkStyles}
              isClearable
              placeholder='Select the person in charge '
              value={state.leader}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "leader",
                    value: e,
                  },
                });
              }}
              options={leaderOptions}
            />
          </Box>

          <Flex>
            <Box my='4'>
              <FormLabel>Sharing the video(s)</FormLabel>

              <Switch
                isChecked={state.sharing}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "sharing",
                      value: e.currentTarget.checked,
                    },
                  });
                }}>
                {state.sharing ? "Shared" : "Not shared"}
              </Switch>
            </Box>

            {/* Send email */}

            <Box my='4' ml='8'>
              <FormLabel>Send an email</FormLabel>

              <Switch
                isChecked={state.sendEmail}
                isDisabled
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "sendEmail",
                      value: e.currentTarget.checked,
                    },
                  });
                }}>
                {state.sendEmail ? "Email sent" : "Email dont' sent"}
              </Switch>
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter mt='0'>
          {/* {admin && (
            <Button
              isLoading={state.isLoadingTwo}
              loadingText="Loading"
              colorScheme="gray"
              mr={3}
              onClick={(e) => remove(e)}
            >
              Delete
            </Button>
          )}
          {admin && (
            <Button
              isLoading={state.isLoadingOne}
              loadingText="Loading"
              colorScheme="blue"
              mr={3}
              onClick={(e) => save(e)}
            >
              Save
            </Button>
          )} */}

          {admin && !deleteButton && (
            <Button mr={3} onClick={() => setDeleteButton(true)}>
              Delete
            </Button>
          )}
          {!deleteButton && (
            <Button
              bgColor='primary'
              color='white'
              mr={3}
              onClick={() => save()}
              isLoading={state.isLoadingOne}
              loadingText='Loading'>
              Save
            </Button>
          )}
          {deleteButton && (
            <Box mr='20px'>
              <FormControl id='confirmation'>
                <Input
                  variant='filled'
                  placeholder='Enter DELETE to confirm'
                  value={confirmMessage}
                  onChange={(e) => setConfirmMessage(e.target.value)}
                />
              </FormControl>
            </Box>
          )}
          {deleteButton && (
            <Button mr={3} onClick={() => setDeleteButton(false)}>
              Cancel
            </Button>
          )}
          {confirmMessage === "DELETE" && deleteButton && (
            <Button
              colorScheme='red'
              mr={3}
              onClick={() => remove()}
              isLoading={state.isLoadingTwo}
              loadingText='Loading'>
              Delete
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

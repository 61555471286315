import React, { useState, useEffect } from "react";
import { Box, Heading, useColorMode, useDisclosure } from "@chakra-ui/react";
import fetchTasks from "./api";
import { fetchTasksByUser } from "./api";
import TasksList from "./TasksList";
import NavBar from "./NavBar";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useUser } from "../../libs/UserContext";
import { Updating } from "../../components/notification/Updating";
import { useLocation } from "react-router-dom";
import { categoryOptions } from "../../data/taskData";
import MainColumn from "../../components/column/MainColumn";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";
import ProductionHeader from "../../components/header/ProjectHeader";
import Taskheader from "../../components/header/TaskHeader";
import WatchList from "./WatchList";
import Shooting from "../shooting";
import FilterByClient from "../../components/client/FilterByClient";
import { useHotkeys } from "react-hotkeys-hook";

dayjs.locale("fr");

export default function Tasks() {
  const { user, admin, freelance } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { colorMode } = useColorMode();
  const [data, setData] = useState("");
  const [tasks, setTasks] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cognitoId, setCognitoId] = useState("");
  const [currentTasks, setCurrentTasks] = useState("");
  const [lateTasks, setLateTasks] = useState("");
  const [validationTasks, setValidationTasks] = useState("");
  const [futurTasks, setFuturTasks] = useState("");
  const [clients, setClients] = useState("");
  const [people, setPeople] = useState("");
  const [reloadData, setReloadData] = useState(false);
  const [category, setCategory] = useState("");

  const [noDateTasks, setNoDateTasks] = useState([""]);
  const [queryStatus, setQueryStatus] = useState("");
  const [queryCategory, setQueryCategory] = useState("");
  const [queryClient, setQueryClient] = useState("");
  const [queryDisplay, setQueryDisplay] = useState("");
  const [queryManager, setQueryManager] = useState("");
  const [queryPeople, setQueryPeople] = useState("");
  const [queryList, setQueryList] = useState("");
  const [queryDate, setQueryDate] = useState(false);
  const [filteredtasks, setFilteredTasks] = useState("");

  useEffect(() => {
    // !cognitoId && console.log("useEffect fetchTasks");
    if (cognitoId === null && queryDisplay) {
      setIsLoading(true);
      fetchTasks(queryDisplay).then((response) => {
        // setData(response);
        // console.log("tasks all", response);

        setTasks(response);
        setIsLoading(false);
      });

      // cognitoId && console.log("useEffect cognitoId");
    } else if (cognitoId) {
      setIsLoading(true);

      fetchTasksByUser(cognitoId).then((response) => {
        // console.log("tasks by cognito", response);
        setTasks(response);

        setIsLoading(false);
        // console.log("cognito", cognitoId);
        // console.log("response cognito", response);
      });
    }
  }, [reloadData, cognitoId, queryDisplay]);

  function useQuery() {
    // const { search } = useLocation();
    // return React.useMemo(() => new URLSearchParams(search), [search]);
    return new URLSearchParams(useLocation().search);
  }

  const location = useLocation();

  let query = useQuery();

  useEffect(() => {
    const arrayCognito = {
      géraldinemillion: "us-east-2:1be77bef-8ae5-449c-9e6e-be1c8cc7d1af",
      maxencelebreux: "us-east-2:24bf8f7c-684c-46f7-86f7-7d632be13e87",
      geoffroylasne: "us-east-2:d5c1e7f1-bc8d-483c-8380-e43f467e126e",
      mariongauffroy: "us-east-2:0bbe5b02-3b82-4bba-9af3-77829c144316",
      claracolleoni: "us-east-2:292faf1c-b190-46d4-b6fd-bb7103cefe0b",
    };

    const manager = query.get("manager") ? query.get("manager") : "all";
    switch (manager) {
      case "géraldinemillion":
        setCognitoId(arrayCognito["géraldinemillion"]);
        break;
      case "maxencelebreux":
        setCognitoId(arrayCognito["maxencelebreux"]);
        break;
      case "geoffroylasne":
        setCognitoId(arrayCognito["geoffroylasne"]);
        break;
      case "mariongauffroy":
        setCognitoId(arrayCognito["mariongauffroy"]);
        break;
      case "claracolleoni":
        setCognitoId(arrayCognito["claracolleoni"]);
        break;
      default:
        setCognitoId(null);
    }

    setQueryStatus(query.get("status") ? query.get("status") : "all");
    setQueryClient(query.get("client") ? query.get("client") : "all");
    setQueryDisplay(
      query.get("display")
        ? query.get("display")
        : user.username === "florian@kronik.com" ||
          user.username === "christophe@kronik.com" ||
          user.username === "mathieu@kronik.com"
        ? "all"
        : "me"
    );
    setQueryManager(query.get("manager") ? query.get("manager") : "all");
    setQueryCategory(query.get("category") ? query.get("category") : "all");
    setQueryPeople(query.get("people") ? query.get("people") : "all");
    setQueryDate(query.get("date") ? query.get("date") : false);
    setQueryList(query.get("list") ? query.get("list") : "todo");
  }, [location, tasks]);

  // useEffect(() =>{
  // const manager = queryManager
  //   switch(manager) {
  //     case 'géraldinemillion' : setCognitoId(arrayCognito["géraldinemillion"]);
  //     break
  //     case 'maxencelebreux' : setCognitoId(arrayCognito["maxencelebreux"]);
  //     break
  //     case 'geoffroylasne' : setCognitoId(arrayCognito["geoffroylasne"]);
  //     break
  //     case 'mariongauffroy' : setCognitoId(arrayCognito["mariongauffroy"]);
  //     break
  //     case 'claracolleoni' : setCognitoId(arrayCognito["claracolleoni"]);
  //     break
  //     default : setCognitoId(null)
  //   }
  // },[queryManager])

  useEffect(() => {
    // console.log("arrayCognito.queryManager",arrayCognito[queryManager])
    tasks &&
      setFilteredTasks(
        tasks.filter(
          (task) =>
            ((queryClient === "all" ||
              task?.clientName?.toLowerCase() === queryClient?.toLowerCase()) &&
              // && (queryManager === "all" || (arrayCognito[queryManager] ===  cognitoId))
              (queryCategory === "all" ||
                task?.category?.value === queryCategory?.toLowerCase()) &&
              (queryPeople === "all" ||
                task?.people?.toLowerCase() === queryPeople?.toLowerCase()) &&
              (((queryStatus === "all") === true &&
                task?.dateJs !== null &&
                task.dateJs !== undefined &&
                task.status?.value !== "blocked") ||
                (queryStatus === "preview" && task.private) ||
                (queryStatus === "noleader" &&
                  (task?.taskLeader === null ||
                    task?.taskLeader === undefined) &&
                  task?.dateJs !== null &&
                  task?.dateJs !== undefined) ||
                (queryStatus === "todo" &&
                  task?.status?.value === "todo" &&
                  task?.dateJs !== null &&
                  task?.dateJs !== undefined) ||
                (queryStatus === "late" &&
                  task.status?.value === "todo" &&
                  dayjs(task.dateJs) < dayjs().startOf("day")) ||
                (queryStatus === "validation" &&
                  task.validation?.value === "validation") ||
                (queryStatus === "nodate" &&
                  (task.dateJs === null || task.dateJs === undefined)))) ||
            (queryStatus === "blocked" && task.status?.value === "blocked")
        )
      );
  }, [
    isLoading,
    queryStatus,
    queryClient,
    queryDisplay,
    queryManager,
    queryCategory,
    queryPeople,

    cognitoId,
  ]);

  useEffect(() => {
    const clientsArray = tasks && tasks.map((item) => item.clientName);

    setClients(tasks && [...new Set(clientsArray.sort())]);

    const peopleArray = tasks && tasks.map((item) => item.people);

    setPeople(tasks && [...new Set(peopleArray.sort())]);

    const categoryArray = tasks && tasks.map((item) => item.category?.label);

    setCategory(tasks && [...new Set(categoryArray.sort())]);
  }, [tasks]);

  useEffect(() => {
    const current =
      filteredtasks &&
      filteredtasks.filter((el) => {
        const substractDays =
          dayjs().subtract(1, "day").format("d") === 0
            ? 4
            : dayjs().subtract(1, "day").format("d") === 0
            ? 3
            : 2;

        return (
          (dayjs(el.dateJs) >= dayjs().subtract(1, "day") &&
            dayjs(el.dateJs) <= dayjs().add(15, "day").startOf("day")) ||
          // dayjs(el.dateJs) >= dayjs().subtract(substractDays, "day") &&
          (dayjs(el.dateJs) < dayjs(new Date()) && el.status?.value !== "done")
        );
      });

    const noDate =
      filteredtasks &&
      filteredtasks.filter((el) => {
        return el.dateJs === null;
      });

    const late =
      filteredtasks &&
      filteredtasks.filter((el) => {
        return dayjs(el.dateJs) < dayjs().subtract(1, "day").startOf("day");
      });
    const validation =
      filteredtasks &&
      filteredtasks.filter((el) => {
        return el.validation?.value === "validation";
      });

    const futur =
      filteredtasks &&
      filteredtasks.filter((el) => {
        return dayjs(el.dateJs) > dayjs().add(15, "day").startOf("day");
      });

    filteredtasks && setNoDateTasks(noDate);

    filteredtasks &&
      setCurrentTasks(
        current.sort((a, b) => {
          return new Date(a.dateJs) - new Date(b.dateJs);
        })
      );

    filteredtasks &&
      setLateTasks(
        late.sort((a, b) => {
          return new Date(a.dateJs) - new Date(b.dateJs);
        })
      );

    filteredtasks &&
      setValidationTasks(
        validation.sort((a, b) => {
          return new Date(a.dateJs) - new Date(b.dateJs);
        })
      );

    filteredtasks &&
      setFuturTasks(
        futur.sort((a, b) => {
          return new Date(a.dateJs) - new Date(b.dateJs);
        })
      );

    filteredtasks && setNoDateTasks(noDate);
  }, [filteredtasks]);

  useHotkeys("meta+k", () => onOpen());

  return (
    <MainColumn>
      <Taskheader
        setQueryList={setQueryList}
        queryStatus={queryStatus ? queryStatus : "all"}
        queryClient={queryClient ? queryClient : "all"}
        queryDisplay={queryDisplay ? queryDisplay : "all"}
        queryManager={queryManager ? queryManager : "all"}
        queryCategory={queryCategory ? queryCategory : "all"}
        queryPeople={queryPeople ? queryPeople : "all"}
        queryList={queryList ? queryList : "todo"}
      />
      <MainColumn>
        <Box px='20px'>
          {queryList !== "shooting" && (
            <NavBar
              clients={clients}
              people={people}
              admin={admin}
              freelance={freelance}
              category={categoryOptions}
              queryStatus={queryStatus}
              queryClient={queryClient}
              queryDisplay={queryDisplay}
              queryManager={queryManager}
              queryCategory={queryCategory}
              queryPeople={queryPeople}
              queryList={queryList}
            />
          )}

          {filteredtasks && !isLoading && queryList === "todo" && (
            <TasksList
              currentTasks={currentTasks}
              lateTasks={lateTasks}
              futurTasks={futurTasks}
              noDateTasks={noDateTasks}
              reloadData={reloadData}
              setReloadData={setReloadData}
            />
          )}
          {filteredtasks && !isLoading && queryList === "watchlist" && (
            <WatchList
              currentTasks={currentTasks}
              lateTasks={validationTasks}
              futurTasks={futurTasks}
              noDateTasks={noDateTasks}
              reloadData={reloadData}
              setReloadData={setReloadData}
            />
          )}
        </Box>
        {!isLoading && (
          <FilterByClient
            clients={clients}
            isOpen={isOpen}
            onClose={onClose}
            page='library'
            queryStatus={queryStatus}
            queryDisplay={queryDisplay}
            queryManager={queryManager}
            queryCategory={queryCategory}
            queryPeople={queryPeople}
          />
        )}
      </MainColumn>
      {isLoading && <Updating />}
    </MainColumn>
  );
}

import React from "react";
import { Box, useColorMode } from "@chakra-ui/react";
import SideSteps from "../../components/SideSteps";
import SideBarItem from "../../components/SideBarItem.js";
import CardTitle from "../../components/card/CardTitle";
import { LeftColumn } from "../../components/column/LeftColumn";
import { MiniHeader } from "../../components/header/MiniHeader";
export default function SideBar({
  projectName,
  clientName,
  videoName,
  clientLink,
  projectLink,
  videoLink,
  isLoading,
  clientLogo,
}) {
  // const { colorMode } = useColorMode();
  return (
    <LeftColumn>
      {/* <MiniHeader>
        <CardTitle title='Shortcuts' isActive='false' />
      </MiniHeader> */}

      <SideBarItem
        title='Client'
        text={clientName}
        link={!isLoading && clientLink}
        // isActive='true'
        // status='client'
        clientLogo={clientLogo}
      />
    </LeftColumn>
    // </Box>
  );
}

export const initialState = {
  given_name: "",
  family_name: "",

  profile_picture: "",
};

export function reducer(state, action) {
  switch (action.type) {
    case "previousData": {
      // console.log("sharing state:", action.payload.sharing);
      // console.log("private state:", action.payload.private);

      return {
        ...state,
        given_name: action.payload.given_name,
        family_name: action.payload.family_name,
        profile_picture: action.payload.profile_picture,
      };
    }
    case "field": {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      };
    }
    case "save": {
      return {
        ...state,
        isLoadingOne: true,
        // isLoadingTwo: false,
      };
    }
    case "success": {
      // console.log("state private", state.private);
      return initialState;
    }
    case "delete": {
      return {
        ...state,
        isLoadingTwo: true,
        // isLoadingOne: false,
      };
    }
    case "error": {
      return {
        ...state,
        error: "there is an error",
      };
    }
    default: {
      return state;
    }
  }
}

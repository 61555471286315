import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MainColumn from "../../components/column/MainColumn";
import BusinessHeader from "../../components/header/BusinessHeader";
import { useUser } from "../../libs/UserContext";
import NavBar from "./NavBar";

export default function Invoices() {
  const { admin } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState("");
  const [queryDisplay, SetQueryDisplay] = useState("");
  const [queryStatus, SetQueryStatus] = useState("");
  const [queryClient, SetQueryClient] = useState("");
  const [queryManager, SetQueryManager] = useState("");

  const [filteredInvoices, setFilteredInvoices] = useState("");
  const [clients, setClients] = useState("");

  const location = useLocation();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const clientsArray = invoices && invoices.map((item) => item.client);

    setClients(invoices && [...new Set(clientsArray.sort())]);
  }, [invoices]);

  function useQuery() {
    // const { search } = useLocation();
    // return React.useMemo(() => new URLSearchParams(search), [search]);
    return new URLSearchParams(useLocation().search);
  }

  return (
    <Box w='100%'>
      <MainColumn>
        {admin && (
          <Flex flexDirection='column'>
            <BusinessHeader />
            <Box px='20px'>
              <NavBar
                clients={clients}
                admin={admin}
                //   freelance={freelance}
                filteredInvoices={filteredInvoices}
                queryStatus={queryStatus}
                queryClient={queryClient}
                queryDisplay={queryDisplay}
                queryManager={queryManager}
              />
              <MainColumn>yo</MainColumn>
            </Box>
          </Flex>
        )}
      </MainColumn>
    </Box>
  );
}

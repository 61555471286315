import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Box,
  Button,
  Heading,
  Grid,
  Badge,
  SimpleGrid,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { statusSwitch } from "../../data/projectData";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import Card from "../../components/card";
import ButtonColor from "../../components/button/ButtonColor";
import UpdateStatus from "../../components/project/UpdateStatus";
import { MdPermContactCalendar } from "react-icons/md";
import NewContact from "../../components/contact/NewContact";
import { fetchContacts } from "./api";
import EditContact from "../../components/contact/EditContact";

export default function MoreInfo(props) {
  const { colorMode } = useColorMode();
  const {
    projectData,
    admin,
    projectId,
    reloadData,
    setReloadData,
    isLoading,
    client,
    clientId,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const [contacts, setContacts] = useState("");
  const [selectedContact, setSelectedContact] = useState("");

  useEffect(() => {
    fetchContacts(clientId).then((response) => {
      // console.log(`contacts list de ${client} `, response)
      setContacts(response);
    });
  }, [clientId, reloadData]);

  function editContact(data) {
    admin && setSelectedContact(data);
    admin && onOpenEdit();
  }

  return (
    <Flex padding='20px' flexDirection='column'>
      <Box
      // bg={colorMode === "light" ? "white" : "#232323"}

      // rounded='xl'
      // borderWidth={colorMode === "light" ? "1px" : "0px"}
      // borderColor='#F0F0F0'
      // boxShadow='sm'
      >
        <SimpleGrid
          columns={{
            base: 1,
            md: 1,
          }}
          spacing='20px'>
          <Grid direction='column' gap='20px'>
            {/* <Flex justifyContent="space-between">
              <Text fontSize="sm" color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                Status
              </Text>
              <Text>
                <Badge
                  fontSize="xs"
                  colorScheme={statusSwitch(
                    projectData &&
                      projectData.status &&
                      projectData.status.value
                  )}
                >
                  {projectData && projectData.status
                    ? projectData.status.label
                    : ""}
                </Badge>
              </Text>
            </Flex> */}
            {/* {client?.logo && (
              <Image
                mr='10px'
                borderRadius='lg'
                boxSize='42px'
                alt={`Logo of {client?.name}`}
                src={`https://aaliz317.twic.pics/${client?.logo}`}
              />
            )} */}
            <Flex justifyContent='space-between'>
              <Text
                fontSize='sm'
                color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                Adress
              </Text>
              <Text>{client?.address ? client.address : ""}</Text>
            </Flex>

            {admin && (
              <Flex justifyContent='space-between'>
                <Text
                  fontSize='sm'
                  color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                  Note
                </Text>
                <Text>{client?.about ? client.about : ""}</Text>
              </Flex>
            )}
            {admin && (
              <Flex justifyContent='space-between'>
                <Text
                  fontSize='sm'
                  color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                  Contacts
                </Text>
                <Text>
                  {contacts &&
                    contacts.map((contact, index) => {
                      return (
                        <Box
                          onClick={() => editContact(contact)}
                          _hover={{ cursor: "pointer" }}>
                          <Text>
                            {contact.given_name + " " + contact.family_name}
                          </Text>
                          <Text>{contact.job}</Text>
                        </Box>
                      );
                    })}
                </Text>
              </Flex>
            )}
          </Grid>
        </SimpleGrid>
      </Box>

      {admin && !isLoading && (
        <ButtonColor
          title={
            <Flex alignItems='center'>
              <Box mr='5px'>
                <MdPermContactCalendar />
              </Box>
              Add a contact
            </Flex>
          }
          click={() => onOpen()}
        />
      )}

      <NewContact
        isOpen={isOpen}
        onClose={onClose}
        reloadData={reloadData}
        setReloadData={setReloadData}
        clientId={clientId}
      />
      <EditContact
        isOpen={isOpenEdit}
        onClose={onCloseEdit}
        reloadData={reloadData}
        setReloadData={setReloadData}
        clientId={clientId}
        contact={selectedContact}
      />
    </Flex>
  );
}

import React, { useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import CardTitle from "../../components/card/CardTitle";
import Summary from "./Profil";
import Material from "./Material";
import Profil from "./Profil";
import RightColumn from "../../components/column/RightColumn";
import { MiniHeader } from "../../components/header/MiniHeader";

export default function RightBar({
  freelanceData,
  freelanceId,
  setReloadData,
  admin,
  isLoading,
}) {
  const [active, setActive] = useState("Profil");

  const selectDisplay = (title) => {
    setActive(title);
  };

  return (
    <RightColumn>
      <Flex px='20px' pt='20px'>
        <Box
          onClick={() => selectDisplay("Profil")}
          mr='15px'
          cursor={active === "Profil" ? "unset" : "pointer"}>
          <CardTitle title='Profil' isActive={active === "Profil"} />
        </Box>
        <Box
          onClick={() => selectDisplay("Material")}
          cursor={active === "Material" ? "unset" : "pointer"}>
          <CardTitle title='Material' isActive={active === "Material"} />
        </Box>
      </Flex>
      {active === "Profil" && (
        <Profil
          freelanceData={freelanceData}
          admin={admin}
          freelanceId={freelanceId}
          setReloadData={setReloadData}
          isLoading={isLoading}
        />
      )}
      {active === "Material" && (
        <Material
          freelanceData={freelanceData}
          admin={admin}
          freelanceId={freelanceId}
          setReloadData={setReloadData}
          isLoading={isLoading}
        />
      )}
    </RightColumn>
  );
}

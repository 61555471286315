import React, { useState, useReducer, useEffect, useRef } from "react";
import { initialState, reducer } from "./reducer";
import { useUser } from "../../libs/UserContext";
import Select from "react-select";
import fr from "date-fns/locale/fr";
import { setHours, setMinutes, getHours } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale } from "react-datepicker";
import { createTask } from "./api";
import { customStyles, darkStyles } from "../../styles/customStyles";
import {
  categoryOptions,
  statusOptions,
  validationOptions,
} from "../../data/taskData";
import {
  Button,
  Grid,
  Text,
  Box,
  Checkbox,
  Flex,
  Input,
  Switch,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  useColorMode,
  Spacer,
} from "@chakra-ui/react";

export default function NewTask(props) {
  const { colorMode } = useColorMode();

  const {
    projectId,
    videoId,
    projectName,
    videoName,
    admins,
    finalRef,
    isOpen,
    onClose,
    reloadData,
    setReloadData,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);
  const [allDay, setAllday] = useState(true);
  const { admin } = useUser();

  useEffect(() => {
    if (state.status && state.status.value === "done") {
      dispatch({
        type: "field",
        payload: {
          fieldName: "statusDate",
          value: new Date(),
        },
      });
    }
  }, [state.status]);

  useEffect(() => {
    if (
      (state.validation && state.validation.value === "validated") ||
      (state.validation && state.validation.value === "corection")
    ) {
      dispatch({
        type: "field",
        payload: {
          fieldName: "validationDate",
          value: new Date(),
        },
      });
    }
  }, [, state.validation]);

  const DateForm = () => {
    return (
      <DatePicker
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        dateFormat='dd/MM/yyyy'
        placeholderText='Pick a date'
        isClearable
        selected={state.date}
        calendarStartDay={1}
        onChange={(date) => {
          dispatch({
            type: "field",
            payload: {
              fieldName: "date",
              value: date,
            },
          });
        }}
        // locale="fr"
      />
    );
  };

  const StatusDateForm = () => {
    return (
      <DatePicker
        // className="date-picker"
        wrapperClassName={
          colorMode === "light"
            ? "date-picker-wrapper-light"
            : "date-picker-wrapper-dark"
        }
        // calendarClassName="dark-test"
        popperClassName={colorMode === "light" ? "ligth-picker" : "dark-picker"}
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        dateFormat='dd/MM/yyyy'
        placeholderText='Choisir une date'
        isClearable
        calendarStartDay={1}
        selected={state.statusDate}
        onChange={(date) =>
          dispatch({
            type: "field",
            payload: {
              fieldName: "statusDate",
              value: date,
            },
          })
        }
        // locale="fr"
      />
    );
  };

  const ValidationDateForm = () => {
    return (
      <DatePicker
        // className="date-picker"
        wrapperClassName={
          colorMode === "light"
            ? "date-picker-wrapper-light"
            : "date-picker-wrapper-dark"
        }
        // calendarClassName="dark-test"
        popperClassName={colorMode === "light" ? "ligth-picker" : "dark-picker"}
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        dateFormat='dd/MM/yyyy'
        placeholderText='Choisir une date'
        isClearable
        calendarStartDay={1}
        selected={state.validationDate}
        onChange={(date) =>
          dispatch({
            type: "field",
            payload: {
              fieldName: "validationDate",
              value: date,
            },
          })
        }
        // locale="fr"
      />
    );
  };

  const TimeForm = () => {
    return (
      <DatePicker
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        placeholderText='Hour'
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption='Heure'
        dateFormat='HH:mm'
        locale='fr'
        selected={state.date}
        onChange={(date) => {
          dispatch({
            type: "field",
            payload: {
              fieldName: "date",
              value: date,
            },
          });
        }}
        // locale="fr"
        // showMonthYearPicker
      />
    );
  };

  function displayHour() {
    setAllday(!allDay);
    !allDay &&
      dispatch({
        type: "field",
        payload: {
          fieldName: "date",
          value: Date.parse(setHours(setMinutes(state.date, 0), 0)),
        },
      });
  }

  function save() {
    dispatch({ type: "save" });
    // console.log("button save clic");
    createTask(projectName, projectId, videoId, videoName, state, admin).then(
      (response) => {
        onClose();
        setReloadData(!reloadData);
        dispatch({ type: "success" });
      }
    );
  }
  const adminList =
    admins &&
    admins.map((item) => {
      return {
        label:
          item.user && item.user.given_name
            ? item.user.given_name + " " + item.user.family_name
            : item.user && item.user.email,
        value: item.user && item.user._id,
      };
    });

  const leaderOptions = adminList;

  return (
    <Modal
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior='outside'
      size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize='3xl'>New task</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Category */}
          <Box my='4'>
            <FormLabel>Category</FormLabel>

            <Select
              styles={colorMode === "light" ? customStyles : darkStyles}
              // isClearable
              placeholder='Select a category'
              value={state.category}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "category",
                    value: e,
                  },
                });
              }}
              options={categoryOptions}
            />
          </Box>

          {/* Public or private */}
          {(state.category.label == "Prémontage" ||
            state.category.label == "Montage" ||
            state.category.label === "Motion design") && (
            <Box my='4'>
              <Switch
                isChecked={state.private}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "private",
                      value: e.currentTarget.checked,
                    },
                  });
                }}>
                {state.private ? "Interne" : "Publique"}
              </Switch>
            </Box>
          )}

          {/* Subtitle */}
          <Box my='4'>
            <FormControl id='subtitle'>
              <FormLabel>Subtitle</FormLabel>
              <Input
                variant='filled'
                placeholder='Add a subtitle'
                value={state.subtitle}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "subtitle",
                      value: e.target.value,
                    },
                  })
                }
              />
            </FormControl>
          </Box>

          {/* Brief*/}
          <Box my='4'>
            <FormLabel>Brief</FormLabel>
            <Textarea
              variant='filled'
              placeholder='Write a quick brief'
              size='md'
              resize='vertical'
              value={state.brief}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "brief",
                    value: e.target.value,
                  },
                });
              }}
            />
          </Box>

          {/* Date */}
          <Box my='4'>
            <FormLabel>Date</FormLabel>
            <Flex>
              <Box maxW='200'>
                <DateForm />
              </Box>
              <Box maxW='100'>{!allDay && !allDay && <TimeForm />}</Box>
              <Checkbox
                onChange={() => displayHour()}
                defaultIsChecked={allDay}>
                All day
              </Checkbox>
            </Flex>
          </Box>

          <Grid templateColumns='1fr 1fr'>
            {/* Status */}
            <Box my='4' mr='20px'>
              <FormLabel>Status</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                placeholder='Select a status'
                isClearable
                value={state.status}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "status",
                      value: e,
                    },
                  });
                }}
                options={statusOptions}
              />
            </Box>

            {/* StatusDate */}
            <Box my='4'>
              <FormLabel>Status Date</FormLabel>
              <Flex>
                <Box maxW='200'>
                  <StatusDateForm />
                </Box>
              </Flex>
            </Box>
          </Grid>

          <Grid templateColumns='1fr 1fr'>
            {/* Validation status */}
            <Box my='4' mr='20px'>
              <FormLabel>Validation</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                placeholder='Select a validation status'
                isClearable
                value={state.validation}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "validation",
                      value: e,
                    },
                  });
                }}
                options={validationOptions}
              />
            </Box>

            {/* ValidationDate */}
            <Box my='4'>
              <FormLabel>Validation Date</FormLabel>
              <Flex>
                <Box maxW='200'>
                  <ValidationDateForm />
                </Box>
              </Flex>
            </Box>
          </Grid>

          {/* Link */}

          <Box my='4'>
            <FormControl id='link'>
              <FormLabel>Link</FormLabel>
              <Input
                variant='filled'
                placeholder='Add a link (vimeo, Google drive, etc)'
                value={state.link}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "link",
                      value: e.target.value,
                    },
                  })
                }
              />
            </FormControl>
          </Box>

          {/* People */}
          <Box my='4'>
            <FormLabel>Responsable</FormLabel>
            <Select
              styles={colorMode === "light" ? customStyles : darkStyles}
              isClearable
              placeholder='Select the person in charge '
              value={state.leader}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "leader",
                    value: e,
                  },
                });
              }}
              options={leaderOptions}
            />
          </Box>

          <Flex>
            {/* Sharing */}

            <Box my='4'>
              <FormLabel>Sharing the video(s)</FormLabel>

              <Switch
                isChecked={state.sharing}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "sharing",
                      value: e.currentTarget.checked,
                    },
                  });
                }}>
                {state.sharing ? "Shared" : "Not shared"}
              </Switch>
            </Box>

            {/* Send email */}

            <Box my='4' ml='8'>
              <FormLabel>Send an email</FormLabel>

              <Switch
                isChecked={state.sendEmail}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "sendEmail",
                      value: e.currentTarget.checked,
                    },
                  });
                }}>
                {state.sendEmail ? "Send email" : "Don't send email"}
              </Switch>
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter mt='0'>
          <Flex>
            <Button
              isLoading={state.isLoadingOne}
              loadingText='Loading'
              bgColor='primary'
              color='white'
              mr={3}
              onClick={() => save()}>
              New task
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import { AiFillCheckCircle } from "react-icons/ai";
import { BsFillInboxFill } from "react-icons/bs";
import { ImBlocked } from "react-icons/im";
import { IoBuildSharp } from "react-icons/io5";

export const categorytOptions = [
  { value: "client", label: "Client" },
  { value: "team", label: "Équipe" },
  { value: "personnality", label: "Personnalité" },
  { value: "director", label: "Dirigeant" },
  { value: "expert", label: "Expert" },
];

export const goalOptions = [
  { value: "sales", label: "Sales" },
  { value: "pr", label: "RP" },
  { value: "branding", label: "Branding" },
  { value: "employerBranding", label: "Marque employeur" },
  { value: "internalCommunication", label: "Com interne" },
];

export const statusOptions = [
  { value: "preparation", label: "Preparation" },
  { value: "production", label: "Production" },
  { value: "finished", label: "Finished" },
  { value: "blocked", label: "Blocked" },
];
export const statusOptionsWithIcon = [
  {
    value: "preparation",
    label: "Preparation",
    icon: "🕔",
    // <BsFillInboxFill size={32} />
  },
  {
    value: "production",
    label: "Production",
    icon: "🚧",
    // <IoBuildSharp size={32} />
  },
  {
    value: "finished",
    label: "Finished",
    icon: "✅",
    // <AiFillCheckCircle size={32} />
  },
  {
    value: "blocked",
    label: "Blocked",
    icon: "🚫",
    // <ImBlocked size={32} />
  },
];

export function statusSwitch(param) {
  switch (param) {
    case "finished":
      return "#377D50";
    case "production":
      return "#6799F5";
    case "preparation":
      return "gray";
    case "blocked":
      return "#6799F5";
    default:
      return "gray";
  }
}
export function statusEmoji(param) {
  switch (param) {
    case "finished":
      return "✅";
    case "production":
      return "🚧";
    case "preparation":
      return "🕔";
    case "blocked":
      return "🚫";
    default:
      return "";
  }
}
// export const quoteStatusOptions = [
//   { value: "request", label: "Request" },
//   { value: "negociation", label: "Negociation" },
//   { value: "approved", label: "Approved" },
//   { value: "refused", label: "Refused" },
// ];

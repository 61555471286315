import { API } from "aws-amplify";

export async function createVideo(
  admin,
  projectName,
  projectId,
  state,
  clientId
) {
  try {
    await API.post("kronik", `/video/create?admin=${admin}`, {
      body: {
        projectName: projectName,
        projectId: projectId,
        title: state.name,
        brief: state.brief,
        dueDate: state.date,
        status: state.status,
        category: state.category,
        format: state.format,
        duration: state.duration,
        subtitle: state.subtitle,
        places: state.places,
        people: state.people,
        cancel: state.cancel,
        pricing: parseInt(state.pricing),
        interviews: parseInt(state.interviews),
        shootings: parseInt(state.shootings),
        bRoll: state.bRoll,
        speakerLanguage: state.speakerLanguage,
        speakers: state.speakers,
        clientId: clientId,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function updateVideo(
  admin,
  projectName,
  projectId,
  state,
  videoId,
  clientId
) {
  try {
    await API.put("kronik", `/video/${projectId}/${videoId}?admin=${admin}`, {
      body: {
        projectName: projectName,
        projectId: projectId,
        title: state.name,
        brief: state.brief,
        dueDate: state.date,
        status: state.status,
        newStatus: state.newStatus,
        category: state.category,
        format: state.format,
        duration: state.duration,
        subtitle: state.subtitle,
        places: state.places,
        people: state.people,
        cancel: state.cancel,
        pricing: parseInt(state.pricing),
        interviews: parseInt(state.interviews),
        shootings: parseInt(state.shootings),
        bRoll: state.bRoll,
        speakerLanguage: state.speakerLanguage,
        speakers: state.speakers,
        month: state.month,
        clientId: clientId,
        totalJobs: state.totalJobs,

        // archived: null,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function updateNewStatus(
  admin,
  video,
  projectId,
  videoId,
  projectName,
  videoName,
  newStatus,
  clientId
) {
  try {
    // console.log("update status");
    await API.put("kronik", `/video/${projectId}/${videoId}?admin=${admin}`, {
      body: {
        projectName: projectName,
        projectId: projectId,
        title: video.title,
        brief: video.brief,
        dueDate: video.dueDate,
        status: video.status,
        newStatus: newStatus ? newStatus : video.newStatus,
        category: video.category,
        format: video.format,
        duration: video.duration,
        subtitle: video.subtitle,
        places: video.places,
        people: video.people,
        cancel: video.cancel,
        pricing: video.pricing,
        interviews: video.interviews,
        shootings: video.shootings,
        bRoll: video.bRoll,
        speakerLanguage: video.speakerLanguage,
        speakers: video.speakers,
        month: video.month,
        // archived: null,
        clientId: clientId,
        totalJobs: video.totalJobs

      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function deleteVideo(projectId, videoId) {
  // console.log("projectId", projectId);
  // console.log("videoId", videoId);
  try {
    return await API.put("kronik", `/video/delete`, {
      body: {
        projectId: projectId,
        videoId: videoId,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export const initialState = {
  name: "",
  logo: "",
  address: "",
  about: "",
  isLoadingOne: false,
  error: "",
};

export function reducer(state, action) {
  switch (action.type) {
    case "previousData": {
      return {
        ...state,
        name: action.payload.name,
        logo: action.payload.logo,
        address: action.payload.address,
        about: action.payload.about,
      };
    }
    case "field": {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      };
    }
    case "save": {
      return {
        ...state,
        isLoadingOne: true,
      };
    }
    case "error": {
      return { ...state, error: "there is an error" };
    }
    case "success": {
      return initialState;
    }

    default:
      return state;
  }
}

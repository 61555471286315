import React from "react";
import {
  Flex,
  Text,
  Box,
  Button,
  Heading,
  Grid,
  Badge,
  SimpleGrid,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  useDisclosure,
} from "@chakra-ui/react";
import { statusEmoji, statusSwitch } from "../../data/projectData";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import Card from "../../components/card";
import ButtonColor from "../../components/button/ButtonColor";
import UpdateStatus from "../../components/project/UpdateStatus";
import { IoFlash } from "react-icons/io5";

export default function OrderSummary(props) {
  const { colorMode } = useColorMode();
  const { projectData, admin, projectId, setReloadData, isLoading } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex padding='20px' flexDirection='column'>
      <Box
        // bg={colorMode === "light" ? "white" : "#232323"}

        rounded='xl'
        // borderWidth={colorMode === "light" ? "1px" : "0px"}
        // borderColor='#F0F0F0'
        // boxShadow='sm'
      >
        <SimpleGrid
          columns={{
            base: 1,
            md: 1,
          }}
          spacing='20px'>
          <Grid direction='column' gap='20px'>
            <Flex justifyContent='space-between'>
              <Text
                fontSize='sm'
                color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                Status
              </Text>
              <Text>
                {projectData?.status && (
                  <Badge
                    fontSize='xs'
                    variant='solid'
                    borderRadius='full'
                    px='10px'
                    py='5px'
                    // bgColor={statusSwitch(
                    //   projectData &&
                    //     projectData.status &&
                    //     projectData.status.value
                    // )}
                    bgColor='whiteAlpha.100'>
                    {statusEmoji(projectData?.status?.value)} {"  "}
                    {projectData && projectData.status
                      ? projectData.status.label
                      : ""}
                  </Badge>
                )}
              </Text>
            </Flex>
            <Flex justifyContent='space-between'>
              <Text
                fontSize='sm'
                color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                Quantity
              </Text>
              <Text>
                {projectData && projectData.quantity
                  ? projectData.quantity
                  : ""}
              </Text>
            </Flex>

            <Flex justifyContent='space-between'>
              <Text
                fontSize='sm'
                color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                Total HT
              </Text>
              <Heading>
                {projectData && projectData.pricing
                  ? `${projectData.pricing}€`
                  : " "}
              </Heading>
            </Flex>

            <Flex justifyContent='space-between'>
              <Text
                fontSize='sm'
                color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                Won date
              </Text>
              <Text>
                {projectData && projectData.wonDate
                  ? dayjs(projectData.wonDate).format("DD/MM/YYYY")
                  : ""}
              </Text>
            </Flex>

            {/* Cancel */}

            {projectData && projectData.cancel && (
              <Alert status='error' variant='solid'>
                <AlertIcon />
                <AlertTitle mr={2}>Project canceled</AlertTitle>
              </Alert>
            )}
          </Grid>
        </SimpleGrid>
      </Box>
      {admin && !isLoading && (
        <ButtonColor
          title={
            <Flex alignItems='center'>
              <Box mr='5px'>
                <IoFlash />{" "}
              </Box>
              Update status
            </Flex>
          }
          click={() => onOpen()}
        />
      )}
      <UpdateStatus
        isOpen={isOpen}
        onClose={onClose}
        admin={admin}
        projectId={projectId}
        projectData={projectData}
        setReloadData={setReloadData}
      />
    </Flex>
  );
}

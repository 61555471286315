import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Divider,
  Button,
  Flex,
  Box,
  Text,
  Avatar,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorMode,
  Link,
} from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";

import {
  HiLocationMarker,
  HiPencilAlt,
  HiTrash,
  HiOutlineOfficeBuilding,
} from "react-icons/hi";
export default function Day(props) {
  const { tasks, day, rowIdx, i } = props;
  const [dayTasks, setDayTasks] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();

  // console.log("dayTasks", dayTasks);
  useEffect(() => {
    const data =
      tasks &&
      tasks.filter(
        (task) =>
          dayjs(task.dateJs).format("DD-MM-YY") === day.format("DD-MM-YY")
      );
    setDayTasks(data);
  }, [tasks, day]);
  return (
    <Box
      // borderRight="1px"
      // borderColor="gray.200"
      backgroundColor={colorMode === "light" ? "white" : "#232323"}
      minH='100px'
      pt='10px'
      pl='10px'
      borderTopLeftRadius={rowIdx === 0 && i === 0 && "xl"}
      borderTopRightRadius={rowIdx === 0 && i === 6 && "xl"}
      borderBottomLeftRadius={rowIdx === 4 && i === 0 && "xl"}
      borderBottomRightRadius={rowIdx === 4 && i === 6 && "xl"}
      // _first={{ borderTopLeftRadius: "md" }}

      // _first={{
      //   borderTopLeftRadius: "md",
      // }}
      // _last={{
      //   borderBottomRightRadius: "md",
      // }}
    >
      {rowIdx === 0 && (
        <p className='text-sm mt-1'>
          {day.locale("en").format("ddd").toUpperCase()}
        </p>
      )}
      <Text> {day.format("DD")}</Text>
      {dayTasks &&
        dayTasks.slice(0, 2).map((task, index) => {
          let taskUrl = `/tasks/${task.id}/projects/${task.videoId}/quotes/${task.projectId}`;

          return (
            // <Text>{task && task.title}</Text>;

            <Flex flexDirection='column' mb='40px' ml='5px' mr='10px'>
              <Link
                key={index}
                as={RouteLink}
                to={taskUrl}
                _hover={{ opacity: "0.7" }}>
                <Flex alignItems='center' mb='5px'>
                  <Badge
                    variant='solid'
                    fontSize='0.7em'
                    borderRadius='full'
                    px='10px'
                    py='5px'
                    bgColor={
                      task.category.value === "shooting" ? "#6799F5" : "#377D50"
                    }>
                    <Flex>
                      {task.category.value === "shooting" && (
                        <Flex>Shooting</Flex>
                      )}
                      {task.category.value === "interview" && (
                        <Flex>Interview</Flex>
                      )}
                      {/* <HiOutlineOfficeBuilding /> */}
                      {/* <Text pl="5px">{task && task.clientName}</Text> */}
                    </Flex>
                  </Badge>
                </Flex>

                <Flex>
                  {/* {task && task.title} */}
                  <Text>
                    {task && task.clientName} - {task && task.videoName}
                  </Text>
                </Flex>
                <Flex
                  alignItems='center'
                  mt='5px'
                  // color={colorMode === "light" ? "gray.500" : "white"}
                >
                  {/* {task.people && (
                  <Avatar mr="10px" size="xs" name={task.people} />
                )} */}
                  <Text fontSize='small'>{task.people}</Text>
                </Flex>
                {/* <Divider mt="30px" /> */}
              </Link>
            </Flex>
          );
        })}
      {dayTasks.length - 2 >= 1 ? (
        <Button size='xs' mb='20px' onClick={onOpen}>
          +{dayTasks.length - 2}
        </Button>
      ) : (
        ""
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {" "}
            {
              <Text textTransform='capitalize'>
                {" "}
                {dayTasks.length > 0 &&
                  dayjs(dayTasks[0].dateJs).format("dddd DD MMMM YYYY")}
              </Text>
            }
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {dayTasks &&
              dayTasks.map((task) => {
                return (
                  // <Text>{task && task.title}</Text>;

                  <Flex flexDirection='column' mb='40px' ml='5px' mr='10px'>
                    <Flex alignItems='center' mb='5px'>
                      <Badge
                        variant='solid'
                        fontSize='0.7em'
                        colorScheme={
                          task.category.value === "shooting" ? "blue" : "purple"
                        }>
                        <Flex>
                          {task.category.value === "shooting" && (
                            <Flex>Shooting</Flex>
                          )}
                          {task.category.value === "interview" && (
                            <Flex>Interview</Flex>
                          )}
                          {/* <HiOutlineOfficeBuilding /> */}
                          {/* <Text pl="5px">{task && task.clientName}</Text> */}
                        </Flex>
                      </Badge>
                    </Flex>
                    <Flex>
                      {/* {task && task.title} */}
                      <Text>
                        {task && task.clientName} - {task && task.videoName}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems='center'
                      mt='5px'
                      // color={colorMode === "light" ? "gray.500" : "white"}
                    >
                      {/* {task.people && (
                  <Avatar mr="10px" size="xs" name={task.people} />
                )} */}
                      <Text fontSize='small'>{task.people}</Text>
                    </Flex>
                    {/* <Divider mt="30px" /> */}
                  </Flex>
                );
              })}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

import axios from "axios";

export async function uploadFiles(file, fileName) {
  const url = "https://api.kronik.com/media/getSignedUrl";
  // console.log("API uploadFiles");
  // console.log("file :", file);
  // console.log("fileName :", fileName);

  return await axios
    .post(url, { fileName: fileName })
    .then((r) => {
      // console.log("r :", r);
      return r.data.preSignedUrl;
    })
    .catch((err) => console.error(err));

}

export async function sendFiles(APIFirstPost, file) {
  if (APIFirstPost) {
    return await axios
      .put(APIFirstPost, file)
      .then((r) => {
        // console.log("APIFirstPost :", APIFirstPost);
        // console.log("r2 :", r);
        //   status = "200";
        return r;
      })
      .catch((err) => console.error(err));
  }
}

import { AiFillCheckCircle } from "react-icons/ai";
import { BsFillInboxFill } from "react-icons/bs";
import { ImBlocked } from "react-icons/im";
import { IoBuildSharp } from "react-icons/io5";

export const statusOptions = [
  { value: "todo", label: "À faire" },
  { value: "inProgress", label: "En cours" },
  { value: "done", label: "Terminé" },
  { value: "blocked", label: "Bloqué" },
];

export const statusOptionsWithIcon = [
  {
    value: "todo",
    label: "À faire",
    icon: "📥",
    // <BsFillInboxFill size={32} />
  },
  { value: "inProgress", label: "En cours", icon: "🚧" },
  // <IoBuildSharp size={32} />

  {
    value: "done",
    label: "Terminé",
    icon: "✅",
    // <AiFillCheckCircle size={32} /> }
  },
  {
    value: "blocked",
    label: "Bloqué",
    icon: "🚫",
    // <ImBlocked size={32} />
  },
];

export const validationOptions = [
  { value: "validation", label: "En validation" },
  { value: "corection", label: "À modifier" },
  { value: "validated", label: "Validé" },
  { value: "", label: "" },
];

export const validationOptionsWithIcon = [
  {
    value: "validation",
    label: "En validation",
    icon: "💬",
    // <BsFillInboxFill size={32} />
  },
  {
    value: "corection",
    label: "À modifier",
    icon:
      // <IoBuildSharp size={32} />
      "❌",
  },
  {
    value: "validated",
    label: "Validé",
    icon: "✅",
    // <AiFillCheckCircle size={32} />
  },
  { value: "", label: "" },
];

export const categoryOptions = [
  { value: "kickOff", label: "Kick off" },
  { value: "director", label: "Director" },
  { value: "narrative", label: "Narrative" },
  { value: "interview", label: "Interview" },
  { value: "shooting", label: "Shooting" },
  { value: "makingUp", label: "Making up" },
  { value: "preProduction", label: "Prémontage" },
  { value: "motionDesign", label: "Motion design" },
  { value: "production", label: "Montage" },
  { value: "subTitles", label: "Sous titres" },
  { value: "thumbnail", label: "Vignettes" },
];

export function statusSwitch(param) {
  switch (param) {
    case "done":
      return "#377D50";
    case "inProgress":
      return "#6799F5";
    case "todo":
      return "gray";
    case "blocked":
      return "#6799F5";
    default:
      return "gray";
  }
}

export function statusEmoji(param) {
  switch (param) {
    case "done":
      return "✅";
    case "inProgress":
      return "🚧";
    case "todo":
      return "📥";
    case "blocked":
      return "🚫";
    default:
      return "📥";
  }
}

export function validationSwitch(param) {
  switch (param) {
    case "validated":
      return "#377D50";
    case "validation":
      return "#6799F5";
    case "corection":
      return "#F3BF72";
    default:
      return "gray";
  }
}

export function validationEmoji(param) {
  switch (param) {
    case "validated":
      return "✅";
    case "validation":
      return "💬";
    case "corection":
      return "❌";
    default:
      return "";
  }
}

export const customStyles = {
  option: (provided, state) => ({
    ...provided,

    opacity: 0.8,
    padding: 20,
  }),
  control: (provided) => ({
    ...provided,
    border: "0",
    boxShadow: "none",

    "&:hover": {
      cursor: "pointer",
    },

    border: "0px dotted black",

    background: "none",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "white",
    // backgroundColor: state.data.color,
    backgroundColor:
      state.data.value === "todo"
        ? "#FDB544"
        : state.data.value === "inProgress"
        ? "#63A5FC"
        : state.data.value === "done"
        ? "#01CE81"
        : state.data.value === "blocked"
        ? "#FE1795"
        : state.data.value === "validation"
        ? "#63A5FC"
        : state.data.value === "corection"
        ? "#FDB544"
        : state.data.value === "validated"
        ? "#01CE81"
        : "gey",

    borderRadius: "5px",
    padding: "5px",
    fontSize: "12px",
  }),

  indicatorSeparator: () => null,
  dropdownIndicator: () => ({
    display: "none",
  }),
};

import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  styles: {
    global: (props) => ({
      body: {
        // bg: props.colorMode === "light" ? "#F8FAFC" : "#171717",
        bg: props.colorMode === "light" ? "#F8FAFC" : "#1C1C1C",
        color: props.colorMode === "light" ? "black" : "white",
      },
    }),
  },

  colors: {
    // primary: "#5080EA",
    // primary: "#4150DE ",
    primary: "#302F30",
    lightBorder: "#333333",
    darkBorder: "#E1E1E1",
    // secondary: "#142D53",
    secondary: "#15125B",
    tertiary: "#F2F7FA",
    // secondary: "gray.200",
    // secondary: "white",
    // secondary: "#EDF2F6",
    light: "#F8F9FD",
    dark: "#333",
    highlight: "",
    warning: "",
    danger: "",
  },
  components: {
    Modal: {
      baseStyle: (props) => ({
        dialog: {
          bg: props.colorMode === "light" ? "white" : "#171717",
        },
      }),
    },
    Menu: {
      baseStyle: (props) => ({
        list: {
          bg: props.colorMode === "light" ? "white" : "#171717",
        },
      }),
    },
  },
});

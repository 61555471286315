import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Grid,
  Heading,
  useColorMode,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Link,
  Flex,
  IconButton,
  useDisclosure,
  isLoading,
  Avatar,
  AvatarGroup,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { Link as RouteLink } from "react-router-dom";
import NewVideo from "../../components/video/NewVideo";
import { HiPencilAlt } from "react-icons/hi";
import { IoFlash } from "react-icons/io5";
import { HiPlusCircle } from "react-icons/hi";

import { newStatusSwitch } from "../../data/videoData";
import CardTitle from "../../components/card/CardTitle";
import TableRow from "../../components/table/TableRow";
import EditVideo from "../../components/video/EditVideo";
import UpdateStatus from "../../components/project/UpdateStatus";
import NewProject from "../../components/project/NewProject";
import { fetchProjects } from "./api";
import { EditProject } from "../../components/project/EditProject";
import { statusEmoji } from "../../data/projectData";
export default function ProjectsList(props) {
  const { colorMode } = useColorMode();
  const {
    reloadData,
    setReloadData,
    admin,
    isLoading,
    setIsLoading,
    clientId,
    setClient,
    client,
    projects,
    setProjects,
    // setProject,
  } = props;
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenStatus,
    onOpen: onOpenStatus,
    onClose: onCloseStatus,
  } = useDisclosure();
  const [currentVideo, setCurrentVideo] = useState("");
  const [currentProject, setCurrentProject] = useState("");

  // console.log("projects", projects);
  useEffect(() => {
    setIsLoading(true);
    fetchProjects(clientId, admin).then((response) => {
      setProjects(response);
      setIsLoading(false);
      // console.log("response.client", response.client);
      setClient(response.client);
    });
  }, [reloadData]);

  function statusSwitch(param) {
    switch (param) {
      case "finished":
        return "#377D50";
      case "production":
        return "#6799F5";
      case "preparation":
        return "#6799F5";
      case "blocked":
        return "#D8707F";
      default:
        return "gray";
    }
  }

  function OpenProject(e, link) {
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function clickStatus(e, project) {
    e.preventDefault();

    // firstRenderStatus.current = true;
    // firstRenderValidation.current = true;
    // console.log("project", project);
    setCurrentProject(project);
    onOpenStatus();
    e.stopPropagation();
  }

  // console.log("projectsData", projects);
  // console.log("project avec tout", project);
  // function openTask(e, video) {
  //   e.preventDefault();

  //   firstRenderStatus.current = true;
  //   firstRenderValidation.current = true;

  //   setCurrentVideo(video);
  //   onOpenEdit();
  //   e.stopPropagation();
  // }

  function openEdit(e, project) {
    e.preventDefault();
    // console.log("currentProject:", project);
    setCurrentProject(project);
    onOpenEdit();
    e.stopPropagation();
  }

  // const firstRenderStatus = useRef(true);
  // const firstRenderValidation = useRef(true);
  return (
    <Box
      // borderWidth={colorMode === "light" ? "1px" : "0px"}
      // boxShadow="sm"
      // rounded="md"
      // bg={colorMode === "light" ? "white" : "gray.900"}
      // mb="6"
      mt='40px'>
      <CardTitle title='Quotes' isActive={true} />

      {/* <TableRow
        click={(e) => OpenProject(e, Link)}
        gridCol={
          admin ? "1fr 6fr 2fr 3fr 2fr  2fr 1fr" : "6fr 2fr 3fr 2fr  2fr"
        }
      >
        {admin && (
          <IconButton
            aria-label="Edit"
            icon={<IoFlash />}
            rounded="full"
            size="sm"
            mr="10px"
          />
        )}
        <Box color="gray.500">Project Name </Box>
        <Box color="gray.500">
   
          <Badge colorScheme={statusSwitch(project?.project?.status?.value)}>
         Préparation
          </Badge>
  
        </Box>
        <Flex alignItems="center">
          <AvatarGroup size="sm" max={2} spacing={-1.5} mr="5px">
            {project.accountManager &&
              project.accountManager.map((project, index) => {
                return (
                  <Avatar
                 
                    key={index}
                    name={project}
                  />
                );
              })}
          </AvatarGroup>
          {project.accountManager}
        </Flex>

        <Box color="gray.500">{project.videoCount}</Box>
        <Box color="gray.500">
          {project?.project?.pricing} {project?.project?.pricing && "€"}
        </Box>
        {admin && (
          <Flex
            // onClick={(e) => openEdit(e, project)}
            color="gray.500"
            alignItems="center"
            _hover={{ opacity: "0.4" }}
          >
            <HiPencilAlt />
          </Flex>
        )}
      </TableRow> */}

      {projects &&
        projects.projects.map((item, index) => {
          const Link = `/quotes/${item._id}`;
          return (
            // <Box key={index}>
            <TableRow
              key={index}
              click={(e) => OpenProject(e, Link)}
              gridCol={
                admin ? "1fr 6fr  3fr 2fr  2fr 1fr" : "6fr  3fr 2fr  2fr"
              }>
              {admin && (
                <IconButton
                  aria-label='Edit'
                  icon={<IoFlash />}
                  rounded='full'
                  size='sm'
                  onClick={(e) => clickStatus(e, item)}
                  mr='10px'
                />
              )}
              <Box color={colorMode === "light" ? "gray.500" : "white"}>
                {item?.name}{" "}
                {item?.cancel && (
                  <Badge variant='solid' colorScheme='red' ml='10px'>
                    Cancel
                  </Badge>
                )}
              </Box>
              <Box>
                <Badge
                  fontSize='xs'
                  variant='solid'
                  borderRadius='full'
                  px='10px'
                  py='5px'
                  // bgColor={statusSwitch(item?.status?.value)}
                  bgColor='whiteAlpha.100'>
                  {statusEmoji(item?.status?.value)} {"  "}
                  {item?.status?.label}
                </Badge>
              </Box>
              <Box color={colorMode === "light" ? "gray.500" : "white"}>
                {item?.quantity}
              </Box>
              <Box color={colorMode === "light" ? "gray.500" : "white"}>
                {" "}
                {item?.pricing && item?.pricing + "€"}{" "}
              </Box>
              {admin && (
                <Flex
                  onClick={(e) => openEdit(e, item)}
                  color={colorMode === "light" ? "gray.500" : "white"}
                  alignItems='center'
                  _hover={{ opacity: "0.4" }}>
                  <HiPencilAlt />
                </Flex>
              )}
            </TableRow>
            // </Box>
          );
        })}

      <Box py='10px'>
        {admin && !isLoading && (
          <Button
            w='full'
            onClick={() => onOpen()}
            border='1px solid'
            borderColor={
              colorMode === "light" ? "blackAlpha.200" : "whiteAlpha.300"
            }
            rounded='lg'
            bg={colorMode === "light" ? "#F8FAFC" : "#1C1C1C"}>
            <Flex alignItems='center'>
              <Box mr='5px'>
                <HiPlusCircle />
              </Box>{" "}
              Add a quote
            </Flex>
          </Button>
        )}
      </Box>

      <NewProject
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        reloadData={reloadData}
        setReloadData={setReloadData}
        preSelectedClient={clientId}
      />

      <EditProject
        isOpen={isOpenEdit}
        onClose={onCloseEdit}
        setReloadData={setReloadData}
        reloadData={reloadData}
        projectData={currentProject}
        client={client}
      />

      <UpdateStatus
        isOpen={isOpenStatus}
        onClose={onCloseStatus}
        admin={admin}
        projectId={currentProject?._id}
        projectData={currentProject}
        setReloadData={setReloadData}
      />
    </Box>
  );
}

import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import CardTitle from "../../components/card/CardTitle";
import MainColumn from "../../components/column/MainColumn";
import ProjectsList from "./ProjectsList";

export default function Main({ projects, admin, reloadData, setReloadData }) {
  return (
    <MainColumn>
      <Flex direction='column' px='20px'>
        <Box pt='20px'>
          <CardTitle title='Description' isActive='false' />

          <Text whiteSpace='pre-wrap' color='white'>
            Description here
          </Text>
        </Box>
        <Box pt='20px'>
          <Text whiteSpace='pre-wrap' color='white'>
            Contact : name
          </Text>
        </Box>
        <ProjectsList
          // projects={""}
          admin={admin}
          reloadData={reloadData}
          setReloadData={setReloadData}
        />

        <Box pt='20px'>
          <CardTitle title='Term' isActive='false' />

          <Text whiteSpace='pre-wrap' color='white'>
            Term here
          </Text>
        </Box>

        <Box pt='20px'>
          <CardTitle title='Note (not visible)' isActive='false' />

          <Text whiteSpace='pre-wrap' color='white'>
            Note here
          </Text>
        </Box>
      </Flex>
    </MainColumn>
  );
}

import { Box, Button, Grid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import { deleteBrief, fetchBrief } from "./api";


export default function Brief(){

    const { admin, user } = useUser();
    const [brief, setBrief] = useState([]);

    let { briefId } = useParams();

    useEffect(()=> {
        fetchBrief(briefId, admin).then((response) => {
            setBrief(response)
            // console.log("briefs list", response)
        })
    },[])

    return(
       <Box>

    <Grid templateColumns="2fr 5fr 2fr" gap="10px">
        <Box>
            Sidebar
        </Box>
        <Box>
            <Box>
                Brief
            </Box>
            <Box>
                {brief  && 

                  
                        <Box>
                            {brief.company}
                            <Button onClick={(e) => deleteBrief(e, brief._id, admin)}>Delete</Button>
                        </Box>
                    
                }
            </Box>
        </Box>
        <Box>
            Rightbar
        </Box>
    </Grid>

       </Box>
    )
}


import { API } from "aws-amplify";

export default async function fetchJob(jobId, admin) {
  let url = `/job/get/${jobId}?admin=${admin}`;
  return API.get("kronik", url, {})
}


export async function fetchTasks(projectId, videoId, admin) {
  let url = `/task/get/${projectId}/${videoId}?admin=${admin}`;

  return API.get("kronik", url, {});
}





import { API } from "aws-amplify";

export async function createFreelance(state) {
  let url = `/freelance/create`;

  try {
    await API.post("kronik", url, {
      body: {
        given_name: state.given_name,
        family_name: state.family_name,
        email: state.email,
        url: state.url,
        state: state.about,
        // profil: state.profil,
        note: state.note,
        experience: state.experience,
        adress: state.adress,
        bankAccount: state.bankAccount,
        birthDate: state.birthDate,
        status: state.status,
        setup: state.setup,
        skills: state.skills,
        category: state.category,
        reference: state.reference,
        averageGrade: state.averageGrade,
        review: state.review,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function updateFreelance(freelanceId, state, admin) {
  // console.log("state", state);
  try {
    await API.put("kronik", `/freelance/update/${freelanceId}?admin=${admin}`, {
      body: {
        given_name: state.given_name,
        family_name: state.family_name,
        // email: state.email,
        url: state.url,
        about: state.about,
        // profil: state.profil,
        note: state.note,
        experience: state.experience,
        adress: state.adress,
        bankAccount: state.bankAccount,
        birthDate: state.birthDate,
        status: state.status,
        setup: state.setup,
        skills: state.skills,
        category: state.category,
        reference: state.reference,
        averageGrade: state.averageGrade,
        review: state.review,
        // jobs:state.jobs
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function deleteFreelance(freelanceId, admin) {
  const url = `/freelance/delete/${freelanceId}?admin=${admin}`;
  try {
    await API.put("kronik", url);
  } catch (e) {
    console.log(e.message);
  }
}

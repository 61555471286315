import React from "react";

import {
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuOptionGroup,
  Button,
  Checkbox,
  Switch,
  Box,
  Flex,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { MdAddBox } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { skillOptions, statusOptions } from "../../data/freelanceData";

export default function NavBar(props) {
  const history = useHistory();

  const {
    status,
    setStatus,
    skill,
    setSkill,
    queryStatus,
    querySkill,
    admin,
    onOpen,
    isLoading,
    freelances,
  } = props;

  return (
    <Flex justifyContent='space-between' py='20px'>
      {admin && (
        <Flex gap='10px'>
          {/* Status filter */}
          <Menu>
            <MenuButton
              as={Button}
              variant='outline'
              rounded='full'
              rightIcon={<ChevronDownIcon />}
              textTransform='capitalize'>
              {`${queryStatus === "all" ? "All status" : queryStatus} (${
                freelances?.length
              })`}
            </MenuButton>

            <MenuList maxH='300px' overflow='auto'>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?status=all&skill=${querySkill?.toLowerCase()}`
                  );
                }}>
                All
              </MenuItem>

              {statusOptions &&
                statusOptions.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        history.push(
                          `?status=${
                            item?.value
                          }&skill=${querySkill?.toLowerCase()}`
                        );
                      }}>
                      {item.label}
                    </MenuItem>
                  );
                })}
            </MenuList>
          </Menu>

          {/* Skills filter */}
          <Menu>
            <MenuButton
              as={Button}
              variant='outline'
              rounded='full'
              rightIcon={<ChevronDownIcon />}
              textTransform='capitalize'>
              {querySkill === "all" ? "All skills" : querySkill}
            </MenuButton>

            <MenuList maxH='300px' overflow='auto'>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?status=${queryStatus?.toLowerCase()}&skill=all`
                  );
                }}>
                All
              </MenuItem>

              {skillOptions &&
                skillOptions.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        history.push(
                          `?status=${queryStatus?.toLowerCase()}&skill=${
                            item?.value
                          }`
                        );
                      }}>
                      {item.label}
                    </MenuItem>
                  );
                })}
            </MenuList>
          </Menu>
        </Flex>
      )}
      <Spacer />
      {admin && !isLoading && (
        <Button color='white' bg='primary' onClick={() => onOpen()}>
          <MdAddBox /> <Text ml='5px'>Add a freelance</Text>
        </Button>
      )}
    </Flex>
  );
}

import {
  Badge,
  Box,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Img,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { HiPencilAlt } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import FilterByClient from "../../components/client/FilterByClient";
import MainColumn from "../../components/column/MainColumn";
import { Updating } from "../../components/notification/Updating";
import { useUser } from "../../libs/UserContext";
import getFiles, { getClients, getClientsMe } from "./api";
import FilesList from "./FilesList";
import Header from "./Header";
import NavBar from "./NavBar";

export default function Library() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { colorMode } = useColorMode();
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [clients, setClients] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [queryClient, setQueryClient] = useState("");
  const [queryDisplay, setQueryDisplay] = useState("");
  const [queryCategory, setQueryCategory] = useState("");
  const [queryTag, setQueryTag] = useState("");
  const { admin, freelance, user } = useUser();
  const location = useLocation();
  const [reloadData, setReloadData] = useState(false);

  const [pageNum, setPageNum] = useState(0);
  const [lastElement, setLastElement] = useState(null);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNum((no) => no + 1);
      }
    })
  );

  const TOTAL_PAGES = 80;

  function useQuery() {
    // const { search } = useLocation();
    // return React.useMemo(() => new URLSearchParams(search), [search]);
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  useEffect(() => {
    console.log("useEffect location");

    setQueryClient(query.get("client") ? query.get("client") : "all");
    setQueryCategory(query.get("category") ? query.get("category") : "all");
    setQueryTag(query.get("tag") ? query.get("tag") : "all");
    setQueryDisplay(
      query.get("display")
        ? query.get("display")
        : user.username === "florian@kronik.com" ||
          user.username === "christophe@kronik.com" ||
          user.username === "mathieu@kronik.com"
        ? "all"
        : "me"
    );
  }, [location]);

  useEffect(() => {
    console.log("useEffect reset");
    setFiles([]);
    setPageNum(0);
    // setReloadData(!reloadData);
    !isFirstLoad && setReloadData(!reloadData);
    isFirstLoad && setReloadData(true);

    // reloadData && setReloadData(false);
  }, [queryClient, queryDisplay, queryCategory]);

  useEffect(() => {
    setIsLoading(true);
    console.log("useEffect getFiles");

    queryDisplay &&
      pageNum <= TOTAL_PAGES &&
      getFiles(
        queryDisplay,
        TOTAL_PAGES,
        pageNum,
        admin,
        queryClient,
        queryCategory
      ).then((response) => {
        setIsFirstLoad(false);
        console.log("useEffect get Files");
        // console.log("getFiles", response);
        let allFiles = new Set([...files, ...response]);
        setFiles(
          [...allFiles].sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
        );
        // console.log("allFiles", [...allFiles]);
        setIsLoading(false);
      });
  }, [pageNum, reloadData]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  useEffect(() => {
    if (
      (queryClient.length > 0 || queryDisplay === "all") &&
      admin &&
      queryDisplay !== "me"
    ) {
      console.log("useEffect getClients all");
      // console.log("queryClient all", queryClient);
      getClients().then((response) => {
        // console.log("console clients all", response);
        setClients(response.sort((item) => item.name));
      });
    } else {
      // console.log("queryClient me", queryClient);
      console.log("useEffect getClients me");
      getClientsMe().then((response) => {
        // console.log("clients", response);
        // console.log("console clients me", response);

        setClients(
          response
          // .sort((item) => item.name)
        );
      });
    }
  }, [queryClient, queryDisplay]);

  // useHotkeys("meta+k", () => onOpen());

  return (
    <Box w='100%'>
      <MainColumn>
        <Header />
        <MainColumn>
          <Box px='20px' pt='20px'>
            {/* {!isLoading && ( */}
            <>
              <NavBar
                clients={clients}
                admin={admin}
                freelance={freelance}
                queryClient={queryClient}
                queryDisplay={queryDisplay}
                queryCategory={queryCategory}
                isLoading={isLoading}
              />
              <FilesList
                // files={filteredFiles}
                files={files}
                setLastElement={setLastElement}
              />
              {pageNum - 1 === TOTAL_PAGES && (
                <p className='text-center my-10'>♥</p>
              )}
              {/* <FilesList files={files} /> */}
            </>
            {/* )} */}
          </Box>
          {isLoading && <Updating />}
        </MainColumn>
      </MainColumn>
      <FilterByClient
        clients={clients}
        isOpen={isOpen}
        onClose={onClose}
        page='library'
        // queryStatus={queryStatus}
        queryDisplay={queryDisplay}
        // queryManager={queryManager}
      />
    </Box>
  );
}

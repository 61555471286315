export const initialState = {
  name: "",
  brief: "",
  status: "",
  newStatus: "",
  date: "",
  category: "",
  subtitle: "",
  format: "",
  duration: "",
  error: "",
  archive: "",
  option: "",
  places: "",
  people: "",
  isLoadingOne: false,
  isLoadingTwo: false,
  cancel: false,
  pricing: "",
  interviews: "",
  shootings: "",
  bRoll: false,
  speakerLanguage: "",
  speakers: "",
  month: "",
  client: "",
  totalJobs: "",
};

export function reducer(state, action) {
  switch (action.type) {
    case "previousData": {
      return {
        ...state,
        name: action.payload.name,
        brief: action.payload.brief,
        status: action.payload.status,
        newStatus: action.payload.newStatus,
        date: action.payload.date,
        category: action.payload.category,
        subtitle: action.payload.subtitle,
        format: action.payload.format,
        duration: action.payload.duration,
        people: action.payload.people,
        places: action.payload.places,
        cancel: action.payload.cancel,
        pricing: action.payload.pricing,
        interviews: action.payload.interviews,
        shootings: action.payload.shootings,
        bRoll: action.payload.bRoll,
        speakerLanguage: action.payload.speakerLanguage,
        month: action.payload.month,
        speakers: action.payload.speakers,
        client: action.payload.client,
        totalJobs: action.payload.totalJobs,
      };
    }

    case "field": {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      };
    }
    case "save": {
      return {
        ...state,
        isLoadingOne: true,
      };
    }
    case "delete": {
      return {
        ...state,
        isLoadingTwo: true,
      };
    }
 

    case "success": {
      return {
        ...state,
        isLoadingOne: false,
      };
    }

    case "error": {
      return {
        ...state,
        error: "there is an error",
      };
    }
    default:
      return state;
  }
}

import { equipment, team, testimonialTemplate } from "../../data/quoteData";
const firstTemplate="premium"
export const initialState = {
    template: firstTemplate,
    location:testimonialTemplate[firstTemplate]?.location,
    goal:testimonialTemplate[firstTemplate]?.goal,
    brief:testimonialTemplate[firstTemplate]?.brief,
    distribution:testimonialTemplate[firstTemplate]?.distribution,
    speaker:testimonialTemplate[firstTemplate]?.speaker,
    style:testimonialTemplate[firstTemplate]?.style,
    quality: "initial",
    quantity: 0,
    interviewNumber:0,
    feedbackNumber:0,
    motion:testimonialTemplate[firstTemplate]?.motion,
    preparationInterview:testimonialTemplate.template?.preparationInterview,
    equipment:testimonialTemplate[firstTemplate]?.equipment,
    addOn:testimonialTemplate[firstTemplate]?.addOn,
    team:testimonialTemplate[firstTemplate]?.team,
    exportSimple:testimonialTemplate[firstTemplate]?.exportSimple,
    exportWithSubtitles:testimonialTemplate[firstTemplate]?.exportWithSubtitles,
    exportOtherLanguage:testimonialTemplate[firstTemplate]?.exportOtherLanguage,
    description: "",
    isOtherSubtitlesLanguage: false,
  };
  
  export function reducer(state, action) {
    switch (action.type) {
    
    case "changeTemplate": {
            return {
              ...state,
              template: action.payload.template,
              location: action.payload.location,
              brief:action.payload.brief,
              goal:action.payload.goal,
              distribution:action.payload.distribution,
              style:action.payload.style,
              speaker:action.payload.speaker,
              preparationInterview: action.payload.preparationInterview,
              motion:action.payload.motion,
              equipment:action.payload.equipment,
              addOn:action.payload.addOn,
              team:action.payload.team,
              exportSimple:action.payload.exportSimple,
              exportWithSubtitles:action.payload.exportWithSubtitles,
              exportOtherLanguage:action.payload.exportOtherLanguage,
              isOtherSubtitlesLanguage:action.payload.isOtherSubtitlesLanguage,
            //   preparationInterview: testimonialTemplate.standard.preparationInterview,
            //   quality: action.payload.quality,
            //   quantity: action.payload.quantity,
            //   interviewNumber: action.payload.interviewNumber,
            //   feedbackNumber: action.payload.feedbackNumber,
            //   motion: action.payload.motion,
            //   exportSimple: action.payload.exportSimple,
            //   exportWithSubtitles: action.payload.exportWithSubtitles,
            //   team: action.payload.team,
            //   description: action.payload.description,
             
            };
          }
    case "previousData": {
        return {
          ...state,
          template: action.payload.template,
         
        };
      }
    case "field": {
        return {
          ...state,
          [action.payload.fieldName]: action.payload.value,
        };
      }
    case "save": {
        return {
          ...state,
          isLoadingOne: true,
        };
      }
    case "delete": {
        return {
          ...state,
          isLoadingTwo: true,
        };
      }
    case "error": {
        return { ...state, error: "there is an error" };
      }
    case "success": {
        return initialState;
      }
  
    default:
        return state;
    }
  }
  
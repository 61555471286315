import React, { useState, useEffect } from "react";
import { Link as RouteLink } from "react-router-dom";
import {
  Link,
  Flex,
  Grid,
  Box,
  Heading,
  Text,
  useColorMode,
  Avatar,
  AvatarGroup,
  Badge,
  IconButton,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import TableRow from "../../components/table/TableRow";
import { IoFlash } from "react-icons/io5";
// import UpdateStatus from "../../components/project/UpdateStatus";
import { HiPencilAlt } from "react-icons/hi";
import UpdateStatus from "../../components/video/UpdateStatus";
// import { EditProject } from "../../components/project/EditProject";

export function GroupByMonth(props) {
  const { videos, admin, reloadData, setReloadData } = props;
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // console.log("videos ", videos);
  const {
    isOpen: isOpenStatus,
    onOpen: onOpenStatus,
    onClose: onCloseStatus,
  } = useDisclosure();
  const [currentVideo, setCurrentVideo] = useState("");

  function statusSwitch(param) {
    switch (param) {
      case "finished":
        return "#377D50";
      case "production":
        return "#6799F5";
      case "preparation":
        return "gray";
      case "blocked":
        return "#D8707F";
      default:
        return "gray";
    }
  }

  const groupBy = (array, key) => {
    // const arraySortByDate = array.sort(function (b, a) {
    //   if (a.project.wonDate > b.project.wonDate) {
    //     return -1;
    //   }
    //   if (a.project.wonDate < b.project.wonDate) {
    //     return 1;
    //   }
    //   return 0;
    // });

    // console.log("arraySortByDate", array);
    return array.reduce((groupedProjects, project) => {
      if (groupedProjects[project[key]] == null)
        groupedProjects[project[key]] = [];
      groupedProjects[project[key]].push(project);
      return groupedProjects;

      //   (groupedProjects[project[key].name] =
      //     groupedProjects[project[key].name] || []).push(project);
      //   return groupedProjects;
    }, {});
  };

  const projectsGroupByMonth = videos && groupBy(videos, "monthRevenue");

  // console.log("projectsGroupByMonth", projectsGroupByMonth);

  function clickStatus(e, video) {
    e.preventDefault();

    setCurrentVideo(video);
    // console.log("project", project);
    onOpenStatus();
    e.stopPropagation();
  }

  // function openEdit(e, project) {
  //   e.preventDefault();
  //   // console.log("currentProject:", project);
  //   setCurrentProject(project);
  //   onOpen();
  //   e.stopPropagation();
  // }

  return (
    <Flex direction='column'>
      {videos &&
        projectsGroupByMonth &&
        Object.keys(projectsGroupByMonth).map((item, index) => {
          const totalPricing =
            projectsGroupByMonth &&
            projectsGroupByMonth[item].reduce((acc, currenVal) => {
              return acc + currenVal.pricing;
            }, 0);

          const totalCosts =
            projectsGroupByMonth &&
            projectsGroupByMonth[item].reduce((acc, currenVal) => {
              return acc + currenVal.totalJobs;
            }, 0);

          return (
            <Box
              key={index}
              mb='40px'
              // bg={colorMode === "light" ? "#F6F6F6" : "gray.900"}
              rounded='xl'
              // p="10px"
              pb='0px'>
              <Grid
                gridTemplateColumns={"6fr 3fr 2fr 2fr  4fr 2fr 2fr "}
                // px="3"
                py='10px'>
                <Heading
                  as='h2'
                  fontSize='xl'
                  mb='2'
                  textTransform='capitalize'>
                  {item ? dayjs(item).format("MMM YYYY") : ""}
                </Heading>

                <Text fontSize='sm'>Client</Text>
                <Text fontSize='sm'>Status</Text>
                <Text fontSize='sm'>Month</Text>
                <Text fontSize='sm'>Project manager</Text>
                <Text fontSize='lg' as='b'>
                  {totalPricing}€
                </Text>
                <Text fontSize='lg'>{totalCosts}€</Text>
                {/* {admin && <Text fontSize='sm'>Edit</Text>} */}
              </Grid>

              {videos &&
                projectsGroupByMonth &&
                projectsGroupByMonth[item].map((video, index) => {
                  return (
                    <Link
                      key={index}
                      as={RouteLink}
                      to={`/projects/${video.id}/quotes/${video.projectId}`}
                      _hover={{ textDecor: "none" }}>
                      <TableRow
                        key={index}
                        gridCol={"6fr 3fr 2fr 2fr  4fr 2fr 2fr "}>
                        <Box
                          color={colorMode === "light" ? "gray.500" : "white"}>
                          {/* {admin && (
                            <IconButton
                              aria-label="Edit"
                              icon={<IoFlash />}
                              rounded="full"
                              size="sm"
                              onClick={(e) => clickStatus(e, video)}
                              mr="10px"
                            />
                          )} */}
                          {video.title}{" "}
                        </Box>
                        <Flex
                          alignItems='center'
                          color={colorMode === "light" ? "gray.500" : "white"}>
                          {video.clientLogo && (
                            <Image
                              mr='10px'
                              borderRadius='md'
                              boxSize='32px'
                              alt={`Logo of ${video.client}`}
                              src={`https://aaliz317.twic.pics/${video.clientLogo}`}
                            />
                          )}
                          {video.client}
                          {video.cancel && (
                            <Badge colorScheme='red' ml='10px'>
                              Cancel
                            </Badge>
                          )}
                        </Flex>
                        <Box
                          color={colorMode === "light" ? "gray.500" : "white"}>
                          {video.newStatus && (
                            <Badge
                              variant='solid'
                              borderRadius='full'
                              px='10px'
                              py='5px'
                              bgColor={statusSwitch(
                                video &&
                                  video.newStatus &&
                                  video.newStatus.value
                              )}>
                              {video.newStatus.label}
                            </Badge>
                          )}
                        </Box>
                        <Box>
                          {video.monthRevenue &&
                            dayjs(video.monthRevenue).format("MMM YYYY")}
                        </Box>
                        <Flex alignItems='center'>
                          <AvatarGroup
                            size='sm'
                            max={2}
                            spacing={-1.5}
                            mr='5px'>
                            {video.accountManager &&
                              video.accountManager.map((project, index) => {
                                return (
                                  <Avatar
                                    // bg="gray.300"
                                    // color="white"
                                    key={index}
                                    name={project}
                                  />
                                );
                              })}
                          </AvatarGroup>
                          {video.accountManager}
                        </Flex>
                        <Box
                          color={colorMode === "light" ? "gray.500" : "white"}>
                          {video.pricing && video.pricing}
                          {"€"}
                        </Box>

                        <Box
                          color={colorMode === "light" ? "gray.500" : "white"}>
                          {video.totalJobs > 0 && `${video.totalJobs}€`}
                        </Box>
                        {/* {admin && (
                          <Flex
                            onClick={(e) => openEdit(e, project)}
                            color="gray.500"
                            alignItems="center"
                            _hover={{ opacity: "0.4" }}
                          >
                            <HiPencilAlt />
                          </Flex>
                        )} */}
                      </TableRow>
                    </Link>
                  );
                })}
            </Box>
          );
        })}
      {/* <EditProject
        isOpen={isOpen}
        onClose={onClose}
        setReloadData={setReloadData}
        reloadData={reloadData}
        projectData={currentProject?.project}
        client={currentProject?.client}
      /> */}

      {/* <UpdateStatus
        isOpen={isOpenStatus}
        onClose={onCloseStatus}
        videoData={currentVideo}
        admin={admin}
        projectId={currentVideo?.projectId}
        videoId={currentVideo?.id}
        projectName={currentVideo?.project}
        videoName={currentVideo?.title}
        setReloadData={setReloadData}
      /> */}
    </Flex>
  );
}

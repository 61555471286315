import { API } from "aws-amplify";

export async function createJob(
    email,
    projectId,
    projectName,
    clientId,
    clientName,
    video,
    title,
    brief,
    pricing,
    category,
    role,
    status,
    sendEmail,
) {
  try {
    // console.log("sendEmail", sendEmail)
      const url = `/job/create`
    await API.post(
      "kronik",
      url,
      {
        body: {
            email: email,
            projectId:projectId,
            clientId:clientId,
            clientName:clientName,
            video: { role: role.value, video: video },
            videoId:video,
            status:status,
            title:title,
            brief:brief,
            pricing:Number(pricing),
            ipAdress:null,
            note:null,
            category:category,
            rating:null,
            deliverables:null,
            paymentStatus:null,
            internalFeedback:null,
            clientFeedback:null,
            name:projectName, 
            sendEmail:sendEmail 
        },
      }
    );
  
  } catch (e) {
    console.log(e.message);
  }
}



export async function updateJob(

    jobId,
    email,
    projectId,
    clientId,
    clientName,
    video,
    title,
    brief,
    pricing,
    category,
    role,
    status,
    sendEmail,
    admin
) {
  try {
      // console.log("brief",brief)
      // console.log("clientId",clientId)
      // console.log("email",email)
      // console.log("projectId",projectId)
      // console.log("clientName",clientName)
      // console.log("video",role.value + " - "+ video._id)
      // console.log("videoId",video._id)
      // console.log("status",status)
      // console.log("title",title)
      // console.log("pricing",Number(pricing))
      // console.log("category",category)
      const url = `/job/update/${jobId}?admin=${admin}`
    await API.put(
      "kronik",
      url,
      {
        body: {
            email: email,
            projectId:projectId,
            clientId:clientId,
            clientName:clientName,
            video: { role: role.value, video: video._id },
            videoId:video._id,
            status:status,
            title:title,
            brief:brief,
            pricing:Number(pricing),
            ipAdress:null,
            note:null,
            category:category,
            rating:null,
            deliverables:null,
            paymentStatus:null,
            internalFeedback:null,
            clientFeedback:null,
            sendEmail:sendEmail
            // name:projectName, 
        },
      }
    );
  
  } catch (e) {
    console.log(e.message);
  }
}


const dev = {
  name: "dev",
  s3: {
    REGION: "eu-west-3",
    BUCKET: "kronik-media-uploads",
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://api.kronik.com",
  },
  cognito: {
    MANDATORY_SIGN_IN: false,
    REGION: "us-east-2",
    // USER_POOL_ID: "us-east-2_XMYxqQXgY", // TEST
    // APP_CLIENT_ID: "3ar1oupsc4fj7nj94vun6u62sv", // TEST
    USER_POOL_ID: "us-east-2_oFFLQFkoV", // PROD
    APP_CLIENT_ID: "981f3roc5j3t3p19757ku0uir", // PROD
    IDENTITY_POOL_ID: "us-east-2:f994800f-47d3-4ab2-8299-b46087543007", // PROD
  },
};

const prod = {
  name: "prod",
  s3: {
    REGION: "eu-west-3",
    BUCKET: "kronik-media-uploads",
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://api.kronik.com",
  },
  cognito: {
    MANDATORY_SIGN_IN: false,
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_oFFLQFkoV",
    APP_CLIENT_ID: "981f3roc5j3t3p19757ku0uir",
    IDENTITY_POOL_ID: "us-east-2:f994800f-47d3-4ab2-8299-b46087543007",
  },
};

const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
  // AMAZON CONFIG
  ...config,
};

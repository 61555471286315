import React, { useState, useRef, useEffect } from "react";
import { Flex, Box, Text, Heading, Button, Input } from "@chakra-ui/react";
import { AiFillCheckCircle } from "react-icons/ai";

import { sendFiles, uploadFiles } from "./api";

export default function Upload() {
  const [file, setFile] = useState("");
  const [confirm, setConfirm] = useState("");

  const hiddenFileInput = useRef(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUpload = event.target.files[0];
    uploadFiles(fileUpload, fileUpload.name).then((response) => {
      // console.log("response:", response);
      setFile(fileUpload);
      sendFiles(response, fileUpload).then((response2) => {
        // console.log("response2:", response2);
        setConfirm(response2);
      });
    });

    // setFile(fileUpload);
  };
  return (
    <Flex
      direction="column"
      pt={{ base: "80px", sm: "30px" }}
      // px={{ base: "20px", sm: "40px" }}
    >
      <Heading as="h1" fontSize="2xl" mb="40px">
        Upload
      </Heading>

      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <Button onClick={handleClick} className="btn btn-primary">
        {!file && !confirm && "Select"}
        {file && !confirm && "uploading..."}
        {confirm && (
          <Flex>
            <AiFillCheckCircle />
            <Text>Finished</Text>
          </Flex>
        )}
      </Button>
    </Flex>
  );
}

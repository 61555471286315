import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Heading,
  useColorMode,
  Link,
  Flex,
  Badge,
  Text,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  Button,
} from "@chakra-ui/react";
import {
  Link as RouteLink,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";
import fetchVideos from "./api";

import { useUser } from "../../libs/UserContext";
import Card from "../../components/card";
import { newStatusSwitch } from "../../data/videoData";
import { Updating } from "../../components/notification/Updating";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function ProjectsLists(props) {
  const { projects } = props;
  const history = useHistory();
  const { admin, freelance } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const { colorMode } = useColorMode();

  // console.log("projetcs 2", projects);
  return (
    <>
      <Box
        as='main'
        // py={{ base: "80px", sm: "30px" }}
        // px={{ base: "20px", sm: "40px" }}
      >
        <Grid templateColumns='1fr 1fr 1fr 1fr ' gap='20px' mt='20px'>
          {projects &&
            projects.map((project, index) => {
              return (
                <Link
                  key={index}
                  as={RouteLink}
                  to={`/quotes/${project.project._id}`}
                  // to={`/videos/${video.id}/projects/${video.projectId}`}
                  _hover={{ textDecor: "none" }}>
                  <Flex
                    // borderWidth={colorMode === "light" ? "0px" : "0px"}
                    boxShadow='sm'
                    rounded='xl'
                    // bg={colorMode === "light" ? "white" : "gray.900"}
                    // my="6"
                    m='5px'
                    minH='140px'
                    p='20px'
                    direction='column'
                    // transition= "0.3s"
                    // transform="auto"
                    //  _hover={{ translateY: "-1" }}
                    _hover={{
                      bgColor:
                        colorMode === "light" ? "gray.50" : "blackAlpha.600",
                      shadow: "lg",
                    }}
                    bg={colorMode === "light" ? "white" : "blackAlpha.400"}>
                    <Text
                      color={
                        colorMode === "light" ? "primary" : "whiteAlpha.500"
                      }
                      fontSize='sm'>
                      {admin || freelance ? project.clientName : ""}
                    </Text>
                    <Text>{project.project.name}</Text>
                    <Flex>
                      <Flex justifyContent='space-between'>
                        <Text>
                          <Badge
                            mt='5px'
                            fontSize='xs'
                            variant='solid'
                            colorScheme={newStatusSwitch(
                              project?.project?.status?.value
                            )}>
                            {project?.project?.status
                              ? project?.project?.status?.label
                              : ""}
                          </Badge>
                        </Text>
                      </Flex>

                      <Flex justifyContent='space-between'>
                        <Text>
                          <Badge
                            mt='5px'
                            ml='10px'
                            fontSize='xs'
                            colorScheme='red'>
                            {project?.cancel ? "cancel" : ""}
                          </Badge>
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Link>
              );
            })}
        </Grid>
      </Box>
      {isLoading && <Updating />}
    </>
  );
}

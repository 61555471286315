import React, { useEffect, useState, useRef } from "react";
import videojs from "@mux/videojs-kit";
import "@mux/videojs-kit/dist/index.css";
import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import {
  Badge,
  Box,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Img,
  Text,
  Textarea,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import ButtonColor from "../../components/button/ButtonColor";
import { fetchComments, postCommment } from "./api";
import "@videojs/themes/dist/sea/index.css";
import { TimeIcon } from "@chakra-ui/icons";

import { format } from "date-fns";
import { HiOutlineTag, HiPencilAlt } from "react-icons/hi";

export function VideoPlayer({
  muxPlaybackId,
  muxId,
  clientId,
  fileId,
  setComments,
  reloadComments,
  setReloadComments,
  isLoading,
  setIsLoading,
  timecode,
  setTimecode,
  currentFile,
  setCurrentFile,
  files,
}) {
  const [currentTime, setCurrentTime] = useState(null);
  const [comment, setComment] = useState("");
  const [player, setPlayer] = useState("");
  const [previousThumbnail, setPreviousThumbnail] = useState("");
  const [currentThumbnail, setCurrentThumbnail] = useState("");
  const [nextThumbnail, setNextThumbnail] = useState("");
  const [breakPoint, setBreakPoint] = useState("");
  const [isChecked, setIsCheked] = useState(true);
  const { colorMode } = useColorMode();
  const { user, admin } = useUser();
  let { projectId, videoId, taskId } = useParams();

  const videoPlayerRef = useRef(null); // Instead of ID

  useEffect(() => {
    // console.log("isLoading status", isLoading)
    // console.log("setIsLoading", true)
    // setIsLoading(true)
    fetchComments(projectId, fileId, admin)
      .then(
        (response) => {
          setComments(response);
          // console.log("comments", response);
        }
        // console.log("setIsLoading", false)
      )
      .then(() => setIsLoading(false));
  }, [reloadComments, currentFile]);

  // console.log("currentFile", currentFile)

  useEffect(() => {
    if (videoPlayerRef) {
      const player = videojs(
        videoPlayerRef.current,
        {
          userActions: {
            hotkeys: true,
          },
          // controlBar: {
          //   volumePanel: {
          //     inline: false
          //   }
          // },
          controlBar: {
            children: [
              "playToggle",
              "volumeMenuButton",
              "currentTimeDisplay",
              "timeDivider",
              "durationDisplay",
              "progressControl",
              // "remainingTimeDisplay",
              "fullscreenToggle",
            ],
          },
          timelineHoverPreviews: true,
          plugins: {
            mux: {
              debug: false,
              data: {
                env_key: "v8suvj8uf38aqv7fqqht07t0b", // pass your own personal key in an `.env` file
                video_title: "Example Title",
              },
            },
          },
        },
        () => {
          player.src({
            // this is the public playback ID without the 'https://stream.mux.com/' prefix
            src: muxPlaybackId,
            type: "video/mux",
          });

          player.on("timeupdate", () => {
            setCurrentTime(player.currentTime());
          });
        }
      );
      setPlayer(player);
      player && player.httpSourceSelector();
    }

    return () => {};
  }, [muxPlaybackId]);

  useEffect(() => {
    if (player) {
      player.currentTime(timecode);
    }
  }, [timecode]);

  function addComment(value) {
    setComment(value);
    player.pause();
  }

  function createComment() {
    // console.log("newComment",comment)
    setComment("");
    setIsLoading(true);
    postCommment(
      isChecked && currentTime
        ? `https://image.mux.com/${muxPlaybackId}/thumbnail.jpg?time=${parseInt(
            currentTime
          )}`
        : null,
      isChecked ? currentTime : null,
      comment,
      null,
      null,
      null,
      muxId,
      muxPlaybackId,
      fileId,
      taskId,
      videoId,
      projectId,
      clientId,
      admin
    )
      .then((response) => setReloadComments(Math.random()))
      .then((response) => setIsLoading(false));
  }
  const formatted = format(
    parseFloat(currentTime ? currentTime : 0) * 1000,
    "mm:ss"
  );

  return (
    <Box className='App'>
      {/* {muxPlaybackId && <div>{muxPlaybackId}</div>} */}
      <Box
        shadow='md'
        bgColor={colorMode === "light" ? "white" : "#232323"}
        rounded='md'>
        <Box
          borderTopRightRadius='md'
          borderTopLeftRadius='md'
          overflow='hidden'>
          {muxPlaybackId && (
            <video
              ref={videoPlayerRef}
              class='video-js vjs-16-9 vjs-big-play-centered '
              controls
              preload='auto'
              width='100%'
              // data-setup='{
              //   "plugins": {
              //     "mux": {
              //       "debug": true,
              //       "data":{
              //         "env_key": "ENV_KEY",
              //         "video_title": "Example Title"
              //       }
              //     },
              //     "httpSourceSelector": {}
              //   }
              // }'
            ></video>
          )}
        </Box>
        <Flex
          justifyContent='space-between'
          alignItems='center'
          py='10px'
          px='20px'>
          <Box mt='5px' fontSize='md'>
            {currentFile.originalName}
          </Box>
          <Flex pt='10px'>
            {currentFile.tags &&
              currentFile.tags.map((tag, index) => {
                return (
                  <Badge key={index} mr='5px'>
                    {tag.label}
                  </Badge>
                );
              })}
          </Flex>
          {/* <Box>
                      <IconButton
                        mr="10px"
                        variant="outline"
                        // onClick={(e)=> onOpen()}
                        rounded="full" size="sm" colorScheme="gray"
                        icon={<HiPencilAlt />}
                        />
                      <IconButton
                        variant="outline"
                        // onClick={(e)=> onOpen()}
                        rounded="full" size="sm" colorScheme="gray"
                        icon={<HiOutlineTag />}
                        />
                </Box> */}
        </Flex>
      </Box>

      <Textarea
        border='0px'
        boxShadow='sm'
        bgColor={colorMode === "light" ? "white" : "#232323"}
        mt='20px'
        placeholder='Write your comment here...'
        value={comment}
        onFocus={() => player.pause()}
        onChange={(e) => addComment(e.target.value)}
      />
      <Flex textAlign='center' mt='20px'>
        <ButtonColor mt='0' title='Send' click={(e) => createComment()} />
        {currentTime > 0 && (
          <Flex textAlign='center' ml='10px'>
            <Flex pl='10px' textAlign='center'>
              <Checkbox
                colorScheme='gray'
                isChecked={isChecked}
                onChange={(e) => setIsCheked(!isChecked)}></Checkbox>
            </Flex>
            <Flex ml='15px'>
              <HStack
                fontSize='sm'
                color={colorMode === "light" ? "gray.600" : "whiteAlpha.600"}>
                <TimeIcon /> <Text ml='0'> {formatted && formatted}</Text>
              </HStack>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

import {
  Box,
  Center,
  Flex,
  Icon,
  useColorModeValue,
  Spinner,
  useColorMode,
  Text,
} from "@chakra-ui/react";
import * as React from "react";
import { FiInfo } from "react-icons/fi";

export const Updating = (props) => {
  const { children, ...flexProps } = props;
  const { colorMode } = useColorMode();
  return (
    <Box position="fixed" bottom="20px" left="50%" translateX="-50%">
      <Flex
        direction={{
          base: "column",
          sm: "row",
        }}
        //   width="2xs"
        boxShadow="lg"
        bg={useColorModeValue("white", "#232323")}
        borderRadius={{
          base: "none",
          sm: "base",
        }}
        overflow="hidden"
        borderTopWidth={{
          base: "4px",
          sm: "0",
        }}
        borderColor={useColorModeValue("blue.500", "blue.300")}
        {...flexProps}
      >
        <Center
          display={{
            base: "none",
            sm: "flex",
          }}
          bg="primary"
          px="4"
        >
          {/* <Icon
          as={FiInfo}
          boxSize="9"
          color={useColorModeValue("white", "gray.900")}
        /> */}
          <Spinner color="white" />
        </Center>
        <Box px="4" py="3">
          {/* {children} */}
          <Text
            fontSize="md"
            color={colorMode === "light" ? "gray.500" : "white"}
          >
            Updating
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

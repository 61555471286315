import React, { useState, useEffect, useReducer } from "react";
import { useUser } from "../../libs/UserContext";
import { initialState, reducer } from "./reducer";
import { customStyles, darkStyles } from "../../styles/customStyles";
// import { goalOptions, statusOptions } from "../../data/projectData";
import { getClients, updateBrief, deleteBrief, createBrief } from "./api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Textarea,
  // NumberInput,
  // NumberInputField,
  useColorMode,
  // Switch,
} from "@chakra-ui/react";
import Select from "react-select";
import { sourceOptions, statusOptions } from "../../data/briefData";

export function NewBrief(props) {
  const { colorMode } = useColorMode();

  const { isOpen, onClose, reloadData, setReloadData, client } = props;

  const { user, admin } = useUser();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [clients, setClients] = useState("");
  const [dataDeleted, setDataDeleted] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [deleteButton, setDeleteButton] = useState(false);

  useEffect(() => {
    if (admin) {
      getClients().then((response) => {
        setClients(
          response.map((item) => {
            return {
              label: item.name,
              value: item._id,
            };
          })
        );
      });
    }
  }, []);

  useEffect(() => {
    if (isOpen === true) {
      dispatch({
        type: "field",
        payload: {
          fieldName: "date",
          value: new Date(),
        },
      });
    }
  }, [isOpen]);

  function save() {
    dispatch({ type: "save" });
    // console.log("state :", state);
    createBrief(admin, state).then((response) => {
      onClose();
      dispatch({ type: "success" });
      setReloadData(!reloadData);
      // console.log("response save", response);
    });
  }

  const DateForm = () => {
    return (
      <DatePicker
        // className="date-picker"
        wrapperClassName={
          colorMode === "light"
            ? "date-picker-wrapper-light"
            : "date-picker-wrapper-dark"
        }
        // calendarClassName="dark-test"
        popperClassName={colorMode === "light" ? "ligth-picker" : "dark-picker"}
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        dateFormat='dd/MM/yyyy'
        placeholderText='Choisir une date'
        isClearable
        selected={state.date ? state.date : new Date()}
        calendarStartDay={1}
        onChange={(date) =>
          dispatch({
            type: "field",
            payload: {
              fieldName: "date",
              value: date,
            },
          })
        }
        // locale="fr"
      />
    );
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior='outside'
        size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit brief</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Status */}
            <Box my='4'>
              <FormLabel>Status</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                placeholder='Select a status'
                value={state.status}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "status",
                      value: e,
                    },
                  });
                }}
                options={statusOptions}
              />
            </Box>

            {/* Client */}
            <Box my='4'>
              <FormLabel>Client</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                placeholder='Select a client '
                value={state.currentClient}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "currentClient",
                      value: e,
                    },
                  });
                }}
                options={clients}
              />
            </Box>

            {/* Company */}
            <Box my='4'>
              <FormControl id='subtitle'>
                <FormLabel>Company</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a company'
                  value={state.company}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "company",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/* Brief*/}
            <Box my='4'>
              <FormLabel>Brief</FormLabel>
              <Textarea
                variant='filled'
                riant='filled'
                placeholder='Write a quick brief'
                size='md'
                resize='vertical'
                value={state.brief}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "brief",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Box>

            {/* Date */}
            <Box my='4'>
              <FormLabel>Date</FormLabel>
              <Flex>
                <Box maxW='200'>{state && <DateForm />}</Box>
              </Flex>
            </Box>

            {/* Product */}
            <Box my='4'>
              <FormControl id='subtitle'>
                <FormLabel>Product</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a product'
                  value={state.product}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "product",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/* Creator */}
            <Box my='4'>
              <FormControl id='subtitle'>
                <FormLabel>Creator</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a product'
                  value={state.creator}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "creator",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/* Pricing */}
            <Box my='4'>
              <FormControl id='subtitle'>
                <FormLabel>Pricing</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a pricing'
                  value={state.pricing}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "pricing",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/* First Name */}
            <Box my='4'>
              <FormControl id='subtitle'>
                <FormLabel>First Name</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a firstName'
                  value={state.firstName}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "firstName",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/* Last Name */}
            <Box my='4'>
              <FormControl id='subtitle'>
                <FormLabel>Last Name</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a firstName'
                  value={state.lastName}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "lastName",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/*Job */}
            <Box my='4'>
              <FormControl id='subtitle'>
                <FormLabel>Job</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a job'
                  value={state.job}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "job",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/*email */}
            <Box my='4'>
              <FormControl id='subtitle'>
                <FormLabel>email</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a email'
                  value={state.email}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "email",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/*Location */}
            <Box my='4'>
              <FormControl id='subtitle'>
                <FormLabel>Location</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a location'
                  value={state.location}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "location",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/*Quantity */}
            <Box my='4'>
              <FormControl id='subtitle'>
                <FormLabel>Quantity</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a quantity'
                  value={state.quantity}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "quantity",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/*DueDate */}
            <Box my='4'>
              <FormControl id='subtitle'>
                <FormLabel>Due date</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a dueDate'
                  value={state.dueDate}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "dueDate",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/* Source */}
            <Box my='4'>
              <FormLabel>Source</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                // isMulti
                placeholder='Select the source  '
                value={state.source}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "source",
                      value: e,
                    },
                  });
                }}
                options={sourceOptions}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            {(user.username === "florian@kronik.com" ||
              user.username === "matt@kronik.com") &&
              !deleteButton && (
                <Button mr={3} onClick={() => setDeleteButton(true)}>
                  Delete
                </Button>
              )}
            {!deleteButton && (
              <Button
                // isDisabled={!state.currentClient || !state.client}
                bgColor='primary'
                color='white'
                mr={3}
                onClick={() => save()}
                isLoading={state.isLoadingOne}
                loadingText='Loading'>
                Save
              </Button>
            )}
            {deleteButton && (
              <Box mr='20px'>
                <FormControl id='confirmation'>
                  <Input
                    variant='filled'
                    placeholder='Enter DELETE to confirm'
                    value={confirmMessage}
                    onChange={(e) => setConfirmMessage(e.target.value)}
                  />
                </FormControl>
              </Box>
            )}
            {/* {deleteButton && (
              <Button
                // colorScheme="blue"
                mr={3}
                onClick={() => setDeleteButton(false)}
              >
                Cancel
              </Button>
            )}
            {confirmMessage === "DELETE" && deleteButton && (
              <Button
                colorScheme="red"
                mr={3}
                onClick={() => remove()}
                isLoading={state.isLoadingTwo}
                loadingText="Loading"
              >
                Delete
              </Button>
            )} */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

import { Flex } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import CardTitle from "../card/CardTitle";
import { MiniHeader } from "./MiniHeader";

export default function ProjectHeader({
  queryDisplay,
  queryStatus,
  queryClient,
  queryManager,
  queryCancel,
}) {
  const { admin } = useUser();
  const location = useLocation();

  // console.log("location", location);
  return (
    <MiniHeader>
      <Flex gap='20px'>
        {/* {admin && (
          <Link
            //   as={NavLink}
            to='/projects'
            // activeClassName='selected'
            _hover={{
              textDecor: "none",
            }}>
            <CardTitle
              title='Projects'
              isActive={location.pathname === "/projects"}
            />
          </Link>
        )} */}
        {/* {admin && ( */}
        <Link
          //   as={NavLink}
          to={`/projects?status=all&client=${
            queryClient ? queryClient : "all"
          }&display=${queryDisplay ? queryDisplay : "me"}&manager=${
            queryManager ? queryManager : "all"
          }`}
          // activeClassName='selected'
          _hover={{
            textDecor: "none",
          }}>
          <CardTitle
            title='All'
            isActive={
              location.pathname === "/projects" &&
              location.search.search("status=all") > 0
            }
          />
        </Link>
        {/* )} */}
        {/* {admin && ( */}
        <Link
          //   as={NavLink}
          to={`/projects?status=notstarted&client=${
            queryClient ? queryClient : "all"
          }&display=${queryDisplay ? queryDisplay : "me"}&manager=${
            queryManager ? queryManager : "all"
          }`}
          // activeClassName='selected'
          _hover={{
            textDecor: "none",
          }}>
          <CardTitle
            title='Not started'
            isActive={
              location.pathname === "/projects" &&
              location.search.search("status=notstarted") > 0
            }
          />
        </Link>
        {/* )} */}
        {/* {admin && ( */}
        <Link
          //   as={NavLink}
          to={`/projects?status=production&client=${
            queryClient ? queryClient : "all"
          }&display=${queryDisplay ? queryDisplay : "me"}&manager=${
            queryManager ? queryManager : "all"
          }`}
          // activeClassName='selected'
          _hover={{
            textDecor: "none",
          }}>
          <CardTitle
            title='Production'
            isActive={
              location.pathname === "/projects" &&
              location.search.search("status=production") > 0
            }
          />
        </Link>
        {/* )} */}
        {/* {admin && ( */}
        <Link
          //   as={NavLink}
          to={`/projects?status=validated&client=${
            queryClient ? queryClient : "all"
          }&display=${queryDisplay ? queryDisplay : "me"}&manager=${
            queryManager ? queryManager : "all"
          }`}
          // activeClassName='selected'
          _hover={{
            textDecor: "none",
          }}>
          <CardTitle
            title='Validated'
            isActive={
              location.pathname === "/projects" &&
              location.search.search("status=validated") > 0
            }
          />
        </Link>
        {/* )} */}
        {/* {admin && ( */}
        <Link
          //   as={NavLink}
          to={`/projects?status=delivered&client=${
            queryClient ? queryClient : "all"
          }&display=${queryDisplay ? queryDisplay : "me"}&manager=${
            queryManager ? queryManager : "all"
          }`}
          // activeClassName='selected'
          _hover={{
            textDecor: "none",
          }}>
          <CardTitle
            title='Delivered'
            isActive={
              location.pathname === "/projects" &&
              location.search.search("status=delivered") > 0
            }
          />
        </Link>
        {/* )} */}
      </Flex>
    </MiniHeader>
  );
}

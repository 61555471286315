import React, { useState, useEffect } from "react";
import Month from "./Month";
import dayjs from "dayjs";
import { Flex, Box, Button, Text, Heading, Spacer } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { MiniHeader } from "../../header/MiniHeader";
import CardTitle from "../../card/CardTitle";
import MainColumn from "../../column/MainColumn";

export default function Calendar(props) {
  const { tasks, monthView, setMonthView } = props;
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month());
  const [currenMonth, setCurrentMonth] = useState(getMonth(selectedMonth));

  useEffect(() => {
    setCurrentMonth(getMonth(selectedMonth));
  }, [selectedMonth]);

  function getMonth(month) {
    month = Math.floor(month);
    const year = dayjs().year();
    const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
    // console.log("firstDayOfTheMonth", firstDayOfTheMonth);
    let currentMonthCount = 1 - firstDayOfTheMonth;
    // console.log("currentMonthCount", currentMonthCount);
    const daysMatrix = new Array(5).fill([]).map(() => {
      return new Array(7).fill(null).map(() => {
        currentMonthCount++;
        return dayjs(new Date(year, month, currentMonthCount));
      });
    });
    return daysMatrix;
  }

  function todayMonth() {
    setSelectedMonth(dayjs().month());
  }

  function previousMonth() {
    setSelectedMonth(selectedMonth - 1);
  }

  function nextMonth() {
    setSelectedMonth(selectedMonth + 1);
  }

  function changeView() {
    setMonthView(false);
  }

  return (
    <Box w='100%'>
      <MainColumn>
        <MainColumn>
          <Flex
            justifyContent='space-between'
            px='20px'
            pt='20px'
            alignItems='center'>
            <CardTitle
              title={dayjs(new Date(dayjs().year(), selectedMonth)).format(
                "MMMM YYYY"
              )}
              isActive
            />

            <Spacer />
            <Box mr='0px'>
              <Button onClick={changeView} mr='20px'>
                Week view
              </Button>

              <Button onClick={previousMonth} mr='10px'>
                <ChevronLeftIcon />
              </Button>
              <Button onClick={todayMonth} mr='10px'>
                Today
              </Button>
              <Button onClick={nextMonth}>
                <ChevronRightIcon />
              </Button>
            </Box>
          </Flex>

          <Box px='20px' pt='20px'>
            <Month month={currenMonth} tasks={tasks} />
          </Box>
        </MainColumn>
      </MainColumn>
    </Box>
  );
}

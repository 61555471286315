
import { API } from "aws-amplify";

export default async function fetchFreelance(freelanceId, admin) {
  let url = `/freelance/get/${freelanceId}?admin=${admin}`;
  //   console.log("projectId", projectId);
  return API.get("kronik", url, {});
}






import React, { useState, useEffect } from "react";
import { Grid, Box, Heading, useColorMode, Link } from "@chakra-ui/react";
import { Link as RouteLink, NavLink, useLocation } from "react-router-dom";

import AreaChart from "../../components/chart/AreaChart";
import BarChart from "../../components/chart/BarChart";
import TaskChart from "../../components/chart/TaskChart";
import DonutChart from "../../components/chart/DonutChart";
import fetchVideos from "./api";
import { fetchTasks } from "./api";
import { fetchProjects } from "./api";
import dayjs from "dayjs";
import ColumnChart from "../../components/chart/ColumnChart";
import NavBar from "./NavBar";
import { useUser } from "../../libs/UserContext";
import TreemapChart from "../../components/chart/TreemapChart";
import { Updating } from "../../components/notification/Updating";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";
import MainColumn from "../../components/column/MainColumn";

export default function Charts() {
  const { admin } = useUser();
  const { colorMode } = useColorMode();
  const [dateSelected, setDateSelected] = useState("all");
  const [display, setDisplay] = useState("all");
  const [accountManagerSelected, setAccountManagerSelected] = useState("all");

  const [videos, setVideos] = useState("");
  const [categoryVideos, setCategoryVideos] = useState("");
  const [categoryLabel, setCategoryLabel] = useState("");
  const [projects, setProjects] = useState("");
  const [month, setMonth] = useState("");
  const [revenues, setRevenues] = useState("");
  const [costs, setCosts] = useState("");
  const [monthRevenues, setMonthRevenues] = useState("");
  const [clients, setClients] = useState("");
  const [clientsLabel, setClientsLabel] = useState("");
  const [series, setSeries] = useState("");
  const [dataPlanning, setDataPlanning] = useState("");
  const [labelPlanning, setLabelPlanning] = useState("");
  const [dataProjects, setDataProjects] = useState([]);
  const [options, setOptions] = useState([
    "Preparation",
    "Interview",
    "Pre-production",
    "Production",
    "Validated",
    "Delivered",
    "No status",
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { status: "preparation", title: "Preparation", count: 0 },
    { status: "interview", title: "Shooting", count: 0 },
    { status: "preProduction", title: "Pre-production", count: 0 },
    { status: "production", title: "Production", count: 0 },
    { status: "approve", title: "Validated", count: 0 },
    // { status: "approval", title: "Validated", count: 0 },
    { status: "delivered", title: "Delivered", count: 0 },
    // { status: "broadcast", title: "Delivered", count: 0 },
    { status: "noStatus", title: "No status", count: 0 },
  ];
  const todayPlanning = dayjs(new Date());
  const planning = [
    { date: null, label: "Nodate", count: 0, todo: 0, done: 0 },

    { date: null, label: "Watchlist", count: 0, todo: 0, done: 0 },
    {
      date: dayjs(todayPlanning),
      label: "Today",
      count: 0,
      todo: 0,
      done: 0,
    },
    {
      date: dayjs(todayPlanning).add(1, "days"),
      label: "Tomorrow",
      count: 0,
      todo: 0,
      done: 0,
    },
    {
      date: dayjs(todayPlanning).add(2, "days"),
      label: dayjs(todayPlanning).add(2, "days").format("dddd DD"),
      count: 0,
      todo: 0,
      done: 0,
    },
    {
      date: dayjs(todayPlanning).add(3, "days"),
      label: dayjs(todayPlanning).add(3, "days").format("dddd DD"),
      count: 0,
      todo: 0,
      done: 0,
    },
    {
      date: dayjs(todayPlanning).add(4, "days"),
      label: dayjs(todayPlanning).add(4, "days").format("dddd DD"),
      count: 0,
      todo: 0,
      done: 0,
    },
    {
      date: dayjs(todayPlanning).add(5, "days"),
      label: dayjs(todayPlanning).add(5, "days").format("dddd DD"),
      count: 0,
      todo: 0,
      done: 0,
    },
    {
      date: dayjs(todayPlanning).add(6, "days"),
      label: dayjs(todayPlanning).add(6, "days").format("dddd DD"),
      count: 0,
      todo: 0,
      done: 0,
    },
  ];

  useEffect(() => {
    // setIsLoading(true);
    fetchTasks(display).then((response) => {
      // setIsLoading(false);
      // console.log("response tasks", response);
      response.map((task) =>
        planning.map((it) => {
          // console.log("tasks", response);
          // console.log("datejs", dayjs(task.dateJs));
          const dateTask = dayjs(task.dateJs).format("YYYY-MM-DD");
          const dateNow = dayjs(new Date()).format("YYYY-MM-DD");
          if (
            (task.dateJs !== null || undefined) &&
            dateTask < dateNow &&
            it.label === "Watchlist"
          ) {
            it.count = it.count + 1;
            it.todo = task.status?.value !== "done" ? it.todo + 1 : it.todo;
            it.done = task.status?.value === "done" ? it.done + 1 : it.done;
            // console.log("delay task", task);
          } else if (
            dayjs(task.dateJs).format("DD MM YY") ===
              dayjs(it.date).format("DD MM YY") &&
            it.label !== "Watchlist"
          ) {
            it.count = it.count + 1;
            it.todo = task.status?.value !== "done" ? it.todo + 1 : it.todo;
            it.done = task.status?.value === "done" ? it.done + 1 : it.done;
          } else if (
            (task.dateJs === null || undefined) &&
            it.label === "Nodate"
          ) {
            it.count = it.count + 1;
            it.todo = task.status?.value !== "done" ? it.todo + 1 : it.todo;
            it.done = task.status?.value === "done" ? it.done + 1 : it.done;
          }
          // console.log("planning", planning);
        })
      );
      // const serieOne = planning.map((it) => it.count);
      const serieOne = planning.map((it) => it.todo);
      const serieTwo = planning.map((it) => it.done);
      setDataPlanning([
        { name: "Todo", data: serieOne },
        // { name: "series2", data: [2, 2, 2, 2, 2, 2, 2, 2] },
        { name: "Done", data: serieTwo },
      ]);
      // console.log("planing", planning);
      setLabelPlanning(planning && planning.map((it) => it.label));
    });
  }, [display]);

  useEffect(() => {
    // console.log("dateSelected", dateSelected);
    // console.log("display", display);
    // console.log("fetchVideo");
    // console.log("planning", planning);
    setIsLoading(true);
    fetchVideos(dateSelected, display).then((response) => {
      // console.log("fetchVideos", response);

      // const videosTotal = response
      setIsLoading(false);

      var resultRevenues = [];
      // console.log("videosRevenues", response);
      response
        .filter(
          (item) =>
            item?.cancel !== true &&
            item?.month !== null &&
            (item?.newStatus?.value === "delivered" ||
              item?.newStatus?.value === "approve" ||
              item?.newStatus?.value === "production")
        )
        .sort(function (a, b) {
          return new Date(a.month) - new Date(b.month);
        })

        .reduce(function (res, value) {
          if (!res[value.month]) {
            res[value.month] = {
              month: value.month,
              value: 0,
              cost: 0,
            };
            resultRevenues.push(res[value.month]);
          }
          res[value.month].value += value.pricing;
          res[value.month].cost += value.totalJobs;
          // res[value.wonDate].value += value.project.pricing;

          // console.log("resultRevenues", resultRevenues);
          return res;
        }, {});

      setRevenues(resultRevenues.map((item) => item.value));
      setCosts(resultRevenues.map((item) => item.cost));
      setMonthRevenues(
        resultRevenues
          .sort(function (a, b) {
            return new Date(a.month) - new Date(b.month);
          })
          .map((item) => dayjs(item.month).format("MMMM YYYY"))
      );

      // console.log("responseFetchVideos", response);
      response.map((item) => {
        columns.map((it) => {
          if (item?.newStatus?.value === it?.status) {
            it.count = it.count + 1;
          } else if (
            (item?.newStatus?.value === null ||
              item?.newStatus?.value === undefined) &&
            it?.status === "noStatus"
          ) {
            it.count = it.count + 1;
            // console.log("noStatus", item);
          }
        });
      });

      var result = [];
      response.reduce(function (res, value) {
        if (!res[value.category?.label]) {
          res[value.category?.label] = {
            category: value.category?.label,
            value: 0,
          };
          result.push(res[value.category?.label]);
        }
        res[value.category?.label].value += 1;

        return res;
      }, {});
      setCategoryVideos(
        result.filter((item) => item.category).map((item) => item.value)
      );
      setCategoryLabel(
        result.map((item) => item.category).filter((item) => item)
      );
      // console.log(
      //   "categoryVideos",
      //   result.map((item) => item.value)
      // );

      setVideos(columns);

      const responseOptions = columns.map((item) => item.title);
      const responseSeries = columns.map((item) => item.count);
      setOptions(responseOptions);
      setSeries(responseSeries);
    });
  }, [display]);

  useEffect(() => {
    // console.log("fetchProject");
    // setIsLoading(true);
    fetchProjects(display).then((response) => {
      // setIsLoading(false);
      var result = [];
      // console.log("projects", response);
      response
        .filter((item) => item.project?.cancel !== true)
        .reduce(function (res, value) {
          if (!res[value.wonDate]) {
            res[value.wonDate] = { month: value.wonDate, value: 0 };
            result.push(res[value.wonDate]);
          }
          res[value.wonDate].value += value.project.pricing;
          // console.log("result", result);

          return res;
        }, {});

      // console.log("result", result);
      setProjects(result.map((item) => item.value));
      setMonth(result.map((item) => item.month));

      const data = response.reduce((acc, currentVal) => {
        return acc + (currentVal.pricing ? parseInt(currentVal.pricing) : 0);
      }, 0);
      // setDataProjects(response);
      // console.log("data", data);
      // console.log("projects", response);

      var topClients = [];

      response.reduce(function (res, value) {
        if (!res[value.clientName]) {
          res[value.clientName] = {
            x: value.clientName,
            y: 0,
            id: value.client._id,
          };
          topClients.push(res[value.clientName]);
        }
        res[value.clientName].y += value.pricing;
        return res;
      }, {});
      // console.log("topClients", topClients);
      setClients(
        topClients
          .sort(function (a, b) {
            return b.y - a.y;
          })
          // .map((item) => item?.y)
          .slice(0, 39)
      );
      // setClientsLabel(
      //   topClients
      //     // .sort(function (a, b) {
      //     //   return b.y - a.y;
      //     // })
      //     .map((item) => item?.x)
      //     .slice(0, 19)
      // );
    });
  }, [display]);
  return (
    <>
      {admin && (
        <Box
          as='main'
          py={{ base: "80px", sm: "0px" }}
          // px={{ base: "20px", sm: "40px" }}
        >
          <NavBar
            display={display}
            setDisplay={setDisplay}
            admin={admin}
            accountManagerSelected={accountManagerSelected}
            setAccountManagerSelected={setAccountManagerSelected}
          />
          <Grid templateColumns='1fr 1fr ' gap='20px' mt='40px'>
            {projects && (
              <Box
                boxShadow='sm'
                rounded='xl'
                borderWidth={colorMode === "light" ? "1px" : "0px"}
                bg={colorMode === "light" ? "white" : "#232323"}>
                <AreaChart
                  dataY={projects}
                  dataX={month}
                  // dataZ={[45, 52, 38, 45, 19, 23, 2]}

                  // dataOptions={options}
                  title='Quotes'
                  link='/quotes'
                />
              </Box>
            )}
            {series && (
              <Box
                boxShadow='sm'
                rounded='xl'
                borderWidth={colorMode === "light" ? "1px" : "0px"}
                bg={colorMode === "light" ? "white" : "#232323"}>
                <AreaChart
                  dataY={revenues}
                  dataX={monthRevenues}
                  // dataZ={[45, 52, 38, 45, 19, 23, 2]}
                  dataZ={costs}
                  // dataOptions={options}
                  title='Revenues'
                  link='/revenues'
                />
              </Box>
            )}
          </Grid>
          {/* <Grid templateColumns="1fr 1fr" gap="20px" mt="40px"> */}

          {/* </Grid> */}

          <Grid templateColumns=' 1fr ' gap='20px' mt='20px'>
            {clients && (
              <Box
                boxShadow='sm'
                rounded='xl'
                borderWidth={colorMode === "light" ? "1px" : "0px"}
                bg={colorMode === "light" ? "white" : "#232323 "}>
                <TreemapChart
                  dataSeries={clients}
                  dataOptions={clientsLabel}
                  title='Top clients'
                  link='/clients'
                />
              </Box>
            )}
          </Grid>
          <Grid templateColumns=' 1fr 1fr' gap='20px' mt='20px'>
            {series && (
              <Box
                boxShadow='sm'
                rounded='xl'
                borderWidth={colorMode === "light" ? "1px" : "0px"}
                bg={colorMode === "light" ? "white" : "#232323 "}>
                <ColumnChart
                  dataSeries={series}
                  dataOptions={options}
                  title='Projects'
                  link='/projects'
                />
              </Box>
            )}
            {dataPlanning && (
              <Box
                boxShadow='sm'
                rounded='xl'
                borderWidth={colorMode === "light" ? "1px" : "0px"}
                bg={colorMode === "light" ? "white" : "#232323 "}>
                <TaskChart
                  dataSeries={dataPlanning}
                  dataOptions={labelPlanning}
                  title='Tasks'
                  link='/tasks'
                />
              </Box>
            )}
          </Grid>

          {/* {videos && (
        <DonutChart
          data={categoryVideos}
          legend={categoryLabel}
          dataOptions={options}
        />
      )} */}
        </Box>
      )}
      {isLoading && <Updating />}
    </>
  );
}

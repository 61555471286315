import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdAddBox } from "react-icons/md";
// import TableRow from '../../components/table/TableRow';
// import { Link as RouteLink } from "react-router-dom";

import { useUser } from "../../libs/UserContext";
import { FreelancesList } from "./FreelancesList";
import NewFreelance from "../../components/freelance/NewFreelance";
import MainColumn from "../../components/column/MainColumn";
import CardTitle from "../../components/card/CardTitle";
import { MiniHeader } from "../../components/header/MiniHeader";
import NavBar from "./NavBar";
import { Updating } from "../../components/notification/Updating";
import { useLocation } from "react-router-dom";
import fetchFreelances from "./api";

export function Freelances() {
  const { admin } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [queryStatus, setQueryStatus] = useState("");
  const [querySkill, setQuerySkill] = useState("");
  const [freelances, setFreelances] = useState("");
  const [filteredFreelances, setFilteredFreelances] = useState("");

  const location = useLocation();

  function useQuery() {
    // const { search } = useLocation();
    // return React.useMemo(() => new URLSearchParams(search), [search]);
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    if (admin) {
      setIsLoading(true);

      fetchFreelances().then((response) => {
        setFreelances(
          response
          // .sort(
          //   (a, b) => (b.jobs && b.jobs.length) - (a.jobs && a.jobs.length)
          // )
        );
        setIsLoading(false);
        // console.log(
        //   "freelances list",
        //   response.sort(
        //     (a, b) => (b.jobs && b.jobs.length) - (a.jobs && a.jobs.length)
        //   )
        // );
      });
    }
  }, [reloadData]);

  let query = useQuery();

  useEffect(() => {
    setQueryStatus(query.get("status") ? query.get("status") : "all");
    setQuerySkill(query.get("skill") ? query.get("skill") : "all");
  }, [location, freelances]);

  useEffect(() => {
    setFilteredFreelances(
      freelances &&
        freelances
          // .filter((freelance) => freelance?.status?.value !== "test")
          .filter(
            (freelance) =>
              (freelance?.status?.value?.toLowerCase() ===
                queryStatus.toLowerCase() ||
                (queryStatus.toLowerCase() === "all" &&
                  freelance?.status?.value !== "test")) &&
              (freelance?.skills?.some(
                (skill) => skill.value === querySkill.toLowerCase()
              ) ||
                querySkill === "all")
          )
    );
  }, [queryStatus, querySkill, isLoading]);

  return (
    <MainColumn>
      {admin && (
        <Box
          as='main'
          py={{ base: "80px", sm: "0px" }}
          // px={{ base: "20px", sm: "0px" }}
        >
          <MiniHeader>
            <CardTitle title={"freelances"} isActive />
            <Spacer />
          </MiniHeader>
          <MainColumn>
            <Box px='20px'>
              <NavBar
                isLoading={isLoading}
                admin={admin}
                onOpen={onOpen}
                querySkill={querySkill}
                queryStatus={queryStatus}
                freelances={filteredFreelances}
              />
              <FreelancesList
                setIsLoading={setIsLoading}
                reloadData={reloadData}
                freelances={filteredFreelances}
              />
            </Box>
          </MainColumn>
        </Box>
      )}
      {isLoading && <Updating />}
      <NewFreelance
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        reloadData={reloadData}
        setReloadData={setReloadData}
      />
    </MainColumn>
  );
}

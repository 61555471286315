import React, { useState } from "react";
import Chart from "react-apexcharts";
import { Box, Heading, Link, useColorMode } from "@chakra-ui/react";
import { Link as RouteLink, NavLink, useLocation } from "react-router-dom";

export default function ColumnChart(props) {
  const { colorMode } = useColorMode();

  const { dataSeries, dataOptions, title, link } = props;

  const series = [
    {
      name: "Videos quantity", //will be displayed on the y-axis
      data: dataSeries,
      //   data: [25, 53, 50, 57],
    },
  ];
  const options = {
    tooltip: {
      theme: colorMode === "light" ? "light" : "dark",
    },
    chart: {
      foreColor: colorMode === "light" ? "#333" : "white",

      id: "bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: dataOptions, //will be displayed on the x-asis
    },
    // plotOptions: {
    //   bar: {
    //     // barHeight: "90%",
    //     // distributed: true,
    //     horizontal: false,
    //     dataLabels: {
    //       position: "bottom",
    //     },
    //   },
    // },
    colors: [
      // "#5F5CD2",
      colorMode === "light" ? "#76A7F0" : "#3E45FF" ,
      "#C4BFFA",
      // "#546E7A",
      // "#d4526e",
      // "#13d8aa",
      // "#A5978B",
      // "#2b908f",
      // "#f9a3a4",
      // "#90ee7e",
      // "#f48024",
      // "#69d2e7",
    ],
    dataLabels: {
      enabled: true,
      // textAnchor: "start",
      style: {
        colors: colorMode === "light" ? ["#333"] : ["white"],
      },
      // formatter: function (val, opt) {
      //   return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      // },

      offsetX: 0,
      //   dropShadow: {
      //     enabled: true,
      //   },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
  };

  return (
    <Box
      bg="white"
      m="20px"
      background={colorMode === "light" ? "#fffff" : "#232323"}
    >
      <Heading as="h2" fontSize="xs" mb="4" textTransform="uppercase">
        <Link as={NavLink} to={link} _hover={{ textDecor: "none" }}>
          {title}
        </Link>
      </Heading>{" "}
      <Chart options={options} type="bar" series={series} height="350" />
    </Box>
  );
}

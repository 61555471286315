import React from "react";
import { Box, useColorMode } from "@chakra-ui/react";
import SideSteps from "../../components/SideSteps";
import SideBarItem from "../../components/SideBarItem.js";
import CardTitle from "../../components/card/CardTitle";
import { MiniHeader } from "../../components/header/MiniHeader";
import { LeftColumn } from "../../components/column/LeftColumn";
export default function SideBar({
  freelanceName,
  freelanceLink,
  isLoading,
  admin,
  jobName,
  jobStatus,
  clientLogo,
}) {
  const { colorMode } = useColorMode();
  return (
    <LeftColumn>
      <Box overflowY='auto' mt='10px'>
        <SideBarItem
          title='Freelance'
          text={freelanceName}
          link={!isLoading && (admin ? freelanceLink : "/jobs")}
          // status='freelance'
          clientLogo={clientLogo}
        />
        <SideBarItem
          title='Job'
          text={jobName}
          isActive='true'
          status={jobStatus ? jobStatus : " ?"}
        />
      </Box>
    </LeftColumn>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Grid,
  Heading,
  useColorMode,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Link,
  Flex,
  IconButton,
  useDisclosure,
  isLoading,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { Link as RouteLink } from "react-router-dom";
import NewVideo from "../../components/video/NewVideo";
import { HiPencilAlt } from "react-icons/hi";
import { IoFlash } from "react-icons/io5";
import { HiPlusCircle } from "react-icons/hi";

import { newStatusEmoji, newStatusSwitch } from "../../data/videoData";
import CardTitle from "../../components/card/CardTitle";
import TableRow from "../../components/table/TableRow";
import EditVideo from "../../components/video/EditVideo";
import UpdateStatus from "../../components/video/UpdateStatus";
import { fetchVideos } from "./api";
export default function VideosList(props) {
  const { colorMode } = useColorMode();
  const {
    videos,
    reloadData,
    setReloadData,
    projectData,
    projectId,
    projectName,
    project,
    admin,
    isLoading,
    setIsLoading,
    clientId,
    setVideos,
    setClient,
    setClientLogo,
  } = props;
  const history = useHistory();
  const [display, setDisplay] = useState("me");
  const [year, setYear] = useState("all");
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const {
  //   isOpen: isOpenEdit,
  //   onOpen: onOpenEdit,
  //   onClose: onCloseEdit,
  // } = useDisclosure();
  // const {
  //   isOpen: isOpenStatus,
  //   onOpen: onOpenStatus,
  //   onClose: onCloseStatus,
  // } = useDisclosure();
  // const [currentVideo, setCurrentVideo] = useState("");

  console.log("videos", videos);

  function OpenVideo(e, link) {
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  // function clickStatus(e, video) {
  //   e.preventDefault();

  //   firstRenderStatus.current = true;
  //   firstRenderValidation.current = true;
  //   console.log("video", video);
  //   setCurrentVideo(video);
  //   onOpenStatus();
  //   e.stopPropagation();
  // }

  // console.log("projectData", projectData);
  // console.log("project avec tout", project);
  // function openTask(e, video) {
  //   e.preventDefault();

  //   firstRenderStatus.current = true;
  //   firstRenderValidation.current = true;

  //   setCurrentVideo(video);
  //   onOpenEdit();
  //   e.stopPropagation();
  // }

  useEffect(() => {
    setIsLoading(true);
    // console.log("api videos");
    fetchVideos(display, year, clientId).then((response) => {
      // console.log("respnse fetch", response);
      setVideos(response);
      // setClient(response);
      setClient(response[0].clientName);
      setClientLogo(response[0].clientLogo);

      // setClient("test");
      setIsLoading(false);
    });
  }, [reloadData]);
  // const firstRenderStatus = useRef(true);
  // const firstRenderValidation = useRef(true);
  return (
    <Box
      // borderWidth={colorMode === "light" ? "1px" : "0px"}
      // boxShadow="sm"
      // rounded="md"
      // bg={colorMode === "light" ? "white" : "gray.900"}
      // mb="6"
      mt='40px'>
      <CardTitle title='Projects' isActive={true} />
      {
        videos && (
          // videos.map((item, index) => {
          //   return (
          <Box>
            {videos.map((video, index) => {
              const Link = `/projects/${video?._id}/quotes/${video?.project}`;

              return (
                <TableRow
                  key={index}
                  click={(e) => OpenVideo(e, Link)}
                  gridCol='6fr 2fr 2fr'>
                  <Box color={colorMode === "light" ? "gray.500" : "white"}>
                    {video.title}{" "}
                  </Box>
                  <Box color={colorMode === "light" ? "gray.500" : "white"}>
                    {video.category?.label}{" "}
                  </Box>
                  <Box>
                    <Badge
                      variant='solid'
                      fontSize='xs'
                      // colorScheme={newStatusSwitch(video.newStatus?.value)}
                      bgColor='whiteAlpha.100'>
                      {newStatusEmoji(video?.newStatus?.value)}
                      {"  "} {video.newStatus?.label}
                    </Badge>
                    {videos.cancel && (
                      <Badge
                        variant='solid'
                        fontSize='xs'
                        colorScheme='red'
                        ml='10px'>
                        canceled
                      </Badge>
                    )}
                  </Box>
                  <Box color={colorMode === "light" ? "gray.500" : "white"}>
                    {" "}
                    {videos?.pricing && videos?.pricing + "€"}{" "}
                  </Box>
                  {/* {admin && (
                <Flex
                  onClick={(e) => openTask(e, item)}
                  color="gray.500"
                  alignItems="center"
                  _hover={{ opacity: "0.4" }}
                >
                  <HiPencilAlt />
                </Flex>
              )} */}
                </TableRow>
              );
            })}
          </Box>
        )
        //   );
        // })
      }

      {/* <Box py="10px">
        {admin && !isLoading && (
          <Button w="full" onClick={() => onOpen()}>
            <Flex alignItems="center">
              <Box mr="5px">
                <HiPlusCircle />
              </Box>{" "}
              Add a video
            </Flex>
          </Button>
        )}
      </Box> */}
      {/* <NewVideo
        isOpen={isOpen}
        onClose={onClose}
        reloadData={reloadData}
        setReloadData={setReloadData}
        // projectData={projectData}
        projectName={projectName}
        projectId={projectId}
      /> */}

      {/* <EditVideo
        isOpen={isOpenEdit}
        onClose={onCloseEdit}
        reloadData={reloadData}
        setReloadData={setReloadData}
        // projectData={videoData.project}
        projectName={projectName}
        projectId={projectId}
        videoId={currentVideo._id}
        videoData={currentVideo}
      /> */}

      {/* <UpdateStatus
        isOpen={isOpenStatus}
        onClose={onCloseStatus}
        videoData={currentVideo}
        admin={admin}
        projectId={currentVideo?.project}
        videoId={currentVideo?._id}
        projectName={currentVideo?.project?.name}
        videoName={currentVideo?.title}
        setReloadData={setReloadData}
      /> */}
    </Box>
  );
}

import { API } from "aws-amplify";


export async function createContact(
    email,
    given_name,
    family_name, 
    address,
    client,
    phone, 
    job
    ) {

    const url =`/contact/create`

    try {
        await API.post("kronik", url, {
            body: {
                email:email,
                given_name: given_name,
                family_name:family_name, 
                address:address,
                client:client,
                phone:phone, 
                role:"client",
                job:job,
            }
        })
    }

    catch (e){
        console.log(e.message)
    }
}


export async function updateContact(
    contactId,
    email,
    given_name,
    family_name, 
    address,
    client,
    phone, 
    job) {

    const url =`/contact/update/${contactId}`

    try {
        await API.put("kronik", url, {
            body:{
                email:email,
                given_name: given_name,
                family_name:family_name, 
                address:address,
                client:client,
                phone:phone, 
                role:"client",
                job:job,
            }
        })
    }

    catch (e){
        console.log(e.message)
    }
}


export async function deleteContact(
    contactId,
   ) {

    const url =`/contact/delete/${contactId}`

    try {
        await API.put("kronik", url,)
    }

    catch (e){
        console.log(e.message)
    }
}
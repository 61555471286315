import React, { useState } from "react";
import { API } from "aws-amplify";
import { getHours } from "date-fns";
import dayjs from "dayjs";
import "dayjs/locale/fr";

export default async function fetchVideos(dateSelected, display) {
  // console.log("dateSelected", dateSelected);
  // console.log("display", display);
  //   if (dateSelected && dateSelected.lenght > 0) {

  return API.get(
    "kronik",
    `/video/get/videos/?year=${dateSelected}&display=${display}`
  ).then((response) => {
    // console.log("response", response);
    const videosGit = response.filter((item) => item.clientName === "GitHub");

    // console.log("videos videosGit", videosGit);
    const arrayKronik = response.filter((item) => item.clientName === "kronik");
    // console.log("arrayKronik", arrayKronik);
    const videosArray = response.map((item) => {
      const array = [];
      item.admins &&
        item.admins.map((admin) => {
          if (admin.role === "accountManager") {
            array.push(
              (admin.given_name + admin.family_name)
                .toString()
                .toLocaleLowerCase()
            );
          }
          return array;
        });

      return {
        title: item.title,
        organization: item.clientName,
        accountManager: array,
        date: dayjs(item.dueDate).format("MMMM YYYY"),
        dateJs: item.dueDate,
        dueDate: item.dueDate,
        month: dayjs(item.dueDate).format("MMMM YYYY"),
        revenueMonth: item.month,
        project: item.projectName,
        projectId: item.project,
        pricing: item.pricing,

        client: item.clientName,
        logo: item,
        id: item._id,
        brief: item.brief,
        status: item.status,
        newStatus: item.newStatus,
        category: item.category,
        format: item.format,
        subtitle: item.subtitle,
        duration: item.duration,
        people: item.people,
        places: item.places,
        clientId: item.client,
        clientLogo: item.clientLogo,
        totalJobs: item.totalJobs,
      };
    });
    return videosArray;
  });
}

export async function fetchVideosByUser(dateSelected, cognitoId) {
  // console.log("dateSelected", dateSelected);
  // console.log("display", display);
  //   if (dateSelected && dateSelected.lenght > 0) {

  return API.get(
    "kronik",
    `/video/get/admin/${cognitoId}?year=${dateSelected} `
  ).then((response) => {
    // console.log("videos avec user", response);
    const arrayKronik = response.filter((item) => item.clientName === "kronik");
    // console.log("arrayKronik", arrayKronik);
    const videosArray = response.map((item) => {
      // const array = [];
      // item.admins &&
      //   item.admins.map((admin) => {
      //     if (admin.role === "accountManager") {
      //       array.push((admin.given_name  + admin.family_name).toString().toLocaleLowerCase());
      //     }
      //     return array;
      //   });

      return {
        title: item.title,
        organization: item.clientName,
        // accountManager: array,
        date: dayjs(item.dueDate).format("MMMM YYYY"),
        dateJs: item.dueDate,
        dueDate: item.dueDate,
        month: dayjs(item.dueDate).format("MMMM YYYY"),
        revenueMonth: item.month,
        project: item.projectName,
        projectId: item.project,
        client: item.clientName,
        id: item._id,
        brief: item.brief,
        pricing: item.pricing,
        status: item.status,
        newStatus: item.newStatus,
        category: item.category,
        format: item.format,
        subtitle: item.subtitle,
        duration: item.duration,
        people: item.people,
        places: item.places,
        clientId: item.client,
        totalJobs: item.totalJobs,
      };
    });
    return videosArray;
  });
}

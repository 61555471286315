import { MoonIcon, SettingsIcon, SunIcon } from "@chakra-ui/icons";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  Box,
  // Link,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Text,
  useBreakpointValue,
  useColorMode,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import * as React from "react";
import { FiMenu } from "react-icons/fi";
import {
  navItem,
  navItemAdmin,
  navItemClient,
  navItemFreelance,
} from "../../data/headerData";
import { useUser } from "../../libs/UserContext";
import { Logo } from "./Logo";
import Uploader from "./Uploader";
import { FaUserCircle } from "react-icons/fa";

export const Layout = React.memo((props) => {
  const location = useLocation();

  const {
    user,
    admin,
    freelance,
    sidebarVisible,
    setSidebarVisible,
    sidebarMini,
    setSidebarMini,
    uploadFiles,
  } = useUser();

  const [downloadData, setDownloadData] = React.useState([]);
  const { colorMode, toggleColorMode } = useColorMode();

  const [userRole, setUserRole] = React.useState("");
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  function updateDownload(data) {
    setDownloadData(data);
    // console.log("data", data);
  }

  React.useEffect(() => {
    setUserRole(
      user?.attributes["custom:user_role"]
        ? user?.attributes["custom:user_role"]
        : "general"
    );
    // console.log("userRole", user?.attributes["custom:user_role"]);
    // console.log("user", user);
  }, [user]);
  return (
    <>
      {user ? (
        <Flex as='section'>
          {uploadFiles.length > 0 && <Uploader uploadFiles={uploadFiles} />}

          <Flex
            // as='nav'

            bg={colorMode === "light" ? "#F8FAFC" : "#1C1C1C"}
            // position='fixed'
            width='80px'
            // zIndex='100'
            height='100vh'
            // overflowY='auto'
            borderRight='1px solid'
            borderColor={colorMode === "light" ? "darkBorder" : "lightBorder"}>
            <Flex
              // maxW='100px'
              pt={{
                base: "2px",
                lg: "2  px",
              }}>
              <VStack spacing='0' justify='space-between'>
                {isDesktop ? (
                  <>
                    <Flex
                      justify='space-between'
                      flex='1'
                      minH='60px'
                      flexDirection='column'>
                      <Flex flexDirection='column'>
                        <Flex
                          alignItems='center'
                          justifyContent='center'
                          pt='10px'
                          pb='10px'
                          w='35px'
                          margin='auto'>
                          <Logo />
                        </Flex>
                        <Flex
                          flexDirection='column'
                          alignItems='center'
                          as='nav'
                          fontSize='sm'
                          // border="1px solid gray"
                          // bgColor={
                          //   colorMode === "light" ? "white" : "whiteAlpha.100"
                          // }
                          rounded='lg'
                          margin='10px'
                          aria-label='Main Navigation'
                          // borderWidth={colorMode === "light" ? "1px" : "0px"}
                          // borderColor='#F0F0F0'
                          // boxShadow='sm'
                          // minH="60px"

                          // pb="30px"
                          // pt="32px"
                        >
                          {(admin
                            ? navItemAdmin
                            : freelance
                            ? navItemFreelance
                            : navItemClient
                          ).map((item, index) => (
                            <Box
                              // display={{
                              //   base: "inline-flex",
                              //   md: "none",
                              //   xl: "inline-flex",
                              // }}
                              key={index}
                              minH='50px'
                              // mr="30px"
                              padding='3px'
                              fontSize='md'
                              color={
                                colorMode === "light"
                                  ? "#3C393A"
                                  : "whiteAlpha.800"
                              }
                              _selected={{
                                color: "accent",
                              }}>
                              <Tooltip
                                hasArrow
                                label={item.label ? item.label : ""}
                                bg='gray.300'
                                placement='right'
                                color='black'>
                                <Link
                                  // as={NavLink}
                                  // to='/clients'
                                  to={item.link}
                                  // activeClassName='selected'
                                  // className={
                                  //   item?.nav?.filter(
                                  //     (item) => location.pathname === item
                                  //   ).length > 0 && "selected"
                                  // }
                                  className={
                                    item?.nav?.filter(
                                      (item) =>
                                        location.pathname.split("/")[1] === item
                                    ).length > 0 && "selected"
                                  }
                                  _hover={{
                                    textDecor: "none",
                                  }}>
                                  <Box
                                    // paddingX='15px'
                                    fontWeight='600'
                                    paddingX='15px'
                                    paddingY='15px'
                                    _hover={{
                                      // textDecor: "none",
                                      color:
                                        colorMode === "light"
                                          ? "#302F30"
                                          : "white",

                                      bg:
                                        colorMode === "light"
                                          ? "blackAlpha.100"
                                          : "whiteAlpha.200",
                                      rounded: "lg",
                                    }}
                                    sx={{
                                      ".selected &": {
                                        color:
                                          colorMode === "light"
                                            ? "white"
                                            : "white",
                                        bg:
                                          colorMode === "light"
                                            ? "#F3605A"
                                            : "#F3605A",
                                        pb: "5px",
                                        pt: "6px",
                                        rounded: "lg",
                                        paddingX: "15px",
                                        paddingY: "15px",
                                        // borderBottom:"2px",
                                        // opacity: "0.5",

                                        // bg: "whiteAlpha.300",
                                      },
                                    }}>
                                    {/* {item.label} */}
                                    {item.picto ? item.picto : ""}
                                  </Box>
                                </Link>
                              </Tooltip>
                            </Box>
                          ))}

                          {/* <TabIndicator mt="4" height={1} borderTopRadius="md" bg="accent" /> */}
                        </Flex>
                      </Flex>
                      <VStack spacing='3' mb='10px'>
                        {/* <IconButton
                          onClick={toggleColorMode}
                          maxW='30px'
                          variant='ghost'
                          // variant="unstyled"
                          // color={ colorMode === "ligth" ? "red" : "black"}
                          // colorScheme="blue"
                          // display={{ base: "none", sm: "inline-flex" }}
                          fontSize='xl'
                          icon={
                            colorMode === "dark" ? <SunIcon /> : <MoonIcon />
                          }></IconButton> */}
                        <Link
                          as={NavLink}
                          to='/profil'
                          activeClassName='selected'
                          _hover={{ textDecor: "none" }}>
                          <IconButton
                            variant='ghost'
                            // aria-label="Menu"
                            display='inline-flex'
                            // color={{ base: "gray", md: "white" }}
                            // onClick={() => setSidebarVisible(!sidebarVisible)}
                            icon={<SettingsIcon />}
                            // variant="unstyled"
                          />
                        </Link>
                      </VStack>
                    </Flex>
                  </>
                ) : (
                  <IconButton
                    variant='ghost'
                    icon={<FiMenu fontSize='1.25rem' />}
                    aria-label='Open Menu'
                  />
                )}
              </VStack>
            </Flex>
            {/* <Divider /> */}
          </Flex>

          <Flex
            w='100%'
            // flex-direction='row'
            // flex-wrap='wrap'
            // width='100%'
            // marginX='auto'
            // minW='90vw'
            // minW='1280px'

            // px='0'
            // mx='20px'
            // ml='100px'
            // position='absolute'

            // pt={{
            //   base: "2px",
            //   lg: "20px",
            // }}
          >
            {/* {children} */}
            {React.Children.map(props.children, (child) => {
              return React.cloneElement(child, {
                updateDownload: updateDownload,
                setDownloadData: setDownloadData,
              });
            })}
          </Flex>
        </Flex>
      ) : (
        ""
      )}
    </>
  );
});

export const initialState = {
    given_name: "",
    family_name: "",
    email: "",
    url: "",
    about: "",
    note: "",
    experience: "",
    adress: "",
    bankAccount: "",
    birthDate: "",
    status: { label: "", value: "" },
    setup: [],
    skills: [],
    jobs:[],
    category: "",
    reference:"",
    averageGrade:"",
    review:"",
    isLoadingOne: false,
    isLoadingTwo: false,
    error: "",
  };
  
  export function reducer(state, action) {
    switch (action.type) {
      case "previousData": {
        return {
          ...state,
          given_name: action.payload.given_name,
          family_name: action.payload.family_name,
          email: action.payload.email,
          url: action.payload.url,
          about: action.payload.about,
          note: action.payload.note,
          experience: action.payload.experience,
          reference: action.payload.reference,
          adress: action.payload.adress,
          bankAccount: action.payload.bankAccount,
          birthDate: action.payload.birthDate,
          status: action.payload.status,
          setup: action.payload.setup,
          skills: action.payload.skills,
          category: action.payload.category,
          averageGrade:action.payload.averageGrade,
          review:action.payload.review,
          jobs: action.payload.jobs,
        };
      }
      case "field": {
        return {
          ...state,
          [action.payload.fieldName]: action.payload.value,
        };
      }
      case "save": {
        return {
          ...state,
          isLoadingOne: true,
        };
      }
      case "delete": {
        return {
          ...state,
          isLoadingTwo: true,
        };
      }
      case "error": {
        return { ...state, error: "there is an error" };
      }
      case "success": {
        return initialState;
      }
  
      default:
        return state;
    }
  }
  
import React, { useReducer } from "react";
import { API } from "aws-amplify";
import dayjs from "dayjs";
import { useUser } from "../../libs/UserContext";

export default async function fetchProjects(display) {
  return API.get("kronik", `/project/get/?display=${display}`).then(
    (response) => {
      // console.log("response", response);
      const arrayKronik = response.filter(
        (item) => item.clientName === "kronik"
      );
      // console.log("arrayKronik", arrayKronik);
      const projectArray = response
        .map((item) => {
          const array = [];
          item.admins &&
            item.admins.map((admin) => {
              if (admin.role === "accountManager") {
                // array.push(admin.given_name + " " + admin.family_name);
                array.push(
                  (admin.given_name + admin.family_name)
                    .toString()
                    .toLocaleLowerCase()
                );
              }
              return array;
            });
          return {
            project: item.project,
            client: item.client,
            videoCount: item.totalVideos,
            accountManager: array,
            clientName: item.client.name,
            cancel: item.project.cancel ? item.project.cancel : false,
            status: item.project.status
              ? item.project.status.label
              : "no status",
            wonDate: item.project.wonDate
              ? dayjs(item.project.wonDate).format("MMMM YYYY")
              : "",
            sortDate: item.project.wonDate ? dayjs(item.project.wonDate) : null,
          };
        })
        .sort(function (a, b) {
          return new Date(b.sortDate) - new Date(a.sortDate);
        });
      // .sort(function (b, a) {
      //   var c = new Date(a.sortDate);
      //   var d = new Date(b.sortDate);
      //   return c - d;
      // });
      // .sort(function (b, a) {
      //   if (a.project.wonDate > b.project.wonDate) {
      //     return -1;
      //   }
      //   if (a.project.wonDate < b.project.wonDate) {
      //     return 1;
      //   }
      //   return 0;
      // });

      return projectArray;
    }
  );
}

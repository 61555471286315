import React, { useState } from "react";
import Chart from "react-apexcharts";
import { Box, color, Heading, Link, useColorMode } from "@chakra-ui/react";
import { Link as RouteLink, NavLink, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function TreemapChart(props) {
  const { colorMode } = useColorMode();
  const history = useHistory();

  const { dataSeries, dataOptions, title, link } = props;

  const series = [
    {
      name: "Videos quantity", //will be displayed on the y-axis
      data: dataSeries,
      // data: [
      //   {
      //     x: "New Delhi",
      //     y: 218,
      //   },
      //   {
      //     x: "Kolkata",
      //     y: 149,
      //   },
      //   {
      //     x: "Mumbai",
      //     y: 184,
      //   },
      //   {
      //     x: "Ahmedabad",
      //     y: 55,
      //   },
      //   {
      //     x: "Bangaluru",
      //     y: 84,
      //   },
      //   {
      //     x: "Pune",
      //     y: 31,
      //   },
      //   {
      //     x: "Chennai",
      //     y: 70,
      //   },
      //   {
      //     x: "Jaipur",
      //     y: 30,
      //   },
      //   {
      //     x: "Surat",
      //     y: 44,
      //   },
      //   {
      //     x: "Hyderabad",
      //     y: 68,
      //   },
      //   {
      //     x: "Lucknow",
      //     y: 28,
      //   },
      //   {
      //     x: "Indore",
      //     y: 19,
      //   },
      //   {
      //     x: "Kanpur",
      //     y: 29,
      //   },
      // ],

      //   data: [25, 53, 50, 57],
    },
  ];
  const options = {
    
    stroke: {
      show: true,
      curve: 'stepline',
      lineCap: 'butt',
      colors: colorMode === "light" ? ["white"] : ["#232323"],
      width: 1,
      // dashArray: 0,      
  },
    tooltip: {
      theme: colorMode === "light" ? "light" : "dark",

    },
   

    colors: [colorMode === "light" ? "#76A7F0" : "#3E45FF" ],
    
   
    legend: {
      show: false,
      
    },
    
    dataLabels: {

      // enabled: true,
      // textAnchor: "start",

      style: {
        colors: colorMode === "light" ? ["#333"] : ["white"],
      
      },
    },
    chart: {
      
      toolbar: {
        
        show: false,
  

      },
      
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const selected = config.w.globals.selectedDataPoints[0][0];
          // console.log(dataSeries[selected].id);
          history.push(`/clients/${dataSeries[selected].id}`);
        },
        click: function (event, chartContext, config) {
          // console.log("event", event.target);
          // console.log("chartContext", chartContext);
          // console.log("config", config);
          // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
        },
      },
    },
  };

  return (
    <Box
      // bg="white"
      m="20px"
      background={colorMode === "light" ? "#fffff" : "#232323"}
    >
      <Heading as="h2" fontSize="xs" mb="4" textTransform="uppercase" >
        <Link as={NavLink} to={link} _hover={{ textDecor: "none" }}>
          {title}
        </Link>
      </Heading>
      <Chart options={options} type="treemap" series={series} height="500" />
    </Box>
  );
}

import { Box, Button, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { HiPencilAlt } from "react-icons/hi";
import CardTitle from "../../components/card/CardTitle";
import MainColumn from "../../components/column/MainColumn";
import { MiniHeader } from "../../components/header/MiniHeader";
import { Updating } from "../../components/notification/Updating";
import { useUser } from "../../libs/UserContext";
import RightBar from "./RightBar";
import SideBar from "./SideBar";
import Main from "./Main";
export default function Payment() {
  const { admin, client } = useUser();
  const { isLoading, setIsloading } = useState(false);
  return (
    <Box w='100%'>
      {(admin || client) && (
        <MainColumn>
          <MiniHeader>
            <CardTitle
              //   title={project && project.project.name}
              title='Invoice'
              isActive='false'
            />
            {admin && !isLoading && (
              <Button
                aria-label='Edit'
                variant='outline'
                rounded='full'
                size='sm'
                // onClick={() => onOpenEdit()}
                mx='10px'>
                <HiPencilAlt />
              </Button>
            )}
          </MiniHeader>

          <Flex justifyContent='space-between'>
            <SideBar
              //   projectName={project?.project?.name}
              //   clientName={project?.project?.client?.name}
              //   clientLink={`/clients/${project?.project?.client?._id}`}
              isLoading={isLoading}
              admin={admin}
              client={client}
              //   freelance={freelance}
              //   projectStatus={project?.project?.status?.value}
              //   clientLogo={project?.project?.client?.logo}
            />
            <Main
            //   project={project}
            //   videos={videos}
            //   reloadData={reloadData}
            //   setReloadData={setReloadData}
            />
            {(admin || client) && (
              <RightBar
                // projectData={project.project}
                // team={project.admins}
                // projectName={project && project.project.name}
                // projectId={projectId}
                // reloadData={reloadData}
                // setReloadData={setReloadData}
                admin={admin}
                isLoading={isLoading}
                // clientId={project?.project?.client?._id}
              />
            )}
            {isLoading && <Updating />}
          </Flex>
          {/* <EditProject
            isOpen={isOpenEdit}
            onClose={onCloseEdit}
            reloadData={reloadData}
            setReloadData={setReloadData}
            projectData={project?.project}
            client={project?.project?.client} 
          />  */}
        </MainColumn>
      )}
    </Box>
  );
}

import React, { useState, useEffect, useReducer } from "react";
import { useUser } from "../../libs/UserContext";
import { initialState, reducer } from "./reducer";
import { customStyles, darkStyles } from "../../styles/customStyles";
import {   statusOptions } from "../../data/jobData";
import { categoryOptions } from "../../data/taskData";

// import { getClients, updateProject, deleteProject } from "./api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Textarea,
  NumberInput,
  NumberInputField,
  useColorMode,
  Switch,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import Select from "react-select";
import { updateJob } from "./api";

export function EditJob(props) {
  const { colorMode } = useColorMode();

  const { isOpen, onClose, reloadData, setReloadData, jobData, videoName   } =
    props;
  const { user, admin } = useUser();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [clients, setClients] = useState("");
  const [dataDeleted, setDataDeleted] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [deleteButton, setDeleteButton] = useState(false);



  useEffect(() => {
    try {
      // console.log("projectData", projectData);
      dispatch({
        type: "previousData",
        payload: {
          _id:jobData ? jobData._id :"",
          email: jobData ? jobData.email :"",
          projectId:jobData ? jobData.project : "",
          clientId:jobData ?  jobData.client : "",
          clientName:jobData ? jobData.clientName :"",
          video:jobData ? jobData.video :"",
          videoId:jobData ? jobData.videoId : "",
          status: jobData ? jobData.status: "",
          title:jobData ?  jobData.title: "",
          brief:jobData ?  jobData.brief: "",
          pricing:jobData ? jobData.pricing: 0,
          ipAdress:jobData ? jobData.ipAdress: "",
          note: jobData ? jobData.note: "",
          category: jobData ? jobData.category: "",
          rating:jobData ? jobData.rating: "",
          deliverables:jobData ? jobData.deliverables : "",
          paymentStatus:jobData ?  jobData.paymentStatus: "",
          internalFeedback:jobData ? jobData.internalFeedback : "",
          clientFeedback:jobData ? jobData.clientFeedback: "",
          name:jobData ? jobData.name : "",
          videoName:jobData ? jobData.video.title :"",
          sendEmail:jobData ? jobData.sendEmail : false
       
        },
      });
    } catch (e) {
      console.log(e.message);
    }
  }, [jobData]);

  function save() {
    dispatch({ type: "save" });
    // console.log("dataSaved")
    updateJob( 
      state._id,
      state.email,
      state.projectId,
      state.clientId,
      state.clientName,
      state.video,
      state.category?.label + " " + state.videoName,
      state.brief,
      state.pricing,
      state.category,
      state.role,
      state.status,
      state.sendEmail,
      admin
      ).then((response) => {
          setReloadData(!reloadData);
          onClose();
          dispatch({ type: "success" });
        });
    // updateProject(projectData._id, admin, state).then((response) => {
    //   setReloadData(!reloadData);
    //   onClose();
    //   dispatch({ type: "success" });
    // });
  }

  function remove() {
    dispatch({ type: "delete" });

    // console.log("dataDeleted");
    // deleteProject(projectData._id, client._id).then((response) => {
    //   dispatch({ type: "success" });
    //   setDataDeleted(response);
    //   setDeleteButton(false);
    //   setConfirmMessage("");
    //   onClose();
    //   setReloadData(!reloadData);
    // });
  }

  const DateForm = () => {
    return (
      <DatePicker
        // className="date-picker"
        wrapperClassName={
          colorMode === "light"
            ? "date-picker-wrapper-light"
            : "date-picker-wrapper-dark"
        }
        
        // calendarClassName="dark-test"
        popperClassName={colorMode === "light" ? "ligth-picker" : "dark-picker"}
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        dateFormat="dd/MM/yyyy"
        placeholderText="Choisir une date"
        isClearable
        calendarStartDay={1}
        selected={state.birthDate}
        onChange={(date) =>
          dispatch({
            type: "field",
            payload: {
              fieldName: "birthDate",
              value: date,
            },
          })
        }
        // locale="fr"
      />
    );
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="outside"
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit job</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
        

          {/* Brief*/}
          <Box my="4">
              <FormLabel>Brief</FormLabel>
              <Textarea
                variant="filled"
                placeholder="Write a quick brief"
                size="md"
                resize="vertical"
                value={state.brief}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "brief",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Box>


  

              {/* Status */}
            <Box my="4">
              <FormLabel>Status</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                placeholder="Select a status"
                value={state.status}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "status",
                      value: e,
                    },
                  });
                }}
                options={statusOptions}
              />
            </Box>

               {/* Category */}
               <Box my="4">
              <FormLabel>Category</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                placeholder="Select a Category"
                value={state.category}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "category",
                      value: e,
                    },
                  });
                }}
                options={categoryOptions}
              />
            </Box>
           
           {/* Pricing */}
           <Box my="4">
              <FormControl id="pricing">
                <FormLabel>Pricing</FormLabel>
                <NumberInput defaultValue={state.pricing} max={100000} min={0} variant="filled">
                  <NumberInputField
                    placeholder="Add a price"
                    value={state.pricing}
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "pricing",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                  {/* <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper> */}
                </NumberInput>
              </FormControl>
            </Box>

             {/* Send email */}
           
           <Box my="4">
           <FormLabel>Send email</FormLabel>

              <Switch
                isChecked={state.sendEmail}
                isDisabled
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "sendEmail",
                      value: e.currentTarget.checked,
                    },
                  });
                }}
              >
                {state.sendEmail ? "Email sent" : "Email not sent"}
              </Switch>
            </Box>

            

           
        


          

           

            

            
            


           


          

            </ModalBody>

          <ModalFooter>
            <Button
              bgColor="primary"
              color="white"
              mr={3}
              onClick={save}
              isLoading={state.isLoadingOne}
              loadingText="Loading"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

import React, { useEffect, useReducer, useState } from 'react'
import { Box, Button, Center, Flex, FormLabel, Grid, Heading, Select, Text, Textarea } from '@chakra-ui/react'
import SideBarItem from '../../components/SideBarItem.js'
import { addOn, briefOptions, distributionOptions, equipment, exportOtherLanguage, exportSimple, exportWithSubtitles, goalOptions, marginRates, motionOptions, qualityOptions, speakerOptions, styleOptions, team, templateOptions, testimonialTemplate, testimonialTemplateTest, locationOptions } from '../../data/quoteData.js'
import { initialState, reducer } from './reducer.js'
import ButtonIcon from '../../components/button/ButtonIcon.js'
import { BsFillInboxFill } from 'react-icons/bs'
import { SelectMultiple } from './SelectMultiple.js'
import { SelectSimple } from './SelectSimple.js'
import { SelectNumber } from './SelectNumber.js'


export function NewProduct() {
    const[state, dispatch] = useReducer(reducer, initialState);
    const[totalEquipmenent, setTotalEquipment] = useState(0)
    const[totalAddOn, setTotalAddOn] = useState(0)
    const[totalTeam, setTotalTeam] = useState(0)
    const[totalExport, setTotalExport] = useState(0)
    const[totalFreelance, setTotalFreelance] = useState(0)
    const[totalKronikTeam, setTotalKronikTeam] = useState(0)
    const[totalKronikPlatform, setTotalKronikPlatform] = useState(0)
    const[editorQty, setEditorQty] = useState(0)
    const[projectManagerQty, setProjectManagerQty] = useState(0)
    const[totalQuote, setTotalQuote] = useState(0)



    useEffect(() => {
        const newEquipmentArray = state.equipment.reduce((acc, val) => (val.price*val.qty) + acc, 0)
        setTotalEquipment(newEquipmentArray)
        
        const newFreelanceArray = state.team.filter((item => (item.name !=="editor" && item.name !=="project manager"))).reduce((acc, val) => (val.price*val.qty) + acc, 0)
        setTotalFreelance(newFreelanceArray)

        const editornumber =  state.team?.find((item => item.name ==="editor"))?.qty && state.team.find((item => item.name ==="editor")).qty 
        setEditorQty(editornumber ? editornumber : 0)

        const projectManagerNumber =  state.team?.find((item => item.name ==="project manager"))?.qty && state.team.find((item => item.name ==="project manager")).qty
         setProjectManagerQty(projectManagerNumber ? projectManagerNumber : 0)
         
         const newAddOnArray = state.addOn.reduce((acc, val) => (val.price*(val.qty - (val.include > 0 ? val.include : 0))) +acc, 0)
         setTotalAddOn(newAddOnArray)

         const newExportSimpleArray = state.exportSimple.reduce((acc,val) => (val.price*(val.qty - (val.include > 0 ? val.include : 0))) + acc, 0)
         const newExportWithSubtitlesArray = state.exportWithSubtitles.reduce((acc,val) => (val.price*(val.qty - (val.include > 0 ? val.include : 0))) + acc, 0)
         const newExportOtherLanguageArray = state.exportOtherLanguage.reduce((acc,val) => (val.price*(val.qty - (val.include > 0 ? val.include : 0))) + acc, 0)
         setTotalExport(newExportSimpleArray+newExportWithSubtitlesArray+newExportOtherLanguageArray)
    }, [state])


    

    useEffect(() => {
        const totalEditor = editorQty && editorQty * (totalFreelance + totalEquipmenent + totalAddOn + totalExport)* marginRates.director.rate  
        const totalProjectManager = projectManagerQty && projectManagerQty * (totalFreelance + totalEquipmenent + totalAddOn + totalExport) * marginRates.projectManager.rate 
        setTotalKronikTeam(totalEditor + totalProjectManager)
    },[totalFreelance,totalEquipmenent, totalAddOn, totalExport, projectManagerQty, editorQty])


    useEffect(()=> {
        const subTotal = totalEquipmenent+totalFreelance+totalAddOn+totalExport+totalKronikTeam
        setTotalKronikPlatform(subTotal *marginRates.kronik.rate)
    },[totalEquipmenent,totalFreelance,totalAddOn, totalExport, totalKronikTeam]
    )
    useEffect(() =>{
        setTotalQuote(totalFreelance+ totalTeam+ totalKronikTeam+ totalKronikPlatform +totalAddOn + totalEquipmenent + totalExport)

    },[totalFreelance, totalTeam, totalKronikTeam, totalKronikPlatform, totalAddOn, totalEquipmenent, totalExport])

        return(

            <Box pt="0px">
                  <Grid templateColumns="2fr 5fr 2fr">
                    <Box   pl="20px">
                        <Box position="fixed">
                     
                        <SideBarItem
                            title="Client"
                            text="clientName"
                            // link={!isLoading && (admin || freelance) && clientLink}
                            status="client"
                        />
    
                    <SideBarItem
                            title="Quote"
                            text="Testimonial"
                            // link={!isLoading && projectLink}
                            status="preparation"
                            
                            />
                    </Box>
                           
                    </Box>
                    <Box>
                    <Box
                        backgroundImage={
                            state.template==="basic" ? "url('https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=900&q=80')" :
                            state.template==="standard" ? "url('https://images.unsplash.com/photo-1523240795612-9a054b0db644?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=900&q=80')" :
                            "https://images.unsplash.com/photo-1622151834677-70f982c9adef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=900&q=80"
                        }
                        backgroundPosition="center"
                        backgroundBlendMode="darken"
                        backgroundColor="blackAlpha.600"
                        backgroundRepeat="no-repeat"
                        h="450px"
                        minW="100%"
                        rounded="md"
                    >
                        <Flex h="450px" alignItems="center" justifyContent="center" >        
                        <Box>
                            <Box> <Heading size="3xl" color="white">\ Testimonial client \</Heading></Box>
                            {/* <Center><Text color="white">{state.template && state.template }</Text></Center> */}
                            <Center><Text color="white">by studioName</Text></Center>
                        </Box>
                        </Flex>
                    </Box>

                         <Box mt="40px">
                            {qualityOptions.map((item, index) => {
                                return (
                                    <Box    
                                    key={index}
                                    display="inline-grid"
                                    marginX="10px"
                                    width="120px">
                                        <ButtonIcon
                                         title={item.name}
                                            click={(e) =>{
                                                const newtemplate = item.name
                                                // console.log("motion", item)
                                                dispatch({
                                                    type: "changeTemplate",
                                                    payload: {
                                                        template: newtemplate,
                                                        brief:testimonialTemplate[newtemplate]?.brief ? testimonialTemplate[newtemplate]?.brief  : [] ,
                                                        location:testimonialTemplate[newtemplate]?.location ? testimonialTemplate[newtemplate]?.location  : [] ,
                                                        goal:testimonialTemplate[newtemplate]?.goal ? testimonialTemplate[newtemplate]?.goal  : [] ,
                                                        distribution:testimonialTemplate[newtemplate]?.distribution ? testimonialTemplate[newtemplate]?.distribution  : [] ,
                                                        speaker:testimonialTemplate[newtemplate]?.speaker ? testimonialTemplate[newtemplate]?.speaker  : [] ,
                                                        style:testimonialTemplate[newtemplate]?.style ? testimonialTemplate[newtemplate]?.style  : [] ,
                                                        preparationInterview:testimonialTemplate[newtemplate]?.motion,
                                                        motion:testimonialTemplate[newtemplate]?.motion ? testimonialTemplate[newtemplate]?.motion : {},
                                                        equipment:testimonialTemplate[newtemplate]?.equipment ? testimonialTemplate[newtemplate]?.equipment : [],
                                                        addOn:testimonialTemplate[newtemplate]?.addOn ? testimonialTemplate[newtemplate]?.addOn : [],
                                                        team:testimonialTemplate[newtemplate]?.team ? testimonialTemplate[newtemplate]?.team : [],
                                                        exportSimple:testimonialTemplate[newtemplate]?.exportSimple ? testimonialTemplate[newtemplate]?.exportSimple : [],
                                                        exportWithSubtitles:testimonialTemplate[newtemplate]?.exportWithSubtitles ? testimonialTemplate[newtemplate]?.exportWithSubtitles : [],
                                                        exportOtherLanguage:testimonialTemplate[newtemplate]?.exportOtherLanguage ? testimonialTemplate[newtemplate]?.exportOtherLanguage : [],
                                                    }
                                                        
                                                    ,
                                                })
                                                    }
                                                }
                                                checked={ state.template === item.name ? true : false}
                                                icon={item.icon}
                                                />
                                    </Box>

                                )
                            })}
                            
                            </Box>


                         
                            <Heading pt="80px">Brief</Heading>

                           

                             {/* Brief */}
                             <Box mt="40px">
                            <FormLabel>Format</FormLabel>
                          
                                {briefOptions.map((item, index) => {
                                    function newState() {
                                        if(
                                            state.brief.filter(it => it.name === item.name).length === 1   ) {
                                            const newArray = state.brief?.filter(it => it.name !== item.name)
                                            item.qty = 1
                                            return newArray
                                        } else {                                          
                                            return  [...state.brief, item]
                                        }
                                    } 

                                    const itemText = state.brief.find(it => it.name === item.name)?.text  ? state.brief.find(it => it.name === item.name)?.text : ""
                                   
                                    return (
                                       <Box    
                                        display="inline-grid"
                                        marginX="10px"
                                       width="120px">
                                            <ButtonIcon
                                            title={item.name +" "+itemText}
                                            // click={() =>{
                                            //         dispatch({
                                            //             type:"field",
                                            //             payload:{
                                            //                 fieldName:"brief",
                                            //                 value: newState()
                                            //             }
                                            //             })
                                            //         }
                                            //     }
                                                // checked={ state.brief.filter(it => it.category === item.category).length > 0 ? true : false}
                                                icon={item.icon}
                                            />
                                            <Flex alignItems="center">
                                            
                                        </Flex>

                                            
                                        </Box>
                                    )

                                    }
                                    
                                    )}
                                    
                            </Box>
{/*                           
                            <Box mt="40px">
                                <FormLabel>Note</FormLabel>
                                <Textarea placeholder='Here is a sample placeholder' />
                            </Box> */}

                        

                            {/* <SelectMultiple arrayOptions={speakerOptions} category="speaker" state={state} dispatch={dispatch}/> */}
                            {/* <SelectMultiple arrayOptions={goalOptions} category="goal" state={state} dispatch={dispatch}/>       */}
                            {/* <SelectMultiple arrayOptions={distributionOptions} category="distribution" state={state} dispatch={dispatch}/> */}
                            {/* <SelectSimple arrayOptions={styleOptions} category="style" state={state} dispatch={dispatch}/> */}
                            
                         

                    

                            <Heading pt="80px">Options</Heading>

                            <SelectNumber arrayOptions={team} category="team" state={state} dispatch={dispatch} />
                            <SelectNumber arrayOptions={equipment} category="equipment" state={state} dispatch={dispatch} />
                            {/* <SelectMultiple arrayOptions={locationOptions} category="location" state={state} dispatch={dispatch}/> */}
                            <SelectNumber arrayOptions={addOn} category="addOn" state={state} dispatch={dispatch}/>
                            
                            <Heading pt="80px">Exports</Heading>
                            <SelectNumber arrayOptions={exportSimple} category="exportSimple" state={state} dispatch={dispatch} />
                            <SelectNumber arrayOptions={exportWithSubtitles} category="exportWithSubtitles" state={state} dispatch={dispatch} />
                            <Button
                                my="40px"
                                
                                onClick={() =>
                                dispatch({
                                    type:"field",
                                    payload:{
                                        fieldName:"isOtherSubtitlesLanguage",
                                        value:!state.isOtherSubtitlesLanguage
                                    }
                                })
                                }
                            > 
                            is Other Subtitles Language ?</Button>
                            {
                                state.isOtherSubtitlesLanguage &&
                                <SelectNumber arrayOptions={exportOtherLanguage} category="exportOtherLanguage" state={state} dispatch={dispatch} />
                            }
                              
                    </Box>
                    <Box>

                    <Box position="fixed" mx="20px">
                        <Text> Total Freelance: {Math.floor(totalFreelance)}€</Text>
                        <Text> Total Equipement: {Math.floor(totalEquipmenent)}€</Text>
                        <Text> Total export: {Math.floor(totalExport)}€</Text>
                        <Text> Total addOn: {Math.floor(totalAddOn)}€</Text>
                        <Text> Total kronik team: {Math.floor(totalKronikTeam)}€</Text>
                        <Text> Total kronik platform: {Math.floor(totalKronikPlatform)}€</Text>
                        <Heading size="md"> Total quote: {Math.floor(totalQuote)}€</Heading>
                    </Box>

                    </Box>
                </Grid>
            </Box>

    )
}
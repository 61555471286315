import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import SideBar from "./SideBar";
import { HiPencilAlt } from "react-icons/hi";
import { Updating } from "../../components/notification/Updating";
import Profil from "./About";
import RightBar from "./RightBar";
import { EditFreelance } from "../../components/freelance/EditFreelance";
import JobsList from "./JobsList";
import About from "./About";
import fetchFreelance from "./api";
import { Main } from "./Main";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";

export function Freelance() {
  let { freelanceId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { admin } = useUser();
  const [reloadData, setReloadData] = useState(false);
  const [freelanceData, setFreelanceData] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setIsLoading(true);
    fetchFreelance(freelanceId, admin).then((response) => {
      // console.log("response", response);
      setFreelanceData(response);
      setIsLoading(false);
    });
  }, [freelanceId, admin, reloadData]);

  //  const freelance=
  //       {
  //         _id: 1,
  //         given_name: "Michel",
  //         family_name: "Aimget",
  //         email: "michel.aimget@gmail.com",
  //         url: "aimget.com",
  //         about: "Yes I'm filmmaker",
  //         note: "Good profil",
  //         experience: "2 ans",
  //         adress: "10 rue de Penthièvre, 75008 Paris",
  //         bankAccount: "GDJD7EJKZJZJ2 2882",
  //         birthDate: new Date(),
  //         status: { value: "validated", label: "Validated"  },
  //         material: [{value: "micro", label: "Micro" }],
  //         skills: [  { value: "shooting", label: "Shooting" },
  //         { value: "filmmaker", label: "Filmmaker" },],
  //         project:{_id:2, name:"projectname"},
  //         jobs:[
  //             {_id:1, project:"hdy", title :"Montage video 1", newStatus:{value: "validated", label: "Validated" }, pricing:"350"},
  //             {_id:2, project:"zhezez", title :"Montage video 2 + capsule", newStatus:{value: "validated", label: "Validated" }, pricing:"450"}
  //           ]
  //       }

  return (
    <Box width='100%'>
      <MiniHeader>
        <CardTitle
          title={
            freelanceData &&
            freelanceData?.given_name + " " + freelanceData?.family_name
          }
          isActive
        />

        <Flex alignItems='center'>
          {admin && !isLoading && (
            <Button
              aria-label='Edit'
              variant='outline'
              rounded='full'
              size='sm'
              onClick={() => onOpen()}
              mx='10px'>
              <HiPencilAlt />
            </Button>
          )}
        </Flex>
      </MiniHeader>
      {admin && (
        <Flex justifyContent='space-between'>
          <SideBar
            freelanceName={
              freelanceData &&
              freelanceData?.given_name + " " + freelanceData?.family_name
            }
            // clientName={project?.project?.client?.name}
            freelanceLink={`/freelances/${freelanceData?._id}`}
            isLoading={isLoading}
            admin={admin}
            clientLogo={freelanceData?.profile_picture}
          />

          <Main freelanceData={freelanceData} />

          <RightBar
            freelanceData={freelanceData}
            freelanceId={freelanceId}
            reloadData={reloadData}
            setReloadData={setReloadData}
            admin={admin}
            isLoading={isLoading}
          />

          <EditFreelance
            isOpen={isOpen}
            onClose={onClose}
            reloadData={reloadData}
            setReloadData={setReloadData}
            freelanceData={freelanceData}
          />
        </Flex>
      )}

      {isLoading && <Updating />}
    </Box>
  );
}

import React, { useState, useEffect, useReducer } from "react";
import { useUser } from "../../libs/UserContext";
import { initialState, reducer } from "./reducer";
import { customStyles, darkStyles } from "../../styles/customStyles";
import { goalOptions, statusOptions } from "../../data/projectData";
import { getClients, updateProject, deleteProject } from "./api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Textarea,
  NumberInput,
  NumberInputField,
  useColorMode,
  Switch,
} from "@chakra-ui/react";
import Select from "react-select";

export function EditProject(props) {
  const { colorMode } = useColorMode();

  const { isOpen, onClose, reloadData, setReloadData, projectData, client } =
    props;
  const { user, admin } = useUser();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [clients, setClients] = useState("");
  const [dataDeleted, setDataDeleted] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [deleteButton, setDeleteButton] = useState(false);

  useEffect(() => {
    if (admin) {
      getClients().then((response) => {
        setClients(
          response.map((item) => {
            return {
              label: item.name,
              value: item._id,
            };
          })
        );
      });
    }
  }, []);

  useEffect(() => {
    try {
      // console.log("projectData", projectData);
      dispatch({
        type: "previousData",
        payload: {
          clients: clients ? clients : "",
          name: projectData ? projectData.name : "",
          description: projectData ? projectData.description : "",
          quantity: projectData ? projectData.quantity : "",
          pricing: projectData ? projectData.pricing : "",
          status: projectData ? projectData.status : "",
          category: projectData ? projectData.type : "",
          currentGoals: projectData ? projectData.goal : "",
          currentClientId: client ? client._id : "",
          wonDate: Date.parse(projectData && projectData.wonDate),
          cancel: projectData ? projectData.cancel : "",

          currentClient: projectData
            ? clients.find((element) => element.value === client._id)
            : "",
        },
      });
    } catch (e) {
      console.log(e.message);
    }
  }, [projectData]);

  function save() {
    dispatch({ type: "save" });
    updateProject(projectData._id, admin, state).then((response) => {
      setReloadData(!reloadData);
      onClose();
      dispatch({ type: "success" });
    });
  }

  function remove() {
    dispatch({ type: "delete" });

    deleteProject(projectData._id, client._id).then((response) => {
      // console.log("dataDeleted", response);
      dispatch({ type: "success" });
      setDataDeleted(response);
      setDeleteButton(false);
      setConfirmMessage("");
      onClose();
      setReloadData(!reloadData);
    });
  }

  const DateForm = () => {
    return (
      <DatePicker
        // className="date-picker"
        wrapperClassName={
          colorMode === "light"
            ? "date-picker-wrapper-light"
            : "date-picker-wrapper-dark"
        }
        // calendarClassName="dark-test"
        popperClassName={colorMode === "light" ? "ligth-picker" : "dark-picker"}
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        dateFormat='dd/MM/yyyy'
        placeholderText='Choisir une date'
        isClearable
        selected={state.wonDate}
        calendarStartDay={1}
        onChange={(date) =>
          dispatch({
            type: "field",
            payload: {
              fieldName: "wonDate",
              value: date,
            },
          })
        }
        // locale="fr"
      />
    );
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior='outside'
        size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit quote</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Client */}
            <Box my='4'>
              <FormLabel>Client</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                placeholder='Select a client '
                value={state.currentClient}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "currentClient",
                      value: e,
                    },
                  });
                }}
                options={clients}
              />
            </Box>
            {/* Title */}
            <Box my='4'>
              <FormControl id='subtitle'>
                <FormLabel>Project name</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a project name'
                  value={state.name}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "name",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>
            {/* Brief*/}
            <Box my='4'>
              <FormLabel>Brief</FormLabel>
              <Textarea
                variant='filled'
                riant='filled'
                placeholder='Write a quick brief'
                size='md'
                resize='vertical'
                value={state.description}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "description",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Box>
            {/* Goals */}
            <Box my='4'>
              <FormLabel>Goal(s)</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                isMulti
                placeholder='Select one (or more) goal(s) '
                value={state.currentGoals}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "currentGoals",
                      value: e,
                    },
                  });
                }}
                options={goalOptions}
              />
            </Box>

            {/* Quantity */}
            <Box my='4'>
              <FormControl id='quantity'>
                <FormLabel>Video quantity</FormLabel>
                <NumberInput
                  max={1000}
                  min={1}
                  variant='filled'
                  value={
                    state && typeof state.quantity !== "number"
                      ? undefined
                      : state.quantity
                  }>
                  <NumberInputField
                    placeholder='Add a number of video'
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "quantity",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                  {/* <NumberInputStepper
                   
                  >
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper> */}
                </NumberInput>
              </FormControl>
            </Box>
            {/* Pricing */}
            <Box my='4'>
              <FormControl id='pricing'>
                <FormLabel>Total HT</FormLabel>
                <NumberInput
                  max={1000000}
                  min={10}
                  variant='filled'
                  value={
                    state && typeof state.pricing !== "number"
                      ? undefined
                      : state.pricing
                  }>
                  <NumberInputField
                    placeholder='Add a number'
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "pricing",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </NumberInput>
              </FormControl>
            </Box>
            {/* Status */}
            <Box my='4'>
              <FormLabel>Status</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                placeholder='Select a status'
                value={state.status}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "status",
                      value: e,
                    },
                  });
                }}
                options={statusOptions}
              />
            </Box>

            {/* Date */}
            <Box my='4'>
              <FormLabel>Won date</FormLabel>
              <Flex>
                <Box maxW='200'>
                  <DateForm />
                </Box>
              </Flex>
            </Box>

            {/* Cancel switch */}
            <Box my='4'>
              <FormLabel>Cancel</FormLabel>
              <Flex>
                <Box maxW='200'>
                  <Switch
                    size='lg'
                    colorScheme='red'
                    isChecked={state.cancel}
                    onChange={(e) => {
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "cancel",
                          value: e.currentTarget.checked,
                        },
                      });
                    }}
                  />
                </Box>
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter>
            {(user.username === "florian@kronik.com" ||
              user.username === "matt@kronik.com") &&
              !deleteButton && (
                <Button mr={3} onClick={() => setDeleteButton(true)}>
                  Delete
                </Button>
              )}
            {!deleteButton && (
              <Button
                isDisabled={!state.currentClient || !state.name}
                bgColor='primary'
                color='white'
                mr={3}
                onClick={() => save()}
                isLoading={state.isLoadingOne}
                loadingText='Loading'>
                Save
              </Button>
            )}
            {deleteButton && (
              <Box mr='20px'>
                <FormControl id='confirmation'>
                  <Input
                    variant='filled'
                    placeholder='Enter DELETE to confirm'
                    value={confirmMessage}
                    onChange={(e) => setConfirmMessage(e.target.value)}
                  />
                </FormControl>
              </Box>
            )}
            {deleteButton && (
              <Button
                // colorScheme="blue"
                mr={3}
                onClick={() => setDeleteButton(false)}>
                Cancel
              </Button>
            )}
            {confirmMessage === "DELETE" && deleteButton && (
              <Button
                colorScheme='red'
                mr={3}
                onClick={() => remove()}
                isLoading={state.isLoadingTwo}
                loadingText='Loading'>
                Delete
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

import { Flex, useColorMode } from "@chakra-ui/react";

export function LeftColumn({ children }) {
  const { colorMode } = useColorMode();
  return (
    <Flex
      w='280px'
      minW='280px'
      borderRight='1px solid'
      borderColor={colorMode === "light" ? "darkBorder" : "lightBorder"}
      height='100vh'
      flexDirection='column'
      pt='0px'
      pl='0px'>
      {children}
    </Flex>
  );
}

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import SideBar from "./SideBar";
import { HiPencilAlt } from "react-icons/hi";
import { Updating } from "../../components/notification/Updating";
import Profil from "./About";
import RightBar from "./RightBar";
import { EditFreelance } from "../../components/freelance/EditFreelance";
import JobsList from "./JobsList";
import About from "./About";
import fetchFreelance from "./api";
import MainColumn from "../../components/column/MainColumn";
import CardTitle from "../../components/card/CardTitle";
import { MiniHeader } from "../../components/header/MiniHeader";

export function Main({ freelanceData }) {
  let { freelanceId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { admin } = useUser();
  const [reloadData, setReloadData] = useState(false);
  //   const [freelanceData, setFreelanceData] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <MainColumn>
      {admin && (
        <>
          <Box mt='20px'>
            {/* <Flex justifyContent='space-between'>
              <Flex>
                <Flex direction='column'> */}

            {/* </Flex>
              </Flex>
              <Flex alignItems='center'></Flex>
            </Flex> */}
            <Box
              // bg={colorMode === "light" ? "#F0F0F0" : "gray.900"}
              // px="10px"
              py='0px'
              mt='8px'
              rounded='xl'>
              <Box mx='20px'>
                <About freelance={freelanceData} />

                <JobsList
                  jobs={freelanceData?.jobs}
                  freelanceId={freelanceId}
                  setReloadData={setReloadData}
                  reloadData={reloadData}
                  projectData={freelanceData?.project}
                  project={freelanceData?.project}
                  projectId={freelanceData?.project?._id}
                  projectName={freelanceData?.project?.name}
                  admin={admin}
                  isLoading={isLoading}
                />
              </Box>
            </Box>
          </Box>

          <EditFreelance
            isOpen={isOpen}
            onClose={onClose}
            reloadData={reloadData}
            setReloadData={setReloadData}
            freelanceData={freelanceData}
          />
        </>
      )}
    </MainColumn>
  );
}

import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { EditBrief } from "../../components/brief/EditBrief";
import { NewBrief } from "../../components/brief/NewBrief";
import CardTitle from "../../components/card/CardTitle";
import MainColumn from "../../components/column/MainColumn";
import BusinessHeader from "../../components/header/BusinessHeader";
import { MiniHeader } from "../../components/header/MiniHeader";
import TableRow from "../../components/table/TableRow";
import { useUser } from "../../libs/UserContext";
import { deleteBrief, fetchBriefs } from "./api";
import { BriefRow } from "./BriefRow";

export default function BriefsList(props) {
  const { briefs, reloadData, setReloadData, isLoading, setIsloading } = props;
  const { admin, user } = useUser();

  const briefsArray = briefs.map((brief) => ({
    ...brief,
    month: brief.date ? dayjs(brief.date).format("MMMM YYYY") : "",
  }));

  const { isOpen: isOpen, onOpen: onOpen, onClose: onClose } = useDisclosure();

  // const {
  //     isOpen: isOpenEdit,
  //     onOpen: onOpenEdit,
  //     onClose: onCloseEdit,
  //   } = useDisclosure();

  // const [currentBrief, setCurrentBrief] = useState("");

  // function openBrief(e, data){

  //     setCurrentBrief(data)
  //     onOpenEdit()
  //     e.preventDefault();
  //     e.stopPropagation();
  // }

  // function deleteB(e, briefId, admin, user){
  //   e.preventDefault();
  //   e.stopPropagation();
  //     deleteBrief(e,briefId, admin, user).then((response) => {
  //       setReloadData(!reloadData);
  //     })
  // }

  const groupBy = (array, key) => {
    return array.reduce((groupedProjects, array) => {
      if (groupedProjects[array[key]] == null) groupedProjects[array[key]] = [];
      groupedProjects[array[key]].push(array);
      return groupedProjects;
    }, {});
  };

  const briefsGroupByMonth = briefsArray && groupBy(briefsArray, "month");

  // console.log("briefs", briefs);
  return (
    <Box w='100%'>
      <MainColumn>
        <BusinessHeader />
        <Box px='20px' pt='20px'>
          {/* {briefs && briefsArray.map((item, index) => {

                    return ( */}
          <Flex justifyContent='space-between'>
            <Box></Box>
            {admin && (
              <Button color='white' bg='primary' onClick={() => onOpen()}>
                New brief
              </Button>
            )}
          </Flex>
          {briefs &&
            Object.keys(briefsGroupByMonth).map((item, index) => {
              //   const totalPricing =
              //     projectsGroupByMonth &&
              //     projectsGroupByMonth[item].reduce((acc, currenVal) => {
              //       return acc + currenVal.pricing;
              //     }, 0);

              return (
                <Box key={index} pb='40px'>
                  <Heading
                    as='h2'
                    fontSize='xl'
                    mb='2'
                    textTransform='capitalize'>
                    {item}
                  </Heading>

                  {briefsGroupByMonth[item].map((it, index) => {
                    return (
                      <BriefRow
                        it={it}
                        reloadData={reloadData}
                        setReloadData={setReloadData}
                        key={index}
                      />

                      // <TableRow
                      //   key={index}
                      //   click={(e) =>  openBrief(e, it)}
                      //   gridCol="2fr 2fr 2fr 2fr 1fr "
                      // >

                      //     <Box>{it.company}</Box>
                      //     <Box>{it.client?.name}</Box>
                      //     <Box>{it.product}</Box>
                      //     <Box>{it.pricing}</Box>
                      //     {/* <Box>{it.month}</Box> */}
                      //     <Button
                      //       onClick={(e) => deleteB(e, it._id, admin, user)}
                      //       // isLoading={state.isLoadingTwo}
                      //       loadingText="Loading"
                      //     >
                      //       Delete
                      //     </Button>
                      // </TableRow>
                    );
                  })}
                </Box>
              );
            })}

          {/* )
                } */}
          {/* )} */}
        </Box>
      </MainColumn>

      <NewBrief
        isOpen={isOpen}
        onClose={onClose}
        reloadData={reloadData}
        setReloadData={setReloadData}
        // briefData={currentBrief}
      />
    </Box>
  );
}

import { Badge, Box, Button, useDisclosure } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useState } from "react";
import { EditBrief } from "../../components/brief/EditBrief";
import TableRow from "../../components/table/TableRow";
import { statusSwitch } from "../../data/briefData";
import { useUser } from "../../libs/UserContext";
import { deleteBrief } from "./api";

export function BriefRow(props) {
  const { it, reloadData, setReloadData, index } = props;
  const { admin, user } = useUser();

  const [currentBrief, setCurrentBrief] = useState("");
  const [isLoading, setIsloading] = useState(false);
  // const {
  //     isOpen: isOpen,
  //     onOpen: onOpen,
  //     onClose: onClose,
  //   } = useDisclosure();

  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();

  function openBrief(e, data) {
    setCurrentBrief(data);
    onOpenEdit();
    e.preventDefault();
    e.stopPropagation();
  }

  function deleteB(e, briefId, admin, user) {
    setIsloading(true);
    e.preventDefault();
    e.stopPropagation();
    deleteBrief(e, briefId, admin, user).then((response) => {
      setReloadData(!reloadData);
      setIsloading(false);
    });
  }

  //   console.log("it", it)
  return (
    <Box key={index}>
      <TableRow
        click={(e) => openBrief(e, it)}
        gridCol='3fr 2fr 2fr 2fr 2fr 3fr 2fr 1fr '>
        <Box>{it.company}</Box>
        <Box>{dayjs(it.date).format("dddd DD MMM")}</Box>
        <Box>{it.client?.name}</Box>
        <Box>{it.product}</Box>
        <Box>{it.creari}</Box>
        <Box>{it.pricing}</Box>
        <Box>{it.source?.label}</Box>
        <Box>
          <Badge
            fontSize='xs'
            variant='solid'
            colorScheme={statusSwitch(it?.status?.value)}>
            {it?.status?.value ? it.status?.label : ""}
          </Badge>
        </Box>
        {/* <Box>{it.month}</Box> */}
        {/* <Button
                onClick={(e) => deleteB(it._id, admin, user)}
                isLoading={isLoading}
                loadingText="Deleting"
                >
                Delete
            </Button> */}
      </TableRow>

      {currentBrief && (
        <EditBrief
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          reloadData={reloadData}
          setReloadData={setReloadData}
          briefData={currentBrief}
        />
      )}
    </Box>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  Link,
  useColorMode,
  Checkbox,
  list,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import { Link as RouteLink } from "react-router-dom";
import { statusSwitch, validationSwitch } from "../../data/taskData";
import { FaFile, FaFileVideo } from "react-icons/fa";
import { BsFillPlayBtnFill } from "react-icons/bs";

import Upload from "./Upload";
import Card from "../../components/card";
import { downloadFile } from "./api";
import TableRow from "../../components/table/TableRow";
import CardTitle from "../../components/card/CardTitle";
import { VideoPlayer } from "../player/VideoPlayer";

export default function Player({
  timecode,
  setTimecode,
  index,
  file,

  clientId,
  setComments,
  reloadComments,
  setReloadComments,
  isLoading,
  setIsLoading,
  currentFile,
  setCurrentFile,
  files,
  previousCurrentFile,
  nextCurrentFile,
}) {
  const { colorMode } = useColorMode();
  // console.log("taskData", taskData);
  // console.log("user", user);
  let history = useHistory();
  const [listUrl, setListUrl] = useState([]);
  const [playing, setPlaying] = useState(false);

  // console.log("file", file);
  return (
    <Box key={index}>
      {file?.muxPlaybackId?.length > 0 ? (
        <Box key={index}>
          <VideoPlayer
            muxPlaybackId={file?.muxPlaybackId}
            muxId={file?.muxId}
            clientId={clientId}
            fileId={file?._id}
            setComments={setComments}
            reloadComments={reloadComments}
            setReloadComments={setReloadComments}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            timecode={timecode}
            setTimecode={setTimecode}
            currentFile={currentFile}
            setCurrentFile={setCurrentFile}
            files={files}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

import React, { useState } from "react";
import Chart from "react-apexcharts";
import { Box, Heading, Link, useColorMode } from "@chakra-ui/react";
import { Link as RouteLink, NavLink, useLocation } from "react-router-dom";

export default function TaskChart(props) {
  const { colorMode } = useColorMode();

  const { dataSeries, dataOptions, title, link } = props;

  const series = dataSeries;
  // [
  //   {
  //     name: "Videos quantity", //will be displayed on the y-axis
  //     // data: dataSeries,
  //     series: dataSeries,
  //     //   data: [25, 53, 50, 57],
  //   },
  // ];
  const options = {
    tooltip: {
      theme: colorMode === "light" ? "light" : "dark",
    },
    chart: {
      foreColor: colorMode === "light" ? "#333" : "white",
      id: "bar",
      stacked: true,

      toolbar: {
        show: false,
      },
    },

    xaxis: {
      categories: dataOptions, //will be displayed on the x-asis
    },
    plotOptions: {
      bar: {
        barHeight: "90%",

        // distributed: true,

        horizontal: true,

        // dataLabels: {
        //   position: "bottom",
        // },
      },
    },
    colors: [
      // "#C4BFFA",
      colorMode === "light" ? "#BFBFBF" : "#BFBFBF" ,
      colorMode === "light" ? "#76A7F0" : "#3E45FF" ,
      // "#5F5CD2",
      // "#5F5CD2",
      // "#5F5CD2",
      // "#5F5CD2",
      // "#5F5CD2",
      // "#5F5CD2",
      // "#5F5CD2",
    ],
    dataLabels: {
      // enabled: true,
      // textAnchor: "start",

      style: {
        colors: colorMode === "light" ? ["#333"] : ["white"],
      },
      // formatter: function (val, opt) {
      //   return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      // },

      // offsetX: 0,
      //   dropShadow: {
      //     enabled: true,
      //   },
    },
    yaxis: {
      labels: {
        show: true,
        trim: false,
      },
    },
  };

  // console.log("tasks", series);

  return (
    <Box
      bg="white"
      m="20px"
      background={colorMode === "light" ? "#fffff" : "#232323"}
    >
      <Heading as="h2" fontSize="xs" mb="4" textTransform="uppercase">
        <Link as={NavLink} to={link} _hover={{ textDecor: "none" }}>
          {title}
        </Link>
      </Heading>
      <Chart options={options} type="bar" series={series} />
    </Box>
  );
}

import React, { useReducer, useEffect, useState } from 'react'
import { Box, Button, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, useColorMode } from '@chakra-ui/react';
import Select from "react-select";
import { initialState, reducer } from "./reducer";
import { customStyles, darkStyles } from "../../styles/customStyles";
import { Switch } from 'react-router-dom';
import { useUser } from "../../libs/UserContext";
import { updateTags } from './api';
import { tagsOptions } from '../../data/uploadData';


export default function EditTags(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

    const { colorMode } = useColorMode();
    const { user, admin } = useUser();

    const [tags, setTags] = useState([]);
    const {
        isOpen,
        onClose,
        projectId,
        filesIds,
        setReloadData,
        reloadData,
        setPreselectedFiles,
        isPreselected,
        setIsPreselected
        
    } = props


   

    async function save ( ) {
      await updateTags(projectId,filesIds,tags,admin)
      onClose();
      setReloadData(!reloadData);
      setPreselectedFiles([]);
      setTags([]);
        // console.log("save")
    }
    return (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          scrollBehavior="outside"
          size="2xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit tags</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              
              <Box>
                  {filesIds.length > 0 && (filesIds.length === 1 ? `${filesIds.length} upload edited` : `${filesIds.length} upload edited`)}
              </Box>
 
    
              {/* Casting */}
              <Box my="4">
                <FormLabel>Tags</FormLabel>
    
                <Select
                  styles={colorMode === "light" ? customStyles : darkStyles}
                  isMulti
                  isClearable
                  placeholder="Select tag(s)"
                  value={tags}
                  onChange={(e) => {
                    setTags(e)
                  }}
                  options={tagsOptions}
                />
              </Box>
   
    
              
            </ModalBody>
    
            <ModalFooter>
            
                <Button
                  // colorScheme="blue"
                  mr={3}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={() => save()}
                  // isLoading={state.isLoadingOne}
                  loadingText="Loading"
                >
                  Save
                </Button>
              
               
              
              
            </ModalFooter>
          </ModalContent>
        </Modal>
      )
  
}

import React, { useState } from "react";
import { Box, Flex, useColorMode, useDisclosure } from "@chakra-ui/react";
import OrderSummary from "./OrderSummary";
import CardTitle from "../../components/card/CardTitle";
import UpdateStatus from "./UpdateStatus";
import Comments from "../player/Comments";
import { MiniHeader } from "../../components/header/MiniHeader";
import RightColumn from "../../components/column/RightColumn";

export default function RightBar({
  taskData,
  projectId,
  videoId,
  clientId,
  projectName,
  videoName,
  setReloadData,
  firstRenderStatus,
  firstRenderValidation,
  isLoading,
  comments,
  reloadComments,
  setReloadComments,
  setIsLoading,
  timecode,
  setTimecode,
  active,
  setActive,
}) {
  // const [active, setActive] = useState("Comments");

  const selectDisplay = (title) => {
    setActive(title);
  };

  const { colorMode } = useColorMode();

  return (
    <RightColumn>
      <Flex px='20px' pt='20px'>
        <Box
          onClick={() => selectDisplay("Order")}
          mr='15px'
          cursor={active === "Order" ? "unset" : "pointer"}
          // mb='46px'
        >
          <CardTitle title='Order Summary' isActive={active === "Order"} />
        </Box>
        <Box
          onClick={() => selectDisplay("Comments")}
          cursor={active === "Comments" ? "unset" : "pointer"}>
          <CardTitle title='Comments' isActive={active === "Comments"} />
        </Box>
      </Flex>

      <Flex px='20px' flexDirection='column' pt='10px'>
        {active === "Order" && (
          <OrderSummary
            taskData={taskData}
            projectId={projectId}
            videoId={videoId}
            projectName={projectName}
            videoName={videoName}
            setReloadData={setReloadData}
            firstRenderStatus={firstRenderStatus}
            firstRenderValidation={firstRenderValidation}
            isLoading={isLoading}
          />
        )}
        {active === "Comments" && (
          <Comments
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            comments={comments}
            reloadComments={reloadComments}
            setReloadComments={setReloadComments}
            timecode={timecode}
            setTimecode={setTimecode}
            projectId={projectId}
            videoId={videoId}
            clientId={clientId}
          />
        )}
      </Flex>
    </RightColumn>
  );
}

import { Box, Button, Grid, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { EditBrief } from "../../components/brief/EditBrief";
import MainColumn from "../../components/column/MainColumn";
import { Updating } from "../../components/notification/Updating";
import TableRow from "../../components/table/TableRow";
import { useUser } from "../../libs/UserContext";
import { deleteBrief, fetchBriefs } from "./api";
import BriefsList from "./BriefsList";

export default function Briefs() {
  const { admin, user } = useUser();
  const [briefs, setBriefs] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchBriefs(admin).then((response) => {
      setBriefs(
        response.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        })
      );
      // console.log("briefs list", response);
      setIsLoading(false);
    });
  }, [reloadData]);

  return (
    <>
      {admin && (
        <MainColumn>
          {briefs && (
            <BriefsList
              briefs={briefs}
              reloadData={reloadData}
              setReloadData={setReloadData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
          {isLoading && <Updating />}
        </MainColumn>
      )}
    </>
  );
}

import React, { useState } from "react";
import { Box, Flex, Link, Text, useColorMode } from "@chakra-ui/react";
import Card from "../../components/card";
import { BsFolderSymlink } from "react-icons/bs";
import CardTitle from "../../components/card/CardTitle";

export default function Brief(props) {
  const { colorMode } = useColorMode();
  const { jobData, projectId, videoId } = props;
  // console.log("projectData", projectData);
  return (
    <Box>
      <CardTitle title='Brief' isActive={true} />
      <Box>
        <Text
          whiteSpace='pre-wrap'
          color={colorMode === "light" ? "gray.500" : "white"}>
          {jobData && jobData.brief}
        </Text>
      </Box>
    </Box>
  );
}

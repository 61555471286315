import userEvent from "@testing-library/user-event";
import { API } from "aws-amplify";
import { useUser } from "../../libs/UserContext";

export async function fetchBriefs(admin) {
  const url = `/brief/list?admin=${admin}`;
  return API.get("kronik", url, {});
}

export async function deleteBrief(briefId, admin, user) {
  // console.log("user", user)
  if (user?.username === "florian@kronik.com") {
    const url = `/brief/delete/${briefId}?admin=${admin}`;
    return API.put("kronik", url, {});
  } else {
    alert("You are not allowed to delete this brief");
  }
}

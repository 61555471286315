import {
  Badge,
  Box,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Img,
  useColorMode,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { HiPencilAlt } from "react-icons/hi";
import { Link } from "react-router-dom";

export default function FilesList(props) {
  const { files, setLastElement } = props;
  const { colorMode } = useColorMode();
  // console.log("files", files);
  return (
    <Box>
      <Grid templateColumns='repeat(4, 1fr)' gap='20px'>
        {files &&
          files
            .filter((file) => file.muxPlaybackId && file.status !== "deleted")
            .map((file, index) => {
              return (
                <GridItem
                  className='group'
                  overflow='hidden'
                  key={index}
                  shadow='md'
                  bgColor={colorMode === "light" ? "white" : "#232323"}
                  rounded='md'>
                  <Link
                    ref={setLastElement}
                    to={`/tasks/${file.task?._id}/projects/${file.video?._id}/quotes/${file.project?._id}?file=${file._id}`}>
                    <Box
                      transition='all 0.2s'
                      _groupHover={{
                        // transform: "scale(1.05)",
                        opacity: "0.8",
                      }}>
                      <Box position='relative'>
                        <Img
                          _hover={{ cursor: "pointer" }}
                          //   onClick={(e) => selectFile(e, file)}
                          w='full'
                          h='200px'
                          // boxSize='260px'
                          objectFit='cover'
                          src={`https://image.mux.com/${file.muxPlaybackId}/thumbnail.jpg`}
                        />
                        <Box position='absolute' top='20px' left='20px'></Box>
                      </Box>
                      <Grid p='10px' gridTemplateColumns='6fr 1fr'>
                        <Box>
                          <Box fontSize='xs' fontWeight='bold'>
                            {file.clientName}
                          </Box>
                          <Box fontSize='xs' color={"whiteAlpha.800"}>
                            {file.originalName}
                          </Box>
                          <Box fontSize='xs' color={"whiteAlpha.800"}>
                            {dayjs(file.createdAt).format("DD MMMM YYYY")}
                          </Box>
                          <Flex pt='10px'>
                            {file.tags &&
                              file.tags.map((tag, index) => {
                                return (
                                  <Badge key={index} mr='5px'>
                                    {tag.label}
                                  </Badge>
                                );
                              })}
                          </Flex>
                        </Box>
                      </Grid>
                    </Box>
                  </Link>
                </GridItem>
              );
            })}
      </Grid>
    </Box>
  );
}

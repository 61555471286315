export const sourceOptions = [
    { value: "kronik.com", label: "kronik.com" },
    { value: "recommandation", label: "Recommandation" },
    { value: "linkedin", label: "Linkedin" },
    { value: "newsletter", label: "Newsletter" },
    { value: "facebook", label: "Facebook" },
    { value: "instagram", label: "Instagram" },
  ];

  export const statusOptions = [
    { value: "todo", label: "À faire" },
    { value: "inProgress", label: "En cours" },
    { value: "done", label: "Terminé" },
    { value: "blocked", label: "Bloqué" },
  ];
  

  export function statusSwitch(param) {
    switch (param) {
      case "done":
        return "green";
      case "inProgress":
        return "blue";
      case "todo":
        return "gray";
      case "blocked":
        return "red";
      default:
        return "gray";
    }
  }
  
import React from "react";

import {
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
//
export default function NavBar(props) {
  const {
    clients,
    people,
    admin,
    freelance,
    category,
    queryStatus,
    queryClient,
    queryDisplay,
    queryManager,
    queryCategory,
    queryPeople,
  } = props;

  const history = useHistory();
  return (
    <HStack my='5'>
      {/* Display filter */}
      {admin && (
        <Menu>
          <MenuButton
            as={Button}
            variant='outline'
            rounded='full'
            rightIcon={<ChevronDownIcon />}>
            {queryDisplay === "all" && queryManager === "all"
              ? "All projects"
              : queryDisplay === "all" && queryManager === "géraldinemillion"
              ? "Géraldine Million"
              : queryDisplay === "all" && queryManager === "maxencelebreux"
              ? "Maxence Lebreux"
              : queryDisplay === "all" && queryManager === "mariongaufroy"
              ? "Marion Gaufroy"
              : queryDisplay === "all" && queryManager === "geoffroylasne"
              ? "Geoffroy Lasne"
              : queryDisplay === "all" && queryManager === "claracolleoni"
              ? "Clara Colleoni"
              : "My projects"}
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=all&category=${queryCategory}&people=${queryPeople}`
                );
              }}>
              All projects
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=me&manager=all&category=${queryCategory}&people=${queryPeople}`
                );
              }}>
              My projects
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=géraldinemillion&category=${queryCategory}&people=${queryPeople}`
                );
              }}>
              Géraldine Million
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=maxencelebreux&category=${queryCategory}&people=${queryPeople}`
                );
              }}>
              Maxence Lebreux
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=geoffroylasne&category=${queryCategory}&people=${queryPeople}`
                );
              }}>
              Geoffroy Lasne
            </MenuItem>

            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=mariongaufroy&category=${queryCategory}&people=${queryPeople}`
                );
              }}>
              Marion Gaufroy
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=claracolleoni&category=${queryCategory}&people=${queryPeople}`
                );
              }}>
              Clara Colleoni
            </MenuItem>
          </MenuList>
        </Menu>
      )}
      {/* Status filter */}
      <Menu>
        <MenuButton
          rounded='full'
          as={Button}
          variant='outline'
          rightIcon={<ChevronDownIcon />}
          textTransform='capitalize'>
          {queryStatus === "all" ? "All status" : queryStatus}
        </MenuButton>

        <MenuList maxH='300px' overflow='auto'>
          <MenuItem
            onClick={() => {
              history.push(
                `?status=all&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager?.toLowerCase()}&category=${queryCategory}&people=${queryPeople}`
              );
            }}>
            All status
          </MenuItem>

          {admin && (
            <MenuItem
              onClick={(e) => {
                history.push(
                  `?status=preview&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager?.toLowerCase()}&category=${queryCategory}&people=${queryPeople}`
                );
              }}>
              Preview
            </MenuItem>
          )}

          <MenuItem
            onClick={(e) => {
              history.push(
                `?status=todo&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager?.toLowerCase()}&category=${queryCategory}&people=${queryPeople}`
              );
            }}
            value='noDate'>
            To do
          </MenuItem>

          <MenuItem
            onClick={(e) => {
              history.push(
                `?status=validation&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager?.toLowerCase()}&category=${queryCategory}&people=${queryPeople}`
              );
            }}
            value='noDate'>
            In validation
          </MenuItem>

          <MenuItem
            onClick={(e) => {
              history.push(
                `?status=late&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager?.toLowerCase()}&category=${queryCategory}&people=${queryPeople}`
              );
            }}
            value='noDate'>
            Late
          </MenuItem>

          <MenuItem
            onClick={(e) => {
              history.push(
                `?status=noleader&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager?.toLowerCase()}&category=${queryCategory}&people=${queryPeople}`
              );
            }}
            value='noDate'>
            No Responsable
          </MenuItem>

          <MenuItem
            onClick={(e) => {
              history.push(
                `?status=nodate&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager?.toLowerCase()}&category=${queryCategory}&people=${queryPeople}`
              );
            }}
            value='noDate'>
            No date
          </MenuItem>

          <MenuItem
            onClick={(e) => {
              history.push(
                `?status=blocked&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager?.toLowerCase()}&category=${queryCategory}&people=${queryPeople}`
              );
            }}
            value='noDate'>
            Blocked
          </MenuItem>
        </MenuList>
      </Menu>
      {/* Client filter */}
      {(admin || freelance) && (
        <Menu>
          <MenuButton
            as={Button}
            rounded='full'
            variant='outline'
            rightIcon={<ChevronDownIcon />}>
            {queryClient !== "all" ? (
              <Box textTransform='capitalize'>{queryClient}</Box>
            ) : (
              "All clients"
            )}
          </MenuButton>
          <MenuList maxH='300px' overflow='auto'>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=all&display=all&manager=${queryManager}&category=${queryCategory}&people=${queryPeople}`
                );
              }}>
              All
            </MenuItem>

            {clients &&
              clients.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      history.push(
                        `?status=${queryStatus}&client=${item?.toLowerCase()}&display=all&manager=${queryManager}&category=${queryCategory}&people=${queryPeople}`
                      );
                    }}>
                    {item}
                  </MenuItem>
                );
              })}
          </MenuList>
        </Menu>
      )}
      ){/* Responsable filter */}
      {(admin || freelance) && (
        <Menu>
          <MenuButton
            as={Button}
            rounded='full'
            variant='outline'
            rightIcon={<ChevronDownIcon />}>
            {queryPeople !== "all" ? (
              <Box textTransform='capitalize'>{queryPeople}</Box>
            ) : (
              "All responsable"
            )}
          </MenuButton>
          <MenuList maxH='300px' overflow='auto'>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient}&display=all&manager=${queryManager}&category=${queryCategory}&people=all`
                );
              }}>
              All responsable
            </MenuItem>
            {people &&
              people.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      history.push(
                        `?status=${queryStatus}&client=${queryClient}&display=all&manager=${queryManager}&category=${queryCategory}&people=${item.toLowerCase()}`
                      );
                    }}>
                    {item}
                  </MenuItem>
                );
              })}
          </MenuList>
        </Menu>
      )}
      {/* Category filter */}
      <Menu>
        <MenuButton
          as={Button}
          rounded='full'
          variant='outline'
          rightIcon={<ChevronDownIcon />}>
          {queryCategory !== "all" ? (
            <Box textTransform='capitalize'>{queryCategory}</Box>
          ) : (
            "All categories"
          )}
        </MenuButton>
        <MenuList maxH='300px' overflow='auto'>
          <MenuItem
            onClick={() => {
              // setCategorySelected("all");
              history.push(
                `?status=${queryStatus}&client=${queryClient}&display=all&manager=${queryManager}&category=all&people=${queryPeople}`
              );
            }}>
            All categories
          </MenuItem>
          {category &&
            category.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    history.push(
                      `?status=${queryStatus}&client=${queryClient}&display=all&manager=${queryManager}&category=${item?.value?.toLowerCase()}&people=${queryPeople}`
                    );
                  }}>
                  {item.label}
                </MenuItem>
              );
            })}
        </MenuList>
      </Menu>
    </HStack>
  );
}

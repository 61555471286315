import axios from "axios";
import { API } from "aws-amplify";
import { useUser } from "../../libs/UserContext";

export async function fetchTask(projectId, taskId, admin) {
  const url = `/task/get/task/${projectId}/${taskId}?admin=${admin}`;
  return await API.get("kronik", url).then((response) => {
    // console.log("response task", response);
    return response;
  });
}

export async function isAuthorized(projectId, admin) {
  const url = `/file/get/authorizer/${projectId}?admin=true`;
  return API.get("kronik", url, {});
}

export async function fetchTasks(projectId, videoId, admin) {
  let url = `/task/get/${projectId}/${videoId}?admin=${admin}`;

  return API.get("kronik", url, {});
}

export async function fetchAdmins(projectId, videoId, admin) {
  const url = `/task/get/admins/${projectId}/${videoId}?admin=${admin}`;

  return await API.get("kronik", url).then((response) => {
    // console.log("response task", response);
    return response;
  });
}

export async function iniateUpload(file, fileName) {
  const url = "https://api.kronik.com/file/putPreSignedUrl";
  // console.log("API uploadFiles");
  // console.log("file :", file);
  // console.log("fileName :", fileName);
  // console.log("iniateUpload");

  return await axios
    .post(url, { fileName: fileName })
    .then((r) => {
      return r;
    })
    .catch((err) => console.error(err));
}

export async function uploadFile(
  displayName,
  name,
  APIFirstPost,
  file,
  setProgressStatus,
  fileId,
  filesList,
  setUploadFiles,
  uploadFiles,
  setFilesList,
  removeCanceledFile,
  setNewProgressFile,
  setProgressOnly
) {
  // const cancelTokenSource = axios.CancelToken.source();
  const controller = new AbortController();

  const options = {
    signal: controller.signal,
    onUploadProgress: (progressEvent, e) => {
      const { loaded, total } = progressEvent;
      let precentageValue = parseInt(Math.floor((loaded * 100) / total));

      let newFile = file;
      newFile.percentage = precentageValue;
      newFile.controller = controller;
      newFile.cancel = controller?.signal?.aborted ? true : false;

      setNewProgressFile(newFile);
      setProgressOnly(newFile.percentage);
    },
  };

  if (APIFirstPost) {
    return await axios
      .put(APIFirstPost, file, options)
      .then((r) => {
        // console.log("APIFirstPost :", APIFirstPost);
        // console.log("response uploadFile :", r);
        // console.log(file + "-" + options);
        // console.log("uploadFile 2");
        // console.log("uploadFile not visible ?")
        return r;
      })
      .catch((e) => {
        if (e.message === "canceled") {
          // console.log("abort working")
          let newFile = file;
          newFile.cancel = true;
          removeCanceledFile(newFile);
          return new Response(JSON.stringify({}), {
            status: 499, // Client Closed Request
            statusText: e.message || "Client Closed Request",
          });
        } else {
          console.error(e);
        }
      });
  }
}

export async function uploadMultipartFile(
  file,
  url,
  fileName,
  fileSize,
  uploadFiles,
  setUploadFiles,
  filesList,
  setFilesList,
  setNewProgressFile,
  setProgressOnly
) {
  const controller = new AbortController();

  // console.log("try begin", index)
  //GET UNIQUE ID MULTIPART
  let res = await axios.post(`${url}/getUniqueId`, { fileName: fileName });
  const uploadId = res.data.uploadId;
  // console.log("uploadId", uploadId);
  //ICI CEST LE FILENAME RETOURNE PAR LA FONCTION QUE L'ON DOIT UTILISER POUR LA SUITE

  // sessionStorage.setItem('uploadId', uploadId);

  //CUT THE FILE
  // console.log('Inside uploadMultipartFile');
  const chunkSize = 10 * 1024 * 1024; // 10MiB
  const chunkCount = Math.floor(fileSize / chunkSize) + 1;
  // console.log(`chunkCount: ${chunkCount}`);

  let multiUploadArray = [];

  try {
    for (let uploadCount = 1; uploadCount < chunkCount + 1; uploadCount++) {
      // console.log("boucle for", uploadCount);
      let start = (uploadCount - 1) * chunkSize;
      let end = uploadCount * chunkSize;
      let fileBlob =
        uploadCount < chunkCount ? file.slice(start, end) : file.slice(start);

      let getSignedUrlRes = await axios.post(`${url}/getUploadPart`, {
        fileName: fileName,
        partNumber: uploadCount,
        uploadId: uploadId,
      });
      let preSignedUrl = getSignedUrlRes.data.preSignedUrl;
      console.log(`preSignedUrl ${uploadCount} : ${preSignedUrl}`);
      console.log(fileBlob);
      // Start sending files to S3 part by part

      let uploadChunck = await fetch(preSignedUrl, {
        method: "PUT",
        body: fileBlob,
        signal: controller.signal,
      });
      // let uploadChunck = await axios.put(preSignedUrl, {
      //     fileBlob
      // })
      console.log("uploadChunck", uploadChunck);
      let EtagHeader = uploadChunck.headers.get("ETag");
      // let EtagHeader = uploadChunck.headers.etag;
      console.log(EtagHeader);
      let uploadPartDetails = {
        ETag: EtagHeader,
        PartNumber: uploadCount,
      };

      multiUploadArray.push(uploadPartDetails);

      const newFile = file;
      newFile.percentage = ((uploadCount / chunkCount) * 100).toFixed(2);
      newFile.controller = controller;
      newFile.cancel = controller?.signal?.aborted ? true : false;
      // console.log("percentage", uploadCount / chunkCount * 100)
      // console.log("newFile", newFile)
      setNewProgressFile(newFile);
      setProgressOnly(parseInt(Math.floor(newFile.percentage)));
      //   setFilesList(
      //     filesList.map((item) => {
      //         if (item.name === newFile.name) {
      //           newFile.percentage = uploadCount / chunkCount * 100;
      //         }
      //         return newFile;
      //     }
      // ))
    }
  } catch (e) {
    // console.log("error", e)
    // console.log("error name", e.name)
    if (e.name === "AbortError") {
      // console.log("abort working")
      let newFile = file;
      newFile.cancel = true;
      // removeCanceledFile(newFile)
      file.controller.abort();
      const url = `https://api.kronik.com/file`;
      console.log("abort", { fileName: fileName, uploadId: uploadId });
      axios
        .post(`${url}/abortUpload`, { fileName: fileName, uploadId: uploadId })
        .then((r) => console.log(r))
        .catch((err) => console.error(err));
      clearInterval();

      return new Response(JSON.stringify({}), {
        status: 499, // Client Closed Request
        statusText: e.message || "Client Closed Request",
      });
    } else {
      console.error(e);
    }
  }

  console.log(multiUploadArray);
  const completeUpload = await axios.post(`${url}/completeUpload`, {
    fileName: fileName,
    parts: multiUploadArray,
    uploadId: uploadId,
  });

  return completeUpload;
  // console.log("completeUpload ", completeUpload.data)
}

export async function postDataFiles(
  name,
  file,
  taskData,
  admin,
  setUploadFiles
) {
  const url = `/file/create?admin=true`;
  // console.log("postDataFiles");
  try {
    return API.post("kronik", url, {
      body: {
        name: name,
        displayName: taskData?.title + "-" + taskData?.video?.title,
        originalName: file?.name,
        date: new Date(),
        type: file?.type,
        size: file?.size,
        taskId: taskData?._id,
        taskName: taskData?.title,
        projectId: taskData?.project?._id,
        projectName: taskData?.project?.name,
        videoId: taskData?.video?._id,
        videoName: taskData?.video?.title,
        clientId: taskData?.project?.client?._id,
        clientName: taskData?.project?.client?.name,
        taskCategory: taskData?.category.value,
      },
    });
    // .then((r3) => {
    //   console.log("test return response", r3)
    //   return r3
    // });

    // setUploadFiles(
    //   Array.from(uploadFiles).filter((item) => item.fileName !== name)
    // );
  } catch (e) {
    console.log(e.message);
  }
}

export async function postAsset(signedUrl, fileId, fileName, projectId) {
  // POST api.kronik.com/file/createAsset
  // console.log("post asset")
  const url = `/file/createAsset`;
  try {
    return API.post("kronik", url, {
      body: {
        signedUrl: signedUrl,
        // bucketName: "kronik-video-2021",
        fileId: fileId,
        fileName: fileName,
        projectId: projectId,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function downloadFile(name, admin, projectId, bucketName) {
  const url = `/file/getPreSignedUrl`;
  // console.log("downloadFile props", name, admin, projectId, bucketName);
  try {
    return await API.post("kronik", url, {
      body: {
        bucketName: bucketName ? bucketName : "kronik-video-2022",
        projectId: projectId,
        fileName: name,
        admin: admin.toString(),
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function downloadFiles(names, admin, projectId) {
  // const url = `/file/getPreSignedUrl`;
  const url = `https://api.kronik.com/file/getPreSignedUrl`;

  return await axios
    .post(
      url,
      {
        // body: {
        projectId: projectId,
        fileNames: names,
        admin: admin.toString(),
      }
      // }
    )
    .then((r) => {
      return r;
    })
    .catch((err) => console.error(err));

  // try {
  //   return await API.post("kronik", url, {
  //     body: {
  //       projectId: projectId,
  //       fileNames: names,
  //       admin: admin.toString(),
  //     },
  //   });
  // } catch (e) {
  //   console.log(e.message);
  // }
}

import React, { useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import CardTitle from "../../components/card/CardTitle";
import OrderSummary from "./OrderSummary";
import RightColumn from "../../components/column/RightColumn";
import { MiniHeader } from "../../components/header/MiniHeader";
import Payments from "./Payments";

export default function RightBar({
  jobData,
  freelanceId,
  setReloadData,
  admin,
  isLoading,
}) {
  const [active, setActive] = useState("Order");

  const selectDisplay = (title) => {
    setActive(title);
  };

  return (
    <RightColumn>
      <Flex px='20px' pt='20px'>
        <Box
          onClick={() => selectDisplay("Order")}
          mr='15px'
          cursor={active === "Order" ? "unset" : "pointer"}>
          <CardTitle title='Summary' isActive={active === "Order"} />
        </Box>
        <Box
          onClick={() => selectDisplay("Payments")}
          cursor={active === "Payments" ? "unset" : "pointer"}>
          <CardTitle title='Payments' isActive={active === "Payments"} />
        </Box>
      </Flex>
      {active === "Order" && (
        <OrderSummary
          jobData={jobData}
          admin={admin}
          freelanceId={freelanceId}
          setReloadData={setReloadData}
          isLoading={isLoading}
        />
      )}
      {active === "Payments" && (
        <Payments />
        // <Material
        // freelanceData={freelanceData}
        // admin={admin}
        // freelanceId={freelanceId}
        // setReloadData={setReloadData}
        // isLoading={isLoading}
        // />
      )}
    </RightColumn>
  );
}

import { DownloadIcon } from "@chakra-ui/icons";
import { useState } from "react";
import Downloader from "../components/downloader";

export default function useFileDownloader() {
  const [files, setFiles] = useState(() => []);

  function download(file) {
    // console.log("useDownload", file);
    if (file.length > 0) {
      setFiles((fileList) => [...fileList, ...file]);
    } else {
      setFiles((fileList) => [...fileList, file]);
    }
  }

  function remove(removeId) {
    // console.log("removeFileName", removeId)
    setFiles((files) => [
      ...files.filter((fileName) => fileName.id !== removeId),
    ]);
  }

  return [
    (e) => download(e),
    files.length > 0 ? (
      <Downloader files={files} remove={(e) => remove(e)} />
    ) : null,
  ];
}

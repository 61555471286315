import React, { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import Card from "../../components/card";
import CardTitle from "../../components/card/CardTitle";

export default function About(props) {
  const { projectData } = props;
  // console.log("projectData", projectData);
  return (
    <Box mt='20px'>
      <CardTitle title='About' isActive={true} />
      <Box>
        <Text whiteSpace='pre-wrap' color='gray.500'>
          {projectData && projectData.description}
        </Text>
      </Box>
    </Box>
  );
}

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // border: "1px dotted black",
    // color: state.data.color,
    opacity: 0.8,
    padding: 20,
  }),

  control: (provided) => ({
    ...provided,
    // width: 200,
    color: "#A1AEC0 !important",

    "&:hover": {
      background: "#e2e8f0",
      cursor: "pointer",
    },

    border: "0px dotted black",

    background: "#edf2f7",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    // color: state.data.color,
  }),
  indicatorSeparator: () => null,
  dropdownIndicator: () => ({
    display: "none",
  }),
};

export const darkStyles = {
  input: (provided) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#222222",
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    // border: "1px dotted black",
    // color: state.data.color,
    // opacity: 1,
    padding: 20,
    backgroundColor: isFocused ? "black" : "#222222",
    // backgroundColorFocused: "green",
    // color: "#E5E7EB",

    // background: "#232323",
    "&:hover": {
      background: "black",
    },
    "&:focus": {
      background: "pink",
    },
  }),

  control: (provided) => ({
    ...provided,
    minHeight: "60px",
    // width: 200,

    color: "whiteAlpha.600 !important",

    "&:hover": {
      // background: "#394354",
      cursor: "pointer",
    },

    border: "0px dotted black",
    background: "#232323",
  }),
  singleValue: (provided, state) => ({
    ...provided,

    color: state.data.color,
  }),
  indicatorSeparator: () => null,
  dropdownIndicator: () => ({
    display: "none",
  }),
};

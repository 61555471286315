import React from "react";
import { Flex, Text, Box, Button, useColorMode } from "@chakra-ui/react";

// import { AiFillCheckCircle } from "react-icons/ai";
// import { BsFillInboxFill } from "react-icons/bs";
// import { ImBlocked } from "react-icons/im";
// import { IoBuildSharp } from "react-icons/io5";

export default function ButtonIconV2({ title, click, icon, checked }) {
  const { colorMode } = useColorMode();

  return (
    <Flex
      alignItems="center"
      // mt="20px"
      color={checked ? colorMode ==="light" ? "primary" : "white": colorMode ==="light" ? "primary" : "white"}
      onClick={click}
      // borderWidth="1px"
      variant="ghost"
      // borderColor={checked ? "primary" : "#222222"}
      // bg={checked ? colorMode === "light" ? "blackAlpha.300" : "blackAlpha.300"   : colorMode === "light" ? "white" : "#222222"}
      // marginX="10px"
      // height="120px"
      // width="120px"
     
    >
      {/* <Flex 
        flexDirection={"column"}
        alignItems="center"
        > */}
        {/* <Box marginBottom="10px">{icon}</Box> */}
        <Text fontSize="sm" color={checked ? colorMode === "light" ? "primary" : "white" : colorMode === "light" ? "primary" : "white"}>
          {title}
        </Text>
      {/* </Flex> */}
    </Flex>
  );
}

export const tagsOptions = [
  { value: "interview", label: "Interview" },
  { value: "speaker", label: "Speaker" },
  { value: "office", label: "Office" },
  { value: "team", label: "Team" },
  { value: "demo", label: "Demo" },
  { value: "traveling", label: "Traveling" },
  { value: "outside", label: "Outside" },
  { value: "stock", label: "Stock" },
];

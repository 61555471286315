import React, { useState, useEffect, useReducer } from "react";
import { useUser } from "../../libs/UserContext";
import { initialState, reducer } from "./reducer";
import { customStyles, darkStyles } from "../../styles/customStyles";
import { goalOptions, statusOptions } from "../../data/projectData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Textarea,
  NumberInput,
  NumberInputField,
  useColorMode,
} from "@chakra-ui/react";
import Select from "react-select";
import { createProject, getClients } from "./api";

export default function NewProject(props) {
  const { colorMode } = useColorMode();

  const { isOpen, onClose, reloadData, setReloadData, preSelectedClient } =
    props;
  const { admin } = useUser();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [clients, setClients] = useState("");

  useEffect(() => {
    if (admin) {
      getClients().then((response) => {
        setClients(
          response.map((item) => {
            return {
              label: item.name,
              value: item._id,
            };
          })
        );
      });
    }
  }, []);

  function save() {
    dispatch({ type: "save" });
    createProject(state, admin, preSelectedClient).then((response) => {
      dispatch({ type: "success" });
      onClose();
      setReloadData(!reloadData);
    });
  }

  const DateForm = () => {
    return (
      <DatePicker
        // className="date-picker"
        wrapperClassName={
          colorMode === "light"
            ? "date-picker-wrapper-light"
            : "date-picker-wrapper-dark"
        }
        // calendarClassName="dark-test"
        popperClassName={colorMode === "light" ? "ligth-picker" : "dark-picker"}
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        dateFormat="dd/MM/yyyy"
        placeholderText="Choisir une date"
        isClearable
        calendarStartDay={1}
        selected={state.wonDate}
        onChange={(date) =>
          dispatch({
            type: "field",
            payload: {
              fieldName: "wonDate",
              value: date,
            },
          })
        }
        // locale="fr"
      />
    );
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="outside"
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New quote</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Client */}
            {!preSelectedClient && (
              <Box my="4">
                <FormLabel>Client </FormLabel>
                <Select
                  styles={colorMode === "light" ? customStyles : darkStyles}
                  isClearable
                  placeholder="Select a client "
                  value={state.client}
                  onChange={(e) => {
                    // console.log("state.client", e);
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "currentClient",
                        value: e,
                      },
                    });
                  }}
                  options={clients}
                />
              </Box>
            )}
            {/* Title */}
            <Box my="4">
              <FormControl id="subtitle">
                <FormLabel>Project name</FormLabel>
                <Input
                  variant="filled"
                  placeholder="Add a project name"
                  value={state.name}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "name",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/* Brief*/}
            <Box my="4">
              <FormLabel>Brief</FormLabel>
              <Textarea
                variant="filled"
                placeholder="Write a quick brief"
                size="md"
                resize="vertical"
                value={state.description}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "description",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Box>

            {/* Goals */}
            <Box my="4">
              <FormLabel>Goal(s)</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                isMulti
                placeholder="Select one (or more) goal(s) "
                value={state.goal}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "currentGoals",
                      value: e,
                    },
                  });
                }}
                options={goalOptions}
              />
            </Box>

            {/* Quantity */}
            <Box my="4">
              <FormControl id="quantity">
                <FormLabel>Video quantity</FormLabel>
                <NumberInput max={1000} min={1} variant="filled">
                  <NumberInputField
                    placeholder="Add a number of video"
                    value={state.quantity}
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "quantity",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                  {/* <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper> */}
                </NumberInput>
              </FormControl>
            </Box>

            {/* Pricing */}
            <Box my="4">
              <FormControl id="pricing">
                <FormLabel>Total HT</FormLabel>
                <NumberInput max={1000000} min={10} variant="filled">
                  <NumberInputField
                    placeholder="Add a number"
                    value={state.pricing}
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "pricing",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </NumberInput>
              </FormControl>
            </Box>

            {/* Status */}
            <Box my="4">
              <FormLabel>Status</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                placeholder="Select a status"
                value={state.status}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "status",
                      value: e,
                    },
                  });
                }}
                options={statusOptions}
              />
            </Box>

            {/* Date */}
            <Box my="4">
              <FormLabel>Won date</FormLabel>
              <Flex>
                <Box maxW="200">
                  <DateForm />
                </Box>
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={
                (!state.currentClient || !preSelectedClient) && !state.name
              }
              bgColor="primary"
              color="white"
              mr={3}
              onClick={save}
              isLoading={state.isLoadingOne}
              loadingText="Loading"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

import { API } from "aws-amplify";
import fetchVideos from "../dashboard/api";

export async function fetchTask(projectId, taskId) {
  const url = `/task/get/shared/task/${projectId}/${taskId}`;
  try {
    return API.get("kronik", url, {});
  } catch (e) {
    console.log("error", e.message);
  }
}

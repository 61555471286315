import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import SideBar from "./SideBar";
import { HiPencilAlt } from "react-icons/hi";
import { Updating } from "../../components/notification/Updating";
import RightBar from "./RightBar";
import { EditFreelance } from "../../components/freelance/EditFreelance";
import Brief from "./Brief";
import TasksList from "./TasksList";
import { EditJob } from "../../components/job/EditJob";
import fetchJob, { fetchTasks } from "./api";
import { BsFolderSymlink } from "react-icons/bs";
import VideoLink from "./VideoLink";
import { Main } from "./Main";
import RightColumn from "../../components/column/RightColumn";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";

export function Job() {
  let { freelanceId, jobId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { admin } = useUser();

  const [reloadData, setReloadData] = useState(false);
  const [tasks, setTasks] = useState("");
  const [videoData, setVideoData] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [job, setJob] = useState("");

  useEffect(() => {
    // jobId && console.log("jobId", jobId);
    setIsLoading(true);
    jobId &&
      fetchJob(jobId, admin).then((response) => {
        // console.log("job rsponse", response);
        setIsLoading(false);
        setJob(response);
      });
  }, [jobId, reloadData]);

  useEffect(() => {
    setIsLoading(true);
    job &&
      fetchTasks(
        job.project,
        job.video._id ? job.video._id : job.video,
        admin
      ).then((response) => {
        setVideoData(
          response.tasks.filter(
            (task) =>
              task?.taskLeader?._id === job?.user &&
              task?.category?.value === job?.category?.value
          )
        );
        // console.log("videoData", response.tasks.filter(task => task?.taskLeader?._id === job?.user))
        setIsLoading(false);
      });
    // console.log("job", job);
  }, [job]);

  //  const jobData=
  //       {
  //           videoId:"1",
  //           projectId:"1",
  //           clientId:"1",
  //           freelanceId:"1",
  //           given_name: "Michel",
  //           family_name: "Aimget",
  //           title:"Job title",
  //           brief:"this is a brief",
  //           pricing:350,
  //           dateStamp:new Date(),
  //           ipAdress:"skdhsdesd§èfds!fds",
  //           note: "super note",
  //           status: { label: "accepted", value: "Accepted" },
  //           category: "",
  //           rating:4,
  //           reviews:"super travail",
  //           clientFeedback:"merci bcp",
  //           paymentStatus:"done",
  //           tasks:[{_id:1, title:"Montage V1"},{_id:2, title:"Montage V2"},{_id:3, title:"Montage V3"},]
  //       }

  return (
    <Box w='100%'>
      <MiniHeader>
        <CardTitle title={job?.title} isActive />

        {admin && !isLoading && (
          <Button
            aria-label='Edit'
            variant='outline'
            rounded='full'
            size='sm'
            onClick={() => onOpen()}
            mx='10px'>
            <HiPencilAlt />
          </Button>
        )}
      </MiniHeader>
      <Flex justifyContent='space-between'>
        <SideBar
          freelanceName={
            job && job?.freelance?.given_name
              ? job?.freelance?.given_name + " " + job?.freelance?.family_name
              : job?.email
          }
          jobName={job?.title}
          freelanceLink={`/freelances/${job?.freelance?._id}`}
          isLoading={isLoading}
          admin={admin}
          jobStatus={job && job?.status?.value}
          clientLogo={job?.freelance?.profile_picture}
        />

        <Main videoData={videoData} job={job} isLoading={isLoading} />
        <RightBar
          jobData={job}
          // freelanceId={freelanceId}
          reloadData={reloadData}
          setReloadData={setReloadData}
          admin={admin}
          isLoading={isLoading}
        />

        <EditJob
          isOpen={isOpen}
          onClose={onClose}
          reloadData={reloadData}
          setReloadData={setReloadData}
          jobData={job}
          // videoName={videoName}
        />

        {isLoading && <Updating />}
      </Flex>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import {
  Box,
  Heading,
  Text,
  Button,
  useColorMode,
  Stack,
  Flex,
  Skeleton,
  Spacer,
  Image,
  useDisclosure,
} from "@chakra-ui/react";

import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { fetchJobs, fetchUser } from "./api";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";
import EditUser from "../../components/user/EditUser";
dayjs.locale("fr");

export default function Profil() {
  let history = useHistory();

  const [user, setUser] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    getUser();
    fetchJobs().then((response) => {
      // console.log("jobs", response)
    });
    // console.log("getProfil");
  }, []);

  async function getUser() {
    try {
      // const data = await API.get("kronik", "/user/me", {});
      // const data = await API.get("kronik", "/people/me", {});
      fetchUser().then((response) => {
        setUser(response);
        // console.log("user", response);
      });
    } catch (e) {
      console.log(e.message);
    }
  }

  async function logOut() {
    try {
      await Auth.signOut();
      history.go(0);
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <Box w='100%'>
      <Box>
        <Box>
          <MiniHeader>
            <CardTitle title='Profil' isActive={true} />
            <Spacer />
            <Button onClick={onOpen}>Modifier</Button>
          </MiniHeader>
        </Box>
      </Box>
      <Box px='20px' pt='20px'>
        <Box>
          <Heading as='h1' fontSize='2xl' mb='4'>
            Informations
          </Heading>
          {user?.profile_picture && (
            <Image
              src={`https://aaliz317.twic.pics/${user?.profile_picture}`}
            />
          )}
          <Text>
            {user.given_name} {user.family_name}
          </Text>
          <Text>{user.email}</Text>
        </Box>

        <Box>
          <Text>
            Profil créé le {dayjs(user.createdAt).format("DD MMMM YYYY")}
          </Text>
          <Text>
            Dernière modification le{" "}
            {dayjs(user.updatedAt).format("DD MMMM YYYY")}
          </Text>
        </Box>

        <Box>
          <Button variant='link' onClick={logOut}>
            Se déconnecter
          </Button>
        </Box>
      </Box>
      <EditUser
        userData={user}
        isOpen={isOpen}
        onClose={onClose}
        reloadData={reloadData}
        setReloadData={setReloadData}
      />
      {/* <EditProfil user={user} showModal={showModal} handleClose={handleClose} /> */}
    </Box>
  );
}

import React, { useState, useReducer, useEffect, useRef } from "react";
import { useUser } from "../../libs/UserContext";
import { initialState, reducer } from "./reducer";
import { customStyles } from "../../styles/customStyles";
import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Image,
} from "@chakra-ui/react";
import Select from "react-select";
import { createClient, updateClient, updatePicture } from "./api";
import { IoCloudUpload } from "react-icons/io5";

export default function EditClient(props) {
  const { isOpen, onClose, reloadData, setReloadData, clientData } = props;
  const { admin } = useUser();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [pourcentageUpdate, setPourcentageUpdate] = useState(0);
  //   const { userId } = useParams();
  //   const [file, setFile] = useState(null);
  //   const [fileName, setFileName] = useState("");
  const hiddenFileInput = useRef(null);

  // console.log("clientData", clientData);
  function save() {
    dispatch({ type: "save" });
    updateClient(clientData._id, state, admin).then((response) => {
      dispatch({ type: "success" });
      onClose();
      setReloadData(!reloadData);
    });
  }

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // setFile(fileUploaded);
    // setFileName(fileUploaded.name);

    dispatch({
      type: "field",
      payload: {
        fieldName: "logo",
        value: fileUploaded.name,
      },
    });

    // console.log("fileUploaded", fileUploaded);
    updatePicture(fileUploaded, setPourcentageUpdate);
  };

  const handleUpload = (e) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    try {
      dispatch({
        type: "previousData",
        payload: {
          name: clientData.name,
          logo: clientData.logo,
          address: clientData.address,
          about: clientData.about,
        },
      });
    } catch (e) {
      console.log("error", e);
    }
  }, [clientData]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior='outside'
        size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Name */}
            <Box my='4'>
              <FormControl id='name'>
                <FormLabel>Client name</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a client name'
                  value={state.name}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "name",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>
            {/* Address */}
            <Box my='4'>
              <FormControl id='address'>
                <FormLabel>Address</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add an address '
                  value={state.address}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "address",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>
            {/* About */}
            <Box my='4'>
              <FormControl id='about'>
                <FormLabel>About</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add an note about the client '
                  value={state.about}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "about",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>
            <input
              type='file'
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
            {/* <Box>{state.logo}</Box> */}
            {state?.logo && pourcentageUpdate === 0 && (
              <Image
                borderRadius='lg'
                boxSize='200px'
                src={`https://aaliz317.twic.pics/${state?.logo}`}
              />
            )}
            {/* {pourcentageUpdate > 0 && pourcentageUpdate < 100 && ( */}
            <Box>{pourcentageUpdate}%</Box>
            {/* } */}
            {/* <Img src={`https://aaliz317.twic.pics/photo%20florian.png`} /> */}
            <Button
              rounded='full'
              // isDisabled={uploadFiles.length > 0}
              variant='outline'
              size='sm'
              onClick={() => handleUpload()}>
              <IoCloudUpload />
            </Button>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={!state.name}
              bgColor='primary'
              color='white'
              mr={3}
              onClick={save}
              isLoading={state.isLoadingOne}
              loadingText='Loading'>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

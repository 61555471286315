import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  Heading,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import Card from "../../components/card";
import CardTitle from "../../components/card/CardTitle";

export default function Brief(props) {
  const { videoData } = props;
  const { colorMode } = useColorMode();
  const [displayMore, setDisplayMore] = useState(false);

  function truncate(str, n) {
    if (str.length > 1) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    } else {
      return str;
    }
  }

  const shortBrief = truncate(
    videoData?.video?.brief ? videoData?.video?.brief : "",
    200
  );
  return (
    <Box>
      <CardTitle title='brief' isActive='false' />
      <Box mt='10px'>
        <Text
          whiteSpace='pre-wrap'
          color={colorMode === "light" ? "gray.500" : "white"}>
          {videoData && (!displayMore ? shortBrief : videoData?.video?.brief)}

          <Button variant='link' onClick={() => setDisplayMore(!displayMore)}>
            {videoData?.video?.brief?.length > 199 &&
              (!displayMore ? "\xa0 show more" : "\xa0 show less")}
          </Button>
        </Text>
        {videoData &&
          videoData?.video?.speakers &&
          videoData?.video?.speakers?.length > 0 && (
            <Box mt='20px'>
              <Heading as='h2' fontSize='xs' mb='4'>
                SPEAKERS
              </Heading>
              <Text
                color={colorMode === "light" ? "gray.500" : "white"}
                whiteSpace='pre-wrap'>
                {videoData.video.speakers.map((item) => {
                  return (
                    <Text>
                      {item.name} - {item.function} - {item.company}
                    </Text>
                  );
                })}
              </Text>
            </Box>
          )}
      </Box>
    </Box>
  );
}

import React from "react";
import { Box, useColorMode } from "@chakra-ui/react";
import SideSteps from "../../components/SideSteps";
import SideBarItem from "../../components/SideBarItem.js/index.js";
import CardTitle from "../../components/card/CardTitle";
import { LeftColumn } from "../../components/column/LeftColumn";
import { MiniHeader } from "../../components/header/MiniHeader";
export default function SideBar({
  projectName,
  clientName,
  videoName,
  clientLink,
  projectLink,
  videoLink,
  isLoading,
  admin,
  client,
  freelance,
  projectStatus,
  clientLogo,
}) {
  const { colorMode } = useColorMode();
  return (
    <LeftColumn>
      {/* <MiniHeader>
        <CardTitle title='Shortcuts' isActive='false' />
      </MiniHeader> */}
      <Box overflowY='auto' mt='10px'>
        <SideBarItem
          title={admin || freelance ? "Client" : "Organisation"}
          // text={clientName}
          link={!isLoading && (admin || freelance) && clientLink}
          // status='client'
          // clientLogo={clientLogo}
        />

        {(admin || client) && (
          <SideBarItem
            title='Invoices'
            // text={projectName}
            link={!isLoading && projectLink}
            isActive='true'
            // status={projectStatus}
          />
        )}
      </Box>
    </LeftColumn>
  );
}

import React, { useState, useEffect, useReducer } from "react";
// import { useUser } from "../../libs/UserContext";
// import { initialState, reducer } from "./reducer";
// import { customStyles, darkStyles } from "../../styles/customStyles";
// import { setupOptions, statusOptions } from "../../data/freelanceData";
import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Textarea,
  NumberInput,
  NumberInputField,
  useColorMode,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import Select from "react-select";
import { useUser } from "../../libs/UserContext";
// import { createFreelance, createProject, getClients } from "./api";
// import { skillOptions } from "../../data/freelanceData";

export default function NewInvoicedVideo(props) {
  //   const { colorMode } = useColorMode();

  const { isOpen, onClose, reloadData, setReloadData } = props;
  const { admin } = useUser();
  //   const [state, dispatch] = useReducer(reducer, initialState);
  const [clients, setClients] = useState("");

  function save() {
    // console.log("freelance state", state)
    // dispatch({ type: "save" });
    // createFreelance(state).then((response) => {
    //   //   dispatch({ type: "success" });
    //   onClose();
    //   setReloadData(!reloadData);
    // });
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior='outside'
        size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a project</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Status */}
            <Box my='4'>
              <FormLabel>Status</FormLabel>
              <Select
                // styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                placeholder='Select a status'
                // value={state.status}
                // onChange={(e) => {
                //   dispatch({
                //     type: "field",
                //     payload: {
                //       fieldName: "status",
                //       value: e,
                //     },
                //   });
                // }}
                // options={statusOptions}
              />
            </Box>

            {/* Pricing */}
            {/* <Box my="4">
              <FormControl id="pricing">
                <FormLabel>Total HT</FormLabel>
                <NumberInput max={1000000} min={10} variant="filled">
                  <NumberInputField
                    placeholder="Add a number"
                    value={state.pricing}
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "pricing",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </NumberInput>
              </FormControl>
            </Box> */}
          </ModalBody>

          <ModalFooter>
            <Button
              bgColor='primary'
              color='white'
              mr={3}
              //   onClick={save}
              //   isLoading={state.isLoadingOne}
              loadingText='Loading'>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

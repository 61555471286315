import { API } from "aws-amplify";
import { GiSattelite } from "react-icons/gi";

export async function createTask(
  projectName,
  projectId,
  videoId,
  videoName,
  state,
  admin
) {
  // console.log("projectName", projectName);
  // console.log("projectId", projectId);
  // console.log("videoId", videoId);
  // console.log("videoName", videoName);
  // console.log("state", state);
  // console.log("admin", admin);
  try {
    await API.post("kronik", `/task/create?admin=${admin}`, {
      body: {
        projectName: projectName,
        project: projectId,
        video: videoId,
        videoName: videoName,
        title: state.subtitle
          ? state.category.label + " " + state.subtitle
          : state.category.label,
        subtitle: state.subtitle,
        category: state.category,
        brief: state.brief,
        link: state.link,
        status: state.status,
        validation: state.validation,
        dueDate: state.date ? state.date.toISOString() : null,
        taskLeader: state.leader.value,
        private: state.private,
        sharing:state.sharing,
        sendEmail:state.sendEmail
        // medias: medias,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function updateTask(
  task,
  state,
  admin,
  projectId,
  videoId,
  projectName,
  videoName,
  taskId
) {
  try {
    // console.log("task 2", task);
    // console.log("state 2", state);
    // console.log("taskleader", state.leader);
    // console.log("task._id", task._id);
    await API.put("kronik", `/task/${taskId}?admin=${admin}`, {
      body: {
        project: projectId,
        video: videoId,
        projectName: projectName,
        videoName: videoName,
        title: state.subtitle
          ? state.category.label + " " + state.subtitle
          : state.category.label,
        subtitle: state.subtitle,
        category: state.category,
        brief: state.brief,
        link: state.link,
        status: state.status,
        validation: state.validation,
        dueDate: state.date,
        private: state.private,
        taskLeader: state.leader ? state.leader.value : null,
        validationDate: state.validationDate,
        statusDate: state.statusDate,
        sharing:state.sharing,
        sendEmail:state.sendEmail ? state.sendEmail : false
        // medias: medias,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function updateStatus(
  task,
  admin,
  projectId,
  videoId,
  projectName,
  videoName,
  status,
  validation,
  taskId,
  statusDate,
  validationDate
) {
  try {
    // console.log("send api task", status);
    // console.log("admin", admin);
    // console.log("task", task);
    // console.log("projectId", projectId);
    // console.log("projectName", projectName);
    // console.log("videoId", videoId);
    // console.log("validation", validation);
    // console.log("task id", task.id);
    return await API.put("kronik", `/task/${taskId}?admin=${admin}`, {
      body: {
        project: projectId,
        video: videoId,
        projectName: projectName,
        videoName: videoName,
        title: task.title,
        subtitle: task.subtitle,
        category: task.category,
        brief: task.brief,
        link: task.link,
        status: status ? status : task.status,
        validation: validation ? validation : task.validation,
        dueDate: task.dueDate,
        private: task.private,
        taskLeader: task.taskLeader && task.taskLeader._id,
        validationDate: validationDate ? validationDate : task.validationDate,
        statusDate: statusDate ? statusDate : task.statusDate,
        sharing:task.sharing,
        sendEmail:task.sendEmail ? task.sendEmail : false
        // medias: medias,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function deleteTask(projectId, task, admin) {
  try {
    await API.put("kronik", `/task/delete/${task._id}?admin=${admin}`, {
      body: {
        projectId: projectId,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

import { Flex } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import CardTitle from "../card/CardTitle";
import { MiniHeader } from "./MiniHeader";

export default function BusinessHeader() {
  const { admin, freelance, client } = useUser();
  const location = useLocation();

  // console.log("location", location.pathname);
  return (
    <MiniHeader>
      <Flex gap='20px'>
        {/* <Link
          //   as={NavLink}
          to='/dashboard'
          // activeClassName='selected'
          _hover={{
            textDecor: "none",
          }}>
          <CardTitle
            title='Dashboard'
            isActive={location.pathname === "/dashboard"}
          />
        </Link> */}
        {(admin || freelance) && (
          <Link
            //   as={NavLink}
            to='/clients'
            // activeClassName='selected'
            _hover={{
              textDecor: "none",
            }}>
            <CardTitle
              title='Clients'
              isActive={location.pathname === "/clients"}
            />
          </Link>
        )}
        {freelance && (
          <Link
            //   as={NavLink}
            to='/jobs'
            // activeClassName='selected'
            _hover={{
              textDecor: "none",
            }}>
            <CardTitle title='Jobs' isActive={location.pathname === "/jobs"} />
          </Link>
        )}
        {admin && (
          <Link
            //   as={NavLink}
            to='/lead'
            // activeClassName='selected'
            _hover={{
              textDecor: "none",
            }}>
            <CardTitle title='Leads' isActive={location.pathname === "/lead"} />
          </Link>
        )}

        {(admin || client) && (
          <Link
            //   as={NavLink}
            to='/quotes'
            // activeClassName='selected'
            _hover={{
              textDecor: "none",
            }}>
            <CardTitle
              title='Quotes'
              isActive={location.pathname === "/quotes"}
            />
          </Link>
        )}
        {admin && (
          <Link
            //   as={NavLink}
            to='/revenues'
            // activeClassName='selected'
            _hover={{
              textDecor: "none",
            }}>
            <CardTitle
              title='Revenues'
              isActive={location.pathname === "/revenues"}
            />
          </Link>
        )}
        {/* {admin && (
          <Link
            //   as={NavLink}
            to='/invoices'
            // activeClassName='selected'
            _hover={{
              textDecor: "none",
            }}>
            <CardTitle
              title='Invoices'
              isActive={location.pathname === "/invoices"}
            />
          </Link>
        )}
        {admin && (
          <Link
            //   as={NavLink}
            to='/payments'
            // activeClassName='selected'
            _hover={{
              textDecor: "none",
            }}>
            <CardTitle
              title='Payments'
              isActive={location.pathname === "/payments"}
            />
          </Link>
        )}
        {admin && (
          <Link
            //   as={NavLink}
            to='/expenses'
            // activeClassName='selected'
            _hover={{
              textDecor: "none",
            }}>
            <CardTitle
              title='Expenses'
              isActive={location.pathname === "/expenses"}
            />
          </Link>
        )} */}
      </Flex>
    </MiniHeader>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  Link,
  useColorMode,
  Checkbox,
  list,
  Text,
  Img,
  Grid,
  GridItem,
  Spacer,
  Badge,
  IconButton,
  useDisclosure,
  onOpenTags,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon, ExternalLinkIcon } from "@chakra-ui/icons";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import { Link as RouteLink } from "react-router-dom";
import { statusSwitch, validationSwitch } from "../../data/taskData";
import { FaFile, FaFileVideo } from "react-icons/fa";
import { BsFillPlayBtnFill } from "react-icons/bs";

import Upload from "./Upload";
import Card from "../../components/card";
import { downloadFile } from "./api";
import TableRow from "../../components/table/TableRow";
import CardTitle from "../../components/card/CardTitle";
import { VideoPlayer } from "../player/VideoPlayer";
import Player from "./Player";
import {
  MdLink,
  MdLinkOff,
  MdOutlineCloseFullscreen,
  MdOutlineOpenInFull,
} from "react-icons/md";
import { useUser } from "../../libs/UserContext";
import { IoArrowBackOutline, IoCloudUpload } from "react-icons/io5";
import NewUpload from "./NewUpload";
import MultiPartUpload from "./MultiPartUpload";
import {
  HiArrowLeft,
  HiArrowRight,
  HiOutlineTag,
  HiPencilAlt,
  HiTag,
} from "react-icons/hi";
import EditUpload from "../../components/upload/EditUpload";
import TestUpload from "./TestUpload";
import Comments from "../player/Comments";
import { tagsOptions } from "../../data/uploadData";

export default function Files({
  link,
  taskData,
  user,
  admin,
  files,
  projectId,
  setReloadData,
  reloadData,
  setIsLoading,
  isLoading,
  clientId,
  setComments,
  comments,
  reloadComments,
  setReloadComments,
  timecode,
  setTimecode,
  setLargePlayer,
  largePlayer,
  handleUpload,
  hiddenFileInput,
  setActive,
  currentFile,
  setCurrentFile,
  preselectedFiles,
  setPreselectedFiles,
  isPreselected,
  setIsPreselected,
  onOpenTags,
  filteredFiles,
  setFilteredFiles,
  activeFilter,
  setActiveFilter,
}) {
  const { colorMode } = useColorMode();
  const { uploadFiles, setUploadFiles, client } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAll, setSelectedAll] = useState(false);
  const [queryFile, setQueryFile] = useState("");
  const [queryTag, setQueryTag] = useState("all");
  const location = useLocation();
  function useQuery() {
    // const { search } = useLocation();
    // return React.useMemo(() => new URLSearchParams(search), [search]);
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  useEffect(() => {
    setQueryFile(query.get("file") ? query.get("file") : null);
    // console.log("query", query.get("file"));
  }, [location]);

  // console.log("taskData", taskData);
  // console.log("user", user);
  let history = useHistory();
  // const  [preselectedFiles, setPreselectedFiles ] = useState("");
  const [listUrl, setListUrl] = useState([]);
  const [playing, setPlaying] = useState(false);
  // const [currentFile, setCurrentFile] = useState(null)
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [currentEdit, setCurrentEdit] = useState("");
  const handleDownload = (file) => {
    // console.log("download", file);
    downloadFile(file.name, admin, projectId).then((response) => {
      const link = document.createElement("a");
      link.href = response.preSignedUrl;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  useEffect(() => {
    if (!activeFilter) {
      files &&
        setFilteredFiles(
          files
            .filter((file) => file.status !== "deleted")
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
    } else {
      files &&
        setFilteredFiles(
          files.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
    }
  }, [files, activeFilter]);

  useEffect(() => {
    if (filteredFiles?.length === 0) {
      setCurrentPlayer(null);
      setCurrentFile(null);
      setActive("Order");
    }

    if (filteredFiles?.length > 1 && queryFile === null) {
      setCurrentPlayer(1);
      setCurrentFile(null);
      // console.log("no queryFile");
    }
    if (queryFile?.length > 0) {
      setCurrentPlayer(0);
      setCurrentFile(filteredFiles.filter((file) => file._id === queryFile)[0]);
      // console.log(
      //   "queryFile",
      //   filteredFiles.filter((file) => file._id === queryFile)
      // );
    }
    if (filteredFiles?.length === 1) {
      setCurrentPlayer(0);
      setCurrentFile(filteredFiles[0]);
      // console.log("file", filteredFiles[0]);
    }
  }, [filteredFiles, queryFile]);

  function selectFile(e, file) {
    setCurrentFile(file);
    // setPreselectedFiles([])
  }

  useEffect(() => {
    if (selectedAll) {
      setPreselectedFiles(
        filteredFiles.filter(
          (file) =>
            file.tags?.some(
              (tag) => tag?.value.toLowerCase() === queryTag.toLowerCase()
            ) || queryTag === "all"
        )
      );
    } else {
      setPreselectedFiles([]);
    }
  }, [selectedAll]);

  function preselectFile(e, file) {
    if (Array.from(preselectedFiles).find((item) => item._id === file._id)) {
      // console.log("remove file", file._id);
      setPreselectedFiles(
        Array.from(preselectedFiles).filter((item) => item._id !== file._id)
      );
    } else {
      // console.log("add file", file._id);
      setPreselectedFiles([...Array.from(preselectedFiles), file]);
    }

    e.stopPropagation();
  }

  function previousCurrentFile(currentFile) {
    const index = filteredFiles.findIndex(
      (item) => item._id === currentFile._id
    );
    const previousFile = filteredFiles[index - 1];
    // console.log("index", index)
    // console.log("currentFile", currentFile)
    // console.log("nextFile", nextFile)
    // console.log("nextFile", nextFile)
    previousFile && setCurrentFile(previousFile);
  }

  function nextCurrentFile(currentFile) {
    const index = filteredFiles.findIndex(
      (item) => item._id === currentFile._id
    );
    const nextFile = filteredFiles[index + 1];
    // console.log("index", index)
    // console.log("currentFile", currentFile)
    // console.log("nextFile", nextFile)
    // console.log("nextFile", nextFile)
    nextFile && setCurrentFile(nextFile);
  }

  function openEdit(file) {
    onOpen();
    setCurrentEdit(file);
    // console.log("open edit", file)
  }

  // useEffect(() => {
  //   window.addEventListener('keydown', e => {
  //    if(e.key === 'ArrowRight') {
  //     console.log('You pressed ArrowRight', currentFile)
  //     currentFile && nextCurrentFile(currentFile)
  //    }
  //    if(e.key === 'ArrowLeft') {
  //     console.log('You pressed ArrowLeft', currentFile)
  //     currentFile && previousCurrentFile(currentFile)
  //    }
  //   })
  //  },[currentFile])

  useEffect(() => {
    currentFile && comments.length > 0
      ? setActive("Comments")
      : setActive("Order");
    // console.log("comments", comments);
    // console.log("currentFile", currentFile);
  }, [currentFile, comments]);

  const copyToClipboard = (e) => {
    const url = `https://app.kronik.com/review/${taskData._id}/project/${taskData.project._id}`;
    navigator.clipboard.writeText(url);
  };
  return (
    <>
      <Box>
        <Flex
          alignItems='center'
          mb='4px'
          //  minH="64px"
        >
          <Box width='100%'>
            {files && files.length === 1 && (
              <Flex justifyContent='space-between'>
                <Box>
                  <CardTitle
                    isActive='true'
                    title={`${filteredFiles.length} video `}
                  />
                </Box>

                <Flex>
                  {files.filter((file) => file && file.status === "deleted")
                    .length > 0 && (
                    <Flex pb={0} mr='10px' alignItems='center'>
                      <Checkbox
                        isChecked={activeFilter}
                        onChange={(e) => setActiveFilter(!activeFilter)}>
                        <Text fontSize='xs' fontWeight='600'>
                          Show deleted
                        </Text>
                      </Checkbox>
                    </Flex>
                  )}

                  <Button
                    // ml="10px"
                    // mr="10px"
                    variant='ghost'
                    onClick={() => openEdit(currentFile)}
                    rounded='full'
                    size='sm'
                    colorScheme='gray'
                    leftIcon={<HiPencilAlt />}>
                    Edit
                  </Button>
                </Flex>
              </Flex>
            )}

            {files && files.length > 1 && !currentFile && (
              <Flex>
                <Box width='100%'>
                  <Flex justifyContent='space-between'>
                    <Box>
                      <CardTitle
                        isActive='true'
                        title={
                          preselectedFiles.length >= 1
                            ? `${preselectedFiles.length > 1}`
                              ? `${preselectedFiles.length} videos selected`
                              : `${preselectedFiles.length} video selected`
                            : `${
                                filteredFiles.filter(
                                  (file) =>
                                    file.tags?.some(
                                      (tag) =>
                                        tag?.value.toLowerCase() ===
                                        queryTag.toLowerCase()
                                    ) || queryTag === "all"
                                ).length
                              } videos`
                        }
                      />
                    </Box>

                    <Flex alignItems='center'>
                      {files && !currentFile && (
                        <Box mr='20px'>
                          <Menu>
                            <MenuButton
                              textTransform='capitalize'
                              as={Button}
                              variant='outline'
                              rounded='full'
                              rightIcon={<ChevronDownIcon />}>
                              {queryTag === "all" ? "All tags" : queryTag}
                            </MenuButton>
                            <MenuList>
                              <MenuItem
                                onClick={() => {
                                  setQueryTag("all");
                                }}
                                // onClick={() => {
                                //   history
                                //     .push
                                //     `?client=${queryClient?.toLowerCase()}&display=${queryDisplay}&categroy=all`
                                //     ();
                                // }}
                              >
                                All tags
                              </MenuItem>
                              {tagsOptions.map((item) => {
                                return (
                                  <MenuItem
                                    onClick={() => {
                                      setQueryTag(item.value);
                                      // console.log("item", item.value);
                                    }}

                                    // onClick={() => {
                                    //   history
                                    //     .push
                                    //     // `?client=${queryClient?.toLowerCase()}&display=${queryDisplay}&category=${item.toLowerCase()}`
                                    //     ();
                                    // }}
                                  >
                                    {item?.label}
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </Menu>
                        </Box>
                      )}
                      {preselectedFiles.length > 0 && (
                        <Flex ml='10px' pb={0}>
                          {!isLoading && (
                            <Button
                              rounded='full'
                              // isDisabled={uploadFiles.length > 0}
                              variant='ghost'
                              size='xs'
                              onClick={() => onOpenTags()}
                              leftIcon={<HiOutlineTag />}>
                              Edit tags
                            </Button>
                          )}
                        </Flex>
                      )}
                      {files.filter((file) => file && file.status === "deleted")
                        .length > 0 && (
                        <Flex pb={0} mr='10px' alignItems='center'>
                          <Checkbox
                            isChecked={activeFilter}
                            onChange={(e) => setActiveFilter(!activeFilter)}>
                            <Text fontSize='xs' fontWeight='600'>
                              Show deleted
                            </Text>
                          </Checkbox>
                        </Flex>
                      )}
                      <Flex pb={0} alignItems='center'>
                        <Checkbox
                          isChecked={selectedAll}
                          onChange={(e) => setSelectedAll(!selectedAll)}>
                          <Text fontSize='xs' fontWeight='600'>
                            Select all
                          </Text>
                        </Checkbox>
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            )}

            {files && files.length > 1 && currentFile && (
              <Flex justifyContent='space-between'>
                <Flex
                  _hover={
                    files?.length > 1 && currentFile && { cursor: "pointer" }
                  }
                  onClick={
                    files?.length > 1 && currentFile
                      ? () => setCurrentFile(null)
                      : null
                  }
                  textAlign='center'>
                  <CardTitle
                    pb={0}
                    title={
                      <Flex>
                        <IoArrowBackOutline /> all videos
                      </Flex>
                    }
                  />
                </Flex>

                <Flex alignItems='center' pb={1}>
                  <Flex alignItems='center'>
                    <Checkbox
                      isChecked={
                        preselectedFiles
                          ? preselectedFiles.find(
                              (item) => item._id === currentFile._id
                            )
                            ? true
                            : false
                          : false
                      }
                      onChange={(e) => preselectFile(e, currentFile)}>
                      <Text fontSize='xs' fontWeight='600'>
                        Select
                      </Text>
                    </Checkbox>
                  </Flex>
                  {!client && (
                    <Button
                      ml='10px'
                      mr='10px'
                      variant='ghost'
                      onClick={() => openEdit(currentFile)}
                      rounded='full'
                      size='sm'
                      colorScheme='gray'
                      leftIcon={<HiPencilAlt />}
                      fontSize='xs'>
                      Edit
                    </Button>
                  )}
                  <IconButton
                    mr='10px'
                    disabled={currentFile === filteredFiles[0]}
                    // disabled={true}
                    variant='ghost'
                    onClick={(e) => previousCurrentFile(currentFile)}
                    rounded='full'
                    size='sm'
                    colorScheme='gray'
                    icon={<HiArrowLeft />}
                  />
                  <IconButton
                    mr='10px'
                    disabled={
                      currentFile === filteredFiles[filteredFiles.length - 1]
                    }
                    // disabled={true}
                    variant='ghost'
                    onClick={(e) => nextCurrentFile(currentFile)}
                    rounded='full'
                    size='sm'
                    colorScheme='gray'
                    icon={<HiArrowRight />}
                  />
                </Flex>
              </Flex>
            )}
          </Box>
        </Flex>

        {/* <Text>{currentPlayer}</Text> */}
        {files && currentFile && (
          // files.length === 1
          <Player
            timecode={timecode}
            setTimecode={setTimecode}
            // index={index}
            file={currentFile}
            clientId={clientId}
            setComments={setComments}
            reloadComments={reloadComments}
            setReloadComments={setReloadComments}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            currentFile={currentFile}
            setCurrentFile={setCurrentFile}
            files={files}
            previousCurrentFile={previousCurrentFile}
            nextCurrentFile={nextCurrentFile}
          />
        )}
        <Grid templateColumns='repeat(3, 1fr)' gap='20px'>
          {filteredFiles &&
            !currentFile &&
            filteredFiles
              .filter(
                (file) =>
                  file.tags?.some(
                    (tag) => tag?.value.toLowerCase() === queryTag.toLowerCase()
                  ) || queryTag === "all"
              )
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((file, index) => {
                // console.log("file", file);
                return (
                  <GridItem
                    className='group'
                    overflow='hidden'
                    key={index}
                    shadow='md'
                    bgColor={colorMode === "light" ? "white" : "#232323"}
                    rounded='md'>
                    <Box
                      transition='all 0.2s'
                      _groupHover={{
                        // transform: "scale(1.05)",
                        opacity: "0.8",
                      }}>
                      <Box position='relative'>
                        <Img
                          _hover={{ cursor: "pointer" }}
                          onClick={(e) => selectFile(e, file)}
                          w='full'
                          h='200px'
                          // boxSize='260px'
                          objectFit='cover'
                          src={`https://image.mux.com/${file.muxPlaybackId}/thumbnail.jpg`}
                        />
                        <Box position='absolute' top='20px' left='20px'>
                          <Checkbox
                            shadow='md'
                            borderColor='white'
                            isChecked={
                              preselectedFiles
                                ? preselectedFiles.find(
                                    (item) => item._id === file._id
                                  )
                                  ? true
                                  : false
                                : false
                            }
                            onChange={(e) => preselectFile(e, file)}
                          />
                        </Box>
                      </Box>
                      <Grid p='10px' gridTemplateColumns='6fr 1fr'>
                        <Box>
                          <Flex
                            fontSize='xs'
                            paddingRight='10px'
                            wordBreak='break-word'>
                            {file.originalName}
                          </Flex>
                          <Flex pt='10px'>
                            {file.tags &&
                              file.tags.map((tag, index) => {
                                return (
                                  <Badge key={index} mr='5px'>
                                    {tag.label}
                                  </Badge>
                                );
                              })}
                          </Flex>
                        </Box>
                        <Flex
                          justifyContent='space-between'
                          flexDirection='column'>
                          <IconButton
                            variant='outline'
                            onClick={() => openEdit(file)}
                            rounded='full'
                            size='xs'
                            colorScheme='gray'
                            icon={<HiPencilAlt />}
                          />
                          {/* <IconButton variant="outline"  rounded="full" size="xs" colorScheme="gray" icon={<HiOutlineTag />}/> */}
                        </Flex>
                      </Grid>
                    </Box>
                  </GridItem>

                  //   <Player timecode={timecode}
                  //   setTimecode={setTimecode}
                  //   index={index}
                  //   file={file}
                  //   clientId={clientId}
                  //   setComments={setComments}
                  //   reloadComments={reloadComments}
                  //   setReloadComments={setReloadComments}
                  //   isLoading={isLoading}
                  //   setIsLoading={setIsLoading}
                  // />
                );
              })}
        </Grid>
        {/* <Upload
        taskData={taskData}
        user={user}
        admin={admin}
        setReloadData={setReloadData}
        reloadData={reloadData}
        isLoading={isLoading}
        projectId={projectId}
        // updateDownload={updateDownload}
        // setDownloadData={setDownloadData}

        handleUpload={handleUpload}
        hiddenFileInput={hiddenFileInput}
      /> */}
        {/* <NewUpload
          taskData={taskData}
          user={user}
          admin={admin}
          setReloadData={setReloadData}
          reloadData={reloadData}
          isLoading={isLoading}
          projectId={projectId}
          handleUpload={handleUpload}
          hiddenFileInput={hiddenFileInput}
        /> */}
        <MultiPartUpload
          taskData={taskData}
          user={user}
          admin={admin}
          setReloadData={setReloadData}
          reloadData={reloadData}
          isLoading={isLoading}
          projectId={projectId}
          handleUpload={handleUpload}
          hiddenFileInput={hiddenFileInput}
        />
        {/* <TestUpload
          taskData={taskData}
           user={user}
           admin={admin}
           setReloadData={setReloadData}
           reloadData={reloadData}
           isLoading={isLoading}
           projectId={projectId}
           handleUpload={handleUpload}
           hiddenFileInput={hiddenFileInput}
      /> */}
        {currentEdit && (
          <EditUpload
            isOpen={isOpen}
            onClose={onClose}
            file={currentEdit}
            projectId={projectId}
            taskId={taskData && taskData._id}
            videoId={taskData && taskData.video._id}
            clientId={clientId}
            setReloadData={setReloadData}
            reloadData={reloadData}
          />
        )}
      </Box>
    </>
  );
}

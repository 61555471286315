import React from "react";

import {
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Flex,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import { statusOptions } from "../../data/projectData";
import { MdAddBox } from "react-icons/md";

export default function NavBar(props) {
  const history = useHistory();
  const {
    clients,
    admin,
    freelance,
    queryStatus,
    queryClient,
    queryDisplay,
    queryManager,
    queryCancel,
    onOpen,
    isLoading,
  } = props;

  return (
    <Flex my='5' justifyContent='space-between'>
      {/* Display filter */}
      <Flex>
        {admin && (
          <Menu>
            <MenuButton
              as={Button}
              variant='outline'
              rounded='full'
              rightIcon={<ChevronDownIcon />}>
              {queryDisplay === "all" && queryManager === "all"
                ? "All Quotes"
                : queryDisplay === "all" && queryManager === "géraldinemillion"
                ? "Géraldine Million"
                : queryDisplay === "all" && queryManager === "maxencelebreux"
                ? "Maxence Lebreux"
                : queryDisplay === "all" && queryManager === "geoffroylasne"
                ? "Geoffroy Lasne"
                : queryDisplay === "all" && queryManager === "mariongaufroy"
                ? "Marion Gaufroy"
                : queryDisplay === "all" && queryManager === "claracolleoni"
                ? "Clara Colleoni"
                : "My quotes"}
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=all&cancel=${queryCancel}`
                  );
                }}>
                All quotes
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=me&manager=all&cancel=${queryCancel}`
                  );
                }}>
                My quotes
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=géraldinemillion&cancel=${queryCancel}`
                  );
                }}>
                Géraldine Million
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=maxencelebreux&cancel=${queryCancel}`
                  );
                }}>
                Maxence Lebreux
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=geoffroylasne&cancel=${queryCancel}`
                  );
                }}>
                Geoffroy Lasne
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=mariongaufroy&cancel=${queryCancel}`
                  );
                }}>
                Marion Gaufroy
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=claracolleoni&cancel=${queryCancel}`
                  );
                }}>
                Clara Colleoni
              </MenuItem>
            </MenuList>
          </Menu>
        )}

        {/* Status filter */}
        <Menu>
          <MenuButton
            as={Button}
            variant='outline'
            rounded='full'
            rightIcon={<ChevronDownIcon />}
            textTransform='capitalize'>
            {queryCancel === "true"
              ? "Cancel"
              : queryStatus === "all"
              ? "All status"
              : queryStatus}
          </MenuButton>

          <MenuList maxH='300px' overflow='auto'>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=all&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager?.toLowerCase()}&cancel=false`
                );
              }}>
              All
            </MenuItem>

            {statusOptions &&
              statusOptions.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      history.push(
                        `?status=${
                          item?.value
                        }&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager?.toLowerCase()}&cancel=false`
                      );
                    }}>
                    {item.label}
                  </MenuItem>
                );
              })}
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=all&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager?.toLowerCase()}&cancel=true`
                );
              }}>
              Cancel
            </MenuItem>
          </MenuList>
        </Menu>

        {/* Client filter */}
        {(admin || freelance) && (
          <Menu>
            <MenuButton
              as={Button}
              variant='outline'
              rounded='full'
              rightIcon={<ChevronDownIcon />}
              textTransform='capitalize'>
              {queryClient === "all" ? "All clients" : queryClient}
            </MenuButton>
            <MenuList maxH='300px' overflow='auto'>
              <MenuItem
                onClick={() => {
                  history.push(
                    `?status=${queryStatus}&client=all&display=all&manager=${queryManager?.toLowerCase()}&cancel=${queryCancel}`
                  );
                }}>
                All
              </MenuItem>

              {clients &&
                clients.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        history.push(
                          `?status=${queryStatus}&client=${item.toLowerCase()}&display=all&manager=${queryManager?.toLowerCase()}&cancel=${queryCancel}`
                        );
                      }}>
                      {item}
                    </MenuItem>
                  );
                })}
            </MenuList>
          </Menu>
        )}
      </Flex>
      {admin && !isLoading && (
        <Button color='white' bg='primary' onClick={() => onOpen()}>
          <MdAddBox /> <Text ml='5px'>New quote</Text>
        </Button>
      )}
    </Flex>
  );
}

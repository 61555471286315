import React from "react";
import { API } from "aws-amplify";
import axios from "axios";

export async function fetchClient() {
  return API.get("kronik", "/client/all", {});
}
export async function fetchProjects(clientId, admin) {
  // console.log("clientId", clientId);
  return API.get(
    "kronik",
    `/client/get/projects/${clientId}?admin=${admin}`,
    {}
  );
}

// export async function fetchVideos(clientId) {
//   console.log("clientId", clientId);
//   return API.get("kronik", `/client/get/videos/${clientId}`, {});
// }

export async function fetchContacts(clientId) {
  const url = `/contact/list/${clientId}`;

  try {
    return await API.get("kronik", url, {});
  } catch (e) {
    console.log(e.message);
  }
}
export async function fetchVideos(display, year, clientId) {
  // console.log("api", `/video/get/videos/?display=${display}&client=${clientId}&year=${year}`);
  // console.log("api", `/client/get/all/videos/${clientId}?display=${display}`);
  // return API.get("kronik", `/client/get/all/videos/${clientId}?display=${display}`, {});
  return API.get(
    "kronik",
    `/video/get/videos/?display=${display}&client=${clientId}&year=${year}`,
    {}
  );

  // .then((response) => {

  //   return response;
  // });;
}

import React, { useState, useRef } from "react";
import {
  Box,
  Grid,
  Heading,
  Flex,
  Text,
  Avatar,
  Badge,
  useColorMode,
  IconButton,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { IoFlash } from "react-icons/io5";
import { differenceInBusinessDays } from "date-fns";

import { Link as RouteLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { HiPencilAlt } from "react-icons/hi";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import ModalView from "./ModalView";
import {
  statusEmoji,
  statusSwitch,
  validationEmoji,
  validationSwitch,
} from "../../data/taskData";
import TableRow from "../../components/table/TableRow";
import UpdateStatus from "../../components/task/UpdateStatus";
import { useUser } from "../../libs/UserContext";
import EditTask from "../../components/task/EditTask";
import { fetchAdmins } from "../task/api";
import { RowDetail } from "./RowDetail";
dayjs.locale("fr");
export default function TasksList(props) {
  const { colorMode } = useColorMode();
  const { admin } = useUser();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenStatus,
    onOpen: onOpenStatus,
    onClose: onCloseStatus,
  } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const [currentTask, setCurrentTask] = useState("");
  const [admins, setAdmins] = useState("");

  let history = useHistory();

  const {
    currentTasks,
    lateTasks,
    futurTasks,
    noDateTasks,
    reloadData,
    setReloadData,
    // admins,
  } = props;

  function groupBy(objectArray, property) {
    return (
      objectArray &&
      objectArray.reduce((acc, obj) => {
        const key = obj[property];

        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {})
    );
  }

  // console.log("tasks", tasks);
  // console.log("late tasks", lateTasks);
  // console.log("reloadData", reloadData);
  // const groupByStatus = groupBy(tasks, "dateStatus");
  const groupByDate = groupBy(currentTasks, "date");

  const firstRenderStatus = useRef(true);
  const firstRenderValidation = useRef(true);

  // console.log("groupByStatus", groupByStatus);
  // console.log("groupByDate", groupByDate);

  function week(dateItem) {
    switch (dateItem) {
      case dayjs(new Date()).subtract(1, "day").format("DD MMMM YYYY"):
        return "Yesterday";
      case dayjs(new Date()).format("DD MMMM YYYY"):
        return "Today";
      case dayjs(new Date()).add(1, "day").format("DD MMMM YYYY"):
        return "Tomorrow";

      default:
        return dateItem;
    }
  }

  function openLink(e, link) {
    window.open(link, "_blank");
    e.stopPropagation();
  }

  function taskLink(e, link) {
    // console.log("metaKey", e.metaKey);
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function videoLink(e, link) {
    // console.log("metaKey", e.metaKey);
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function openModal(e, task) {
    onOpen();
    setCurrentTask(task);
  }

  function clickStatus(e, task) {
    e.preventDefault();

    setCurrentTask(task);
    fetchAdmins(task.projectId, task.videoId, admin).then((response) => {
      // console.log("admins:", response);
      setAdmins(response);
    });
    onOpenStatus();
    // console.log("task", task);
    e.stopPropagation();
  }

  function openTask(e, task) {
    e.preventDefault();

    firstRenderStatus.current = true;
    firstRenderValidation.current = true;

    setCurrentTask(task);
    fetchAdmins(task.projectId, task.videoId, admin).then((response) => {
      // console.log("admins:", response);
      setAdmins(response);
    });
    onOpenEdit();
    e.stopPropagation();
  }

  return (
    <>
      {/* NoDate tasks */}

      <RowDetail
        tasksList={noDateTasks}
        title='No date'
        openLink={openLink}
        videoLink={videoLink}
        taskLink={taskLink}
        clickStatus={clickStatus}
        openTask={openTask}
      />

      {/* Late tasks */}
      {/* <RowDetail
        tasksList={lateTasks}
        title='Watchlist'
        tasksLimit={3}
        openLink={openLink}
        videoLink={videoLink}
        taskLink={taskLink}
        clickStatus={clickStatus}
        openTask={openTask}
      /> */}

      {/* Current tasks */}

      {Object.keys(groupByDate).map((item, index) => {
        return (
          <Box pb='40px'>
            <Grid
              gridTemplateColumns={
                admin
                  ? "5fr 3fr 6fr 5fr 2fr 2fr 2fr 1fr"
                  : "5fr 3fr 6fr 5fr 2fr 2fr 2fr"
              }
              // px="3"
              py='10px'>
              <Heading as='h2' fontSize='xl' mb='2' textTransform='capitalize'>
                {week(item)}
              </Heading>

              <Text fontSize='sm'>Client</Text>
              <Text fontSize='sm'>Project</Text>
              <Text fontSize='sm'>Responsable</Text>
              <Text fontSize='sm'>Date</Text>
              <Text fontSize='sm'>Status</Text>
              <Text fontSize='sm'>Validation</Text>
              {admin && <Text fontSize='sm'>Edit</Text>}
            </Grid>
            {groupByDate &&
              groupByDate[item].map((task, index) => {
                // console.log("task", task);

                let taskUrl = `/tasks/${task.id}/projects/${task.videoId}/quotes/${task.projectId}`;
                let videoUrl = `/projects/${task.videoId}/quotes/${task.projectId}`;
                // let shootingUrl = `/shooting/${task.videoId}/projects/${task.projectId}`;
                const deliveryTime =
                  task.statusDate &&
                  differenceInBusinessDays(
                    new Date(task.statusDate),
                    new Date(task.dueDate)
                  );
                const validationTime =
                  task.validationDate &&
                  differenceInBusinessDays(
                    new Date(task.validationDate),
                    new Date(task.statusDate ? task.statusDate : task.dueDate)
                  );
                // console.log("task test", task);
                return (
                  <TableRow
                    key={index}
                    gridCol={
                      admin
                        ? "5fr 3fr 6fr 5fr 2fr 2fr 2fr 1fr"
                        : "5fr 3fr 6fr 5fr 2fr 2fr 2fr"
                    }
                    click={(e, link) => taskLink(e, taskUrl)}>
                    <Box>
                      <Text
                        color={colorMode === "light" ? "gray.500" : "white"}>
                        <Flex alignItems='center'>
                          {admin && (
                            <IconButton
                              aria-label='Edit'
                              icon={<IoFlash />}
                              rounded='full'
                              size='sm'
                              onClick={(e) => clickStatus(e, task)}
                              mr='10px'
                            />
                          )}
                          <Text>{task.title}</Text>
                          {task.private ? (
                            <Badge variant='outline' ml='2' colorScheme='gray'>
                              Preview
                            </Badge>
                          ) : (
                            ""
                          )}
                        </Flex>
                      </Text>
                    </Box>
                    <Flex alignItems='center'>
                      {task?.clientLogo && (
                        <Image
                          mr='10px'
                          borderRadius='md'
                          boxSize='32px'
                          alt={`Logo of ${task?.client}`}
                          src={`https://aaliz317.twic.pics/${task?.clientLogo}`}
                        />
                      )}
                      {task.clientName}
                    </Flex>
                    <Box
                      mr='5px'
                      onClick={(e, link) => videoLink(e, videoUrl)}
                      color={colorMode === "light" ? "gray.500" : "white"}
                      _hover={{
                        textDecoration: "underline",
                        color:
                          colorMode === "light" ? "gray.900" : "whiteAlpha.600",
                      }}>
                      {task.videoName}
                    </Box>

                    <Box>
                      <Flex
                        alignItems='center'
                        color={colorMode === "light" ? "gray.500" : "white"}>
                        {task.people && !task?.taskLeader?.profile_picture && (
                          <Avatar
                            mr='10px'
                            size='sm'
                            name={task.people}
                            bgColor='blackAlpha.500'
                            color='white'
                          />
                        )}
                        {task?.taskLeader?.profile_picture && (
                          <Image
                            mr='10px'
                            borderRadius='full'
                            boxSize='32px'
                            src={`https://aaliz317.twic.pics/${task.taskLeader.profile_picture}`}
                            alt={` Picture of {item.taskLeader.given_name}`}
                          />
                        )}
                        {task.people}
                      </Flex>
                    </Box>
                    <Box color={colorMode === "light" ? "gray.500" : "white"}>
                      {task.dateJs
                        ? dayjs(task.dateJs).format("DD/MM/YYYY")
                        : "-"}
                    </Box>
                    <Flex>
                      {task.status && (
                        <Badge
                          variant='solid'
                          borderRadius='full'
                          px='10px'
                          py='5px'
                          // bgColor={statusSwitch(task.status.value)}
                          bgColor='whiteAlpha.100'>
                          {statusEmoji(task.status.value)} {task.status.label}
                        </Badge>
                      )}
                      {admin && (
                        <Text color='gray.500' mx='4px' fontSize='xs'>
                          {task.statusDate &&
                            task.category.value != "interview" &&
                            task.category.value != "shooting" &&
                            (deliveryTime > 0 ? "+" : "") + deliveryTime + "J"}
                        </Text>
                      )}
                    </Flex>
                    <Flex>
                      {task.validation && (
                        <Badge
                          variant='solid'
                          borderRadius='full'
                          px='10px'
                          py='5px'
                          // bgColor={validationSwitch(task.validation.value)}
                          bgColor='whiteAlpha.100'>
                          {validationEmoji(task.validation.value)}{" "}
                          {task.validation.label}
                        </Badge>
                      )}
                      {admin && (
                        <Text color='gray.500' mx='4px' fontSize='xs'>
                          {task.validationDate &&
                            task.category.value != "interview" &&
                            task.category.value != "shooting" &&
                            (validationTime > 0 ? "+" : "") +
                              validationTime +
                              "J"}
                        </Text>
                      )}
                    </Flex>
                    {admin && (
                      <Flex
                        onClick={(e) => openTask(e, task)}
                        color={
                          colorMode === "light" ? "gray.600" : "whiteAlpha.600"
                        }
                        alignItems='center'
                        _hover={{ opacity: "0.4" }}
                        ml='10px'>
                        <HiPencilAlt />
                      </Flex>
                    )}
                  </TableRow>
                );
              })}
          </Box>
        );
      })}

      {/* Futur tasks */}
      <RowDetail
        tasksList={futurTasks}
        title='Soon'
        openLink={openLink}
        videoLink={videoLink}
        taskLink={taskLink}
        clickStatus={clickStatus}
        openTask={openTask}
      />

      <ModalView
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        currentTask={currentTask}
      />

      <EditTask
        projectId={currentTask?.projectId}
        videoId={currentTask?.videoId}
        projectName={currentTask?.projectName}
        videoName={currentTask?.videoName}
        admins={admins}
        isOpen={isOpenEdit}
        onOpen={onOpenEdit}
        onClose={onCloseEdit}
        task={currentTask}
        reloadData={reloadData}
        setReloadData={setReloadData}
        firstRenderStatus={firstRenderStatus}
        firstRenderValidation={firstRenderValidation}
        taskId={currentTask?.id}
      />

      <UpdateStatus
        isOpen={isOpenStatus}
        onClose={onCloseStatus}
        taskData={currentTask}
        admin={admin}
        projectId={currentTask.projectId}
        videoId={currentTask.videoId}
        projectName={currentTask.projectName}
        videoName={currentTask.videoName}
        setReloadData={setReloadData}
        taskId={currentTask.id}
        firstRenderStatus={firstRenderStatus}
        firstRenderValidation={firstRenderValidation}
      />
    </>
  );
}

import { Box, Heading } from "@chakra-ui/react"
import React from "react"
import { useUser } from "../../libs/UserContext"
import { NewProduct } from "./newProduct"
import { NewQuote } from "./newQuote"


export function Quotes() {

    const {admin} = useUser()

    return (
        <Box>
            {admin && 
                // <NewQuote/>
                <NewProduct/>
            }
        </Box>
    )
}
import React from "react";

import {
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuOptionGroup,
  Button,
  Checkbox,
  Switch,
  Box,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ClientsList from "./ClientsList";
import { useHistory } from "react-router-dom";

export default function NavBar(props) {
  const {
    // display,
    // setDisplay,
    queryDisplay,
    queryClient,
    setQueryDisplay,
    admin,
    onOpen,
    clients,
  } = props;
  const history = useHistory();

  return (
    <Flex justifyContent='space-between' py='20px'>
      {admin && (
        <Menu>
          <MenuButton
            as={Button}
            variant='outline'
            rounded='full'
            rightIcon={<ChevronDownIcon />}>
            {queryDisplay === "all"
              ? `All clients (${clients.length})`
              : `My clients (${clients.length})`}
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                // setQueryDisplay("all");
                history.push(
                  `/clients?display=all&client=${queryClient?.toLowerCase()}`
                );
              }}>
              All clients
            </MenuItem>
            <MenuItem
              onClick={() => {
                // setQueryDisplay("me");
                history.push(
                  `/clients?display=me&client=${queryClient?.toLowerCase()}`
                );
              }}>
              My clients
            </MenuItem>
          </MenuList>
        </Menu>
      )}
      <Spacer />

      {admin && (
        <Button color='white' bg='primary' onClick={() => onOpen()}>
          New client
        </Button>
      )}
    </Flex>
  );
}

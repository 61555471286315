import { Flex, useColorMode } from "@chakra-ui/react";
import React from "react";

export default function RightColumn({ children }) {
  const { colorMode } = useColorMode();
  return (
    <Flex
      direction='column'
      mt='0px'
      w='280px'
      minW='280px'
      borderLeft='1px solid'
      borderColor={colorMode === "light" ? "darkBorder" : "lightBorder"}
      height='100vh'
      overflowY='scroll'>
      {children}
    </Flex>
  );
}

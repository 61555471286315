import React from "react";
import {
  Badge,
  Box,
  ColorModeContext,
  Divider,
  Flex,
  Heading,
  IconButton,
  Image,
  Spinner,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import SideSteps from "../../components/SideSteps";
import { statusSwitch, validationSwitch } from "../../data/taskData";
import { useHistory } from "react-router-dom";
import SideBarItem from "../../components/SideBarItem.js";
import { CheckIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  MdOutlineAlarm,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";
import { GiStarCycle } from "react-icons/gi";
import {
  BsArrowRepeat,
  BsChatSquareDots,
  BsChatSquareText,
  BsCheck,
  BsFillAlarmFill,
  BsQuestionCircle,
  BsXLg,
} from "react-icons/bs";
import { AiOutlineLike } from "react-icons/ai";
import CardTitle from "../../components/card/CardTitle";
import { MiniHeader } from "../../components/header/MiniHeader";
import { LeftColumn } from "../../components/column/LeftColumn";
import { IoClose, IoCloseOutline } from "react-icons/io5";
export default function SideBar({
  projectName,
  clientName,
  videoName,
  taskName,
  clientLink,
  projectLink,
  videoLink,
  isLoading,
  admin,
  freelance,
  tasksData,
  taskId,
  videoStatus,
  quoteStatus,
  clientLogo,
}) {
  let history = useHistory();
  const { colorMode } = useColorMode();

  const taskStatus = (status, validation) => {
    switch (true) {
      case status === "blocked":
        return <Box bgColor='#D34C45' w='20px' h='20px' rounded='md' />;
      case status === "inProgress":
        return (
          <Flex
            // bgColor='#6799F5'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            {/* <BsArrowRepeat />  */}
            🚧
          </Flex>
        );
      case status === "done" && validation === "validated":
        return (
          <Flex
            // bgColor='#377D50'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            {/* <BsCheck /> */}✅
          </Flex>
        );
      // <AiOutlineLike/>;
      case status === "done" && validation === "null":
        return (
          <Flex
            // bgColor='#377D50'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            {/* <BsCheck /> */}✅
          </Flex>
        );
      // <MdOutlineCheckBox/>;
      case status === "done" && validation === "validation":
        return (
          <>
            <Flex
              // bgColor='#377D50'
              bgColor='whiteAlpha.100'
              w='20px'
              h='20px'
              rounded='md'
              justifyContent='center'
              alignItems='center'>
              {/* <BsCheck /> */}✅
            </Flex>
            <Flex
              // bgColor='#6799F5'
              bgColor='whiteAlpha.100'
              w='20px'
              h='20px'
              ml='-5px'
              rounded='md'
              justifyContent='center'
              alignItems='center'>
              {/* <BsArrowRepeat /> */}
              💬
            </Flex>
          </>
        );
      // <BsChatSquareDots/>;
      case status === "done" && validation === "corection":
        return (
          <Flex
            // bgColor='#F3BF72'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            {/* <IoClose />  */}❌
          </Flex>
        );
      // <BsChatSquareText/>;
      case status === "todo" && validation === "null":
        return (
          <Box
            // bgColor='#B6B6B6'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
          />
        );
      // <MdOutlineCheckBoxOutlineBlank/>;
      case status === "null" && validation === "null":
        return (
          <Box
            // bgColor='#B6B6B6'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
          />
        );
      // <BsQuestionCircle/>;
      default:
        return (
          <Box
            // bgColor='#B6B6B6'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
          />
        );
      // <BsQuestionCircle/>;
    }
  };

  return (
    <LeftColumn>
      {/* <MiniHeader>
        <CardTitle title='Shortcuts' isActive='false' />
      </MiniHeader> */}
      {/* </Flex> */}
      <Box overflowY='auto' mt='10px'>
        <SideBarItem
          title={admin || freelance ? "Client" : "Organisation"}
          text={clientName}
          link={!isLoading && (admin || freelance) && clientLink}
          // status='client'
          clientLogo={clientLogo}
        />

        {admin && (
          <SideBarItem
            title='Quote'
            text={projectName}
            link={!isLoading && projectLink}
            status={quoteStatus}
            // status="delivered"
          />
        )}

        <SideBarItem
          title='Project'
          text={videoName}
          link={!isLoading && videoLink}
          status={videoStatus}
        />

        <Box padding='20px' rounded='xl'>
          <Heading
            fontWeight='semibold'
            color={colorMode === "light" ? "gray.500" : "white"}
            fontSize='md'
            mb='10px'>
            Tasks
          </Heading>

          {tasksData &&
            tasksData.map((task, index) => {
              let taskUrl = `/tasks/${task._id}/projects/${task.video}/quotes/${task.project}`;

              function taskLink(e, link) {
                // console.log("link", link);
                if (e.metaKey) {
                  var win = window.open(link, "_blank");
                  win.focus();
                } else {
                  history.push(link);
                }
                e.preventDefault();
                e.stopPropagation();
              }

              return (
                <Box
                  key={index}
                  alignItems='center'
                  onClick={(e) => taskLink(e, taskUrl)}
                  _hover={{ cursor: "pointer" }}
                  mb='0px'
                  mr='0px'>
                  {/* { (task.status ? task.status.value : "null")  + "-" +(task.validation ? task.validation.value : "null")} */}

                  <Flex
                    color={
                      colorMode === "light"
                        ? task._id === taskId
                          ? "primary"
                          : "black"
                        : task._id === taskId
                        ? "whiteAlpha.600"
                        : "white"
                    }
                    alignItems='center'
                    bgColor={
                      colorMode === "light"
                        ? task._id === taskId
                          ? "blackAlpha.50"
                          : null
                        : task._id === taskId
                        ? "whiteAlpha.50"
                        : null
                    }
                    _hover={{
                      bgColor:
                        colorMode === "light"
                          ? "blackAlpha.50"
                          : "whiteAlpha.50",
                    }}
                    // borderRadius='5px'
                    p='5px'>
                    <Flex>
                      <IconButton
                        variant='ghost'
                        mr='5px'
                        icon={taskStatus(
                          task.status ? task.status.value : "null",
                          task.validation ? task.validation.value : "null"
                        )}
                        size='xs'
                      />
                      {task.title}
                    </Flex>
                  </Flex>
                </Box>
              );
            })}
        </Box>
      </Box>
    </LeftColumn>
  );
}

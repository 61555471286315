import React, { useState, useEffect } from "react";
import { Box, Heading, Flex } from "@chakra-ui/react";

import fetchVideos, { fetchAdmins } from "./api";
import NavBar from "./NavBar";
import { useUser } from "../../libs/UserContext";
import { GroupByMonth } from "./GroupByMonth";
import { Updating } from "../../components/notification/Updating";
import { Link, useLocation } from "react-router-dom";
import MainColumn from "../../components/column/MainColumn";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";
import BusinessHeader from "../../components/header/BusinessHeader";

export default function Revenues() {
  const { user, admin, freelance } = useUser();
  const [dateSelected, setDateSelected] = useState("all");
  const [data, setData] = useState("");
  const [videos, setVideos] = useState("");
  const [clients, setClients] = useState("");
  const [reloadData, setReloadData] = useState(false);
  const [filteredVideos, setFilteredVideos] = useState("");
  const [queryStatus, SetQueryStatus] = useState("");
  const [queryClient, SetQueryClient] = useState("");
  const [queryDisplay, SetQueryDisplay] = useState("");
  const [queryManager, SetQueryManager] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    queryDisplay &&
      fetchVideos(dateSelected, queryDisplay).then((response) => {
        setVideos(response);
        setIsLoading(false);
      });
  }, [dateSelected, queryDisplay, reloadData]);

  // useEffect(() => {
  //   setVideos(
  //     data &&
  //       data.filter(
  //         (video) =>
  //           (queryClient === "all" || video.client === queryClient)
  //        && (queryManager === "all" || video.accountManager.includes(queryManager))
  //       )
  //   );
  // }, [data]);

  useEffect(() => {
    videos &&
      setFilteredVideos(
        videos.filter(
          (video) =>
            (queryClient === "all" ||
              video.client?.toLowerCase() === queryClient?.toLowerCase()) &&
            (queryManager === "all" ||
              video.accountManager.includes(queryManager?.toLowerCase()))
        )
      );
    // console.log("filteredVideos all", filteredVideos)
  }, [isLoading, queryStatus, queryClient, queryManager]);

  function useQuery() {
    // const { search } = useLocation();
    // return React.useMemo(() => new URLSearchParams(search), [search]);
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  useEffect(() => {
    SetQueryStatus(query.get("status") ? query.get("status") : "all");
    SetQueryClient(query.get("client") ? query.get("client") : "all");
    SetQueryDisplay(
      query.get("display")
        ? query.get("display")
        : user.username === "florian@kronik.com" ||
          user.username === "christophe@kronik.com" ||
          user.username === "mathieu@kronik.com"
        ? "all"
        : "me"
    );
    SetQueryManager(query.get("manager") ? query.get("manager") : "all");
  }, [location, videos]);

  useEffect(() => {
    const clientsArray = videos && videos.map((item) => item.client);

    setClients(videos && [...new Set(clientsArray.sort())]);
  }, [videos]);

  // console.log("videos", videos);
  return (
    <Box w='100%'>
      <MainColumn>
        {admin && (
          <Flex flexDirection='column'>
            <BusinessHeader />
            <MainColumn>
              <Box px='20px'>
                <NavBar
                  clients={clients}
                  admin={admin}
                  freelance={freelance}
                  filteredVideos={filteredVideos}
                  queryStatus={queryStatus}
                  queryClient={queryClient}
                  queryDisplay={queryDisplay}
                  queryManager={queryManager}
                />

                <GroupByMonth
                  videos={filteredVideos}
                  admin={admin}
                  reloadData={reloadData}
                  setReloadData={setReloadData}
                />
              </Box>
            </MainColumn>
            {isLoading && <Updating />}
          </Flex>
        )}
      </MainColumn>
    </Box>
  );
}

import { API } from "aws-amplify";

export async function updateBrief(briefId, admin, state) {
  // console.log("fetchBriefs");
  const url = `/brief/${briefId}?admin=${admin}`;
  return API.put("kronik", url, {
    body: {
      brief: state.brief,
      product: state.product,
      creator: state.creator,
      pricing: state.pricing,
      date: state.date,
      firstName: state.firstName,
      lastName: state.lastName,
      job: state.job,
      email: state.email,
      company: state.company,
      source: state.source,
      status: state.status,
      location: state.location,
      quantity: state.quantity,
      dueDate: state.dueDate,
      client: state.currentClient ? state.currentClient.value : null,
    },
  });
}

export async function createBrief(admin, state) {
  const url = `/brief/create?admin=${admin}`;

  try {
    // console.log("state", state)
    await API.post("kronik", url, {
      body: {
        brief: state.brief,
        product: state.product,
        creator: state.creator,
        pricing: state.pricing,
        date: state.date,
        firstName: state.firstName,
        lastName: state.lastName,
        job: state.job,
        email: state.email,
        company: state.company,
        source: state.source,
        status: state.status,
        location: state.location,
        quantity: state.quantity,
        dueDate: state.dueDate,
        client: state.currentClient ? state.currentClient.value : null,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function deleteBrief(briefId, admin) {
  // console.log("fetchBriefs");
  const url = `/brief/delete/${briefId}?admin=${admin}`;
  return API.put("kronik", url, {});
}

export async function getClients() {
  try {
    return await API.get("kronik", "/client/all", {});
    // setClients(data);
  } catch (e) {
    console.log(e.message);
  }
}

import React, { useState, useEffect } from "react";
import {
  Flex,
  Button,
  Box,
  Heading,
  useColorMode,
  useDisclosure,
  Spacer,
} from "@chakra-ui/react";
import { fetchClientsMe, fetchClientsAll } from "./api";
import ClientsList from "./ClientsList";
import NewClient from "../../components/client/NewClient";
import { useUser } from "../../libs/UserContext";
import NavBar from "./NavBar";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";
import MainColumn from "../../components/column/MainColumn";
import ProductionHeader from "../../components/header/ProjectHeader";
import BusinessHeader from "../../components/header/BusinessHeader";
import FilterByClient from "../../components/client/FilterByClient";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "react-router-dom";

export default function Clients() {
  const [clients, setClients] = useState("");
  const [filteredClients, setFilteredClients] = useState("");
  const [clientList, setClientList] = useState("");
  const [reloadData, setReloadData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [display, setDisplay] = useState("");
  const [queryDisplay, setQueryDisplay] = useState("");
  const [queryClient, SetQueryClient] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isFilterOpen,
    onOpen: onFilterOpen,
    onClose: onFilterClose,
  } = useDisclosure();
  const { user, admin } = useUser();

  useHotkeys("meta+k", () => onFilterOpen());

  function useQuery() {
    // const { search } = useLocation();
    // return React.useMemo(() => new URLSearchParams(search), [search]);
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const location = useLocation();

  useEffect(() => {
    SetQueryClient(query.get("client") ? query.get("client") : "all");

    setQueryDisplay(
      query.get("display")
        ? query.get("display")
        : user.username === "florian@kronik.com" ||
          user.username === "christophe@kronik.com" ||
          user.username === "mathieu@kronik.com"
        ? "all"
        : "me"
    );
  }, [location]);

  return (
    <Box width='100%'>
      <MainColumn>
        <BusinessHeader />
        <MainColumn>
          <Box px='20px'>
            {admin && (
              <NavBar
                admin={admin}
                queryDisplay={queryDisplay}
                queryClient={queryClient}
                // setDisplay={setDisplay}
                onOpen={onOpen}
                clients={clients}
              />
            )}
            <ClientsList
              clients={clients}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setClients={setClients}
              reloadData={reloadData}
              admin={admin}
              queryDisplay={queryDisplay}
              clientList={clientList}
              setClientList={setClientList}
              queryClient={queryClient}
              setFilteredClients={setFilteredClients}
              filteredClients={filteredClients}
            />
          </Box>
        </MainColumn>

        <NewClient
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          reloadData={reloadData}
          setReloadData={setReloadData}
        />
      </MainColumn>

      {clientList.length > 0 && (
        <FilterByClient
          clients={clientList}
          isOpen={isFilterOpen}
          onClose={onFilterClose}
          queryDisplay={queryDisplay}
          page='clients'
        />
      )}
    </Box>
  );
}

import React, { useState, useEffect, useReducer } from "react";
import { useUser } from "../../libs/UserContext";
import { initialState, reducer } from "./reducer";
import { customStyles, darkStyles } from "../../styles/customStyles";
import {
  setupOptions,
  skillOptions,
  statusOptions,
} from "../../data/freelanceData";

// import { getClients, updateProject, deleteProject } from "./api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Textarea,
  NumberInput,
  NumberInputField,
  useColorMode,
  Switch,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import Select from "react-select";
import { deleteFreelance, updateFreelance } from "./api";

export function EditFreelance(props) {
  const { colorMode } = useColorMode();

  const { isOpen, onClose, reloadData, setReloadData, freelanceData } = props;
  const { user, admin } = useUser();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [clients, setClients] = useState("");
  const [dataDeleted, setDataDeleted] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [deleteButton, setDeleteButton] = useState(false);

  useEffect(() => {
    try {
      // console.log("freelanceData", freelanceData);
      dispatch({
        type: "previousData",
        payload: {
          given_name: freelanceData ? freelanceData.given_name : "",
          family_name: freelanceData ? freelanceData.family_name : "",
          email: freelanceData ? freelanceData.email : "",
          url: freelanceData ? freelanceData.url : "",
          about: freelanceData ? freelanceData.about : "",
          note: freelanceData ? freelanceData.note : "",
          experience: freelanceData ? freelanceData.experience : "",
          adress: freelanceData ? freelanceData.adress : "",
          bankAccount: freelanceData ? freelanceData.bankAccount : "",
          birthDate: freelanceData ? Date.parse(freelanceData.birthDate) : "",
          status: freelanceData
            ? freelanceData.status
            : { label: "", value: "" },
          setup: freelanceData ? freelanceData.setup : [],
          skills: freelanceData ? freelanceData.skills : [],
          category: freelanceData ? freelanceData.category : "",
          averageGrade: freelanceData ? freelanceData.averageGrade : "",
          review: freelanceData ? freelanceData.review : "",
          jobs: freelanceData ? freelanceData.jobs : [],
        },
      });
    } catch (e) {
      console.log(e.message);
    }
  }, [freelanceData]);

  function save() {
    dispatch({ type: "save" });
    // console.log("dataSaved")
    updateFreelance(freelanceData._id, state, admin).then((response) => {
      setReloadData(!reloadData);
      onClose();
      dispatch({ type: "success" });
      // console.log("response update", response);
    });
    // updateProject(projectData._id, admin, state).then((response) => {
    //   setReloadData(!reloadData);
    //   onClose();
    //   dispatch({ type: "success" });
    // });
  }

  function remove() {
    dispatch({ type: "delete" });

    // console.log("dataDeleted");
    deleteFreelance(freelanceData._id, admin).then((response) => {
      dispatch({ type: "success" });
      setDataDeleted(response);
      setDeleteButton(false);
      setConfirmMessage("");
      onClose();
      setReloadData(!reloadData);
    });
  }

  const DateForm = () => {
    return (
      <DatePicker
        // className="date-picker"
        wrapperClassName={
          colorMode === "light"
            ? "date-picker-wrapper-light"
            : "date-picker-wrapper-dark"
        }
        // calendarClassName="dark-test"
        popperClassName={colorMode === "light" ? "ligth-picker" : "dark-picker"}
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        dateFormat='dd/MM/yyyy'
        placeholderText='Choisir une date'
        isClearable
        calendarStartDay={1}
        selected={state.birthDate}
        onChange={(date) =>
          dispatch({
            type: "field",
            payload: {
              fieldName: "birthDate",
              value: date,
            },
          })
        }
        // locale="fr"
      />
    );
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior='outside'
        size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New freelance</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Status */}
            <Box my='4'>
              <FormLabel>Status</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                placeholder='Select a status'
                value={state.status}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "status",
                      value: e,
                    },
                  });
                }}
                options={statusOptions}
              />
            </Box>

            {/* Name */}
            <Grid templateColumns='1fr 1fr' gap='20px'>
              <GridItem>
                <Box my='4'>
                  <FormControl id='subtitle'>
                    <FormLabel>First name</FormLabel>
                    <Input
                      variant='filled'
                      placeholder='Add a first name'
                      value={state.given_name}
                      onChange={(e) =>
                        dispatch({
                          type: "field",
                          payload: {
                            fieldName: "given_name",
                            value: e.target.value,
                          },
                        })
                      }
                    />
                  </FormControl>
                </Box>
              </GridItem>
              <GridItem>
                <Box my='4'>
                  <FormControl id='subtitle'>
                    <FormLabel>Last name</FormLabel>
                    <Input
                      variant='filled'
                      placeholder='Add a last name'
                      value={state.family_name}
                      onChange={(e) =>
                        dispatch({
                          type: "field",
                          payload: {
                            fieldName: "family_name",
                            value: e.target.value,
                          },
                        })
                      }
                    />
                  </FormControl>
                </Box>
              </GridItem>
            </Grid>

            <Box my='4'>
              <FormControl id='email'>
                <FormLabel>Email</FormLabel>
                <Input
                  variant='filled'
                  type='email'
                  disabled
                  placeholder='Enter an email adress'
                  value={state.email}
                  isRequired
                  onChange={(e) => {
                    dispatch({
                      type: "field",
                      payload: { fieldName: "email", value: e.target.value },
                    });
                  }}
                />
              </FormControl>
            </Box>

            {/* Birth date */}
            <Box my='4'>
              <FormLabel>Birth date</FormLabel>
              <Flex>
                <Box maxW='200'>
                  <DateForm />
                </Box>
              </Flex>
            </Box>

            {/* About*/}
            <Box my='4'>
              <FormLabel>About</FormLabel>
              <Textarea
                variant='filled'
                placeholder='Write a quick profil'
                size='md'
                resize='vertical'
                value={state.about}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "about",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Box>

            {/* Skills */}
            <Box my='4'>
              <FormLabel>Skill(s)</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                isMulti
                placeholder='Select one (or more) goal(s) '
                value={state.skills}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "skills",
                      value: e,
                    },
                  });
                }}
                options={skillOptions}
              />
            </Box>

            {/* setup */}
            <Box my='4'>
              <FormLabel>Setup</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                isMulti
                placeholder='Select one (or more) setup '
                value={state.setup}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "setup",
                      value: e,
                    },
                  });
                }}
                options={setupOptions}
              />
            </Box>

            {/* Adress*/}
            <Box my='4'>
              <FormLabel>Adress</FormLabel>
              <Textarea
                variant='filled'
                placeholder='Adress'
                size='md'
                resize='vertical'
                value={state.adress}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "adress",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Box>

            {/* bankAccount */}
            <Box my='4'>
              <FormControl id='subtitle'>
                <FormLabel>Bank Account Number</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a last name'
                  value={state.bankAccount}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "bankAccount",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/* Pricing */}
            {/* <Box my="4">
              <FormControl id="pricing">
                <FormLabel>Total HT</FormLabel>
                <NumberInput max={1000000} min={10} variant="filled">
                  <NumberInputField
                    placeholder="Add a number"
                    value={state.pricing}
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "pricing",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </NumberInput>
              </FormControl>
            </Box> */}
          </ModalBody>

          <ModalFooter>
            {(user.username === "florian@kronik.com" ||
              user.username === "matt@kronik.com") &&
              !deleteButton && (
                <Button mr={3} onClick={() => setDeleteButton(true)}>
                  Delete
                </Button>
              )}
            {!deleteButton && (
              <Button
                bgColor='primary'
                color='white'
                mr={3}
                onClick={save}
                isLoading={state.isLoadingOne}
                loadingText='Loading'>
                Save
              </Button>
            )}

            {deleteButton && (
              <Box mr='20px'>
                <FormControl id='confirmation'>
                  <Input
                    variant='filled'
                    placeholder='Enter DELETE to confirm'
                    value={confirmMessage}
                    onChange={(e) => setConfirmMessage(e.target.value)}
                  />
                </FormControl>
              </Box>
            )}
            {deleteButton && (
              <Button
                // colorScheme="blue"
                mr={3}
                onClick={() => setDeleteButton(false)}>
                Cancel
              </Button>
            )}
            {confirmMessage === "DELETE" && deleteButton && (
              <Button
                colorScheme='red'
                mr={3}
                onClick={() => remove()}
                isLoading={state.isLoadingTwo}
                loadingText='Loading'>
                Delete
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

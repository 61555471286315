import React, { useState, useRef } from "react";
import {
  Box,
  Grid,
  Heading,
  Flex,
  Text,
  Avatar,
  Badge,
  useColorMode,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { IoFlash } from "react-icons/io5";
import { differenceInBusinessDays } from "date-fns";

import { Link as RouteLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { HiPencilAlt } from "react-icons/hi";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import ModalView from "./ModalView";
import { statusSwitch, validationSwitch } from "../../data/taskData";
import TableRow from "../../components/table/TableRow";
import UpdateStatus from "../../components/task/UpdateStatus";
import { useUser } from "../../libs/UserContext";
import EditTask from "../../components/task/EditTask";
import { fetchAdmins } from "../task/api";
import { RowDetail } from "./RowDetail";
dayjs.locale("fr");
export default function WatchList(props) {
  const { colorMode } = useColorMode();
  const { admin } = useUser();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenStatus,
    onOpen: onOpenStatus,
    onClose: onCloseStatus,
  } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const [currentTask, setCurrentTask] = useState("");
  const [admins, setAdmins] = useState("");

  let history = useHistory();

  const {
    currentTasks,
    lateTasks,
    futurTasks,
    noDateTasks,
    reloadData,
    setReloadData,
    // admins,
  } = props;

  function groupBy(objectArray, property) {
    return (
      objectArray &&
      objectArray.reduce((acc, obj) => {
        const key = obj[property];

        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {})
    );
  }

  // console.log("tasks", tasks);
  // console.log("late tasks", lateTasks);
  // console.log("reloadData", reloadData);
  // const groupByStatus = groupBy(tasks, "dateStatus");
  const groupByDate = groupBy(currentTasks, "date");

  const firstRenderStatus = useRef(true);
  const firstRenderValidation = useRef(true);

  // console.log("groupByStatus", groupByStatus);
  // console.log("groupByDate", groupByDate);

  function week(dateItem) {
    switch (dateItem) {
      case dayjs(new Date()).subtract(1, "day").format("DD MMMM YYYY"):
        return "Yesterday";
      case dayjs(new Date()).format("DD MMMM YYYY"):
        return "Today";
      case dayjs(new Date()).add(1, "day").format("DD MMMM YYYY"):
        return "Demain";

      default:
        return dateItem;
    }
  }

  function openLink(e, link) {
    window.open(link, "_blank");
    e.stopPropagation();
  }

  function taskLink(e, link) {
    // console.log("metaKey", e.metaKey);
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function videoLink(e, link) {
    // console.log("metaKey", e.metaKey);
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function openModal(e, task) {
    onOpen();
    setCurrentTask(task);
  }

  function clickStatus(e, task) {
    e.preventDefault();

    setCurrentTask(task);
    fetchAdmins(task.projectId, task.videoId, admin).then((response) => {
      // console.log("admins:", response);
      setAdmins(response);
    });
    onOpenStatus();
    // console.log("task", task);
    e.stopPropagation();
  }

  function openTask(e, task) {
    e.preventDefault();

    firstRenderStatus.current = true;
    firstRenderValidation.current = true;

    setCurrentTask(task);
    fetchAdmins(task.projectId, task.videoId, admin).then((response) => {
      // console.log("admins:", response);
      setAdmins(response);
    });
    onOpenEdit();
    e.stopPropagation();
  }

  return (
    <>
      {/* NoDate tasks */}

      <RowDetail
        tasksList={noDateTasks}
        title='No date'
        openLink={openLink}
        videoLink={videoLink}
        taskLink={taskLink}
        clickStatus={clickStatus}
        openTask={openTask}
      />

      {/* Late tasks */}
      <RowDetail
        tasksList={lateTasks}
        title='Validation'
        // tasksLimit={3}
        openLink={openLink}
        videoLink={videoLink}
        taskLink={taskLink}
        clickStatus={clickStatus}
        openTask={openTask}
      />

      {/* Current tasks */}

      <ModalView
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        currentTask={currentTask}
      />

      <EditTask
        projectId={currentTask?.projectId}
        videoId={currentTask?.videoId}
        projectName={currentTask?.projectName}
        videoName={currentTask?.videoName}
        admins={admins}
        isOpen={isOpenEdit}
        onOpen={onOpenEdit}
        onClose={onCloseEdit}
        task={currentTask}
        reloadData={reloadData}
        setReloadData={setReloadData}
        firstRenderStatus={firstRenderStatus}
        firstRenderValidation={firstRenderValidation}
        taskId={currentTask?.id}
      />

      <UpdateStatus
        isOpen={isOpenStatus}
        onClose={onCloseStatus}
        taskData={currentTask}
        admin={admin}
        projectId={currentTask.projectId}
        videoId={currentTask.videoId}
        projectName={currentTask.projectName}
        videoName={currentTask.videoName}
        setReloadData={setReloadData}
        taskId={currentTask.id}
        firstRenderStatus={firstRenderStatus}
        firstRenderValidation={firstRenderValidation}
      />
    </>
  );
}

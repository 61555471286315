import React, { useState, useReducer } from "react";
import { useUser } from "../../libs/UserContext";
// import { initialState, reducer } from "./reducer";
// import { customStyles } from "../../styles/customStyles";
// import { darkStyles } from "../../styles/customStyles";

import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  MenuItem,
  useColorMode,
  MenuList,
  Menu,
  MenuButton,
} from "@chakra-ui/react";
import Select from "react-select";
import { createClient } from "./api";
import { useHistory } from "react-router-dom";
import { customStyles, darkStyles } from "../../styles/customStyles";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function FilterByClient(props) {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const { colorMode } = useColorMode();

  const {
    isOpen,
    onClose,
    clients,
    queryStatus,
    queryDisplay,
    queryManager,
    queryPeople,
    queryCategory,
    page,
  } = props;
  const { admin } = useUser();
  const history = useHistory();

  //   const [state, dispatch] = useReducer(reducer, initialState);

  function save() {
    // dispatch({ type: "save" });
    // createClient(state, admin).then((response) => {
    //   dispatch({ type: "success" });
    //   onClose();
    //   setReloadData(!reloadData);
    // });
  }

  const clientsOption =
    clients &&
    clients.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  clientsOption &&
    clientsOption.unshift({ value: "all", label: "All clients" });
  // console.log("clients :", clientsOption);

  function urlFilter(e) {
    switch (page) {
      case "projects":
        return `?status=${queryStatus}&client=${e?.value?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}`;
      case "library":
        return `?client=${e?.value?.toLowerCase()}&display=${queryDisplay}`;
      case "tasks":
        return `?status=${queryStatus}&client=${e?.value?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}&category=${queryCategory}&people=${queryPeople}`;
      case "clients":
        return `?display=${queryDisplay}&client=${e?.value?.toLowerCase()}`;

      default:
        return "#";
    }
  }

  return (
    <div>
      <Modal
        autoFocus={true}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior='outside'
        size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter by client</ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            {/* Name */}
            {/* <Box my='4'> */}
            {/* {clients &&
                clients.map((item, index) => { */}

            <>
              {/* <Select
                  styles={darkStyles}
                  isClearable
                  placeholder='Select a client'
                  value=''
                  onChange={(e) => {
                    console.log("client :", e);
                    history.push(
                      `?status=delivered&client=${e.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}`
                    );
                    onClose();
                  }}
                  options={clients}
                /> */}

              {/* <Menu autoSelect={true}>
                  <MenuButton
                    as={Button}
                    variant='outline'
                    rounded='full'
                    rightIcon={<ChevronDownIcon />}
                    textTransform='capitalize'>
                    Filter by a client
                  </MenuButton>
                  <MenuList maxH='300px' overflow='auto' autoSelect={true}>
                    <MenuItem
                      onClick={() => {
                        history.push(
                          `?status=delivered&client=all&display=${queryDisplay}&manager=${queryManager}`
                        );
                        onClose();
                      }}>
                      All clients
                    </MenuItem>

                    {clients &&
                      clients.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            onChange={(e) => {
                              console.log("client :", e);
                              history.push(
                                `?status=delivered&client=${e.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}`
                              );
                              onClose();
                            }}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </MenuList>
                </Menu> */}

              <Select
                defaultMenuIsOpen
                placeholder='Select a client ...'
                styles={colorMode === "light" ? customStyles : darkStyles}
                className='basic-single'
                classNamePrefix='select'
                // defaultValue={clients[0]}
                isDisabled={isDisabled}
                isLoading={isLoading}
                // isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                name='client'
                options={clientsOption}
                onChange={(e) => {
                  history.push(
                    urlFilter(e)
                    // `?status=${queryStatus}&client=${e?.value?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}`
                  );
                  onClose();
                }}
              />
            </>
            {/* })} */}
            {/* </Box> */}
          </ModalBody>

          {/* <ModalFooter>
            <Button
              isDisabled={!state.name}
              bgColor='primary'
              color='white'
              mr={3}
              onClick={save}
              isLoading={state.isLoadingOne}
              loadingText='Loading'>
              Save
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </div>
  );
}

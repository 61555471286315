import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { differenceInBusinessDays } from "date-fns";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { HiPencilAlt } from "react-icons/hi";
import { IoFlash } from "react-icons/io5";
import TableRow from "../../components/table/TableRow";
import {
  statusEmoji,
  statusSwitch,
  validationEmoji,
  validationSwitch,
} from "../../data/taskData";
import { useUser } from "../../libs/UserContext";

export function RowDetail(props) {
  // const [limit, setLimit] = useState(null);

  const { admin } = useUser();
  const { colorMode } = useColorMode();
  const {
    tasksList,
    title,
    tasksLimit,
    openLink,
    videoLink,
    taskLink,
    clickStatus,
    openTask,
  } = props;

  // useEffect(() => {
  //   tasksLimit && setLimit(tasksLimit);
  //   !tasksLimit && setLimit(tasksList.length);
  // }, [tasksLimit]);

  return (
    tasksList.length > 0 && (
      <Box pb='40px'>
        <Grid
          gridTemplateColumns={
            admin
              ? "5fr 3fr 6fr 5fr 2fr 2fr 2fr 1fr"
              : "5fr 3fr 6fr 5fr 2fr 2fr 2fr"
          }
          // px="3"
          py='10px'>
          <Heading as='h2' fontSize='xl' mb='2' textTransform='capitalize'>
            {title}
          </Heading>

          <Text fontSize='sm'>Client</Text>
          <Text fontSize='sm'>Project</Text>
          <Text fontSize='sm'>Responsable</Text>
          <Text fontSize='sm'>Date</Text>
          <Text fontSize='sm'>Status</Text>
          <Text fontSize='sm'>Validation</Text>
          {admin && <Text fontSize='sm'>Edit</Text>}
        </Grid>
        {tasksList &&
          tasksList
            // .slice(0, limit ? limit : null)
            .map((task, index) => {
              let taskUrl = `/tasks/${task.id}/projects/${task.videoId}/quotes/${task.projectId}`;
              let videoUrl = `/projects/${task.videoId}/quotes/${task.projectId}`;

              const deliveryTime =
                task.statusDate &&
                differenceInBusinessDays(
                  new Date(task.statusDate),
                  new Date(task.dueDate)
                );
              const validationTime =
                task.validationDate &&
                differenceInBusinessDays(
                  new Date(task.validationDate),
                  new Date(task.statusDate ? task.statusDate : task.dueDate)
                );

              return (
                <TableRow
                  key={index}
                  gridCol={
                    admin
                      ? "5fr 3fr 6fr 5fr 2fr 2fr 2fr 1fr"
                      : "5fr 3fr 6fr 5fr 2fr 2fr 2fr"
                  }
                  click={(e, link) => taskLink(e, taskUrl)}>
                  <Box>
                    <Text color={colorMode === "light" ? "gray.500" : "white"}>
                      <Flex alignItems='center'>
                        {admin && (
                          <IconButton
                            aria-label='Edit'
                            icon={<IoFlash />}
                            rounded='full'
                            size='sm'
                            onClick={(e) => clickStatus(e, task)}
                            mr='10px'
                          />
                        )}
                        <Text>{task.title}</Text>
                        {task.private ? (
                          <Badge variant='outline' ml='2' colorScheme='gray'>
                            Preview
                          </Badge>
                        ) : (
                          ""
                        )}
                      </Flex>
                    </Text>
                  </Box>

                  <Box>
                    <Flex alignItems='center'>
                      {task?.clientLogo && (
                        <Image
                          mr='10px'
                          borderRadius='md'
                          boxSize='32px'
                          alt={`Logo of ${task?.client}`}
                          src={`https://aaliz317.twic.pics/${task?.clientLogo}`}
                        />
                      )}
                      {task.clientName}
                    </Flex>
                  </Box>

                  <Box
                    mr='5px'
                    color={colorMode === "light" ? "gray.500" : "white"}
                    onClick={(e, link) => videoLink(e, videoUrl)}
                    _hover={{
                      textDecoration: "underline",
                      color:
                        colorMode === "light" ? "gray.900" : "whiteAlpha.600",
                    }}>
                    {task.videoName}
                  </Box>

                  <Box>
                    <Flex
                      alignItems='center'
                      color={colorMode === "light" ? "gray.500" : "white"}>
                      {task.people && !task?.taskLeader?.profile_picture && (
                        <Avatar
                          mr='10px'
                          size='sm'
                          name={task.people}
                          bgColor='blackAlpha.500'
                          color='white'
                        />
                      )}
                      {task?.taskLeader?.profile_picture && (
                        <Image
                          mr='10px'
                          borderRadius='full'
                          boxSize='32px'
                          src={`https://aaliz317.twic.pics/${task.taskLeader.profile_picture}`}
                          alt={` Picture of {item.taskLeader.given_name}`}
                        />
                      )}
                      {task.people}
                    </Flex>
                  </Box>
                  <Box color={colorMode === "light" ? "gray.500" : "white"}>
                    {task.dateJs
                      ? dayjs(task.dateJs).format("DD/MM/YYYY")
                      : "-"}
                  </Box>
                  <Flex>
                    {task.status && (
                      <Badge
                        variant='solid'
                        borderRadius='full'
                        px='10px'
                        py='5px'
                        // bgColor={statusSwitch(task.status.value)}
                        bgColor='whiteAlpha.100'>
                        {statusEmoji(task.status.value)}
                        {task.status.label}
                      </Badge>
                    )}
                    {admin && (
                      <Text
                        color={colorMode === "light" ? "gray.500" : "white"}
                        mx='4px'
                        fontSize='xs'>
                        {task.statusDate &&
                          task.category.value != "interview" &&
                          task.category.value != "shooting" &&
                          (deliveryTime > 0 ? "+" : "") + deliveryTime + "J"}
                      </Text>
                    )}
                  </Flex>
                  <Flex>
                    {task.validation && (
                      <Badge
                        variant='solid'
                        borderRadius='full'
                        px='10px'
                        py='5px'
                        // bgColor={validationSwitch(task.validation.value)}
                        bgColor='whiteAlpha.100'>
                        {validationEmoji(task.validation.value)}{" "}
                        {task.validation.label}
                      </Badge>
                    )}
                    {admin && (
                      <Text
                        color={colorMode === "light" ? "gray.500" : "white"}
                        mx='4px'
                        fontSize='xs'>
                        {task.validationDate &&
                          task.category.value != "interview" &&
                          task.category.value != "shooting" &&
                          (validationTime > 0 ? "+" : "") +
                            validationTime +
                            "J"}
                      </Text>
                    )}
                  </Flex>
                  {admin && (
                    <Flex
                      onClick={(e) => openTask(e, task)}
                      color='gray.500'
                      alignItems='center'
                      _hover={{ opacity: "0.4" }}
                      ml='10px'>
                      <HiPencilAlt />
                    </Flex>
                  )}
                </TableRow>
              );
            })}
        {/* <Flex justifyContent='center' mt='20px'>
          {tasksLimit && (
            <Button
              onClick={() => {
                if (limit < tasksList.length) {
                  setLimit(tasksList.length);
                } else {
                  setLimit(tasksLimit);
                }
              }}>
              {limit < tasksList.length
                ? `Show all ${tasksList.length} tasks (${title.toLowerCase()})`
                : `Show only ${tasksLimit} tasks (${title.toLowerCase()})`}
            </Button>
          )}
        </Flex> */}
      </Box>
    )
  );
}

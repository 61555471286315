import { API } from "aws-amplify";

export default async function fetchUsers(display = "all") {
  //   const url = `/people/all?display=${display}}`;
  const url = `/people/all`;

  return await API.get("kronik", url).then((response) => {
    console.log("response all API", response);
    return response;
  });
}

import React from "react";
import { Flex, Box, useColorMode } from "@chakra-ui/react";
import CardTitle from "./CardTitle";

export default function Card({ title, isActive, children, p="20px", bgColor="white" }) {
  const { colorMode } = useColorMode();

  return (
    <Box>
      {title && <CardTitle title={title} isActive={isActive} />}
      <Flex
        borderWidth={colorMode === "light" ? "1px" : "0px"}
        borderColor="#F0F0F0"
        boxShadow="sm"
        rounded="xl"
        // borderColor="#e2e8f0"
        mt="5px"
    
        bg={colorMode === "light" ? bgColor : "#232323"}
        // my="6"
        p={p}
        
        direction="column"
      >
        {children}
      </Flex>
    </Box>
  );
}

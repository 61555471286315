
import { API } from "aws-amplify";

export default async function fetchFreelances() {
  let url = `/freelance/list`;
  //   console.log("projectId", projectId);
  return API.get("kronik", url, {})
}






import React, { useState, useEffect, useReducer } from "react";
import { useUser } from "../../libs/UserContext";
import { initialState, reducer } from "./reducer";
import { customStyles, darkStyles } from "../../styles/customStyles";
import {statusOptions} from "../../data/jobData"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Textarea,
  NumberInput,
  NumberInputField,
  useColorMode,
  GridItem,
  Grid,
  Switch,
} from "@chakra-ui/react";
import Select from "react-select";
import axios from "axios";
import fetchFreelances from "../../pages/freelances/api";
import { categoryOptions } from "../../data/taskData";
import { createJob } from "./api";
// import { createProject, getClients } from "./api";

export default function NewJob(props) {
  const { colorMode } = useColorMode();

  const { isOpen, onClose, reloadData, setReloadData,  email,
    projectId,
    clientId,
    clientName,
    video,
    videoName,
    projectName,
    // title,
    // brief,
    // pricing,
    // ipAdress,
    // note,
    // category,
    // rating,
    // deliverables,
    // paymentStatus,
    // internalFeedback,
    // clientFeedback,
   
   } = props
  const { admin } = useUser();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [clients, setClients] = useState("");
const[freelances, setFreelances] = useState("")
  const [ip, setIP] = useState('');

  //creating function to load ip address from the API
  const getIp = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    // console.log("ipAdress data", res.data);
    setIP(res.data.IPv4)
  }
  

  useEffect( () => {
    //passing getData method to the lifecycle method
    fetchFreelances().then((response) => {
    setFreelances(
      // response
      response.sort(function (a, b) {
        if (a.family_name?.toLowerCase() < b.family_name?.toLowerCase()) {
          return -1;
        }
        if (a.family_name?.toLowerCase() > b.family_name?.toLowerCase()) {
          return 1;
        }
        return 0;
      }).map((item)=> {
        return {
          label:item.given_name + "  " + item.family_name,
          value:item.email
        }
      })
    )
    })
    getIp()


  }, [])



  // email,
  // projectId,
  // clientId,
  // video,
  // title,
  // brief,
  // pricing,
  // ipAdress,
  // note,
  // category,
  // rating,
  // deliverables,
  // paymentStatus,
  // internalFeedback,
  // clientFeedback,
  // name,
  // role,
  function save() {
    // console.log("freelances list", freelances)
    // console.log("freelance", state.freelanceEmail )
    // console.log("projectId", projectId)
    // console.log("projectName", projectName)
    // console.log("clientId", clientId)
    // console.log("video", video)
    // console.log("title", state.category?.label + " " + videoName)
    // console.log("brief", state.brief)
    // console.log("pricing", state.pricing)
    // console.log("category", state.category)
    // console.log("role", state.role)

    // console.log("ipAdress", ip)
    // console.log("note", state.note)
    // console.log("rating", state.rating)
    // console.log("deliverables", state.deliverables)
    // console.log("paymentStatus", state.paymentStatus)
    // console.log("internalFeedback", state.internalFeedback)
    // console.log("clientFeedback", state.clientFeedback)
    // console.log("name", name)
    dispatch({ type: "save" });
    createJob( 
      state.freelanceEmail?.value,
      projectId,
      projectName,
      clientId,
      clientName,
      video,
      state.category?.label + " " + videoName,
      state.brief,
      state.pricing,
      state.category,
      state.role,
      state.status,
      state.sendEmail,
      ).then((response) => {
        
      dispatch({ type: "success" });
      onClose();
      setReloadData(!reloadData);
    });
  }

  const DateForm = () => {
    return (
      <DatePicker
        // className="date-picker"
        wrapperClassName={
          colorMode === "light"
            ? "date-picker-wrapper-light"
            : "date-picker-wrapper-dark"
        }
        
        // calendarClassName="dark-test"
        popperClassName={colorMode === "light" ? "ligth-picker" : "dark-picker"}
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        dateFormat="dd/MM/yyyy"
        placeholderText="Choisir une date"
        isClearable
        calendarStartDay={1}
        selected={state.birthDate}
        onChange={(date) =>
          dispatch({
            type: "field",
            payload: {
              fieldName: "birthDate",
              value: date,
            },
          })
        }
        // locale="fr"
      />
    );
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="outside"
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Propose a new job</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
        

         {/* Freelances */}
         <Box my="4">
              <FormLabel>Freelances</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                placeholder="Select a freelance"
                value={state.freelanceEmail}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "freelanceEmail",
                      value: e,
                    },
                  });
                }}
                options={freelances}
              />
            </Box>
              

                 {/* Brief*/}
                 <Box my="4">
              <FormLabel>Brief</FormLabel>
              <Textarea
                variant="filled"
                placeholder="Write a quick brief"
                size="md"
                resize="vertical"
                value={state.brief}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "brief",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Box>


            {/* Status */}
            <Box my="4">
              <FormLabel>Status</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                placeholder="Select a status"
                value={state.status}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "status",
                      value: e,
                    },
                  });
                }}
                options={statusOptions}
              />
            </Box>

            
               {/* Category */}
               <Box my="4">
              <FormLabel>Category</FormLabel>
              <Select
                styles={colorMode === "light" ? customStyles : darkStyles}
                isClearable
                placeholder="Select a Category"
                value={state.category}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "category",
                      value: e,
                    },
                  });
                }}
                options={categoryOptions}
              />
            </Box>
           
        
           {/* Pricing */}
           <Box my="4">
              <FormControl id="pricing">
                <FormLabel>Pricing</FormLabel>
                <NumberInput max={100000} min={1} variant="filled">
                  <NumberInputField
                    placeholder="Add a price"
                    value={state.pricing}
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "pricing",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                  {/* <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper> */}
                </NumberInput>
              </FormControl>
            </Box>

            {/* Send email */}
           
           <Box my="4">
           <FormLabel>Send email</FormLabel>

              <Switch
                isChecked={state.sendEmail}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "sendEmail",
                      value: e.currentTarget.checked,
                    },
                  });
                }}
              >
                {state.sendEmail ? "Send email" : "Don't send email"}
              </Switch>
            </Box>



       

           

            

            
            


           


            {/* Pricing */}
            {/* <Box my="4">
              <FormControl id="pricing">
                <FormLabel>Total HT</FormLabel>
                <NumberInput max={1000000} min={10} variant="filled">
                  <NumberInputField
                    placeholder="Add a number"
                    value={state.pricing}
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "pricing",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </NumberInput>
              </FormControl>
            </Box> */}

          

            </ModalBody>

          <ModalFooter>
            <Button
              bgColor="primary"
              color="white"
              mr={3}
              onClick={save}
              isLoading={state.isLoadingOne}
              loadingText="Loading"
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

import React, { useState } from "react";
import { Box, Text, useColorMode } from "@chakra-ui/react";
import CardTitle from "../../components/card/CardTitle";

export default function Brief(props) {
  const { colorMode } = useColorMode();

  const { projectData } = props;
  // console.log("projectData", projectData);
  return (
    <Box pt='20px'>
      <CardTitle title='brief' isActive='false' />

      <Text
        whiteSpace='pre-wrap'
        color={colorMode === "light" ? "gray.500" : "white"}>
        {projectData && projectData.description}
      </Text>
    </Box>
  );
}

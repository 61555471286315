import React, { useState, useEffect, useReducer } from "react";
import { useUser } from "../../libs/UserContext";
import { initialState, reducer } from "./reducer";
import { customStyles, darkStyles } from "../../styles/customStyles";
import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Textarea,
  NumberInput,
  NumberInputField,
  useColorMode,
  Switch,
} from "@chakra-ui/react";
import Select from "react-select";
import axios from "axios";
import { createContact, deleteContact, updateContact } from "./api";
// import { createProject, getClients } from "./api";

export default function EditContact(props) {
  const { colorMode } = useColorMode();

  const {
    isOpen,
    onClose,
    reloadData,
    setReloadData,
    client,
    clientId,
    contact,
  } = props;
  const { admin } = useUser();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [deleteButton, setDeleteButton] = useState(false);
  const [isLoadingOne, setIsLoadingOne] = useState(false);
  const [isLoadingTwo, setIsLoadingTwo] = useState(false);

  useEffect(() => {
    try {
      // console.log("contact", contact);
      dispatch({
        type: "previousData",
        payload: {
          email: contact ? contact.email : "",
          given_name: contact ? contact.given_name : "",
          family_name: contact ? contact.family_name : "",
          address: contact ? contact.address : "",
          client: contact ? contact.client : clientId,
          phone: contact ? contact.phone : "",
          role: contact ? contact.role : "",
          job: contact ? contact.job : "",
        },
      });
    } catch (e) {
      console.log(e.message);
    }
  }, [contact]);

  function save() {
    dispatch({ type: "save" });
    // console.log("client", client)
    // console.log("email", state.email)
    // console.log("given_name", state.given_name)
    // console.log("family_name", state.family_name)
    // console.log("address", state.address)
    // console.log("phone", state.phone)
    // console.log("job", state.job)
    updateContact(
      contact._id,
      state.email,
      state.given_name,
      state.family_name,
      state.address,
      client,
      state.phone,
      state.job
    ).then((response) => {
      dispatch({ type: "success" });
      onClose();
      setReloadData(!reloadData);
    });
  }

  function remove() {
    dispatch({ type: "delete" });

    // console.log("dataDeleted");
    deleteContact(contact._id).then((response) => {
      dispatch({ type: "success" });
      // setDataDeleted(response);
      setDeleteButton(false);
      setConfirmMessage("");
      onClose();
      setReloadData(!reloadData);
    });
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior='outside'
        size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit contact</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Name */}
            <Box my='4'>
              <FormControl id='firstName'>
                <FormLabel>First name</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add the first name'
                  value={state.given_name}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "given_name",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            <Box my='4'>
              <FormControl id='lastName'>
                <FormLabel>Last name</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add the last name'
                  value={state.family_name}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "family_name",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/* Email */}
            <Box my='4'>
              <FormControl id='email'>
                <FormLabel>Email</FormLabel>
                <Input
                  variant='filled'
                  type='email'
                  placeholder='Enter an email adress'
                  value={state.email}
                  isRequired
                  onChange={(e) => {
                    dispatch({
                      type: "field",
                      payload: { fieldName: "email", value: e.target.value },
                    });
                  }}
                />
              </FormControl>
            </Box>

            {/* Job */}
            <Box my='4'>
              <FormControl id='job'>
                <FormLabel>Job</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add the job'
                  value={state.job}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "job",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/* Adress*/}
            <Box my='4'>
              <FormLabel>Adress</FormLabel>
              <Textarea
                variant='filled'
                placeholder='Adress'
                size='md'
                resize='vertical'
                value={state.address}
                onChange={(e) => {
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "address",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Box>

            {/* Phone */}
            <Box my='4'>
              <FormControl id='phone'>
                <FormLabel>Phone</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add the phone number'
                  value={state.phone}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "phone",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter>
            {admin && !deleteButton && (
              <Button mr={3} onClick={() => setDeleteButton(true)}>
                Remove
              </Button>
            )}
            {!deleteButton && (
              <Button
                bgColor='primary'
                color='white'
                mr={3}
                onClick={save}
                isLoading={state.isLoadingOne}
                loadingText='Loading'>
                Save
              </Button>
            )}
            {deleteButton && (
              <Box mr='20px'>
                <FormControl id='confirmation'>
                  <Input
                    variant='filled'
                    placeholder='Enter REMOVE to confirm'
                    value={confirmMessage}
                    onChange={(e) => setConfirmMessage(e.target.value)}
                  />
                </FormControl>
              </Box>
            )}
            {deleteButton && (
              <Button mr={3} onClick={() => setDeleteButton(false)}>
                Cancel
              </Button>
            )}
            {confirmMessage === "REMOVE" && deleteButton && (
              <Button
                colorScheme='red'
                mr={3}
                onClick={() => remove()}
                isLoading={isLoadingTwo}
                loadingText='Loading'>
                Remove
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

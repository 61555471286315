import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import SideBar from "./SideBar";
import { HiPencilAlt } from "react-icons/hi";
import { Updating } from "../../components/notification/Updating";
import RightBar from "./RightBar";
import { EditFreelance } from "../../components/freelance/EditFreelance";
import Brief from "./Brief";
import TasksList from "./TasksList";
import { EditJob } from "../../components/job/EditJob";
import fetchJob, { fetchTasks } from "./api";
import { BsFolderSymlink } from "react-icons/bs";
import VideoLink from "./VideoLink";
import MainColumn from "../../components/column/MainColumn";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";

export function Main({ videoData, job, isLoading }) {
  let { freelanceId, jobId } = useParams();
  //   const [isLoading, setIsLoading] = useState(false);
  const { admin } = useUser();
  // console.log("job", job);
  //   const [reloadData, setReloadData] = useState(false);
  //   const [tasks, setTasks] = useState("");
  //   const [videoData, setVideoData] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  //   const [job, setJob] = useState("");

  return (
    <MainColumn>
      <Box mt='0px'>
        <Box>
          <Box py='0px' mt='20px' rounded='md' px='20px'>
            <Box>
              <Brief
                jobData={job}
                projectId={job?.project}
                videoId={job?.video}
              />

              {videoData && (
                <TasksList tasks={videoData} projectData={videoData?.project} />
              )}

              <VideoLink
                jobData={job}
                projectId={job?.project}
                videoId={job?.video?._id}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </MainColumn>
  );
}

import React from "react";
import {
  Flex,
  Text,
  Box,
  Button,
  Heading,
  Grid,
  Badge,
  SimpleGrid,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  useDisclosure,
} from "@chakra-ui/react";
import { statusSwitch } from "../../data/projectData";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import Card from "../../components/card";
import ButtonColor from "../../components/button/ButtonColor";
import UpdateStatus from "../../components/project/UpdateStatus";
import { IoFlash } from "react-icons/io5";

export default function OrderSummary(props) {
  const { colorMode } = useColorMode();
  const { jobData, admin, freelanceId, setReloadData, isLoading } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex flexDirection='column' padding='20px'>
      <Box>
        <SimpleGrid
          columns={{
            base: 1,
            md: 1,
          }}
          spacing='20px'>
          <Grid direction='column' gap='20px'>
            <Flex justifyContent='space-between'>
              <Text
                fontSize='sm'
                color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                Status
              </Text>
              <Text>
                <Badge
                  fontSize='xs'
                  variant='solid'
                  colorScheme={statusSwitch(jobData?.status?.value)}>
                  {jobData?.status ? jobData?.status.label : ""}
                </Badge>
              </Text>
            </Flex>

            <Flex justifyContent='space-between'>
              <Text
                fontSize='sm'
                color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                Pricing
              </Text>
              <Text>{jobData?.pricing && `${jobData?.pricing} €`}</Text>
            </Flex>

            {/* Cancel */}
          </Grid>
        </SimpleGrid>
      </Box>
      {/* {admin && !isLoading && (
        <ButtonColor
          title={
            <Flex alignItems="center">
              <Box mr="5px">
                <IoFlash />{" "}
              </Box>
              Update status
            </Flex>
          }
          click={() => onOpen()}
        />
      )} */}
      {/* <UpdateStatus
        isOpen={isOpen}
        onClose={onClose}
        admin={admin}
        projectId={projectId}
        projectData={projectData}
        setReloadData={setReloadData}
      /> */}
    </Flex>
  );
}

import { Box, Grid, useColorMode } from "@chakra-ui/react";
import React from "react";

export default function TableRow({ click, gridCol, children, key }) {
  const { colorMode } = useColorMode();
  return (
    <Box
      key={key}
      onClick={click}
      _hover={{
        cursor: "pointer",
        opacity: "0.8",
      }}
        // colorMode === "light"
        //   ? { bg: "#edf2f7", cursor: "pointer" }
        //   : { bg: "#4F5054", cursor: "pointer" }
          
      
    >
      <Grid
        gridTemplateColumns={gridCol}
        shadow="sm"
        borderWidth={colorMode === "light" ? "1px" : "0px"}
        borderColor="#F0F0F0"
        bg={colorMode === "light" ? "white" : "#232323"}
        my="4px"
        rounded="xl"
        
        alignItems="center"
        p="3"
        mb="5px"
        _hover={{
          bgColor: colorMode === "light" ? "gray.50" : "whiteAlpha.100",
          shadow: "md",
        }}
      >
        {children}
      </Grid>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Grid,
  Flex,
  Box,
  Button,
  Text,
  Heading,
  useColorMode,
  Spacer,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import Day from "./Day";
import "dayjs/locale/fr";
import CardTitle from "../../card/CardTitle";
import { MiniHeader } from "../../header/MiniHeader";
import MainColumn from "../../column/MainColumn";
dayjs.locale("fr");

export default function WeekCalendar(props) {
  const { tasks, monthView, setMonthView } = props;
  const [selectedWeek, setSelectedWeek] = useState(dayjs().startOf("week"));
  const [currenWeek, setCurrentWeek] = useState(getWeek(selectedWeek));
  const { colorMode } = useColorMode();

  useEffect(() => {
    setCurrentWeek(getWeek(selectedWeek));
  }, [selectedWeek]);

  function getWeek(date) {
    const month = Math.floor(dayjs(selectedWeek).month());
    const year = dayjs(selectedWeek).year();
    const firstDayOfTheweek = 1 - dayjs().day();

    let currentWeekCount = parseInt(
      dayjs(date).locale("fr").startOf("week").format("D") - 1
    );

    const daysMatrix = new Array(7).fill(null).map(() => {
      currentWeekCount++;

      return dayjs(new Date(year, month, currentWeekCount));
    });

    return daysMatrix;
  }

  function todayWeek() {
    setSelectedWeek(dayjs().startOf("week"));
  }

  function previousWeek() {
    setSelectedWeek(dayjs(selectedWeek).subtract(1, "week").startOf("week"));
  }

  function nextWeek() {
    setSelectedWeek(dayjs(selectedWeek).add(1, "week").startOf("week"));
  }

  function changeView() {
    setMonthView(true);
  }

  return (
    <Box w='100%'>
      <MainColumn>
        <MainColumn>
          <Flex
            justifyContent='space-between'
            mx='20px'
            mt='20px'
            alignItems='center'>
            <CardTitle
              title={dayjs(selectedWeek).startOf("week").format(" MMMM YYYY")}
              isActive
            />
            <Spacer />
            <Box mr='0px'>
              <Button onClick={changeView} mr='20px'>
                Month view
              </Button>
              <Button onClick={previousWeek} mr='10px'>
                <ChevronLeftIcon />
              </Button>
              <Button onClick={todayWeek} mr='10px'>
                Today
              </Button>
              <Button onClick={nextWeek}>
                <ChevronRightIcon />
              </Button>
            </Box>
          </Flex>

          <Box px='20px' pt='20px'>
            <Grid
              templateColumns='repeat(7, 1fr)'
              borderWidth={colorMode === "light" ? "1px" : "0px"}
              borderColor={colorMode === "light" ? "F0F0F0" : "gray.900"}
              gridGap='1px'
              backgroundColor={colorMode === "light" ? "gray.200" : "black.50"}
              rounded='xl'
              boxShadow='sm'>
              {currenWeek.map((day, idx) => (
                <Day day={day} tasks={tasks} key={idx} />
              ))}
            </Grid>
          </Box>
        </MainColumn>
      </MainColumn>
    </Box>
  );
}

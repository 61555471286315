import React, { useState } from "react";
import { Box, Flex, Link, Text, useColorMode } from "@chakra-ui/react";
import Card from "../../components/card";
import { BsFolderSymlink } from "react-icons/bs";

export default function VideoLink(props) {
  const { colorMode } = useColorMode();
  const { jobData, projectId, videoId } = props;
  // console.log("projectData", projectData);
  return (
    <Box mt='40px'>
      {/* <Card title="Video" isActive={true}> */}

      {videoId && (
        <Link
          href={`/projects/${videoId}/quotes/${projectId}`}
          _hover={{
            textDecor: "underline",
            color: colorMode === "light" ? "gray.500" : "white",
          }}>
          <Flex
            mt='10px'
            color={colorMode === "light" ? "gray.500" : "white"}
            pb='4'
            alignItems='center'
            justifyContent='center'>
            <Box mr='10px'>
              <BsFolderSymlink />
            </Box>
            Go to the project :{" "}
            {jobData ? jobData.video.title : "the video planning"}
          </Flex>
        </Link>
      )}

      {/* </Card> */}
    </Box>
  );
}

import { Badge, Box, Button, Flex, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { HiPlusCircle } from "react-icons/hi";
import CardTitle from "../../components/card/CardTitle";
import NewInvoicedVideo from "../../components/invoice/NewInvoicedVideo";
import TableRow from "../../components/table/TableRow";

export default function ProjectsList(
  { projects = [{}, {}] },
  admin,
  reloadData,
  setReloadData
) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box mt='40px'>
      <CardTitle title='Projects' isActive={true} />
      {projects &&
        projects
          .sort((a, b) => {
            if (a.createdAt < b.createdAt) {
              return 1;
            }
            if (a.createdAt > b.createdAt) {
              return -1;
            } else return 0;
          })

          .map((item, index) => {
            // const Link = `/projects/${videoId}/quotes/${projectId}`;
            const Link = ``;
            return (
              <TableRow
                key={index}
                // click={(e) => OpenVideo(e, Link)}
                gridCol={"6fr  2fr 2fr 2fr"}>
                <Box color='white'>
                  {/* {`${item?.title} - ${item?.clientName}`}{" "} */}
                </Box>
                {/* <Box color={colorMode === "light" ? "gray.500" : "white"}> {item?.category?.label} </Box> */}
                <Box>
                  <Badge
                    fontSize='xs'
                    variant='solid'
                    // colorScheme={newStatusSwitc(
                    //   projectData && item.newStatus && item.newStatus.value
                    // )}
                  >
                    {/* {item.newStatus && item.newStatus.label} */}
                  </Badge>
                </Box>
                <Box color='white'>
                  {" "}
                  {/* {item?.pricing && item?.pricing + "€"}{" "} */}
                </Box>
                <Box color='white'>
                  {" "}
                  {/* {item?.createdAt && dayjs(item?.createdAt).format("DD/MM/YYYY")} */}
                </Box>
              </TableRow>
            );
          })}
      {admin && (
        <Box py='10px'>
          <Button
            w='100%'
            onClick={() => onOpen()}
            border='1px solid'
            borderColor='whiteAlpha.300'
            rounded='lg'
            bg='#1C1C1C'>
            <Flex alignItems='center'>
              <Box mr='5px'>
                <HiPlusCircle />
              </Box>{" "}
              Select a project
            </Flex>
          </Button>
        </Box>
      )}

      <NewInvoicedVideo
        isOpen={isOpen}
        onClose={onClose}
        reloadData={reloadData}
        setReloadData={setReloadData}
      />
    </Box>
  );
}

import { Box, Button, Flex, Spacer } from "@chakra-ui/react";
import React, { useRef } from "react";
import { HiPencilAlt } from "react-icons/hi";
import { IoCloudDownload, IoCloudUpload } from "react-icons/io5";
import { v4 as uuidv4 } from "uuid";

import {
  MdLink,
  MdLinkOff,
  MdOutlineCloseFullscreen,
  MdOutlineOpenInFull,
} from "react-icons/md";
import CardTitle from "../../components/card/CardTitle";
import { MiniHeader } from "../../components/header/MiniHeader";
import useFileDownloader from "../../hook/useFileDownloader";
import { useUser } from "../../libs/UserContext";
import { downloadFile, downloadFiles, isAuthorized } from "./api";

export default function Header(props) {
  const { admin, freelance } = useUser();
  // const [download, downloaderComponent] = useFileDownloader();

  const {
    taskData,
    isLoading,
    onOpenEdit,
    setLargePlayer,
    largePlayer,
    handleUpload,
    uploadFiles,
    currentFile,
    preselectedFiles,
    projectId,
    download,
  } = props;

  const copyToClipboard = (e) => {
    const url = `https://app.kronik.com/review/${taskData._id}/project/${taskData.project._id}`;
    navigator.clipboard.writeText(url);
  };
  // console.log("taskData", taskData);

  const handleDownload = (e) => {
    // console.log("handleDownload", currentFile);
    // console.log("currentFile.name", currentFile.name);
    // console.log("admin", admin);
    // console.log("projectId", projectId);
    // console.log("currentFile.bucketName", currentFile.bucketName);
    downloadFile(
      currentFile.name,
      admin,
      projectId,
      currentFile.bucketName
    ).then((response) => {
      // console.log("test");
      const link = document.createElement("a");
      link.download = `${currentFile.originalName}`;
      // link.setAttribute("download", "test.mp4");

      link.href = response.preSignedUrl;
      // link.setAttribute("download", `${currentFile.originalName}`);

      document.body.appendChild(link);
      // console.log("link", link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  const handleMultiDownload = (e) => {
    // console.log("handleMultiDownload");
    // console.log("preselectedFiles", preselectedFiles);
    // console.log("taskData", taskData);
    const filesNames = () => {
      // console.log("currentFile test");
      // return taskData?.files.map((file) => file.name)
      if (currentFile) {
        // console.log("currentFile", currentFile);
        return new Array(currentFile.name);
      } else if (preselectedFiles.length > 0) {
        // console.log("preselectedFiles", preselectedFiles);
        return preselectedFiles.map((file) => file.name);
      } else {
        return taskData?.files
          .filter((item) => item.status !== "deleted")
          .map((file) => file.name);
      }
    };

    const orignalName = () => {
      // return taskData?.files.map((file) => file.name)
      // console.log("taskData?.files", taskData?.files);
      if (currentFile) {
        // console.log("orignalName", new Array(currentFile.originalName));
        return new Array(currentFile.originalName);
      } else if (preselectedFiles.length > 0) {
        // console.log("preselectedFiles2", preselectedFiles);
        return preselectedFiles.map((file) => file.originalName);
      } else {
        return taskData?.files
          .filter((item) => item.status !== "deleted")
          .map((file) => file.originalName);
      }
    };

    isAuthorized(projectId, admin).then((response) => {
      // response.authorized && console.log("downloadFiles");
      downloadFiles(filesNames(), admin, projectId).then((response) => {
        // console.log("response", response);

        const newArray =
          response &&
          response?.data?.preSignedUrl?.map((data, index) => {
            // const newOriginalName = taskData?.files.filter((item) => item.name === data.name)

            return {
              url: data,
              id: uuidv4(),
              originalName: orignalName()[index],
            };
          });

        // console.log("newArray", newArray);
        download(newArray);
        // download(response.data.preSignedUrl)
        // setFiles(response.data.preSignedUrl)
        // const link = document.createElement("a");
        // link.href = response.preSignedUrl;
        // document.body.appendChild(link);
        // link.click();
        // link.parentNode.removeChild(link);
      });
    });
  };
  return (
    <MiniHeader>
      <Flex justifyContent='space-between'>
        <Flex>
          <Flex direction='column'>
            {/* <Heading as='h1' fontSize='xl'> */}
            <Flex alignItems='center'>
              {/* {taskData?.title} */}
              <CardTitle title={taskData?.title} isActive='false' />
              {admin && !isLoading && (
                <Button
                  aria-label='Edit'
                  variant='outline'
                  rounded='full'
                  size='sm'
                  onClick={() => onOpenEdit()}
                  mx='10px'>
                  <HiPencilAlt />
                </Button>
              )}
            </Flex>
            {/* </Heading> */}
          </Flex>
        </Flex>
      </Flex>
      <Spacer />

      <Flex alignItems='center'>
        {/* {preselectedFiles.length > 0 && 
              <Box fontSize="md" fontWeight="normal" >
                {preselectedFiles.length } {preselectedFiles.length === 1 ? "video selected" : "videos selected"}
              </Box>
            } */}
        <Box ml='10px'>
          <Button
            rounded='full'
            variant='outline'
            mx='10px'
            size='sm'
            onClick={(e) => copyToClipboard(e)}>
            {taskData.sharing ? <MdLink /> : <MdLinkOff />}
          </Button>
        </Box>
        {taskData?.files?.length >= 1 && (
          <Box ml='0px'>
            <Button
              rounded='full'
              variant='outline'
              mx='10px'
              size='sm'
              onClick={() => setLargePlayer(!largePlayer)}>
              {largePlayer ? (
                <MdOutlineCloseFullscreen />
              ) : (
                <MdOutlineOpenInFull />
              )}
            </Button>
          </Box>
        )}
        <Box ml='10px'>
          {(admin || freelance) && !isLoading && (
            <Button
              rounded='full'
              // isDisabled={uploadFiles.length > 0}
              variant='outline'
              size='sm'
              onClick={() => handleUpload()}>
              <IoCloudUpload />
            </Button>
          )}
        </Box>

        <Box ml='10px'>
          {!isLoading && (
            <Button
              rounded='full'
              isDisabled={
                uploadFiles.length > 0 || taskData?.files?.length === 0
              }
              variant='outline'
              size='sm'
              onClick={() =>
                // currentFile ? handleDownload() : handleMultiDownload()
                currentFile ? handleMultiDownload() : handleMultiDownload()
              }>
              <IoCloudDownload />
            </Button>
          )}
        </Box>
      </Flex>
    </MiniHeader>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  Link,
  useColorMode,
  Checkbox,
  list,
  Text,
  Img,
  Grid,
  GridItem,
  Spacer,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import { Link as RouteLink } from "react-router-dom";
import { statusSwitch, validationSwitch } from "../../data/taskData";
import { FaFile, FaFileVideo } from "react-icons/fa";
import { BsFillPlayBtnFill } from "react-icons/bs";

// import Upload from "./Upload";
import Card from "../../components/card";
// import { downloadFile } from "./api";
import TableRow from "../../components/table/TableRow";
import CardTitle from "../../components/card/CardTitle";
import { VideoPlayer } from "../player/VideoPlayer";
import Player from "./Player";
import { MdOutlineCloseFullscreen, MdOutlineOpenInFull } from "react-icons/md";
import { useUser } from "../../libs/UserContext";
import { IoArrowBackOutline, IoCloudUpload } from "react-icons/io5";

export default function Files({
  // link,
  // taskData,
  // user,
  files,
  // projectId,
  // setReloadData,
  // reloadData,
  setIsLoading,
  isLoading,
  clientId,
  // setComments,
  // reloadComments,
  // setReloadComments,
  timecode,
  setTimecode,
  // setLargePlayer,
  // largePlayer,
  // handleUpload,
  // hiddenFileInput,
  // setActive
}) {
  const { colorMode } = useColorMode();
  const { uploadFiles, setUploadFiles } = useUser();

  // console.log("taskData", taskData);
  // console.log("user", user);
  let history = useHistory();
  const [listUrl, setListUrl] = useState([]);
  const [playing, setPlaying] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  // const handleDownload = (file) => {
  //   // console.log("download", file);
  //   downloadFile(file.name, admin, projectId).then((response) => {
  //     const link = document.createElement("a");
  //     link.href = response.preSignedUrl;
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //   });
  // };

  useEffect(() => {
    if (files?.length === 0) {
      setCurrentPlayer(null);
      // setActive("Order")
    }

    if (files?.length === 1) {
      setCurrentPlayer(0);
      setCurrentFile(files[0]);
    }

    if (files?.length > 1) {
      setCurrentPlayer(1);
    }
  }, [files]);

  function selectFile(e, file) {
    setCurrentFile(file);
    // console.log("selected file", file)
  }

  // useEffect(() => {
  //   currentFile ?   setActive("Comments") :   setActive("Order")
  // }, [currentFile])

  return (
    <>
      <Box>
        <Flex alignItems='center' mb='10px' minH='64px'>
          <Box
            _hover={files?.length > 1 && currentFile && { cursor: "pointer" }}
            onClick={
              files?.length > 1 && currentFile
                ? () => setCurrentFile(null)
                : null
            }>
            <CardTitle
              title={
                files && files.length > 1 && currentFile ? (
                  <Flex>
                    <IoArrowBackOutline /> back
                  </Flex>
                ) : (
                  files &&
                  `${
                    files &&
                    files.filter((file) => file.status !== "deleted").length
                  }  Files`
                )
              }
            />
          </Box>
          <Spacer />

          <Flex alignItems='center'>
            {/* <Box  ml="10px" pb="6">
              {files?.length >1  && currentFile && <Button variant='outline' size="sm"  onClick={() => setCurrentFile(null)}> All files</Button>}
            </Box> */}
            {/* {files?.length >=1 && 
            <Box ml="10px" pb="6">
            <Button  rounded="full"  variant='outline'  mx="10px"  size="sm" onClick={() => setLargePlayer(!largePlayer)}>{largePlayer ? <MdOutlineCloseFullscreen/>   : <MdOutlineOpenInFull/>}</Button>
            </Box>  
            } */}
            {/* < Box  ml="10px" pb="6">
              {   !isLoading && (

                <Button   rounded="full"  isDisabled={uploadFiles.length > 0} variant='outline'  size="sm" onClick={() => handleUpload()}><IoCloudUpload/></Button>
              )}
            </Box> */}
          </Flex>
        </Flex>

        {/* <Text>{currentPlayer}</Text> */}
        {files && currentFile && (
          // files.length === 1

          <Player
            timecode={timecode}
            setTimecode={setTimecode}
            // index={index}
            file={currentFile}
            clientId={clientId}
            // setComments={setComments}
            // reloadComments={reloadComments}
            // setReloadComments={setReloadComments}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            currentFile={currentFile}
          />
        )}

        <Grid templateColumns='repeat(3, 1fr)' gap='10px'>
          {files &&
            !currentFile &&
            files
              .filter((file) => file.status !== "deleted")
              .map((file, index) => {
                // console.log("file",file)
                return (
                  <GridItem className='group' overflow='hidden'>
                    <Img
                      transition='all 0.2s'
                      _groupHover={{
                        transform: "scale(1.05)",
                      }}
                      w='full'
                      h='full'
                      _hover={{ cursor: "pointer" }}
                      onClick={(e) => selectFile(e, file)}
                      key={index}
                      boxSize='260px'
                      objectFit='cover'
                      src={`https://image.mux.com/${file.muxPlaybackId}/thumbnail.jpg`}
                    />
                    <Flex
                      pt='10px'
                      fontSize='xs'
                      paddingRight='10px'
                      wordBreak='break-word'>
                      {file.originalName}
                    </Flex>
                  </GridItem>

                  //   <Player
                  //   timecode={timecode}
                  //   setTimecode={setTimecode}
                  //   index={index}
                  //   file={file}
                  //   clientId={clientId}
                  //   setComments={setComments}
                  //   reloadComments={reloadComments}
                  //   setReloadComments={setReloadComments}
                  //   isLoading={isLoading}
                  //   setIsLoading={setIsLoading}
                  // />
                );
              })}
        </Grid>
      </Box>
    </>
  );
}

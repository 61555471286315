import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import CardTitle from "../../components/card/CardTitle";
import MainColumn from "../../components/column/MainColumn";

export default function Main() {
  return (
    <MainColumn>
      <Flex direction='column' px='20px'>
        <Box pt='20px'>
          <CardTitle title='brief' isActive='false' />

          <Text whiteSpace='pre-wrap' color='white'>
            Text
          </Text>
        </Box>
      </Flex>
    </MainColumn>
  );
}

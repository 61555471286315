import React, { useState, useRef } from "react";
import {
  Box,
  Heading,
  Flex,
  useColorMode,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  useDisclosure,
  Avatar,
  Button,
  IconButton,
  Image,
} from "@chakra-ui/react";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import { HiPlusCircle } from "react-icons/hi";
import { FaClock, FaCheckSquare, FaTools } from "react-icons/fa";
import { differenceInBusinessDays } from "date-fns";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import { Link as RouteLink } from "react-router-dom";
import EditTask from "../../components/task/EditTask";
import NewTask from "../../components/task/NewTask";
import {
  statusEmoji,
  statusSwitch,
  validationEmoji,
  validationSwitch,
} from "../../data/taskData";
import { IoFlash } from "react-icons/io5";
import { HiPencilAlt } from "react-icons/hi";
import TableRow from "../../components/table/TableRow";
import CardTitle from "../../components/card/CardTitle";
import UpdateStatus from "../../components/task/UpdateStatus";
export default function TasksList(props) {
  const {
    videoData,
    projectId,
    videoId,
    projectName,
    videoName,
    admins,
    reloadData,
    setReloadData,
    admin,
    isLoading,
  } = props;
  let history = useHistory();

  const columnStage = [
    { title: "Preparation", value: ["narrative", "kickOff"] },
    { title: "Shooting", value: ["makingUp", "shooting", "interview"] },
    { title: "Preproduction", value: ["preProduction"] },
    {
      title: "Production",
      value: ["motionDesign", "subTitles", "production", "thumbnail"],
    },
  ];

  const { colorMode } = useColorMode();
  const [currentTask, setCurrentTask] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();

  const {
    isOpen: isOpenStatus,
    onOpen: onOpenStatus,
    onClose: onCloseStatus,
  } = useDisclosure();
  // console.log("videoData props", videoData);

  function openTask(e, task) {
    e.preventDefault();

    firstRenderStatus.current = true;
    firstRenderValidation.current = true;

    setCurrentTask(task);
    onOpenEdit();
    e.stopPropagation();
  }

  function clickStatus(e, task) {
    e.preventDefault();

    firstRenderStatus.current = true;
    firstRenderValidation.current = true;

    setCurrentTask(task);
    onOpenStatus();
    e.stopPropagation();
  }

  function openLink(e, link) {
    window.open(link, "_blank");
    e.stopPropagation();
  }

  function taskLink(e, link) {
    // console.log("link", link);
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  const firstRenderStatus = useRef(true);
  const firstRenderValidation = useRef(true);

  return (
    <>
      {columnStage.map((stage) => {
        const tasksbyStage = videoData?.tasks?.filter(
          (task) => stage?.value.indexOf(task?.category?.value) >= 0
        );

        const lastTask = tasksbyStage?.filter(
          (task) => task.dueDate !== null
        ).length;

        const date1 =
          tasksbyStage &&
          new Date(
            tasksbyStage.filter((task) => task.dueDate !== null)[
              lastTask - 1
            ]?.dueDate
          );
        const date2 =
          tasksbyStage &&
          new Date(
            tasksbyStage.filter((task) => task.dueDate !== null)[0]?.dueDate
          );
        const stageTime = differenceInBusinessDays(date1, date2) + 1;

        return (
          <Box mt='40px'>
            {tasksbyStage?.length > 0 && (
              <Flex alignItems='center'>
                {" "}
                <CardTitle title={stage.title} isActive={true} />
                <Box color='green.300' fontSize='sm'>
                  {videoData?.video?.newStatus?.value === "preparation" &&
                    stage.title === "Preparation" && (
                      // <FaCheckSquare />
                      <Box> ✅</Box>
                    )}
                  {videoData?.video?.newStatus?.value === "shooting" &&
                    stage.title === "Preparation" && (
                      // <FaCheckSquare />
                      <Box> ✅</Box>
                    )}
                  {videoData?.video?.newStatus?.value === "preProduction" &&
                    (stage.title === "Shooting" ||
                      stage.title === "Preparation") && (
                      // <FaCheckSquare />
                      <Box> ✅</Box>
                    )}
                  {videoData?.video?.newStatus?.value === "production" &&
                    (stage.title === "Preproduction" ||
                      stage.title === "Shooting" ||
                      stage.title === "Preparation") && (
                      // <FaCheckSquare />
                      <Box> ✅</Box>
                    )}
                  {(videoData?.video?.newStatus?.value === "approve" ||
                    videoData?.video?.newStatus?.value === "delivered") &&
                    (stage.title === "Production" ||
                      stage.title === "Preproduction" ||
                      stage.title === "Shooting" ||
                      stage.title === "Preparation") && (
                      // <FaCheckSquare />
                      <Box> ✅</Box>
                    )}
                </Box>
                <Box color='orange.300' fontSize='sm'>
                  {videoData?.video?.newStatus?.value.toLowerCase() ===
                    stage.title.toLowerCase() && (
                    // <FaTools />
                    <Box> 🚧</Box>
                  )}
                </Box>
                <Flex
                  color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}
                  fontSize='xs'
                  // pb='4'
                  alignItems='center'
                  ml='10px'>
                  <FaClock />
                  <Flex ml='5px'>{stageTime}J</Flex>
                </Flex>
              </Flex>
            )}

            {tasksbyStage &&
              tasksbyStage.map((item, index) => {
                let taskUrl = `/tasks/${item._id}/projects/${item.video}/quotes/${item.project}`;

                const deliveryTime =
                  item.statusDate &&
                  differenceInBusinessDays(
                    new Date(item.statusDate),
                    new Date(item.dueDate)
                  );
                const validationTime =
                  item.validationDate &&
                  differenceInBusinessDays(
                    new Date(item.validationDate),
                    new Date(item.statusDate ? item.statusDate : item.dueDate)
                  );

                return (
                  <TableRow
                    key={index}
                    gridCol={
                      admin
                        ? "1fr 3fr 1fr 4fr 3fr 2fr 3fr 1fr"
                        : " 3fr 1fr 4fr 2fr 2fr 2fr "
                    }
                    click={(e) => taskLink(e, taskUrl)}>
                    {admin && (
                      <IconButton
                        aria-label='Edit'
                        icon={<IoFlash />}
                        rounded='full'
                        size='sm'
                        onClick={(e) => clickStatus(e, item)}
                        mr='10px'
                      />
                    )}

                    <Box>
                      {/* <Flex alignItems="center"> */}
                      <Text
                        color={colorMode === "light" ? "gray.500" : "white"}>
                        {" "}
                        {item && item.title}{" "}
                      </Text>
                      {item.private ? (
                        <Badge variant='outline' ml='2' colorScheme='gray'>
                          Preview
                        </Badge>
                      ) : (
                        ""
                      )}
                      {/* </Flex> */}
                    </Box>
                    <Box>
                      {" "}
                      {item.link && (
                        <IconButton
                          colorScheme='gray'
                          aria-label='Search database'
                          icon={<ExternalLinkIcon />}
                          size='xs'
                          onClick={(e) => openLink(e, item.link)}
                        />
                      )}
                    </Box>
                    <Box color={colorMode === "light" ? "gray.500" : "white"}>
                      <Flex alignItems='center'>
                        {item.taskLeader &&
                          !item.taskLeader.profile_picture && (
                            <Avatar
                              bgColor='blackAlpha.500'
                              color='white'
                              mr='10px'
                              size='sm'
                              name={
                                item.taskLeader.given_name +
                                " " +
                                item.taskLeader.family_name
                              }
                            />
                          )}

                        {item?.taskLeader?.profile_picture && (
                          <Image
                            mr='10px'
                            borderRadius='full'
                            boxSize='32px'
                            src={`https://aaliz317.twic.pics/${item.taskLeader.profile_picture}`}
                            alt={` Picture of {item.taskLeader.given_name}`}
                          />
                        )}
                        {item.taskLeader &&
                          item.taskLeader.given_name +
                            " " +
                            item.taskLeader.family_name}
                      </Flex>
                    </Box>
                    <Box color={colorMode === "light" ? "gray.500" : "white"}>
                      {item ? dayjs(item.dueDate).format("DD/MM/YYYY") : "-"}
                    </Box>

                    <Flex>
                      {item.status && (
                        <Badge
                          variant='solid'
                          borderRadius='full'
                          px='10px'
                          py='5px'
                          // bgColor={statusSwitch(item.status.value)}
                          bgColor='whiteAlpha.100'>
                          {statusEmoji(item.status.value)}{" "}
                          {item.status && item.status.label}
                        </Badge>
                      )}
                      {admin && (
                        <Text
                          color={colorMode === "light" ? "gray.500" : "white"}
                          mx='4px'
                          fontSize='xs'>
                          {item.statusDate &&
                            item.category.value != "interview" &&
                            item.category.value != "shooting" &&
                            (deliveryTime > 0 ? "+" : "") + deliveryTime + "J"}
                        </Text>
                      )}
                    </Flex>
                    <Flex>
                      {item.validation && (
                        <Badge
                          variant='solid'
                          borderRadius='full'
                          px='10px'
                          py='5px'
                          // bgColor={validationSwitch(item.validation?.value)}
                          bgColor='whiteAlpha.100'>
                          {validationEmoji(item.validation.value)}{" "}
                          {item.validation && item.validation?.label}
                        </Badge>
                      )}
                      {admin && (
                        <Text
                          color={colorMode === "light" ? "gray.500" : "white"}
                          mx='4px'
                          fontSize='xs'>
                          {item.validationDate &&
                            item.category.value != "interview" &&
                            item.category.value != "shooting" &&
                            (validationTime > 0 ? "+" : "") +
                              validationTime +
                              "J"}
                        </Text>
                      )}
                    </Flex>
                    {admin && (
                      <Flex
                        onClick={(e) => openTask(e, item)}
                        color={colorMode === "light" ? "gray.500" : "white"}
                        alignItems='center'
                        _hover={{ opacity: "0.4" }}>
                        <HiPencilAlt />
                      </Flex>
                    )}
                  </TableRow>
                );
              })}
          </Box>
        );
      })}

      {videoData && videoData.tasks.length === 0 && (
        <Flex
          minHeight='200px'
          justifyContent='center'
          alignItems='center'
          color={colorMode === "light" ? "gray.500" : "white"}
          my='40px'>
          No task
        </Flex>
      )}
      {admin && !isLoading && (
        <Box py='10px'>
          <Button
            w='100%'
            onClick={() => onOpen()}
            border='1px solid'
            borderColor={
              colorMode === "light" ? "blackAlpha.200" : "whiteAlpha.300"
            }
            rounded='lg'
            bg={colorMode === "light" ? "#F8FAFC" : "#1C1C1C"}>
            <Flex alignItems='center'>
              <Box mr='5px'>
                <HiPlusCircle />
              </Box>{" "}
              Add a task
            </Flex>
          </Button>
        </Box>
      )}
      <NewTask
        projectId={projectId}
        videoId={videoId}
        projectName={videoData && videoData.project.name}
        videoName={videoData?.video?.title}
        admins={videoData && videoData.admins}
        leaderOptions={videoData && videoData.leaderOptions}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        reloadData={reloadData}
        setReloadData={setReloadData}
      />
      <EditTask
        projectId={projectId}
        videoId={videoId}
        projectName={projectName}
        videoName={videoName}
        admins={admins}
        isOpen={isOpenEdit}
        onOpen={onOpenEdit}
        onClose={onCloseEdit}
        task={currentTask}
        reloadData={reloadData}
        setReloadData={setReloadData}
        firstRenderStatus={firstRenderStatus}
        firstRenderValidation={firstRenderValidation}
        taskId={currentTask._id}
      />
      <UpdateStatus
        isOpen={isOpenStatus}
        onClose={onCloseStatus}
        taskData={currentTask}
        admin={admin}
        projectId={projectId}
        videoId={videoId}
        projectName={projectName}
        videoName={videoName}
        setReloadData={setReloadData}
        firstRenderStatus={firstRenderStatus}
        firstRenderValidation={firstRenderValidation}
        taskId={currentTask?._id}
      />
    </>
  );
}

import React from "react";
import { API } from "aws-amplify";

export async function fetchComments(projectId, fileId, admin) {
  let url = `/comment/all/${projectId}/${fileId}?admin=${admin}`;
  // console.log("projectId", projectId);
  // console.log("url api", url)

  return API.get("kronik", url, {});
}

export async function deleteComment(commentId, projectId, admin) {
  const url = `/comment/delete/${commentId}?admin=${admin}`;
  try {
    // console.log("url api", url)
    // console.log("delete comment", commentId)
    return API.put("kronik", url, {
      body: {
        projectId: projectId,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function postCommment(
  urlPreview,
  timecode,
  text,
  category,
  status,
  relatedComment,
  muxId,
  muxPlaybackId,
  fileId,
  taskId,
  videoId,
  projectId,
  clientId,
  admin
) {
  const url = `/comment/create?admin=${admin}`;
  // console.log("urlPreview",urlPreview);
  // console.log("timecode",timecode);
  // console.log("text",text);
  // console.log("category",category);
  // console.log("status",status);
  // console.log("relatedComment",relatedComment);
  // console.log("muxId",muxId);
  // console.log("muxPlaybackId",muxPlaybackId);
  // console.log("fileId",fileId);
  // console.log("taskId",taskId);
  // console.log("videoId",videoId);
  // console.log("projectId",projectId);
  // console.log("clientId",clientId);
  try {
    return API.post("kronik", url, {
      body: {
        urlPreview: urlPreview,
        timecode: timecode,
        text: text,
        category: category,
        status: { label: "Todo", value: "todo" },
        relatedComment: relatedComment,
        muxId: muxId,
        muxPlaybackId: muxPlaybackId,
        fileId: fileId,
        taskId: taskId,
        videoId: videoId,
        projectId: projectId,
        clientId: clientId,
      },
    });

    // .then((r3) => {
    //   console.log("test return response", r3)
    //   return r3
    // });

    // setUploadFiles(
    //   Array.from(uploadFiles).filter((item) => item.fileName !== name)
    // );
  } catch (e) {
    console.log(e.message);
  }
}

export async function updateComment(
  projectId,
  commentId,
  urlPreview,
  timecode,
  text,
  category,
  status,
  admin
) {
  const url = `/comment/update/${commentId}?admin=${admin}`;

  try {
    // console.log("urlPreview",commentId);
    // console.log("urlPreview",urlPreview);
    // console.log("timecode",timecode);
    // console.log("text",text);
    // console.log("category",category);
    // console.log("status",status);
    return API.put("kronik", url, {
      body: {
        projectId: projectId,
        urlPreview: urlPreview,
        timecode: timecode,
        text: text,
        category: category,
        status: status,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function updateStatus(
  projectId,
  commentId,
  urlPreview,
  timecode,
  text,
  category,
  status,
  admin
) {
  const url = `/comment/update/${commentId}?admin=${admin}`;

  try {
    return API.put("kronik", url, {
      body: {
        projectId: projectId,
        urlPreview: urlPreview,
        timecode: timecode,
        text: text,
        category: category,
        status: status,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

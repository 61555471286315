import React, { useRef } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Editable,
  EditablePreview,
  EditableInput,
  Input,
  Text,
} from "@chakra-ui/react";

export default function ModalView(props) {
  const { isOpen, onOpen, onClose, currentTask } = props;
  const finalRef = React.useRef();

  return (
    <>
      {/* <Box ref={finalRef} tabIndex={-1} aria-label="Focus moved to this box">
        Some other content that'll receive focus on close.
      </Box> */}

      {/* <Button mt={4} onClick={onOpen}>
        Open Modal
      </Button> */}
      <Modal
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{currentTask.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Editable
              defaultValue={currentTask.title}
              borderRadius="5"
              margin="2"
              _hover={{
                background: "gray.200",
                color: "blue.500",
              }}
            >
              <EditablePreview />
              <EditableInput />
            </Editable> */}
            <Text>Title</Text>
            <Input placeholder="Basic usage" value={currentTask.title} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Box,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import TableRow from "../../components/table/TableRow";
import {
  statusEmoji,
  statusSwitch,
  validationEmoji,
  validationSwitch,
} from "../../data/taskData";
import { differenceInBusinessDays } from "date-fns";

export function TaskRow(props) {
  const { tasksList } = props;
  const { colorMode } = useColorMode();
  const history = useHistory();

  function openLink(e, link) {
    window.open(link, "_blank");
    e.stopPropagation();
  }

  function taskLink(e, link) {
    // console.log("metaKey", e.metaKey);
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function videoLink(e, link) {
    // console.log("metaKey", e.metaKey);
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  //   console.log("taskList", tasksList)
  return (
    tasksList.length > 0 && (
      <Box pb='40px'>
        <Grid
          gridTemplateColumns={"6fr 1fr 6fr 5fr 2fr 2fr 2fr"}
          // px="3"
          py='10px'>
          <Text mb='2' textTransform='capitalize'>
            Task
            {/* {title} */}
          </Text>

          <Text fontSize='sm'>Link</Text>
          <Text fontSize='sm'>Project</Text>
          <Text fontSize='sm'>Responsable</Text>
          <Text fontSize='sm'>Date</Text>
          <Text fontSize='sm'>Status</Text>
          <Text fontSize='sm'>Validation</Text>
        </Grid>
        {tasksList &&
          tasksList.map((task, index) => {
            let taskUrl = `/tasks/${task.id}/projects/${task.videoId}/quotes/${task.projectId}`;
            let videoUrl = `/projects/${task.videoId}/quotes/${task.projectId}`;

            const deliveryTime =
              task.statusDate &&
              differenceInBusinessDays(
                new Date(task.statusDate),
                new Date(task.dueDate)
              );
            const validationTime =
              task.validationDate &&
              differenceInBusinessDays(
                new Date(task.validationDate),
                new Date(task.statusDate ? task.statusDate : task.dueDate)
              );

            // console.log("day task", dayjs(task.dueDate).format("DD MMMM YYYY"))
            return (
              <TableRow
                key={index}
                gridCol={"6fr 1fr 6fr 5fr 2fr 2fr 2fr"}
                click={(e, link) => taskLink(e, taskUrl)}>
                <Box>
                  <Text color={colorMode === "light" ? "gray.500" : "white"}>
                    <Flex alignItems='center'>
                      <Text>
                        {task.title} ({task.clientName})
                      </Text>
                      {task.private ? (
                        <Badge variant='outline' ml='2' colorScheme='gray'>
                          Preview
                        </Badge>
                      ) : (
                        ""
                      )}
                    </Flex>
                  </Text>
                </Box>

                <Box>
                  {task.link && (
                    <IconButton
                      colorScheme='gray'
                      aria-label='Search database'
                      icon={<ExternalLinkIcon />}
                      size='xs'
                      onClick={(e) => openLink(e, task.link)}
                    />
                  )}
                </Box>

                <Box
                  mr='5px'
                  color={colorMode === "light" ? "gray.500" : "white"}
                  onClick={(e, link) => videoLink(e, videoUrl)}
                  _hover={{
                    textDecoration: "underline",
                    color: "whiteAlpha.600",
                  }}>
                  {task.videoName}
                </Box>

                <Box>
                  <Flex
                    alignItems='center'
                    color={colorMode === "light" ? "gray.500" : "white"}>
                    {task.people && (
                      <>
                        {task.taskLeader.profile_picture && (
                          <Image
                            mr='10px'
                            borderRadius='full'
                            boxSize='32px'
                            src={`https://aaliz317.twic.pics/${task.taskLeader.profile_picture}`}
                            alt={` Picture of {item.taskLeader.given_name}`}
                          />
                        )}
                        {!task.taskLeader.profile_picture && (
                          <Avatar mr='10px' size='sm' name={task.people} />
                        )}
                      </>
                    )}
                    {task.people}
                  </Flex>
                </Box>
                <Box color='whiteAlpha.500'>
                  {task.dateJs ? dayjs(task.dateJs).format("DD/MM/YYYY") : "-"}
                </Box>
                <Flex>
                  {task.status && (
                    <Badge
                      variant='solid'
                      // colorScheme={statusSwitch(task.status.value)}
                      bgColor='whiteAlpha.100'>
                      {statusEmoji(task.status.value)} {task.status.label}
                    </Badge>
                  )}
                </Flex>
                <Flex>
                  {task.validation && (
                    <Badge
                      variant='solid'
                      // colorScheme={validationSwitch(task.validation.value)}
                      bgColor='whiteAlpha.100'>
                      {validationEmoji(task.validation.value)}{" "}
                      {task.validation.label}
                    </Badge>
                  )}
                </Flex>
              </TableRow>
            );
          })}
      </Box>
    )
  );
}

import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Divider,
  Flex,
  Box,
  Text,
  Avatar,
  Badge,
  useColorMode,
  Link,
} from "@chakra-ui/react";
import {
  HiLocationMarker,
  HiPencilAlt,
  HiTrash,
  HiOutlineOfficeBuilding,
} from "react-icons/hi";
import { FaMicrophone } from "react-icons/fa";
import { BsCameraReelsFill } from "react-icons/bs";
import { Link as RouteLink } from "react-router-dom";

export default function Day(props) {
  const { tasks, day } = props;
  const [dayTasks, setDayTasks] = useState([]);
  const { colorMode } = useColorMode();

  useEffect(() => {
    const data =
      tasks &&
      tasks.filter(
        (task) =>
          dayjs(task.dateJs).format("DD-MM-YY") === day.format("DD-MM-YY")
      );
    setDayTasks(data);
  }, [tasks, day]);

  return (
    <Box
      backgroundColor={colorMode === "light" ? "white" : "#232323"}
      minH='700px'
      pt='10px'
      pl='10px'
      _first={{
        // borderLeft: "1px",

        borderColor: "gray.200",
        borderTopLeftRadius: "xl",
        borderBottomLeftRadius: "xl",
      }}
      _last={{ borderTopRightRadius: "xl", borderBottomRightRadius: "xl" }}>
      <Text mb='20px'>{day.locale("en").format("ddd DD")}</Text>
      {dayTasks &&
        dayTasks.map((task, index) => {
          let taskUrl = `/tasks/${task.id}/projects/${task.videoId}/quotes/${task.projectId}`;
          return (
            <Flex flexDirection='column' mb='40px' ml='5px' mr='10px'>
              <Link
                key={index}
                as={RouteLink}
                to={taskUrl}
                _hover={{ opacity: "0.7" }}>
                <Flex alignItems='center' mb='5px'>
                  <Badge
                    variant='solid'
                    fontSize='0.7em'
                    borderRadius='full'
                    px='10px'
                    py='5px'
                    bgColor={
                      task.category.value === "shooting" ? "#6799F5" : "#377D50"
                    }>
                    <Flex>
                      {task.category.value === "shooting" && (
                        <Flex>Shooting</Flex>
                      )}
                      {task.category.value === "interview" && (
                        <Flex>Interview</Flex>
                      )}
                    </Flex>
                  </Badge>
                </Flex>

                <Flex>
                  {/* {task && task.title} */}
                  <Text>
                    {task && task.clientName} - {task && task.videoName}
                  </Text>
                </Flex>
                <Flex
                  alignItems='center'
                  mt='5px'
                  // color={colorMode === "light" ? "gray.500" : "white"}
                >
                  {/* {task.people && (
                  <Avatar mr="10px" size="xs" name={task.people} />
                )} */}
                  <Text fontSize='small'>{task.people}</Text>
                </Flex>
              </Link>
            </Flex>
          );
        })}
    </Box>
  );
}

import React from "react";
import {
  Flex,
  Text,
  Box,
  Button,
  Heading,
  Grid,
  Badge,
  SimpleGrid,
  useColorMode,
  Alert,
  AlertIcon,
  AlertTitle,
  useDisclosure,
} from "@chakra-ui/react";
import { statusSwitch } from "../../data/projectData";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import Card from "../../components/card";
import ButtonColor from "../../components/button/ButtonColor";
import UpdateStatus from "../../components/project/UpdateStatus";
import { IoFlash } from "react-icons/io5";

export default function Profil(props) {
  const { colorMode } = useColorMode();
  const { freelanceData, admin, freelanceId, setReloadData, isLoading } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex flexDirection='column' padding='20px'>
      <Box>
        <SimpleGrid
          columns={{
            base: 1,
            md: 1,
          }}
          spacing='20px'>
          <Grid direction='column' gap='20px'>
            <Flex justifyContent='space-between'>
              <Text
                fontSize='sm'
                color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                Status
              </Text>
              <Text>
                <Badge
                  fontSize='xs'
                  variant='solid'
                  colorScheme={statusSwitch(freelanceData?.status?.value)}>
                  {freelanceData?.status ? freelanceData?.status?.label : ""}
                </Badge>
              </Text>
            </Flex>

            <Flex justifyContent='space-between'>
              <Text
                fontSize='sm'
                color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                Birth Date
              </Text>
              <Text>
                {freelanceData?.birthDate
                  ? dayjs(freelanceData?.birthDate).format("DD/MM/YYYY")
                  : ""}
              </Text>
            </Flex>

            {/* Cancel */}
          </Grid>
        </SimpleGrid>
      </Box>
    </Flex>
  );
}

import { API } from "aws-amplify";


// /files/tags

export async function updateTags(
  projectId,
  filesIds,
  tags,
  admin

  ) {
    try {      
      await API.put("kronik", `/file/tags?admin=${admin}`, {
        body: {
         tags:tags,
         filesIds:filesIds,
         projectId:projectId,
        },
      });
    } catch (e) {
      console.log(e.message);
    }
  }


export async function updateFile(
    state,
    fileId,
    projectId,
    taskId,
    videoId,
    clientId,
    admin,
  ) {
    try {
      // console.log("state", state)
      // console.log("projectId", projectId)
      // console.log("taskId", taskId)
      // console.log("videoId", videoId)
      // console.log("clientId", clientId)
      // console.log("admin", admin)
      await API.put("kronik", `/file/${fileId}?admin=${admin}`, {
        body: {
          _id: state._id,
          clientName:state.clientName,
          displayName:state.displayName,
          muxData: state.muxData,
          muxId: state.muxId,
          muxPlaybackId: state.muxPlaybackId,
          name: state.name,
          notes:state.notes,
          originalName: state.originalName,
          size: state.size,
          tags: state.tags,
          status: state.status,
          taskName: state.taskName,
          type: state.type,
          uploader: state.uploader,
          videoName: state.videoName,
          projectId:projectId,
          taskId:taskId,
          videoId:videoId,
          clientId:clientId,

        },
      });
    } catch (e) {
      console.log(e.message);
    }
  }
import React from "react";
import { Flex, Heading, useColorMode } from "@chakra-ui/react";

export default function CardTitle({ title, isActive, pb = "4" }) {
  const { colorMode } = useColorMode();
  return (
    <Flex
      // alignItems='center'
      // justifyContent='center'
      fontSize='md'
      fontWeight='semibold'
      // pb={pb}
      mr='5px'
      textTransform='capitalize'
      color={
        isActive
          ? colorMode === "light"
            ? "000"
            : "white"
          : colorMode === "light"
          ? "gray.500"
          : "whiteAlpha.700"
      }
      _hover={{ opacity: isActive ? "1" : "0.8" }}>
      {title}
    </Flex>
  );
}

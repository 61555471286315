import React from "react";
import { Box, useColorMode } from "@chakra-ui/react";
import SideSteps from "../../components/SideSteps";
import SideBarItem from "../../components/SideBarItem.js";
import CardTitle from "../../components/card/CardTitle";
import { LeftColumn } from "../../components/column/LeftColumn";
import { MiniHeader } from "../../components/header/MiniHeader";
export default function SideBar({
  freelanceName,
  freelanceLink,
  isLoading,
  admin,
  clientLogo,
}) {
  const { colorMode } = useColorMode();
  return (
    <LeftColumn>
      {/* <MiniHeader>
        <CardTitle title='Shortcuts' isActive='false' />
      </MiniHeader> */}
      <Box overflowY='auto' mt='10px'>
        <SideBarItem
          title='Freelance'
          text={freelanceName}
          // isActive='true'
          // status='freelance'
          clientLogo={clientLogo}
        />
      </Box>
    </LeftColumn>
  );
}

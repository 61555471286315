import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { format, subHours, startOfMonth } from "date-fns";
import fetchTasks from "./api";

import MonthCalendar from "../../components/calendar/month";
import WeekCalendar from "../../components/calendar/week";
import { Updating } from "../../components/notification/Updating";
import { useUser } from "../../libs/UserContext";
import MainColumn from "../../components/column/MainColumn";
import ProductionHeader from "../../components/header/ProjectHeader";
import Taskheader from "../../components/header/TaskHeader";

export default function Shooting() {
  const { admin } = useUser();
  const [currentMonth, setCurrentMonth] = useState(startOfMonth(new Date()));
  const [data, setData] = useState("");
  const [tasks, setTasks] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [display, setDisplay] = useState("all");
  const [monthView, setMonthView] = useState(false);

  useEffect(() => {
    // const mounted = true;
    setIsLoading(true);
    fetchTasks(display).then((response) => {
      // if (mounted) {
      console.log("tasks", response);
      setData(response);
      setIsLoading(false);
      // }
    });
    // return () => (mounted = false);
  }, [display]);

  return (
    <Box w='100%'>
      <MainColumn>
        <Taskheader
          queryStatus='all'
          queryClient='all'
          // queryDisplay='all'
          queryManager='all'
          queryCategory='all'
          queryPeople='all'
        />{" "}
        {admin && (
          <Box>
            {monthView ? (
              <MonthCalendar
                tasks={data}
                monthView={monthView}
                setMonthView={setMonthView}
              />
            ) : (
              <WeekCalendar
                tasks={data}
                monthView={monthView}
                setMonthView={setMonthView}
              />
            )}
            {isLoading && <Updating />}
          </Box>
        )}
      </MainColumn>
    </Box>
  );
}

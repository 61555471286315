import React, { useState, useEffect } from "react";
import fetchProject from "./api";
import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import {
  Flex,
  Button,
  Box,
  Grid,
  Heading,
  useDisclosure,
  HStack,
  IconButton,
  Text,
} from "@chakra-ui/react";
import VideosList from "./VideosList";
import ShootingList from "./ShootingList";
import { EditProject } from "../../components/project/EditProject";
import NewVideo from "../../components/video/NewVideo";
import Team from "./Team";
import Brief from "./Brief";
import OrderSummary from "./OrderSummary";
import { HiPencilAlt, HiOutlineOfficeBuilding } from "react-icons/hi";
import { AiTwotoneFolderOpen } from "react-icons/ai";
import SideBar from "./SideBar";
import RightBar from "./RightBar";
import { Updating } from "../../components/notification/Updating";
import MainColumn from "../../components/column/MainColumn";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";

export default function Main({ project, videos, reloadData, setReloadData }) {
  let { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { admin, freelance, client } = useUser();
  //   const [project, setProject] = useState("");
  //   const [videos, setVideos] = useState("");
  // const [reloadData, setReloadData] = useState(false);
  // const {
  //   isOpen: isOpenEdit,
  //   onOpen: onOpenEdit,
  //   onClose: onCloseEdit,
  // } = useDisclosure();
  // const { isOpen, onOpen, onClose } = useDisclosure();

  //   useEffect(() => {
  //     setIsLoading(true);
  //     fetchProject(projectId, admin).then((response) => {
  //       setProject(response);
  //       setIsLoading(false);
  //     });
  //   }, [reloadData]);

  return (
    <MainColumn>
      <Flex direction='column' px='20px'>
        <Brief projectData={project.project} />

        {(admin || client) && (
          <VideosList
            videos={videos}
            setReloadData={setReloadData}
            reloadData={reloadData}
            projectData={project.project}
            project={project}
            projectId={projectId}
            projectName={project && project.project.name}
            admin={admin}
            client={client}
            isLoading={isLoading}
          />
        )}
      </Flex>
    </MainColumn>
  );
}

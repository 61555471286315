import { Box, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoCloudUpload } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import fetchUsers from "./api";

export default function Users() {
  const { admin } = useUser();
  const [users, setUsers] = useState([]);

  let history = useHistory();

  useEffect(() => {
    fetchUsers(admin).then((response) => {
      console.log("response", response);
      setUsers(response);
    });
  }, []);

  function userLink(e, link) {
    // console.log("metaKey", e.metaKey);
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <Box>
      {admin && (
        <Box>
          <Box>User list</Box>
          {users.map((user, index) => {
            let userUrl = `/users/${user._id}`;

            return (
              <Box key={index} onClick={(e, link) => userLink(e, userUrl)}>
                {user?.given_name} {user?.family_name} - {user?.user_role}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}

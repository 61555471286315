import React, { useState } from "react";
import { Box, Flex, useColorMode } from "@chakra-ui/react";
import OrderSummary from "./OrderSummary";
import Team from "./Team";
import CardTitle from "../../components/card/CardTitle";
import { MiniHeader } from "../../components/header/MiniHeader";
import RightColumn from "../../components/column/RightColumn";

export default function RightBar({
  videoData,
  adminProjectId,
  projectData,
  projectId,
  reloadData,
  setReloadData,
  isLoading,
  admin,
  clientId,
  clientName,
  user,
}) {
  const [active, setActive] = useState("Order");
  const { colorMode } = useColorMode();
  const selectDisplay = (title) => {
    setActive(title);
  };

  return (
    <RightColumn>
      {/* <Flex mb='0'> */}
      {/* <MiniHeader> */}
      <Flex p='20px'>
        <Box
          onClick={() => selectDisplay("Order")}
          mr='15px'
          cursor={active === "Order" ? "unset" : "pointer"}>
          <CardTitle title='Order Summary' isActive={active === "Order"} />
        </Box>
        <Box
          onClick={() => selectDisplay("Team")}
          cursor={active === "Team" ? "unset" : "pointer"}>
          <CardTitle title='Team' isActive={active === "Team"} />
        </Box>
      </Flex>
      {/* </MiniHeader> */}
      {/* </Flex> */}
      {active === "Order" && (
        <OrderSummary
          videoData={videoData}
          setReloadData={setReloadData}
          isLoading={isLoading}
          clientId={clientId}
        />
      )}
      {active === "Team" && (
        // <Team
        //   team={team}
        //   projectName={projectName}
        //   projectId={projectId}
        //   reloadData={reloadData}
        //   setReloadData={setReloadData}
        //   admin={admin}
        // />
        <Team
          team={videoData.admins}
          projectName={videoData && videoData.project.name}
          projectId={projectId}
          reloadData={reloadData}
          setReloadData={setReloadData}
          adminProjectId={adminProjectId}
          videoId={videoData && videoData.video._id}
          videoName={videoData && videoData.video.title}
          admin={admin}
          isLoading={isLoading}
          clientId={clientId}
          user={user}
          clientName={clientName}
        />
      )}
    </RightColumn>
  );
}

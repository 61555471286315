import { ExternalLinkIcon, Icon } from "@chakra-ui/icons";
import { v4 as uuidv4 } from "uuid";
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Spacer,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { HiPencilAlt } from "react-icons/hi";
import { IoCloudDownload, IoCloudUpload } from "react-icons/io5";
import {
  MdLink,
  MdLinkOff,
  MdOutlineCloseFullscreen,
  MdOutlineLinkOff,
  MdOutlineOpenInFull,
} from "react-icons/md";
import { useHistory } from "react-router-dom";
import EditTask from "../../components/task/EditTask";
import EditTags from "../../components/upload/EditTags";
import useFileDownloader from "../../hook/useFileDownloader";
import { downloadFile, downloadFiles, isAuthorized } from "./api";
import Files from "./Files";
import { useUser } from "../../libs/UserContext";
import { FaGoogleDrive } from "react-icons/fa";
import { SiNotion } from "react-icons/si";
import CardTitle from "../../components/card/CardTitle";
import { MiniHeader } from "../../components/header/MiniHeader";
import MainColumn from "../../components/column/MainColumn";

export function Main({
  projectId,
  taskData,
  isLoading,
  setIsLoading,
  largePlayer,
  setLargePlayer,
  reloadComments,
  setReloadComments,
  firstRenderStatus,
  setComments,
  comments,
  firstRenderValidation,
  setActive,
  admins,
  timecode,
  setTimecode,
  reloadData,
  setReloadData,
  vimeoId,
  vimeoProvider,
  handleUpload,
  hiddenFileInput,
  currentFile,
  setCurrentFile,
  preselectedFiles,
  setPreselectedFiles,
  setGoogleProvider,
  googleProvider,
  notionProvider,
  setNotionProvider,
}) {
  // const [currentFile, setCurrentFile] = useState(null);
  const { user, admin, uploadFiles, freelance } = useUser();
  const { colorMode } = useColorMode();

  const [download, downloaderComponent] = useFileDownloader();
  // const hiddenFileInput = useRef(null);
  let history = useHistory();

  const [isPreselected, setIsPreselected] = useState(false);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [activeFilter, setActiveFilter] = useState(false);

  const {
    isOpen: isOpenTags,
    onOpen: onOpenTags,
    onClose: onCloseTags,
  } = useDisclosure();

  // const handleUpload = (e) => {
  //   hiddenFileInput.current.click();
  // };

  function openLink(e, link) {
    window.open(link, "_blank");
    e.stopPropagation();
  }

  return (
    <MainColumn>
      {/* </Flex> */}
      <Box px='20px' overflowY='auto' mt='20px'>
        {vimeoId && vimeoProvider > 0 && (
          <>
            <AspectRatio
              ratio={16 / 9}
              borderRadius='md'
              overflow='hidden'
              bgColor='#232323'
              mt='48px'>
              {
                <iframe
                  title={taskData.title}
                  src={`https://player.vimeo.com/video/${vimeoId}`}
                  frameborder='0'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowfullscreen></iframe>
              }
            </AspectRatio>
            <Box>
              {taskData.link && (
                <Flex
                  justifyContent='center'
                  mt='20px'
                  textAlign='center'
                  onClick={(e) => openLink(e, taskData.link)}
                  _hover={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}>
                  <IconButton
                    colorScheme='gray'
                    aria-label='Search database'
                    icon={<ExternalLinkIcon />}
                    size='xs'
                    mr='10px'
                  />
                  <Text>Go to Vimeo to see all the comments</Text>
                </Flex>
              )}
            </Box>
          </>
        )}
        {googleProvider > 0 && (
          <Flex
            mt='48px'
            minH='600px'
            justifyContent='center'
            alignItems='center'
            borderRadius='10px'
            bgColor={colorMode === "light" ? "white" : "#232323"}
            px='20px'
            py='40px'
            shadow='sm'>
            <Flex
              alignItems='center'
              direction='column'
              onClick={(e) => openLink(e, taskData.link)}
              _hover={{ cursor: "pointer", textDecoration: "underline" }}>
              <Icon as={FaGoogleDrive} boxSize='20' color='primary' />
              <Text mt='10px'>Go to Google Drive</Text>
            </Flex>
            {/* {  <iframe src={`https://drive.google.com/embeddedfolderview?id=${googleDriveId}#list`} width="100%" height="700px" frameborder="0"></iframe>} */}
          </Flex>
        )}
        {notionProvider > 0 && (
          <Flex
            mt='48px'
            minH='600px'
            justifyContent='center'
            alignItems='center'
            borderRadius='10px'
            bgColor={colorMode === "light" ? "white" : "#232323"}
            px='20px'
            py='40px'
            shadow='sm'>
            <Flex
              alignItems='center'
              direction='column'
              onClick={(e) => openLink(e, taskData.link)}
              _hover={{ cursor: "pointer", textDecoration: "underline" }}>
              <Icon as={SiNotion} boxSize='20' color='primary' />
              <Text mt='10px'>Go to Notion</Text>
            </Flex>
            {/* {  <iframe src={`https://drive.google.com/embeddedfolderview?id=${googleDriveId}#list`} width="100%" height="700px" frameborder="0"></iframe>} */}
          </Flex>
        )}
        <Box py='0px' mt='0px' rounded='xl'>
          {/* <Grid templateColumns="5fr 2fr" gap="20px"> */}
          <Box>
            <Files
              link={taskData?.link}
              taskData={taskData}
              user={user}
              admin={admin}
              files={taskData?.files}
              projectId={taskData?.project?._id}
              setReloadData={setReloadData}
              reloadData={reloadData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              clientId={taskData?.project?.client?._id}
              setComments={setComments}
              comments={comments}
              setReloadComments={setReloadComments}
              reloadComments={reloadComments}
              timecode={timecode}
              setTimecode={setTimecode}
              setLargePlayer={setLargePlayer}
              largePlayer={largePlayer}
              // updateDownload={updateDownload}
              // setDownloadData={setDownloadData}
              handleUpload={handleUpload}
              hiddenFileInput={hiddenFileInput}
              currentFile={currentFile}
              setCurrentFile={setCurrentFile}
              preselectedFiles={preselectedFiles}
              setPreselectedFiles={setPreselectedFiles}
              setActive={setActive}
              isPreselected={isPreselected}
              setIsPreselected={setIsPreselected}
              onOpenTags={onOpenTags}
              filteredFiles={filteredFiles}
              setFilteredFiles={setFilteredFiles}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          </Box>
        </Box>
        {!isLoading &&
          !taskData.link &&
          filteredFiles?.length === 0 &&
          taskData?.category?.value !== "kickOff" &&
          taskData?.category?.value !== "makingUp" &&
          taskData?.category?.value !== "narrative" &&
          taskData?.category?.value !== "subTitles" &&
          taskData?.category?.value !== "thumbnail" && (
            <Flex
              minH='600px'
              justifyContent='center'
              alignItems='center'
              borderRadius='10px'
              bgColor={colorMode === "light" ? "white" : "#232323"}
              px='20px'
              py='40px'
              shadow='sm'>
              <Flex
                alignItems='center'
                direction='column'
                onClick={() => handleUpload()}
                _hover={{ cursor: "pointer", textDecoration: "underline" }}>
                <Icon as={IoCloudUpload} boxSize='20' color='primary' />
                <Text mt='10px'>Upload a video file (only video)</Text>
              </Flex>
              {/* {  <iframe src={`https://drive.google.com/embeddedfolderview?id=${googleDriveId}#list`} width="100%" height="700px" frameborder="0"></iframe>} */}
            </Flex>
          )}
        {!taskData.link &&
          !taskData?.files?.length > 0 &&
          (taskData?.category?.value === "kickOff" ||
            taskData?.category?.value === "makingUp" ||
            taskData?.category?.value === "narrative" ||
            taskData?.category?.value === "subTitles" ||
            taskData?.category?.value === "thumbnail") && (
            <Flex
              minH='600px'
              justifyContent='center'
              alignItems='center'
              borderRadius='10px'
              bgColor={colorMode === "light" ? "white" : "#232323"}
              px='20px'
              py='40px'
              shadow='sm'>
              <Flex alignItems='center' direction='column'>
                <Icon as={MdOutlineLinkOff} boxSize='20' color='primary' />
                <Text mt='10px'>No link</Text>
              </Flex>
              {/* {  <iframe src={`https://drive.google.com/embeddedfolderview?id=${googleDriveId}#list`} width="100%" height="700px" frameborder="0"></iframe>} */}
            </Flex>
          )}
      </Box>
      {/* <EditTask
        projectId={projectId}
        videoId={taskData?.video?._id}
        projectName={taskData?.project?.name}
        videoName={taskData?.video?.name}
        admins={admins && admins}
        isOpen={isOpenEdit}
        onOpen={onOpenEdit}
        onClose={onCloseEdit}
        task={taskData}
        reloadData={reloadData}
        setReloadData={setReloadData}
        firstRenderStatus={firstRenderStatus}
        firstRenderValidation={firstRenderValidation}
        taskId={taskData?._id}
      /> */}
      <EditTags
        isOpen={isOpenTags}
        onClose={onCloseTags}
        projectId={projectId}
        filesIds={preselectedFiles && preselectedFiles.map((file) => file._id)}
        setReloadData={setReloadData}
        reloadData={reloadData}
        setPreselectedFiles={setPreselectedFiles}
        isPreselected={isPreselected}
        setIsPreselected={setIsPreselected}
      />
    </MainColumn>
  );
}

import { chakra, useColorMode, useToken } from "@chakra-ui/react";
import * as React from "react";

export const Logo = ({ colorTypo = "#302F30", colorPicto = "#F3605A" }) => {
  const { colorMode, toggleColorMode } = useColorMode();

  //   const { iconColor = 'currentColor', ...rest } = props
  //   const color = useToken('colors', iconColor)
  return (
    <chakra.svg
      width='42'
      height='42'
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      {/* <path d="M178.769 8.9859C180.93 8.9859 182.68 10.7573 182.68 12.943C182.68 15.1286 180.93 16.9011 178.769 16.9011C176.608 16.9011 174.858 15.1286 174.858 12.943C174.858 10.7573 176.608 8.9859 178.769 8.9859ZM175.696 44.6043H182.68V19.1626H175.696V44.6043ZM111.805 19.5668V22.2854C112.994 20.5154 115.318 19.1626 117.305 19.1626V26.0162C114.272 26.0162 111.805 28.7289 111.805 32.0626V44.6043H105.57V19.5668H111.805ZM160.006 19.1626C166.783 19.1626 171.784 24.859 171.784 31.8611V44.6043H165.15V31.8611C165.15 28.6381 162.612 26.0162 159.491 26.0162C156.371 26.0162 153.833 28.6381 153.833 31.8611V44.6043H147.199V19.5668H153.833V22.2064C155.101 20.2173 157.964 19.1626 160.006 19.1626ZM132.252 19.1626C139.261 19.1626 144.964 25.0594 144.964 32.3063C144.964 39.5544 139.261 45.4524 132.252 45.4524C125.243 45.4524 119.54 39.5544 119.54 32.3063C119.54 25.0594 125.243 19.1626 132.252 19.1626ZM132.391 38.3852C135.625 38.3852 138.258 35.7226 138.258 32.4483C138.258 29.1751 135.625 26.5124 132.391 26.5124C129.156 26.5124 126.524 29.1751 126.524 32.4483C126.524 35.7226 129.156 38.3852 132.391 38.3852ZM87.9177 31.209L95.5224 19.7041H103.557L95.3442 32.129L103.615 44.6043H95.5719L87.9177 33.056V44.6043H81.2642V11.2474H87.9177V31.209ZM192.406 31.2102L200.012 19.7041H208.047L199.833 32.129L208.104 44.6043H200.062L192.406 33.056V44.6043H185.753V11.2474H192.406V31.2102Z" fill={ colorMode=== "light" ? colorTypo : "white"}/>
      <path d="M0.3057 6.58278C0.168921 5.8305 0.892714 5.20926 1.61556 5.45852L23.1369 12.8797C23.4786 12.9975 23.7302 13.2906 23.7948 13.6462L31.5535 56.3187C31.6902 57.071 30.9664 57.6922 30.2436 57.4429L8.72222 50.0218C8.38052 49.9039 8.12899 49.6109 8.06434 49.2553L0.3057 6.58278Z" fill={colorMode === "light" ? colorPicto : "#F3605A"}/>
      <path d="M28.0801 1.68135C27.9433 0.929067 28.6671 0.307832 29.39 0.55709L50.9114 7.97825C51.2531 8.09608 51.5046 8.38913 51.5692 8.74474L59.3279 51.4172C59.4646 52.1695 58.7409 52.7908 58.018 52.5415L36.4966 45.1203C36.1549 45.0025 35.9034 44.7095 35.8388 44.3538L28.0801 1.68135Z" fill={colorMode === "light" ? colorPicto : "#F3605A"} />

       <path
    d='M5 8.57397L17.4415 12.8641L21.7316 36.46L9.29016 32.1698L5 8.57397Z'
    fill='#F3605A'
  />
  <path
    d='M19.5864 6L32.0279 10.2902L36.318 33.886L23.8766 29.5959L19.5864 6Z'
    fill='#F3605A'
  /> */}

      <path
        d='M5 8.57397L17.4415 12.8641L21.7316 36.46L9.29016 32.1698L5 8.57397Z'
        fill='#F3605A'
      />
      <path
        d='M19.5864 6L32.0279 10.2902L36.318 33.886L23.8766 29.5959L19.5864 6Z'
        fill='#F3605A'
      />
    </chakra.svg>
  );
};

import { API } from "aws-amplify";


export async function fetchBrief(briefId, admin) {
    // console.log("fetchBriefs");
    const url = `/brief/${briefId}?admin=${admin}`
    return API.get("kronik", url, {});
  }

  export async function deleteBrief(e, briefId, admin) {
    // console.log("fetchBriefs");
    e.stopPropagation();
    const url = `/brief/delete/${briefId}?admin=${admin}`
    return API.put("kronik", url, {});
  }
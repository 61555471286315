import { CheckIcon, DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  useColorMode,
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Text,
  Textarea,
  Image,
  Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ButtonColor from "../../components/button/ButtonColor";
import { SubMenuButton } from "../../components/button/SubMenuButton";
import {
  deleteComment,
  postCommment,
  updateComment,
  updateStatus,
} from "./api";

import { format } from "date-fns";
import dayjs from "dayjs";

export function Reply({
  relatedComment,
  index,
  isCompleted,
  setIsLoading,
  setReloadComments,
  videoId,
  projectId,
  clientId,
  user,
  originalComment,
}) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [showInputReply, setShowInputReply] = useState(false);
  const [newText, setNewText] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [reply, setReply] = useState("");
  const [showReply, setShowReply] = useState(false);

  const { colorMode } = useColorMode();

  function showEditor(e, comment) {
    e.stopPropagation();
    setNewText(comment.text);
    setShowInput(!showInput);
  }

  function removeEditor(e) {
    e.stopPropagation();
    setShowInput(!showInput);
  }

  function deleteEdit(e, comment) {
    e.stopPropagation();
    deleteComment(comment._id, comment.project).then(
      (response) => setReloadComments(Math.random()),
      setIsLoading(true)
    );
  }

  function showTextAreaReply(e) {
    e.stopPropagation();
    setShowReply(!showReply);
  }

  function removeEditorReply(e) {
    e.stopPropagation();
    setShowReply(!showReply);
  }

  function sendReplyComment(e) {
    e.stopPropagation();
    // console.log("reply", reply)
    // console.log("originalComment post", originalComment)
    // setComment("")
    setIsLoading(true);
    postCommment(
      originalComment.urlPreview,
      originalComment.timecode,
      reply,
      null,
      null,
      originalComment._id,
      originalComment.muxId,
      originalComment.muxPlaybackId,
      originalComment.file._id,
      originalComment.task,
      videoId,
      projectId,
      clientId
    )
      .then((response) => setReloadComments(Math.random()))
      .then((response) => setIsLoading(false));
    setShowReply(!showReply);
    setReply("");
  }

  function updateTextComment(e, comment) {
    e.stopPropagation();
    setIsLoading(true);
    updateComment(
      comment.project,
      comment._id,
      comment.urlPreview,
      comment.timecode,
      newText,
      comment.category,
      comment.status
    ).then((response) => {
      setReloadComments(Math.random());
      setIsLoading(false);
      setShowInput(!showInput);
    });
  }

  return (
    <Box
      key={index}
      onMouseEnter={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}>
      <Box mt='20px' pl='20px' borderLeft='1px' borderColor='gray.300'>
        <Flex alignItems='center' fontSize='xs' color='gray.600' minH='25px'>
          <Flex>
            {!relatedComment?.author?.profile_picture && (
              <Avatar
                mr='10px'
                size='sm'
                name={
                  relatedComment?.author?.given_name +
                  " " +
                  relatedComment?.author?.family_name
                }
                bgColor='blackAlpha.500'
                color='white'
              />
            )}
            {relatedComment?.author?.profile_picture && (
              <Image
                mr='10px'
                borderRadius='full'
                boxSize='22px'
                src={`https://aaliz317.twic.pics/${relatedComment.author.profile_picture}`}
                alt={` Picture of {item.taskLeader.given_name}`}
              />
            )}
          </Flex>
          <Text
            overflowWrap='anywhere'
            fontSize='xs'
            color={colorMode === "light" ? "gray.600" : "whiteAlpha.600"}>
            {relatedComment.author.given_name}{" "}
            {relatedComment.author.family_name}
            {/* - {  dayjs(relatedComment.createdAt).format("DD MM YY HH:mm   ")} */}
          </Text>

          <Spacer />
          {showEdit && (
            <Flex>
              {user.username === relatedComment.author.email && (
                <IconButton
                  onClick={(e) => showEditor(e, relatedComment)}
                  icon={<EditIcon />}
                  variant='outline'
                  size='xs'
                  rounded='full'
                />
              )}

              {user.username === relatedComment.author.email && (
                <IconButton
                  onClick={(e) => deleteEdit(e, relatedComment)}
                  icon={<DeleteIcon />}
                  variant='outline'
                  size='xs'
                  rounded='full'
                />
              )}
            </Flex>
          )}
        </Flex>
        {!showInput && (
          <Box>
            <Text
              overflowWrap='anywhere'
              fontSize='sm'
              color={
                isCompleted
                  ? colorMode === "light"
                    ? "gray.400"
                    : "whiteAlpha.700"
                  : "inerit"
              }
              textDecoration={isCompleted ? "line-through" : "normal"}>
              {relatedComment.text}
            </Text>
            <Box>
              <Button
                variant='link'
                size='xs'
                onClick={(e) => showTextAreaReply(e)}>
                Reply
              </Button>
            </Box>
          </Box>
        )}
        {showReply && (
          <Box alignItems='center'>
            <Textarea
              bgColor={colorMode === "light" ? "white" : "#232323"}
              fontSize='md'
              value={reply}
              onChange={(e) => setReply(e.target.value)}
              pr='4.5rem'
              size='lg'
            />
            <Flex>
              <Box mr='5px'>
                <ButtonColor
                  mt='10px'
                  title='Send'
                  click={(e) => sendReplyComment(e)}
                  size='sm'
                />
              </Box>
              <ButtonColor
                mt='10px'
                bg='gray.300'
                color='gray.600'
                size='sm'
                title='Cancel'
                click={(e) => removeEditorReply(e)}>
                Cancel
              </ButtonColor>
            </Flex>
          </Box>
        )}
        {showInput && (
          <Box alignItems='center'>
            <Textarea
              bgColor='white'
              fontSize='md'
              value={newText}
              onChange={(e) => setNewText(e.target.value)}
              pr='4.5rem'
              size='lg'
            />
            <Flex>
              <Box mr='5px'>
                <ButtonColor
                  mt='10px'
                  title='Save'
                  click={(e) => updateTextComment(e, relatedComment)}
                  size='sm'
                />
              </Box>
              <ButtonColor
                mt='10px'
                bg='gray.300'
                color='gray.600'
                size='sm'
                title='Cancel'
                click={(e) => removeEditor(e)}>
                Cancel
              </ButtonColor>
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Grid,
  Heading,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
// import SideBar from './SideBar';
import { HiPencilAlt } from "react-icons/hi";
import { Updating } from "../../components/notification/Updating";
// import Profil from './About';
// import RightBar from './RightBar';
import { EditFreelance } from "../../components/freelance/EditFreelance";
import JobsList from "./JobsList";
// import About from './About';
import fetchFreelanceJobs from "./api";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";
import MainColumn from "../../components/column/MainColumn";
import BusinessHeader from "../../components/header/BusinessHeader";

export function Jobs() {
  let { freelanceId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  // const { admin } = useUser();
  const [reloadData, setReloadData] = useState(false);
  const [freelanceData, setFreelanceData] = useState("");
  // const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setIsLoading(true);
    fetchFreelanceJobs().then((response) => {
      setFreelanceData(response);
      // console.log("my jobs", response);
      setIsLoading(false);
    });
  }, [reloadData]);

  return (
    <>
      <MainColumn>
        {/* <MiniHeader>
          <CardTitle title='Jobs' isActive='true' />
        </MiniHeader> */}
        <BusinessHeader />

        <MainColumn>
          <Box py='0px' px='20px' mt='20px' rounded='xl'>
            <Box>
              <JobsList
                jobs={freelanceData?.jobs}
                // freelanceId={freelanceId}
                freelanceId={freelanceData?._id}
                setReloadData={setReloadData}
                reloadData={reloadData}
                projectData={freelanceData?.project}
                project={freelanceData?.project}
                projectId={freelanceData?.project?._id}
                projectName={freelanceData?.project?.name}
                isLoading={isLoading}
              />
            </Box>
          </Box>
        </MainColumn>
      </MainColumn>

      {isLoading && <Updating />}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Link as RouteLink } from "react-router-dom";
import {
  Link,
  Flex,
  Grid,
  Box,
  Heading,
  Text,
  useColorMode,
  Avatar,
  AvatarGroup,
  Badge,
  IconButton,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import TableRow from "../../components/table/TableRow";
import { IoFlash } from "react-icons/io5";
import UpdateStatus from "../../components/project/UpdateStatus";
import { HiPencilAlt } from "react-icons/hi";
import { EditProject } from "../../components/project/EditProject";
import { statusEmoji } from "../../data/projectData";

export function GroupByMonth(props) {
  const { projects, admin, reloadData, setReloadData } = props;
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  console.log("projects return", projects);
  const {
    isOpen: isOpenStatus,
    onOpen: onOpenStatus,
    onClose: onCloseStatus,
  } = useDisclosure();
  const [currentProject, setCurrentProject] = useState("");

  function statusSwitch(param) {
    switch (param) {
      case "finished":
        return "#377D50";
      case "production":
        return "#6799F5";
      case "preparation":
        return "gray";
      case "blocked":
        return "#D8707F";
      default:
        return "gray";
    }
  }

  const groupBy = (array, key) => {
    // const arraySortByDate = array.sort(function (b, a) {
    //   if (a.project.wonDate > b.project.wonDate) {
    //     return -1;
    //   }
    //   if (a.project.wonDate < b.project.wonDate) {
    //     return 1;
    //   }
    //   return 0;
    // });

    // console.log("arraySortByDate", array);
    return array.reduce((groupedProjects, project) => {
      if (groupedProjects[project[key]] == null)
        groupedProjects[project[key]] = [];
      groupedProjects[project[key]].push(project);
      return groupedProjects;

      //   (groupedProjects[project[key].name] =
      //     groupedProjects[project[key].name] || []).push(project);
      //   return groupedProjects;
    }, {});
  };

  const projectsGroupByMonth = projects && groupBy(projects, "wonDate");

  // console.log("projectsGroupByMonth", projectsGroupByMonth);

  function clickStatus(e, project) {
    e.preventDefault();

    setCurrentProject(project);
    // console.log("project", project);
    onOpenStatus();
    e.stopPropagation();
  }

  function openEdit(e, project) {
    e.preventDefault();
    // console.log("currentProject:", project);
    setCurrentProject(project);
    onOpen();
    e.stopPropagation();
  }

  return (
    <Flex direction='column'>
      {projects &&
        projectsGroupByMonth &&
        Object.keys(projectsGroupByMonth).map((item, index) => {
          const totalPricing =
            projectsGroupByMonth &&
            projectsGroupByMonth[item].reduce((acc, currenVal) => {
              return acc + currenVal.project.pricing;
            }, 0);

          return (
            <Box
              key={index}
              mb='40px'
              // bg={colorMode === "light" ? "#F6F6F6" : "gray.900"}
              rounded='xl'
              // p="10px"
              pb='0px'>
              <Grid
                gridTemplateColumns={
                  admin
                    ? "7fr 3fr 2fr 3fr 2fr  2fr 1fr"
                    : "7fr 3fr 2fr 3fr 2fr  2fr"
                }
                // px="3"
                py='10px'>
                <Heading
                  as='h2'
                  fontSize='xl'
                  mb='2'
                  textTransform='capitalize'>
                  {item}
                </Heading>

                <Text fontSize='sm'>Quote</Text>
                <Text fontSize='sm'>Status</Text>
                <Text fontSize='sm'>Project manager</Text>
                <Text fontSize='sm'>Video(s)</Text>
                <Text fontSize='lg' as='b'>
                  {totalPricing}€
                </Text>
                {admin && <Text fontSize='sm'>Edit</Text>}
              </Grid>

              {projects &&
                projectsGroupByMonth &&
                projectsGroupByMonth[item].map((project, index) => {
                  return (
                    <Link
                      key={index}
                      as={RouteLink}
                      to={`/quotes/${project.project._id}`}
                      _hover={{ textDecor: "none" }}>
                      <TableRow
                        key={index}
                        gridCol={
                          admin
                            ? "7fr 3fr 2fr 3fr 2fr  2fr 1fr"
                            : "7fr 3fr 2fr 3fr 2fr  2fr"
                        }>
                        <Flex
                          alignItems='center'
                          color={colorMode === "light" ? "gray.500" : "white"}>
                          {admin && (
                            <IconButton
                              aria-label='Edit'
                              icon={<IoFlash />}
                              rounded='full'
                              size='sm'
                              onClick={(e) => clickStatus(e, project)}
                              mr='10px'
                            />
                          )}
                          <Box
                            color={
                              colorMode === "light" ? "gray.500" : "white"
                            }>
                            {project.project.name}{" "}
                            {project.project.cancel && (
                              <Badge colorScheme='red' ml='10px'>
                                Cancel
                              </Badge>
                            )}
                          </Box>
                        </Flex>
                        <Flex>
                          {project.client.logo && (
                            <Image
                              mr='10px'
                              borderRadius='md'
                              boxSize='32px'
                              alt={`Logo of ${project.client.name}`}
                              src={`https://aaliz317.twic.pics/${project.client.logo}`}
                            />
                          )}
                          {project.client.name}
                        </Flex>

                        <Box
                          color={colorMode === "light" ? "gray.500" : "white"}>
                          {project.project.status && (
                            <Badge
                              variant='solid'
                              borderRadius='full'
                              px='10px'
                              py='5px'
                              // bgColor={statusSwitch(
                              //   project &&
                              //     project.project.status &&
                              //     project.project.status.value
                              // )}
                              bgColor='whiteAlpha.100'>
                              {statusEmoji(project?.project?.status?.value)}{" "}
                              {""}
                              {project.project.status.label}
                            </Badge>
                          )}
                        </Box>
                        <Flex alignItems='center'>
                          <AvatarGroup
                            size='sm'
                            max={2}
                            spacing={-1.5}
                            mr='5px'>
                            {project.accountManager &&
                              project.accountManager.map((project, index) => {
                                return (
                                  <Avatar
                                    // bg="gray.300"
                                    // color="white"
                                    key={index}
                                    name={project}
                                  />
                                );
                              })}
                          </AvatarGroup>
                          {project.accountManager}
                        </Flex>
                        <Box
                          color={colorMode === "light" ? "gray.500" : "white"}>
                          {project.videoCount}
                        </Box>
                        <Box
                          color={colorMode === "light" ? "gray.500" : "white"}>
                          {project.project.pricing && project.project.pricing}{" "}
                          {project.project.pricing && "€"}
                        </Box>
                        {admin && (
                          <Flex
                            onClick={(e) => openEdit(e, project)}
                            color={colorMode === "light" ? "gray.500" : "white"}
                            alignItems='center'
                            _hover={{ opacity: "0.4" }}>
                            <HiPencilAlt />
                          </Flex>
                        )}
                      </TableRow>
                    </Link>
                  );
                })}
            </Box>
          );
        })}
      <EditProject
        isOpen={isOpen}
        onClose={onClose}
        setReloadData={setReloadData}
        reloadData={reloadData}
        projectData={currentProject?.project}
        client={currentProject?.client}
      />
      <UpdateStatus
        isOpen={isOpenStatus}
        onClose={onCloseStatus}
        admin={admin}
        projectId={currentProject?.project?._id}
        projectData={currentProject?.project}
        setReloadData={setReloadData}
      />
    </Flex>
  );
}

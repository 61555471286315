import React, { useState } from "react";
import Chart from "react-apexcharts";
import { Box, Heading, Link, useColorMode } from "@chakra-ui/react";
import { Link as RouteLink, NavLink, useLocation } from "react-router-dom";
import { darkStyles } from "../../styles/customStyles";

export default function AreaChart(props) {
  const { colorMode } = useColorMode();
  const {
    dataY = [0, 0, 0],
    dataX = [0, 0, 0],
    dataZ,
    dataOptions,
    title,
    link,
  } = props;

  // console.log("dataProjects", dataX);
  const series = 
  
  dataZ ?
  [
    {
      name: "CA",
      // data: [45, 52, 38, 45, 19, 23, 2],
      data: dataY,
    },
  
    {
      name: "Cost ",
      // data: [45, 52, 38, 45, 19, 23, 2],
      data: dataZ,
    },
  ] : [
    {
      name: "total",
      // data: [45, 52, 38, 45, 19, 23, 2],
      data: dataY,
    },
  ];
  const options = {
    tooltip: {
      theme: colorMode === "light" ? "light" : "dark",
    },

    chart: {
      foreColor: colorMode === "light" ? "#333" : "white",

      height: 280,
      type: "area",
      background: colorMode === "light" ? "#fffff" : "#232323",

      toolbar: {
        show: false,
      },
    },

    // colors: ["#765AFD", "#F9B0D7"],
    colors: [colorMode === "light" ? "#76A7F0" : "#3E45FF", "#FA716C"],

    dataLabels: {
      enabled: false,
      colors: colorMode === "light" ? ["#0F0D64"] : ["white"],
    },
    xaxis: {
      categories: dataX,
    },
  };

  return (
    <Box
      bg="white"
      m="20px"
      background={colorMode === "light" ? "#fffff" : "#232323"}
    >
      <Heading as="h2" fontSize="xs" mb="4" textTransform="uppercase">
        <Link as={NavLink} to={link} _hover={{ textDecor: "none" }}>
          {title}
        </Link>
      </Heading>

      <Chart options={options} type="area" series={series} />
    </Box>
  );
}

import { API } from "aws-amplify";
import axios from "axios";

export async function createClient(state, admin) {
  try {
    await API.post("kronik", `/client/create?admin=${admin}`, {
      body: {
        name: state.name,
      },
    });
  } catch (e) {
    console.log(e.message);
  }
}

export async function updatePicture(file, setPourcentageUpdate) {
  const fileName = file.name;
  const reader = new FileReader();
  const url = `https://api.kronik.com/file/putObject`;

  const config = {
    onUploadProgress: (progressEvent) => {
      // console.log(
      //   parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
      // );
      setPourcentageUpdate(
        parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
      );
    },
  };

  reader.onloadend = () => {
    const base64String = reader.result.split(`base64,`)[1];
    const dataInfo = {
      fileName: fileName,
      base64String: base64String,
    };

    axios
      .post(url, dataInfo, config)
      .then((r) => {
        console.log(r);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  reader.readAsDataURL(file);
}

export async function updateClient(clientId, state, admin) {
  const url = `/client/update/${clientId}?admin=${admin}`;
  return await API.put("kronik", url, {
    body: {
      name: state.name,
      about: state.about,
      address: state.address,
      logo: state.logo,
    },
  }).then((response) => {
    // console.log("response user", response);
    return response;
  });
}

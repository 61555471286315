import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Box, Button, ButtonGroup } from "@chakra-ui/react";
import Dashboard from "./pages/dashboard";
import { Layout } from "./components/Layout";
import { UserContextProvider } from "./libs/UserContext";
import { useEffect } from "react";

import Login from "./pages/login";
import Profil from "./pages/profil";
import Video from "./pages/video";
import Shooting from "./pages/shooting";
import Videos from "./pages/videos";
import Projects from "./pages/projects";
import Project from "./pages/project";
import Clients from "./pages/clients";
import Charts from "./pages/dashboard";
import Tasks from "./pages/tasks";
import Task from "./pages/task";
import Upload from "./pages/upload";
import Revenues from "./pages/revenues";
import Client from "./pages/client";
import { Player } from "./pages/player";
import { Freelances } from "./pages/freelances";
import { Freelance } from "./pages/freelance";
import { Job } from "./pages/job";
import { Jobs } from "./pages/jobs";
import { Review } from "./pages/review";
import { Quotes } from "./pages/quotes";
import Briefs from "./pages/briefs";
import Brief from "./pages/brief";
// import Leads from "./pages/leads";
import Library from "./pages/library";
import Production from "./pages/production";
import Users from "./pages/users";
import User from "./pages/user";
import Invoices from "./pages/invoices";
import Invoice from "./pages/invoice";
import Payments from "./pages/payments";
import Payment from "./pages/payment";
import Expenses from "./pages/expenses";
import Expense from "./pages/exepense";
function App() {
  useEffect(() => {
    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "c5dd21f3-4748-4235-876f-d215e197a2b6";

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
      // Feed this call with your own custom data.
    })();
  }, []);

  return (
    <div className='App'>
      <Router>
        <UserContextProvider>
          {/* <Dashboard /> */}
          <Switch>
            <Route path='/login/:category'>
              <Login />
            </Route>

            <Route exact path='/'>
              <Redirect to='/dashboard' />
            </Route>

            <Route exact path='/library'>
              <Layout>
                <Library />
              </Layout>
            </Route>

            <Route exact path='/production'>
              <Layout>
                <Production />
              </Layout>
            </Route>

            <Route exact path='/lead'>
              <Layout>
                <Briefs />
              </Layout>
            </Route>

            <Route path='/brief/:briefId'>
              <Layout>
                <Brief />
              </Layout>
            </Route>
          </Switch>
          <Switch>
            <Route path='/dashboard'>
              <Layout>
                <Dashboard />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route path='/newquote'>
              <Layout>
                <Quotes />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            {/* <Route path="/tasks/:taskId/videos/:videoId/projects/:projectId/:playerId"> */}
            <Route path='/review/:taskId/project/:projectId'>
              <Review />
            </Route>
          </Switch>

          <Switch>
            {/* <Route path="/tasks/:taskId/videos/:videoId/projects/:projectId/:playerId"> */}
            <Route path='/player/:taskId/projects/:videoId/quotes/:projectId/playerId'>
              <Layout>
                <Player />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route path='/tasks/:taskId/projects/:videoId/quotes/:projectId/'>
              <Layout>
                <Task />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/users'>
              <Layout>
                <Users />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route path='/users/:userId'>
              <Layout>
                <User />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/tasks'>
              <Layout>
                <Tasks />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/revenues'>
              <Layout>
                <Revenues />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/jobs'>
              <Layout>
                <Jobs />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/invoices'>
              <Layout>
                <Invoices />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/invoices/:invoiceId'>
              <Layout>
                <Invoice />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/payments'>
              <Layout>
                <Payments />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/payments/:paymentId'>
              <Layout>
                <Payment />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/expenses'>
              <Layout>
                <Expenses />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/expenses/:expenseId'>
              <Layout>
                <Expense />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/freelances'>
              <Layout>
                <Freelances />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route path='/freelances/:freelanceId/jobs/:jobId'>
              <Layout>
                <Job />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route path='/jobs/:freelanceId/jobs/:jobId'>
              <Layout>
                <Job />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/freelances/:freelanceId'>
              <Layout>
                <Freelance />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route path='/charts'>
              <Layout>
                <Charts />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/quotes'>
              <Layout>
                <Projects />
              </Layout>
            </Route>

            <Route path='/quotes/:projectId'>
              <Layout>
                <Project />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route path='/projects/:videoId/quotes/:projectId'>
              <Layout>
                <Video />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/shooting'>
              <Layout>
                <Shooting />
              </Layout>
            </Route>
          </Switch>

          {/* <Switch>
            <Route path="/shooting/:videoId/projects/:projectId">
              <Layout>
                <Video />
              </Layout>
            </Route>
          </Switch> */}

          <Switch>
            <Route exact path='/projects'>
              <Layout>
                <Videos />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/upload'>
              <Layout>
                <Upload />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route path='/clients/:clientId'>
              <Layout>
                <Client />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route exact path='/clients'>
              <Layout>
                <Clients />
              </Layout>
            </Route>
          </Switch>

          <Switch>
            <Route path='/profil'>
              <Layout>
                <Profil />
              </Layout>
            </Route>
          </Switch>
        </UserContextProvider>
      </Router>
    </div>
  );
}

export default App;

import React, { useState, useReducer, useEffect, useRef } from "react";
import { useUser } from "../../libs/UserContext";
import { initialState, reducer } from "./reducer";
import { customStyles } from "../../styles/customStyles";
import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Image,
} from "@chakra-ui/react";
import Select from "react-select";
import { updatePicture, updateUser } from "./api";
import { IoCloudUpload } from "react-icons/io5";

export default function EditUser(props) {
  const { isOpen, onClose, reloadData, setReloadData, userData } = props;
  const { admin } = useUser();
  const [state, dispatch] = useReducer(reducer, initialState);

  //   const { userId } = useParams();
  //   const [file, setFile] = useState(null);
  //   const [fileName, setFileName] = useState("");
  const hiddenFileInput = useRef(null);

  // console.log("userData", userData);
  function save() {
    dispatch({ type: "save" });
    // console.log("state", state);
    updateUser(userData._id, state, admin).then((response) => {
      dispatch({ type: "success" });
      onClose();
      // console.log("success");
      setReloadData(!reloadData);
    });
  }

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // setFile(fileUploaded);
    // setFileName(fileUploaded.name);

    dispatch({
      type: "field",
      payload: {
        fieldName: "profile_picture",
        value: fileUploaded.name,
      },
    });

    // console.log("fileUploaded", fileUploaded);
    updatePicture(fileUploaded);
  };

  const handleUpload = (e) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    try {
      dispatch({
        type: "previousData",
        payload: {
          given_name: userData.given_name,
          family_name: userData.family_name,
          profile_picture: userData.profile_picture,
        },
      });
    } catch (e) {
      console.log("error", e);
    }
  }, [userData]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior='outside'
        size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit user</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* First Name */}
            <Box my='4'>
              <FormControl id='name'>
                <FormLabel>User first name</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a first name'
                  value={state.given_name}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "given_name",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            {/* Family Name */}
            <Box my='4'>
              <FormControl id='name'>
                <FormLabel>User family name</FormLabel>
                <Input
                  variant='filled'
                  placeholder='Add a first name'
                  value={state.family_name}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "family_name",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>

            <input
              type='file'
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
            <Box>{state.profile_picture}</Box>
            {state?.profile_picture && (
              <Image
                borderRadius='lg'
                boxSize='200px'
                src={`https://aaliz317.twic.pics/${state?.profile_picture}`}
              />
            )}
            {/* <Img src={`https://aaliz317.twic.pics/photo%20florian.png`} /> */}

            <Button
              rounded='full'
              // isDisabled={uploadFiles.length > 0}
              variant='outline'
              size='sm'
              onClick={() => handleUpload()}>
              <IoCloudUpload />
            </Button>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={!state.given_name || !state.family_name}
              bgColor='primary'
              color='white'
              mr={3}
              onClick={save}
              isLoading={state.isLoadingOne}
              loadingText='Loading'>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

import React, { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import Card from "../../components/card";
import CardTitle from "../../components/card/CardTitle";

export default function About(props) {
  const { freelance } = props;
  // console.log("freelanceData", freelance);
  return (
    <Box>
      <CardTitle title='About' isActive={true} />
      <Text whiteSpace='pre-wrap' color='gray.500'>
        {freelance?.about}
      </Text>
    </Box>
  );
}

export const initialState = {
    _id: "",
    clientName:"",
    displayName:"",
    muxData: "",
    muxId: "",
    muxPlaybackId: "",
    name: "",
    notes:"",
    status:"",
    originalName: "",
    size: "",
    tags:"",
    taskName: "",
    type: "",
    uploader: "",
    videoName: "",
    // projectName: "",
  };


  export function reducer(state, action) {
    switch (action.type) {
      case "previousData": {
        return {
          ...state,
          _id: action.payload._id,
          clientName:action.payload.clientName,
          displayName:action.payload.displayName,
          muxData: action.payload.muxData,
          muxId: action.payload.muxId,
          muxPlaybackId: action.payload.muxPlaybackId,
          name: action.payload.name,
          notes:action.payload.notes,
          status: action.payload.status,
          originalName: action.payload.originalName,
          size: action.payload.size,
          tags:action.payload.tags,
          taskName: action.payload.taskName,
          type: action.payload.type,
          uploader: action.payload.uploader,
          videoName: action.payload.videoName,
        };
      }
  
      case "field": {
        return {
          ...state,
          [action.payload.fieldName]: action.payload.value,
        };
      }
      case "save": {
        return {
          ...state,
          isLoadingOne: true,
        };
      }
      case "delete": {
        return {
          ...state,
          isLoadingTwo: true,
        };
      }
   
  
      case "success": {
        return {
          ...state,
          isLoadingOne: false,
        };
      }
  
      case "error": {
        return {
          ...state,
          error: "there is an error",
        };
      }
      default:
        return state;
    }
  }
import React from "react";

import {
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuOptionGroup,
  Button,
  Checkbox,
  Switch,
  Box,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function NavBar(props) {
  const {
    // clients,
    // clientSelected,
    // setClientSelected,
    // people,
    // peopleSelected,
    // setPeopleSelected,
    admin,
    display,
    setDisplay,
    // category,
    // categorySelected,
    // setCategorySelected,
    accountManagerSelected,
    setAccountManagerSelected,

    // setStatusLate,
    // statusLate,
    // setStatusCurrent,
    // statusCurrent,
    // setStatusFuture,
    // statusFuture,

    // status,
    // setStatus,
    // statusNoDate,
    // setStatusNoDate,
  } = props;

  return (
    <HStack my="5">
      {/* Display filter */}
      {admin && (
        <Menu>
          <MenuButton
            as={Button}
            variant="outline"
            rounded="full"
            rightIcon={<ChevronDownIcon />}
          >
            {display === "all" && accountManagerSelected === "all"
              ? "All projects"
              : display === "all" &&
                accountManagerSelected === "Géraldine Million"
              ? "Géraldine Million"
              : display === "all" && accountManagerSelected === "Marion Gaufroy"
              ? "Marion Gaufroy"
              : display === "all" && accountManagerSelected === "Geoffroy Lasne"
              ? "Geoffroy Lasne"
              : display === "all" && accountManagerSelected === "Clara Colleoni"
              ? "Clara Colleoni"
              : "My projects"}
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                setDisplay("all");
                setAccountManagerSelected("all");
              }}
            >
              All projects
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDisplay("me");
                setAccountManagerSelected("all");
              }}
            >
              My projects
            </MenuItem>
            {/* <MenuItem
              onClick={() => {
                setDisplay("all");
                setAccountManagerSelected("Géraldine Million");
              }}
            >
              Géraldine Million
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDisplay("all");
                setAccountManagerSelected("Geoffroy Lasne");
              }}
            >
              Geoffroy Lasne
            </MenuItem>

            <MenuItem
              onClick={() => {
                setDisplay("all");
                setAccountManagerSelected("Marion Gaufroy");
              }}
            >
              Marion Gaufroy
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDisplay("all");
                setAccountManagerSelected("Clara Colleoni");
              }}
            >
              Clara Colleoni
            </MenuItem> */}
          </MenuList>
        </Menu>
      )}
    </HStack>
  );
}

import React, { useState, useReducer } from "react";
import { useUser } from "../../libs/UserContext";
import { initialState, reducer } from "./reducer";
import { customStyles } from "../../styles/customStyles";
import {
  Box,
  Flex,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import Select from "react-select";
import { createClient } from "./api";

export default function NewClient(props) {
  const { isOpen, onClose, reloadData, setReloadData } = props;
  const { admin } = useUser();
  const [state, dispatch] = useReducer(reducer, initialState);

  function save() {
    dispatch({ type: "save" });
    createClient(state, admin).then((response) => {
      dispatch({ type: "success" });
      onClose();
      setReloadData(!reloadData);
    });
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="outside"
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New client</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Name */}
            <Box my="4">
              <FormControl id="name">
                <FormLabel>Client name</FormLabel>
                <Input
                  variant="filled"
                  placeholder="Add a client name"
                  value={state.name}
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "name",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={!state.name}
              bgColor="primary"
              color="white"
              mr={3}
              onClick={save}
              isLoading={state.isLoadingOne}
              loadingText="Loading"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export const initialState = {
  name: "",
  pricing: "",
  quantity: "",
  status: { label: "", value: "" },
  description: "",
  category: "",
  currentClient: { label: "", value: "" },
  isLoadingOne: false,
  isLoadingTwo: false,
  error: "",
  currentGoals: "",
  wonDate: "",
  cancel: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case "previousData": {
      return {
        ...state,
        clients: action.payload.clients,
        name: action.payload.name,
        description: action.payload.description,
        pricing: action.payload.pricing,
        quantity: action.payload.quantity,
        status: action.payload.status,
        category: action.payload.category,
        currentGoals: action.payload.currentGoals,
        currentClientId: action.payload.currentClientId,
        currentClient: action.payload.currentClient,
        wonDate: action.payload.wonDate,
        cancel: action.payload.cancel,
      };
    }
    case "field": {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      };
    }
    case "save": {
      return {
        ...state,
        isLoadingOne: true,
      };
    }
    case "delete": {
      return {
        ...state,
        isLoadingTwo: true,
      };
    }
    case "error": {
      return { ...state, error: "there is an error" };
    }
    case "success": {
      return initialState;
    }

    default:
      return state;
  }
}

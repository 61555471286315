import { API } from "aws-amplify";



export async function fetchClients(clientId) {

  const url=`/contact/list/${clientId}`
  try{
   return await API.get("kronik", url, {})
  }
  catch(e) {
    return (e.message)
  }

}


export async function createCollab(
  projectId,
  admin,
  email,
  role,
  videoId,
  projectName,
  clientId
) {
  try {
    await API.post(
      "kronik",
      `/collaboration/create/${projectId}?admin=${admin}`,
      {
        body: {
          email: email,
          video: { role: role.value, video: videoId },
          name: projectName,
          clientId: clientId,
        },
      }
    );
    // console.log( 
    //   "Create collab",
    //   `/collaboration/create/${projectId}?admin=${admin}`
    // );
    // console.log("email", email);
    // console.log("role", role.value);
    // console.log("videoId", videoId);
    // console.log("name", projectName);
  } catch (e) {
    console.log(e.message);
  }
}

export async function createSuperCollab(
  projectId,
  admin,
  email,
  role,
  projectName,
  clientId
) {
  try {
    await API.post(
      "kronik",
      `/collaboration/create/${projectId}?admin=${admin}`,
      {
        body: {
          email: email,
          role: role.value,
          name: projectName,
          clientId: clientId,
        },
      }
    );
    // console.log(
    //   "Create supeer collab",
    //   `/collaboration/create/${projectId}?admin=${admin}`
    // );
    // console.log("email", email);
    // console.log("role", role.value);
    // console.log("name", projectName);
  } catch (e) {
    console.log(e.message);
  }
}

export async function putCollab(
  projectId,
  admin,

  role,
  videoId,
  currentAdmin,
  clientId
) {
  try {
    await API.put(
      "kronik",
      `/collaboration/update/${projectId}?admin=${admin}`,
      {
        body: {
          collabId: currentAdmin._id,
          video: { role: role.value, video: videoId },
          clientId: clientId,
        },
      }
    );
    // console.log("currentAdmin simple", currentAdmin);
  } catch (e) {
    console.log(e.message);
  }
}

export async function putSuperCollab(
  projectId,
  admin,
  role,
  currentAdmin,
  clientId
) {
  try {
    await API.put(
      "kronik",
      `/collaboration/update/${projectId}?admin=${admin}`,
      {
        body: {
          collabId: currentAdmin._id,
          role: role.value,
          clientId: clientId,
        },
      }
    );
    // console.log("currentAdmin super", currentAdmin);
  } catch (e) {
    console.log(e.message);
  }
}

export async function removeCollab(projectId, currentAdmin, admin) {
  try {
    await API.put(
      "kronik",
      `/collaboration/delete/${projectId}?admin=${admin}`,
      {
        body: {
          collabId: currentAdmin._id,
        },
      }
    );
  } catch (e) {
    console.log(e.message);
  }
}

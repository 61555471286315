import { Box, Button, Image, Img, useDisclosure } from "@chakra-ui/react";
import { addMilliseconds } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { IoCloudUpload } from "react-icons/io5";
import { useParams } from "react-router-dom";
import EditUser from "../../components/user/EditUser";
import { useUser } from "../../libs/UserContext";
import fetchUser, { updatePicture, updateUser } from "./api";

export default function User() {
  const [user, setUser] = useState("");
  const { userId } = useParams();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const hiddenFileInput = useRef(null);
  const { admin } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reloadData, setReloadData] = useState(false);
  useEffect(() => {
    fetchUser(userId).then((response) => {
      // console.log("response", response);
      setUser(response);
    });
  }, [reloadData]);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setFile(fileUploaded);
    setFileName(fileUploaded.name);
    console.log("fileUploaded", fileUploaded);
    updatePicture(fileUploaded);
  };

  const handleUpload = (e) => {
    hiddenFileInput.current.click();
  };

  return (
    <Box>
      {admin && (
        <Box>
          <Box>
            {user?.given_name} {user?.family_name} - {user?.user_role}
          </Box>
          <input
            type='file'
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          />
          <Box>{fileName}</Box>
          {user?.profile_picture && (
            <Image
              src={`https://aaliz317.twic.pics/${user?.profile_picture}`}
            />
          )}
          {/* <Img src={`https://aaliz317.twic.pics/photo%20florian.png`} /> */}

          <Button onClick={onOpen}>Open</Button>
          <Button
            rounded='full'
            // isDisabled={uploadFiles.length > 0}
            variant='outline'
            size='sm'
            onClick={() => handleUpload()}>
            <IoCloudUpload />
          </Button>
          <Button onClick={() => updateUser(user, fileName, admin)}>
            Update
          </Button>
        </Box>
      )}

      <EditUser
        userData={user}
        isOpen={isOpen}
        onClose={onClose}
        reloadData={reloadData}
        setReloadData={setReloadData}
      />
    </Box>
  );
}

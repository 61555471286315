import React, { useState } from "react";
import { API } from "aws-amplify";
import { getHours } from "date-fns";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

export default async function fetchTasks(display) {
  // return API.get("kronik", `/task/get/tasks?display=${display}`).then(
  return API.get("kronik", `/task/shooting?display=${display}`).then(
    (response) => {
      const shootingArray = response.filter(
        (item) =>
          item.category.value === "shooting" ||
          item.category.value === "interview"
      );
      // console.log("response 1", response);
      const taskArray = shootingArray.map((item) => {
        // account Manager array
        const array = [];
        item.admins &&
          item.admins.map((admin) => {
            if (admin.role === "accountManager") {
              array.push(admin.given_name + " " + admin.family_name);
            }
            return array;
          });

        // const date with or without hour
        const dateHours =
          getHours(Date.parse(item.dueDate)) == 0
            ? dayjs(item.dueDate).format("DD MMMM YYYY")
            : dayjs(item.dueDate).format("DD MMMM YYYY HH:mm");

        return {
          title: item.title && item.title,
          taskLeader: item.taskLead,
          accountManager: array,
          people:
            item.taskLead &&
            item.taskLead.given_name + " " + item.taskLead.family_name,
          // date: dayjs(item.dueDate).format("DD MMMM YYYY"),
          date: dateHours,
          dateJs: item.dueDate,
          status: item.status,
          validation: item.validation,
          brief: item.brief,
          link: item.link,
          subtitle: item.subtitle,
          projectName: item.projectName,
          projectId: item.project,
          videoName: item.videoName,
          videoId: item.video,
          dateStatus: item.taskStatus,
          clientName: item.clientName,
          category: item.category,
          admins: item.admins,
          id: item._id,
          private: item.private,
        };
      });

      return taskArray;
    }
  );
}

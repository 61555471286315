import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Flex, Spacer, useDisclosure } from "@chakra-ui/react";
import SideBar from "./SideBar";
import { useLocation, useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import {
  downloadFiles,
  fetchAdmins,
  fetchTask,
  fetchTasks,
  isAuthorized,
} from "./api";
import RightBar from "./RightBar";
import { Updating } from "../../components/notification/Updating";
import useFileDownloader from "../../hook/useFileDownloader";
import { Main } from "./Main";
import { MiniHeader } from "../../components/header/MiniHeader";
import {
  MdLink,
  MdLinkOff,
  MdOutlineCloseFullscreen,
  MdOutlineOpenInFull,
} from "react-icons/md";
import { HiPencilAlt } from "react-icons/hi";
import CardTitle from "../../components/card/CardTitle";
import { IoCloudDownload, IoCloudUpload } from "react-icons/io5";
import { v4 as uuidv4 } from "uuid";
import EditTask from "../../components/task/EditTask";
import Header from "./Header";

dayjs.locale("fr");

export default function Task() {
  const location = useLocation();
  const [download, downloaderComponent] = useFileDownloader();
  let { projectId, videoId, taskId } = useParams();
  // const { admin, freelance } = useUser();
  const { user, admin, uploadFiles, freelance } = useUser();

  const [timecode, setTimecode] = useState("");
  const [reloadData, setReloadData] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [tasksData, setTasksData] = useState([]);
  const [admins, setAdmins] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [reloadComments, setReloadComments] = useState("");
  const [largePlayer, setLargePlayer] = useState(false);
  const firstRenderStatus = useRef(true);
  const firstRenderValidation = useRef(true);
  const [active, setActive] = useState("Comments");
  const [vimeoUrl, setVimeoUrl] = useState("");
  const [vimeoId, setVimeoId] = useState("");
  const [googleDriveId, setGoogleDriveId] = useState("");
  const [vimeoCode, setVimeoCode] = useState("");
  const [googleProvider, setGoogleProvider] = useState("");
  const [vimeoProvider, setVimeoProvider] = useState("");
  const [notionProvider, setNotionProvider] = useState("");
  const [preselectedFiles, setPreselectedFiles] = useState("");

  const [activeFilter, setActiveFilter] = useState(false);

  const [currentFile, setCurrentFile] = useState(null);
  // const hiddenFileInput = useRef(null);

  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();

  useEffect(() => {
    // console.log("fetchTask");
    // console.log("Location", location);
    setIsLoading(true);

    fetchTask(projectId, taskId, admin).then((response) => {
      // console.log("response", response);
      setIsLoading(false);
      setTaskData(response);
      // const url = response.link
      const cutUrlSlash = response.link && response.link.split("/");
      const cutUrlDot = response.link && response.link.split(".");
      const indexReview =
        response.link &&
        response.link.split("/").findIndex((el) => el === "review");
      const indexFolders =
        response.link &&
        response.link.split("/").findIndex((el) => el === "folders");
      const indexGoogle =
        response.link &&
        response.link.split("/").findIndex((el) => el === "drive.google.com");
      const indexVimeo =
        response.link &&
        response.link.split("/").findIndex((el) => el === "vimeo.com");
      const indexNotion =
        response.link &&
        response.link.split(".").findIndex((el) => el === "notion");
      // const indexReview = response.cutUrlSlash
      setVimeoUrl(response.link && response.link.split("/"));
      setVimeoId(response.link && cutUrlSlash[parseInt(indexReview) + 1]);
      setGoogleDriveId(
        response.link && cutUrlSlash[parseInt(indexFolders) + 1].slice(0, 33)
      );
      setVimeoCode(response.link && cutUrlSlash[parseInt(indexReview) + 2]);
      setGoogleProvider(indexGoogle);
      setVimeoProvider(indexVimeo);
      setNotionProvider(indexNotion);
      // console.log(cutUrlSlash[5])
    });
    fetchAdmins(projectId, videoId, admin).then((response) => {
      setAdmins(response);
    });
    setActiveFilter(false);
  }, [reloadData, taskId]);

  useEffect(() => {
    fetchTasks(projectId, videoId, admin).then((response) => {
      // console.log("tasks", response.tasks)
      setTasksData(response.tasks);
    });
  }, [taskData, reloadData]);

  const hiddenFileInput = useRef(null);

  const handleUpload = (e) => {
    admin || freelance ? hiddenFileInput.current.click() : console.log("no");
  };

  // console.log("taskdata", taskData)
  return (
    <Box w='100%'>
      <Header
        taskData={taskData}
        isLoading={isLoading}
        onOpenEdit={onOpenEdit}
        setLargePlayer={setLargePlayer}
        largePlayer={largePlayer}
        uploadFiles={uploadFiles}
        currentFile={currentFile}
        preselectedFiles={preselectedFiles}
        projectId={projectId}
        handleUpload={handleUpload}
        download={download}
      />
      {downloaderComponent}
      <Flex justifyContent='space-between'>
        {!largePlayer && (
          <SideBar
            clientLogo={taskData?.project?.client.logo}
            projectName={taskData?.project?.name}
            clientName={taskData?.project?.client.name}
            videoName={taskData?.video?.title}
            taskName={taskData?.title}
            projectLink={`/quotes/${taskData?.project?._id}`}
            videoLink={`/projects/${taskData?.video?._id}/quotes/${taskData?.project?._id}`}
            clientLink={`/clients/${taskData?.project?.client?._id}`}
            isLoading={isLoading}
            admin={admin}
            freelance={freelance}
            tasksData={tasksData}
            taskId={taskId}
            videoStatus={taskData?.video?.newStatus?.value}
            quoteStatus={taskData?.project?.status?.value}
          />
        )}

        {/* <Box width='100%'> */}
        <Main
          projectId={projectId}
          taskData={taskData}
          isLoading={isLoading}
          largePlayer={largePlayer}
          setLargePlayer={setLargePlayer}
          reloadComments={reloadComments}
          setReloadComments={setReloadComments}
          firstRenderStatus={firstRenderStatus}
          setIsLoading={setIsLoading}
          setComments={setComments}
          comments={comments}
          firstRenderValidation={firstRenderValidation}
          setActive={setActive}
          admins={admins}
          timecode={timecode}
          setTimecode={setTimecode}
          reloadData={reloadData}
          setReloadData={setReloadData}
          vimeoId={vimeoId}
          vimeoProvider={vimeoProvider}
          handleUpload={handleUpload}
          hiddenFileInput={hiddenFileInput}
          currentFile={currentFile}
          setCurrentFile={setCurrentFile}
          preselectedFiles={preselectedFiles}
          setPreselectedFiles={setPreselectedFiles}
          setGoogleProvider={setGoogleProvider}
          googleProvider={googleProvider}
          notionProvider={notionProvider}
          setNotionProvider={setNotionProvider}
        />
        {/* </Box> */}
        <RightBar
          taskData={taskData}
          projectId={projectId}
          videoId={videoId}
          clientId={taskData?.project?.client?._id}
          projectName={taskData?.project?.name}
          videoName={taskData?.video?.name}
          setReloadData={setReloadData}
          firstRenderStatus={firstRenderStatus}
          firstRenderValidation={firstRenderValidation}
          isLoading={isLoading}
          comments={comments}
          reloadComments={reloadComments}
          setReloadComments={setReloadComments}
          setIsLoading={setIsLoading}
          timecode={timecode}
          setTimecode={setTimecode}
          active={active}
          setActive={setActive}
        />
      </Flex>
      <EditTask
        projectId={projectId}
        videoId={taskData?.video?._id}
        projectName={taskData?.project?.name}
        videoName={taskData?.video?.name}
        admins={admins && admins}
        isOpen={isOpenEdit}
        onOpen={onOpenEdit}
        onClose={onCloseEdit}
        task={taskData}
        reloadData={reloadData}
        setReloadData={setReloadData}
        firstRenderStatus={firstRenderStatus}
        firstRenderValidation={firstRenderValidation}
        taskId={taskData?._id}
      />
      {isLoading && <Updating />}
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import {
  Flex,
  Button,
  Box,
  Grid,
  Heading,
  useDisclosure,
  HStack,
  IconButton,
  Text,
} from "@chakra-ui/react";
// import VideosList from "./VideosList";
// import ShootingList from "./ShootingL  ist";
import { EditProject } from "../../components/project/EditProject";
import NewVideo from "../../components/video/NewVideo";
// import Team from "./Team";
import About from "./About";
// import OrderSummary from "./OrderSummary";
import { HiPencilAlt, HiOutlineOfficeBuilding } from "react-icons/hi";
import { AiTwotoneFolderOpen } from "react-icons/ai";
import SideBar from "./SideBar";
import RightBar from "./RightBar";
import { Updating } from "../../components/notification/Updating";
import { fetchClient, fetchProjects } from "./api";
import ProjectsList from "./ProjectsList";
import VideosList from "./VideosList";
import MainColumn from "../../components/column/MainColumn";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";

export default function Main({
  project,
  projects,
  setProjects,
  videos,
  setVideos,
  client,
  setClient,
  setClientLogo,
}) {
  let { clientId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { admin } = useUser();
  //   const [client, setClient] = useState("");
  const [reloadData, setReloadData] = useState(false);
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();

  return (
    <MainColumn>
      <Box>
        <MainColumn>
          <Flex direction='column' mx='10px'>
            {project?.project?.description && (
              <About projectData={project?.project} />
            )}
            {admin && (
              <ProjectsList
                setReloadData={setReloadData}
                reloadData={reloadData}
                admin={admin}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                clientId={clientId}
                setClient={setClient}
                client={client}
                projects={projects}
                setProjects={setProjects}

                // setProject={setProject}
              />
            )}

            {!admin && (
              <VideosList
                videos={videos}
                setReloadData={setReloadData}
                reloadData={reloadData}
                projectData={project?.project}
                project={project}
                // projectId={projectId}
                projectName={project?.project?.name}
                admin={admin}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                clientId={clientId}
                setVideos={setVideos}
                setClient={setClient}
                setClientLogo={setClientLogo}
              />
            )}
          </Flex>
        </MainColumn>
      </Box>
    </MainColumn>
  );
}

import React, { useReducer, useEffect, useState } from 'react'
import { Box, Button, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Switch, Text, Textarea, useColorMode } from '@chakra-ui/react';
import Select from "react-select";
import { initialState, reducer } from "./reducer";
import { customStyles, darkStyles } from "../../styles/customStyles";
// import { Switch } from 'react-router-dom';
import { useUser } from "../../libs/UserContext";
import { tagsOptions } from '../../data/uploadData';
import { updateFile } from './api';


export default function EditUpload(props) {
    const { colorMode } = useColorMode();
    const { user, admin } = useUser();

    const [state, dispatch] = useReducer(reducer, initialState);
    const [dataDeleted, setDataDeleted] = useState("");
    const [confirmMessage, setConfirmMessage] = useState("");
    const [deleteButton, setDeleteButton] = useState(false);

    const {
      file,
      isOpen,
      onClose,
      projectId,
      taskId,
      videoId,
      clientId,
      setReloadData,
      reloadData,
    } = props

    // console.log("current file", file)

    useEffect(()=> {
      dispatch({
        type: "previousData",
        payload: {
          _id: file && file._id,
          clientName: file && file.clientName,
          displayName: file && file.displayName,
          muxData: file && file.muxData,
          muxId: file && file.muxId,
          muxPlaybackId: file && file.muxPlaybackId,
          name: file && file.name,
          notes:file && file.notes,
          status: file && file.status,
          originalName: file && file.originalName,
          size: file && file.size,
          tags:file && file.tags,
          taskName: file && file.taskName,
          type: file && file.type,  
          uploader: file && file.uploader,
          videoName: file && file.videoName,
        }   
      })
    },[file])

    async function  save () {
      await updateFile(
        state,
        file._id,
        projectId,
        taskId,
        videoId,
        clientId,
        admin)
      onClose();
      setReloadData(!reloadData);
      // setPreselectedFiles([]);
      // setTags([]);
        // console.log("save")
    }

    return (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          scrollBehavior="outside"
          size="2xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* Title */}
              <Box my="4">
                <FormControl id="subtitle">
                  <FormLabel>Name</FormLabel>
                  <Input
                    variant="filled"
                    placeholder="Add a name"
                    value={state.displayName}
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "displayName",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </FormControl>
              </Box>
              {/* Brief*/}
              <Box my="4">
                <FormLabel>Note</FormLabel>
                <Textarea
                  variant="filled"
                  placeholder="Write a quick note"
                  size="md"
                  resize="vertical"
                  value={state.notes}
                  onChange={(e) => {
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "notes",
                        value: e.target.value,
                      },
                    });
                  }}
                />
              </Box>
              
    
            
    
             
    
              {/* Casting */}
              <Box my="4">
                <FormLabel>Tags</FormLabel>
    
                <Select
                  styles={colorMode === "light" ? customStyles : darkStyles}
                  isMulti
                  isClearable
                  placeholder="Select tags"
                  value={state.tags}
                  onChange={(e) => {
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "tags",
                        value: e,
                      },
                    });
                  }}
                  options={tagsOptions}
                />
              </Box>
   
    
              {/* Cancel switch */}
              <Box my="4">
                <FormLabel>Status</FormLabel>
                <Flex>
                  <Flex maxW="200">
                  
                    <Switch
                      size="lg"
                      colorScheme="red"
                      isChecked={state.status === "deleted" ? true : false}
                      onChange={(e) => {
                        dispatch({
                          type: "field",
                          payload: {
                            fieldName: "status",
                            value: e.currentTarget.checked ? "deleted"  : "active",
                          },
                        });
                      }}
                    />
                      <Text ml="10px" >{state.status === "deleted" ? "Deleted" : "Active"}</Text>

               
                  </Flex>
                </Flex>
              </Box>
            </ModalBody>
    
            <ModalFooter>
              {/* {(user.username === "florian@kronik.com" ||
                user.username === "matt@kronik.com") &&
                !deleteButton && (
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => setDeleteButton(true)}
                  >
                    Delete
                  </Button>
                )} */}
                 <Button
                  // colorScheme="blue"
                  mr={3}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
              {!deleteButton && (
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={() => save()}
                  isLoading={state.isLoadingOne}
                  loadingText="Loading"
                >
                  Save
                </Button>
              )}
               
              {/* {deleteButton && (
                <Box mr="20px">
                  <FormControl id="confirmation">
                    <FormLabel>
                      You will delete this video and all the linked tasks
                    </FormLabel>
                    <Input
                      variant="filled"
                      placeholder="Enter DELETE to confirm"
                      value={confirmMessage}
                      onChange={(e) => setConfirmMessage(e.target.value)}
                    />
                  </FormControl>
                </Box>
              )} */}
              {/* {deleteButton && (
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={() => setDeleteButton(false)}
                >
                  Cancel
                </Button>
              )} */}
              {/* {confirmMessage === "DELETE" && deleteButton && (
                <Button
                  bgColor="primary"
                  color="white"
                  mr={3}
                  onClick={() => remove()}
                  isLoading={state.isLoadingTwo}
                  loadingText="Loading"
                >
                  Delete
                </Button>
              )} */}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )
  
}

import { CheckIcon, DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  useColorMode,
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Text,
  Textarea,
  Image,
  Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ButtonColor from "../../components/button/ButtonColor";
import { SubMenuButton } from "../../components/button/SubMenuButton";
import {
  deleteComment,
  postCommment,
  updateComment,
  updateStatus,
} from "./api";

import { format } from "date-fns";
import dayjs from "dayjs";
import { Reply } from "./Reply";

export function Comment({
  comment,
  reloadCommments,
  setReloadComments,
  setIsLoading,
  timecode,
  setTimecode,
  videoId,
  projectId,
  clientId,
  relatedComments,
  user,
  admin,
  key,
}) {
  // console.log("comment", comment.text + " " + comment._id)
  // console.log("relatedComments", relatedComments)
  // console.log("admin", admin)
  const [isUpdating, setIsUpdating] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [showInputReply, setShowInputReply] = useState(false);
  const [newText, setNewText] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [reply, setReply] = useState("");
  const [showReply, setShowReply] = useState(false);
  const { colorMode } = useColorMode();

  const items = [
    {
      title: !showInput ? "Edit" : "Close",
      click: () => setShowInput(!showInput),
    },
    {
      title: "Delete",
      click: () => {
        // console.log("delete comment", admin)
        deleteComment(comment._id, comment.project, admin).then(
          (response) => setReloadComments(Math.random()),
          setIsLoading(true)
          //   console.log("reloadCommments", response)
        );

        console.log("reload Comment");
      },
    },
  ];

  useEffect(() => {
    // setNewText(comment.text)
    setIsCompleted(comment?.status?.value === "done" ? true : false);
  }, [comment]);

  function showEditor(e, comment) {
    e.stopPropagation();
    setNewText(comment.text);
    setShowInput(!showInput);
  }

  function removeEditor(e) {
    e.stopPropagation();
    setShowInput(!showInput);
  }

  function updateNewStatus(e, text) {
    e.stopPropagation();
    setIsLoading(true);
    // setIsCompleted(!isCompleted)
    const newStatus =
      comment?.status?.value === "todo"
        ? { label: "Done", value: "done" }
        : { label: "Todo", value: "todo" };
    updateStatus(
      comment.project,
      comment._id,
      comment.urlPreview,
      comment.timecode,
      text,
      comment.category,
      newStatus,
      admin
    ).then((response) => {
      setReloadComments(Math.random());
      setIsLoading(false);
      // setShowInput(!showInput)
    });
  }

  function deleteEdit(e) {
    e.stopPropagation();
    deleteComment(comment._id, comment.project, admin).then(
      (response) => setReloadComments(Math.random()),
      setIsLoading(true)
    );
  }

  function showTextAreaReply(e) {
    e.stopPropagation();
    setShowReply(!showReply);
  }

  function removeEditorReply(e) {
    e.stopPropagation();
    setShowReply(!showReply);
  }

  function sendReplyComment(e) {
    e.stopPropagation();
    // console.log("reply", reply)
    // console.log("comment", comment)
    // setComment("")
    setIsLoading(true);
    postCommment(
      comment.urlPreview,
      comment.timecode,
      reply,
      null,
      null,
      comment._id,
      comment.muxId,
      comment.muxPlaybackId,
      comment.file._id,
      comment.task,
      videoId,
      projectId,
      clientId,
      admin
    )
      .then((response) => setReloadComments(Math.random()))
      .then((response) => setIsLoading(false));
    setShowReply(!showReply);
    setReply("");
  }

  function updateTextComment(e) {
    e.stopPropagation();
    setIsLoading(true);
    updateComment(
      comment.project,
      comment._id,
      comment.urlPreview,
      comment.timecode,
      newText,
      comment.category,
      comment.status,
      admin
    ).then((response) => {
      setReloadComments(Math.random());
      setIsLoading(false);
      setShowInput(!showInput);
    });
  }

  function playTimecode() {
    if (comment.timecode) {
      // console.log("timecode", comment.timecode)
      setTimecode(comment.timecode);
    } else {
      console.log("no timecode");
    }
  }

  const formatted = format(
    parseFloat(comment.timecode ? comment.timecode : 0) * 1000,
    "mm:ss"
  );

  // console.log("comment", comment);
  return (
    <Box
      minW='240px'
      index={key}
      _hover={{
        cursor: "pointer",
        bgColor: colorMode === "light" ? "gray.100" : "blackAlpha.300",
      }}
      py='20px'
      px='5px'
      // mx="20px"
      onClick={() => playTimecode()}
      onMouseEnter={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}
      // bgColor={colorMode === "light" ? "white" : "#232323"}
      // mb='20px'
      borderRadius='5px'>
      <Grid>
        <Flex
          alignItems='center'
          fontSize='xs'
          color={colorMode === "light" ? "gray.600" : "whiteAlpha.600"}
          minH='25px'>
          <Flex>
            {comment?.author?.profile_picture && (
              <Image
                mr='10px'
                borderRadius='full'
                boxSize='22px'
                src={`https://aaliz317.twic.pics/${comment.author.profile_picture}`}
                alt={` Picture of {item.taskLeader.given_name}`}
              />
            )}
            {!comment?.author?.profile_picture && (
              <Avatar
                mr='10px'
                size='sm'
                name={
                  comment?.author?.given_name +
                  " " +
                  comment?.author?.family_name
                }
                bgColor='blackAlpha.500'
                color='white'
              />
            )}
          </Flex>
          <Flex>
            <Text pr='5px'>
              {comment?.author?.given_name + " " + comment?.author?.family_name}
            </Text>
            <Text fontSize='xs'> {comment.timecode && "at " + formatted}</Text>
          </Flex>

          <Spacer />

          {showEdit && (
            <Flex>
              <IconButton
                bgColor={
                  isCompleted
                    ? "primary"
                    : colorMode === "light"
                    ? "white"
                    : "gray.900"
                }
                color={
                  isCompleted
                    ? "white"
                    : colorMode === "light"
                    ? "inerit"
                    : "white"
                }
                onClick={(e) => updateNewStatus(e, comment.text)}
                // isLoading={isUpdating}
                // loadingText='Submitting'
                icon={<CheckIcon />}
                variant='outline'
                size='xs'
                rounded='full'
                _hover={{
                  bgColor: isCompleted ? "primary" : "white",
                  color: isCompleted ? "white" : "red",
                }}></IconButton>

              {user.username === comment.author.email && (
                <IconButton
                  onClick={(e) => showEditor(e, comment)}
                  // isLoading={isUpdating}
                  // loadingText='Submitting'
                  icon={<EditIcon />}
                  variant='outline'
                  size='xs'
                  rounded='full'
                />
              )}

              {user.username === comment.author.email && (
                <IconButton
                  onClick={(e) => deleteEdit(e)}
                  // isLoading={isUpdating}
                  // loadingText='Submitting'
                  icon={<DeleteIcon />}
                  variant='outline'
                  size='xs'
                  rounded='full'
                />
              )}
            </Flex>
          )}
        </Flex>

        {!showInput && (
          <Box>
            <Flex justifyContent='space-between'>
              <Text
                whiteSpace='pre-wrap'
                overflowWrap='anywhere'
                fontSize='sm'
                color={
                  isCompleted
                    ? colorMode === "light"
                      ? "gray.400"
                      : "whiteAlpha.700"
                    : "inerit"
                }
                textDecoration={isCompleted ? "line-through" : "normal"}>
                {comment.text}
              </Text>
              {/* {comment.urlPreview  &&
                <Img  mt="5px" mr="10px" opacity={isCompleted ? "0.3" : "1"} boxSize='50px' objectFit='cover' src={comment.urlPreview ? comment.urlPreview : ""}/>} */}
            </Flex>
            <Box>
              <Button
                variant='link'
                size='xs'
                onClick={(e) => showTextAreaReply(e)}>
                Reply
              </Button>
            </Box>
          </Box>
        )}
        {showReply && (
          <Box alignItems='center'>
            <Textarea
              bgColor={colorMode === "light" ? "white" : "#232323"}
              fontSize='md'
              value={reply}
              onChange={(e) => setReply(e.target.value)}
              pr='4.5rem'
              size='lg'
            />
            <Flex>
              <Box mr='5px'>
                <ButtonColor
                  mt='10px'
                  title='Send'
                  click={sendReplyComment}
                  size='sm'
                />
              </Box>
              <ButtonColor
                mt='10px'
                bg='gray.300'
                color='gray.600'
                size='sm'
                title='Cancel'
                click={(e) => removeEditorReply(e)}>
                Cancel
              </ButtonColor>
            </Flex>
          </Box>
        )}
        {showInput && (
          <Box alignItems='center'>
            {/* <InputGroup size='md'> */}
            <Textarea
              bgColor={colorMode === "light" ? "white" : "gray.900"}
              fontSize='md'
              value={newText}
              onChange={(e) => setNewText(e.target.value)}
              pr='4.5rem'
              size='lg'
            />
            {/* </InputGroup> */}
            <Flex>
              <Box mr='5px'>
                <ButtonColor
                  mt='10px'
                  title='Save'
                  click={updateTextComment}
                  size='sm'
                />
              </Box>
              <ButtonColor
                mt='10px'
                bg='gray.300'
                color='gray.600'
                size='sm'
                title='Cancel'
                click={(e) => removeEditor(e)}>
                Cancel
              </ButtonColor>
            </Flex>
          </Box>
        )}

        {/* related commment */}
        {/* related commment */}
        {relatedComments.map((relatedComment, index) => {
          return (
            <Reply
              relatedComment={relatedComment}
              index={index}
              isCompleted={isCompleted}
              setIsLoading={setIsLoading}
              setReloadComments={setReloadComments}
              videoId={videoId}
              projectId={projectId}
              clientId={clientId}
              user={user}
              originalComment={comment}
            />
          );
        })}
      </Grid>
    </Box>
  );
}

import React, { useState, useEffect } from "react";

import {
  Box,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormLabel,
} from "@chakra-ui/react";
import ButtonIcon from "../../components/button/ButtonIcon";

import { updateStatus } from "./api";
import { statusOptionsWithIcon } from "../../data/taskData";
import { validationOptionsWithIcon } from "../../data/taskData";
import { useUser } from "../../libs/UserContext";
export default function UpdateStatus(props) {
  const {
    isOpen,
    onClose,
    taskData,

    projectId,
    videoId,
    projectName,
    videoName,
    setReloadData,
    taskId,
    firstRenderStatus,
    firstRenderValidation,
  } = props;
  const [status, setStatus] = useState("");
  const [statusDate, setStatusDate] = useState("");
  const [validation, setValidation] = useState("");
  const [validationDate, setValidationDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { admin, freelance, client } = useUser();

  useEffect(() => {
    // console.log("taskData", taskData);
    setStatus(taskData?.status);
    setValidation(taskData?.validation);
    setStatusDate(taskData?.statusDate);
    setValidationDate(taskData?.validationDate);
  }, [taskData]);

  function update() {
    setReloadData(false);
    setIsLoading(true);
    updateStatus(
      taskData,
      admin,
      projectId,
      videoId,
      projectName,
      videoName,
      status,
      validation,
      taskId,
      statusDate,
      validationDate
    ).then((response) => {
      // console.log("tastk update", status);
      // console.log("response", response);
      onClose();
      setReloadData(true);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (firstRenderStatus.current) {
      firstRenderStatus.current = false;
    } else {
      if (status && status.value === "done") {
        setStatusDate(new Date());
      }
    }
  }, [firstRenderStatus, status]);

  useEffect(() => {
    if (firstRenderValidation.current) {
      firstRenderValidation.current = false;
    } else {
      if (
        (validation && validation.value === "validated") ||
        (validation && validation.value === "corection")
      ) {
        setValidationDate(new Date());
      }
    }
  }, [firstRenderValidation, validation]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior='outside'
      size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize='3xl'>Update</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {(admin || freelance) && (
            <Box marginBottom='40px'>
              <FormLabel>What is the status ?</FormLabel>
              {statusOptionsWithIcon.map((item, id) => {
                return (
                  <ButtonIcon
                    key={id}
                    title={item.label}
                    click={() =>
                      setStatus({ value: item.value, label: item.label })
                    }
                    checked={status?.value === item.value ? true : false}
                    icon={item.icon}
                  />
                );
              })}
            </Box>
          )}

          {(admin || client) && (
            <Box marginBottom='40px'>
              <FormLabel>Is it validated ?</FormLabel>
              {validationOptionsWithIcon.map((item, id) => {
                return (
                  <ButtonIcon
                    key={id}
                    title={item.label}
                    click={() =>
                      setValidation({ value: item.value, label: item.label })
                    }
                    checked={validation?.value === item.value ? true : false}
                    icon={item.icon}
                  />
                );
              })}
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            bgColor='primary'
            color='white'
            mr={3}
            onClick={() => update()}
            isLoading={isLoading}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import React,{ useEffect, useState } from "react"
import videojs from "@mux/videojs-kit";
import "@mux/videojs-kit/dist/index.css";
import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import { VideoPlayer } from "./VideoPlayer";
import {
    Box,
    Flex,
    IconButton,
    Grid,
    Button,
    Heading,
    useColorMode,
    Text,
    useDisclosure,
    Avatar,
    AvatarGroup,
    Link,
  } from "@chakra-ui/react";

  import { Updating } from "../../components/notification/Updating";

export function Player () {
    const [currentTime, setCurrentTime] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { user, admin } = useUser();
    let { projectId, videoId, taskId, playerId } = useParams();


    useEffect(() => {
        const player = videojs("my-player", {
          timelineHoverPreviews: true,
          plugins: {
            mux: {
              debug: false,
              data: {
                env_key: "gjXXXXXX", // pass your own personal key in an `.env` file
                video_title: "Example Title"
              }
            }
          }
        });
    
        player.src({
          // this is the public playback ID without the 'https://stream.mux.com/' prefix
          src: "6kzTcY5Vk0101sGTdvHWA4vxCZ1MIKohQPqGcqTQ00k4Ws",
          type: "video/mux"
        });
    
        player.on("timeupdate", () => {
          setCurrentTime(player.currentTime());
        });
      }, []);

    return (

        <>
      {/* <SideBar
        projectName={taskData?.project?.name}
        clientName={taskData?.project?.client.name}
        videoName={taskData?.video?.title}
        taskName={taskData?.title}
        projectLink={`/projects/${taskData?.project?._id}`}
        videoLink={`/videos/${taskData?.video?._id}/projects/${taskData?.project?._id}`}
        clientLink={`/clients/${taskData?.project?.client?._id}`}
        isLoading={isLoading}
        admin={admin}
      /> */}
      <Box
        as="main"
        py={{ base: "80px", sm: "30px" }}
        q
        // px={{ base: "20px", sm: "40px" }}
        ml="240px"
      >
        <Flex justifyContent="space-between">
          <Flex>
            <Flex direction="column">
              <Heading as="h1" fontSize="2xl" mb="1">
                <Flex>
                  {/* {taskData?.title} */}
                  {/* {admin && !isLoading && (
                    <IconButton
                      aria-label="Edit"
                      icon={<HiPencilAlt />}
                      rounded="full"
                      size="sm"
                      onClick={() => onOpenEdit()}
                      mx="10px"
                    />
                  )} */}
                </Flex>
              </Heading>
            </Flex>
          </Flex>
          <Flex alignItems="center"></Flex>
        </Flex>
        <Box py="0px" mt="20px" rounded="md">
          <Grid templateColumns="5fr 2fr" gap="20px">
            <Box>
              {/* {taskData?.brief && (
                <Box mb="40px">
                  <Brief brief={taskData?.brief} />
                </Box>
              )} */}

              <VideoPlayer/>
            </Box>

            <Box>
              {/* <RightBar
                taskData={taskData}
                projectId={projectId}
                videoId={videoId}
                projectName={taskData?.project?.name}
                videoName={taskData?.video?.name}
                setReloadData={setReloadData}
                firstRenderStatus={firstRenderStatus}
                firstRenderValidation={firstRenderValidation}
                isLoading={isLoading}
              /> */}
            </Box>
          </Grid>
        </Box>
      </Box>

      {isLoading && <Updating />}

      {/* <EditTask
        projectId={projectId}
        videoId={taskData?.video?._id}
        projectName={taskData?.project?.name}
        videoName={taskData?.video?.name}
        admins={admins && admins}
        isOpen={isOpenEdit}
        onOpen={onOpenEdit}
        onClose={onCloseEdit}
        task={taskData}
        reloadData={reloadData}
        setReloadData={setReloadData}
        firstRenderStatus={firstRenderStatus}
        firstRenderValidation={firstRenderValidation}
        taskId={taskData?._id}
      /> */}
    </>

        
    )
}
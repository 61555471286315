import React, { useState, useEffect } from "react";
import {
  Flex,
  Heading,
  Box,
  Button,
  useDisclosure,
  Skeleton,
  SkeletonText,
  useColorMode,
  Text,
  Spacer,
} from "@chakra-ui/react";
import { MdAddBox } from "react-icons/md";

import fetchProjects from "./api";
import ProjectsList from "./ProjectsListOld";
import NavBar from "./NavBar";
import NewProject from "../../components/project/NewProject";
import { useUser } from "../../libs/UserContext";
import { differenceInCalendarDays } from "date-fns/esm";
import { GroupByMonth } from "./GroupByMonth";
import { GroupByClient } from "./GroupByClient";
import TasksSkeleton from "../../components/placeholder/TasksSkeleton";
import { Updating } from "../../components/notification/Updating";
import { useLocation } from "react-router-dom";
import ProjectsLists from "./ProjectsLists";
import CardTitle from "../../components/card/CardTitle";
import { MiniHeader } from "../../components/header/MiniHeader";
import MainColumn from "../../components/column/MainColumn";
import BusinessHeader from "../../components/header/BusinessHeader";

export default function Projects() {
  const { colorMode } = useColorMode();
  const { user, admin, freelance, client } = useUser();
  const [display, setDisplay] = useState("me");
  const [data, setData] = useState("");
  const [projects, setProjects] = useState("");
  const [clients, setClients] = useState("");
  const [clientsList, setClientsList] = useState("");
  const [clientSelected, setClientSelected] = useState("all");
  const [allProjects, setAllProjects] = useState("");
  const [accountManagerSelected, setAccountManagerSelected] = useState("all");
  const [reloadData, setReloadData] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [status, setStatus] = useState([
    "preparation",
    "production",
    "blocked",
    "finished",
    "noStatus",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [queryStatus, SetQueryStatus] = useState("");
  const [queryClient, SetQueryClient] = useState("");
  const [queryDisplay, SetQueryDisplay] = useState("");
  const [queryManager, SetQueryManager] = useState("");
  const [queryCancel, SetQueryCancel] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState("");

  useEffect(() => {
    setIsLoading(true);
    queryDisplay &&
      fetchProjects(queryDisplay).then((response) => {
        setData(response);
        setProjects(response);
        setIsLoading(false);
      });
  }, [queryDisplay, accountManagerSelected, reloadData]);

  useEffect(() => {
    const clientsArray = projects && projects.map((item) => item.client.name);

    setClientsList(projects && [...new Set(clientsArray.sort())]);
  }, [projects]);

  function useQuery() {
    // const { search } = useLocation();
    // return React.useMemo(() => new URLSearchParams(search), [search]);
    return new URLSearchParams(useLocation().search);
  }

  const location = useLocation();

  let query = useQuery();

  useEffect(() => {
    SetQueryStatus(query.get("status") ? query.get("status") : "all");
    SetQueryClient(query.get("client") ? query.get("client") : "all");
    SetQueryDisplay(
      query.get("display")
        ? query.get("display")
        : user.username === "florian@kronik.com" ||
          user.username === "christophe@kronik.com" ||
          user.username === "mathieu@kronik.com"
        ? "all"
        : "me"
    );
    SetQueryManager(query.get("manager") ? query.get("manager") : "all");
    SetQueryCancel(query.get("cancel") ? query.get("cancel") : "false");
  }, [location, projects]);

  useEffect(() => {
    setFilteredProjects(
      projects &&
        projects.filter(
          (project) =>
            (queryClient === "all" ||
              project?.clientName?.toLowerCase() ===
                queryClient?.toLowerCase()) &&
            (queryStatus === "all" ||
              project?.status?.toLowerCase() === queryStatus.toLowerCase()) &&
            (project.accountManager.includes(queryManager?.toLowerCase()) ||
              queryManager === "all") &&
            project?.cancel.toString() === queryCancel
        )
    );
  }, [
    isLoading,
    queryStatus,
    queryClient,
    queryDisplay,
    queryManager,
    queryCancel,
  ]);

  return (
    <>
      {(admin || client) && (
        <MainColumn>
          <BusinessHeader />
          <MainColumn>
            <Box px='20px'>
              <NavBar
                clients={clientsList}
                admin={admin}
                freelance={freelance}
                queryStatus={queryStatus}
                queryClient={queryClient}
                queryDisplay={queryDisplay}
                queryManager={queryManager}
                queryCancel={queryCancel}
                onOpen={onOpen}
                isLoading={isLoading}
              />

              {isLoading && <Updating />}
              {(admin || client) && filteredProjects && !isLoading ? (
                <GroupByMonth
                  projects={filteredProjects}
                  admin={admin}
                  reloadData={reloadData}
                  setReloadData={setReloadData}
                />
              ) : (
                // <Box>yo</Box>
                // <ProjectsList projects={projects} admin={admin}/>
                <Box>
                  {filteredProjects && !isLoading && (
                    <ProjectsLists projects={filteredProjects} admin={admin} />
                  )}
                </Box>

                // <GroupByClient
                //   projects={projects}
                //   admin={admin}
                //   setReloadData={setReloadData}
                // />
              )}
            </Box>
          </MainColumn>
          <NewProject
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            reloadData={reloadData}
            setReloadData={setReloadData}
          />
        </MainColumn>
      )}
    </>
  );
}

export const initialState = {
    email:"",
    given_name:"",
    family_name:"", 
    address:"",
    client:"",
    phone:"", 
    role:"",
    job:"",
    // sendEmail:true,
    isLoadingOne: false,
    error: "",
  };
  
  export function reducer(state, action) {
    switch (action.type) {
      case "previousData": {
        return {
          ...state,
          email:action.payload.email,
          given_name:action.payload.given_name,
          family_name:action.payload.family_name, 
          address:action.payload.address,
          client:action.payload.client,
          phone:action.payload.phone, 
          role:action.payload.role,
          job:action.payload.job
        };
      }
      case "field": {
        return {
          ...state,
          [action.payload.fieldName]: action.payload.value,
        };
      }
      case "save": {
        return {
          ...state,
          isLoadingOne: true,
        };
      }
      case "delete": {
        return {
          ...state,
          isLoadingTwo: true,
        };
      }
      case "error": {
        return { ...state, error: "there is an error" };
      }
      case "success": {
        return initialState;
      }
  
      default:
        return state;
    }
  }
  
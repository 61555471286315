import React, { useState } from "react";
import dayjs from "dayjs";
import { useUser } from "../../libs/UserContext";

import {
  Box,
  Avatar,
  Grid,
  Button,
  Heading,
  useColorMode,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Link,
  useDisclosure,
  IconButton,
  Stack,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { AiFillFolder } from "react-icons/ai";
import { FaMicrophone, FaCalendar } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import { GoDeviceCameraVideo } from "react-icons/go";
import {
  IoTime,
  IoPeople,
  IoChatboxEllipsesSharp,
  IoLanguageSharp,
} from "react-icons/io5";
import {
  MdPhotoSizeSelectLarge,
  MdSubtitles,
  MdPlace,
  MdVideoLibrary,
} from "react-icons/md";
import { Link as RouteLink } from "react-router-dom";
import Card from "../../components/card";
import { statusSwitch, validationSwitch } from "../../data/taskData";
import ButtonColor from "../../components/button/ButtonColor";
import UpdateStatus from "../../components/task/UpdateStatus";
import { IoFlash } from "react-icons/io5";
import { Comment } from "./Comment";

export default function Comments({
  isLoading,
  comments,
  reloadComments,
  setReloadComments,
  setIsLoading,
  timecode,
  setTimecode,
  videoId,
  projectId,
  clientId,
}) {
  const { admin } = useUser();
  const { colorMode } = useColorMode();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useUser();

  return (
    <Flex overflowY='auto' height='100vh'>
      <Box p='0'>
        {comments &&
          comments
            .sort(function (a, b) {
              return Number(a.timecode) - Number(b.timecode);
            })
            .map((comment, index) => {
              const relatedComments = comments.filter(
                (item) => item?.relatedComment?._id === comment._id
              );

              return (
                <>
                  {!comment.relatedComment && (
                    <>
                      <Comment
                        comment={comment}
                        key={index}
                        reloadComments={reloadComments}
                        setReloadComments={setReloadComments}
                        setIsLoading={setIsLoading}
                        timecode={timecode}
                        setTimecode={setTimecode}
                        videoId={videoId}
                        projectId={projectId}
                        clientId={clientId}
                        relatedComments={relatedComments}
                        user={user}
                        admin={admin}
                      />
                      <Divider />
                    </>
                  )}
                </>
              );
            })}
      </Box>
      {/* {!isLoading && (
       
        <ButtonColor
          title={
            <Flex alignItems="center">
              <Box mr="5px">
                <IoFlash />{" "}
              </Box>
              Add a Commment
            </Flex>
          }
          click={() => onOpen()}
        />
      )} */}
    </Flex>
  );
}

import React, { useState } from "react";

import {
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useHistory, useParams } from "react-router-dom";

export default function NavBar(props) {
  const history = useHistory();
  const { videoStatus } = useParams();

  const {
    clients,
    admin,
    freelance,
    filteredVideos,
    queryStatus,
    queryClient,
    queryDisplay,
    queryManager,
  } = props;

  // const [filteredVideos, setFilteredVideos] = useState("");

  return (
    <HStack my='5'>
      {/* Display filter */}
      {admin && (
        <Menu>
          <MenuButton
            as={Button}
            variant='outline'
            rounded='full'
            rightIcon={<ChevronDownIcon />}>
            {queryDisplay === "all" && queryManager === "all"
              ? "All projects"
              : queryDisplay === "all" && queryManager === "géraldinemillion"
              ? "Géraldine Million"
              : queryDisplay === "all" && queryManager === "maxencelebreux"
              ? "Maxence Lebreux"
              : queryDisplay === "all" && queryManager === "mariongaufroy"
              ? "Marion Gaufroy"
              : queryDisplay === "all" && queryManager === "geoffroylasne"
              ? "Geoffroy Lasne"
              : queryDisplay === "all" && queryManager === "claracolleoni"
              ? "Clara Colleoni"
              : "My projects"}
          </MenuButton>
          {/* <MenuButton
            as={Button}
            variant="outline"
            rounded="full"
            rightIcon={<ChevronDownIcon />}
          >
            {queryDisplay === "all" && queryManager === "all"
              ? "All projects"
              : queryDisplay === "all" &&
              queryManager === "géraldinemillion"
              ? "Géraldine Million"
              : queryDisplay === "all" && queryManager === "maxencelebreux"
              ? "Maxence Lebreux"
              : queryDisplay === "all" && queryManager === "geoffroylasne"
              ? "Geoffroy Lasne"
              : queryDisplay === "all" && queryManager === "mariongaufroy"
              ? "Marion Gaufroy"
              : queryDisplay === "all" && queryManager === "claracolleoni"
              ? "Clara Colleoni"
              : "My projects"}
          </MenuButton> */}
          <MenuList>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=all`
                );
              }}>
              All projects
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=me&manager=all`
                );
              }}>
              My projects
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=géraldinemillion`
                );
              }}>
              Géraldine Million
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=maxencelebreux`
                );
              }}>
              Maxence Lebreux
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=geoffroylasne`
                );
              }}>
              Geoffroy Lasne
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=mariongaufroy`
                );
              }}>
              Marion Gaufroy
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=${queryStatus}&client=${queryClient?.toLowerCase()}&display=all&manager=claracolleoni`
                );
              }}>
              Clara Colleoni
            </MenuItem>
          </MenuList>
        </Menu>
      )}

      {/* Status filter */}
      <Menu>
        <MenuButton
          as={Button}
          variant='outline'
          rounded='full'
          rightIcon={<ChevronDownIcon />}>
          {queryStatus === "production"
            ? `In Production (${filteredVideos.length})`
            : queryStatus === "validated"
            ? `Validated (${filteredVideos.length})`
            : queryStatus === "delivered"
            ? `Delivered (${filteredVideos.length})`
            : queryStatus === "all"
            ? `All status (${filteredVideos.length})`
            : queryStatus === "notstarted"
            ? `Not started (${filteredVideos.length})`
            : ""}
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              history.push(
                `?status=all&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}`
              );
            }}>
            All status
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push(
                `?status=notstarted&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}`
              );
            }}>
            Not started
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push(
                `?status=production&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}`
              );
            }}>
            In production
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push(
                `?status=validated&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}`
              );
            }}>
            Validated
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push(
                `?status=delivered&client=${queryClient?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}`
              );
            }}>
            Delivered
          </MenuItem>
        </MenuList>
      </Menu>

      {/* Client filter */}
      {(admin || freelance) && (
        <Menu>
          <MenuButton
            as={Button}
            variant='outline'
            rounded='full'
            rightIcon={<ChevronDownIcon />}
            textTransform='capitalize'>
            {queryClient !== "all" ? queryClient : "All clients"}
          </MenuButton>
          <MenuList maxH='300px' overflow='auto'>
            <MenuItem
              onClick={() => {
                history.push(
                  `?status=delivered&client=all&display=${queryDisplay}&manager=${queryManager}`
                );
              }}>
              All clients
            </MenuItem>

            {clients &&
              clients.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      history.push(
                        `?status=${queryStatus}&client=${item?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}`
                      );
                    }}>
                    {item}
                  </MenuItem>
                );
              })}
          </MenuList>
        </Menu>
      )}
    </HStack>
  );
}

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Avatar,
  Button,
  Heading,
  useColorMode,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Link,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import NewCollab from "../../components/collab/NewCollab";
import EditCollab from "../../components/collab/EditCollab";
import CollabLists from "../../components/collab/CollabLists";
import { displayRole } from "../../data/adminData";
import ButtonColor from "../../components/button/ButtonColor";
import { IoPersonAdd } from "react-icons/io5";
import NewJob from "../../components/job/NewJob";
import { fetchJobs } from "./api";

export default function Team(props) {
  const {
    team,
    projectId,
    projectName,
    reloadData,
    setReloadData,
    adminProjectId,
    videoId,
    admin,
    isLoading,
    clientId,
    videoName,
    clientName,
    user,
  } = props;
  const [jobs, setJobs] = useState("");
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenJob,
    onOpen: onOpenJob,
    onClose: onCloseJob,
  } = useDisclosure();

  const history = useHistory();
  // console.log("team", team)
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenView,
    onOpen: onOpenView,
    onClose: onCloseView,
  } = useDisclosure();
  const [currentAdmin, setCurrentAdmin] = useState("");
  const [displayMore, setDisplayMore] = useState(false);

  function viewCollab(e, people) {
    e.stopPropagation();
    setCurrentAdmin(people);
    onOpenEdit();
  }

  function viewJob(e, link) {
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  useEffect(() => {
    fetchJobs(videoId).then((response) => {
      // console.log("jobs", response)
      setJobs(response);
    });
  }, [team]);

  const teamArray = team && !displayMore ? team.slice(0, 10) : team;

  // console.log("user from team", user);
  return (
    <Flex padding='20px' flexDirection='column'>
      <Flex>
        <Flex
          // borderWidth={colorMode === "light" ? "0px" : "0px"}
          // borderWidth={colorMode === "light" ? "1px" : "0px"}
          // boxShadow="sm"
          // borderColor="#F0F0F0"
          // rounded="md"
          // bg={colorMode === "light" ? "white" : "#021627"}
          // bg={colorMode === "light" ? "white" : "gray.900"}
          direction='column'
          // p='20px'
          // bg={colorMode === "light" ? "white" : "#232323"}
          // padding="20px"
          rounded='xl'
          // borderWidth={colorMode === "light" ? "1px" : "0px"}
          // borderColor="#F0F0F0"
          // boxShadow="sm"
        >
          {teamArray &&
            teamArray.slice(0, 100).map((people, index) => {
              return (
                <Box
                  key={index}
                  onClick={(e) => admin && viewCollab(e, people)}
                  color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}
                  pb='10px'>
                  {/* {people && (
                  <Avatar
                    size="sm"
                    name={
                      people.user.given_name + " " + people.user.family_name
                    }
                  />    
                )} */}

                  <Flex
                    direction='column'
                    // onClick={() => onOpenEdit()}
                    cursor={admin ? "pointer" : ""}>
                    <Flex>
                      {people?.user?.profile_picture && (
                        <Image
                          mr='10px'
                          borderRadius='full'
                          boxSize='32px'
                          src={`https://aaliz317.twic.pics/${people?.user?.profile_picture}`}
                          alt={` Picture of {item.taskLeader.given_name}`}
                        />
                      )}
                      {!people?.user?.profile_picture && (
                        <Avatar
                          mr='10px'
                          size='sm'
                          name={
                            people.user.given_name +
                            " " +
                            people.user.family_name
                          }
                        />
                      )}
                      <Flex flexDirection='column'>
                        <Text
                          color={colorMode === "light" ? "gray.600" : "white"}>
                          {people.user &&
                            (people.user.given_name && people.user.given_name) +
                              " " +
                              (people.user.family_name &&
                                people.user.family_name)}
                        </Text>
                        <Text fontSize='sm'>{displayRole(people)} </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  {admin &&
                    jobs &&
                    jobs
                      .filter((job) => job.email === people.user.email)
                      .map((job) => {
                        return (
                          //  <Link href={`/freelances/${job.freelance}/jobs/${job._id}`}>
                          <Box
                            _hover={{ cursor: "pointer" }}
                            onClick={(e) =>
                              viewJob(
                                e,
                                `/freelances/${job.freelance}/jobs/${job._id}`
                              )
                            }>
                            <Text mt='5px'>
                              {job.category?.label} -{" "}
                              {job.pricing && `${job.pricing}€`}{" "}
                            </Text>
                          </Box>
                          //  </Link>
                        );
                      })}
                </Box>
              );
            })}
          {team && team.length > 10 && (
            <Button
              variant='link'
              size='xs'
              onClick={() => setDisplayMore(!displayMore)}>
              {displayMore ? "Show less" : "show more"}
            </Button>
          )}
          {/* <Button onClick={() => onOpenView()}>View team</Button> */}
        </Flex>

        <NewJob
          isOpen={isOpenJob}
          onOpen={onOpenJob}
          onClose={onCloseJob}
          projectId={projectId}
          projectName={projectName}
          reloadData={reloadData}
          setReloadData={setReloadData}
          adminProjectId={adminProjectId}
          videoId={videoId}
          videoName={videoName}
          clientId={clientId}
          clientName={clientName}
          email={user?.attributes.email ? user.attributes.email : "no email"}
          video={videoId}
          name={projectName}
          role={user?.role ? user.role : "no role"}
          // title={title}
          // brief={brief}
          // pricing={pricing}
          // ipAdress={ipAdress}
          // note={note}
          // category={category}
          // rating={rating}
          // deliverables={deliverables}
          // paymentStatus={paymentStatus}
          // internalFeedback={internalFeedback}
          // clientFeedback={clientFeedback}
        />

        <NewCollab
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          projectId={projectId}
          projectName={projectName}
          reloadData={reloadData}
          setReloadData={setReloadData}
          adminProjectId={adminProjectId}
          videoId={videoId}
          clientId={clientId}
        />
        {currentAdmin && (
          <EditCollab
            isOpen={isOpenEdit}
            onOpen={onOpenEdit}
            onClose={onCloseEdit}
            projectId={projectId}
            projectName={projectName}
            reloadData={reloadData}
            setReloadData={setReloadData}
            currentAdmin={currentAdmin}
            adminProjectId={adminProjectId}
            videoId={videoId}
            clientId={clientId}
          />
        )}

        <CollabLists
          isOpen={isOpenView}
          onOpen={onOpenView}
          onClose={onCloseView}
          isOpenEdit={isOpenEdit}
          onOpenEdit={onOpenEdit}
          onCloseEdit={onCloseEdit}
          team={team}
          setCurrentAdmin={setCurrentAdmin}
          onOpenNew={onOpen}
        />
      </Flex>
      {admin && !isLoading && (
        <>
          {/* <ButtonColor
            click={onOpen}
            title={
              <Flex alignItems="center">
                <Box mr="5px">
                  <IoPersonAdd />{" "}
                </Box>
                Add a freelance
              </Flex>
            }
          /> */}

          <ButtonColor
            click={onOpenJob}
            title={
              <Flex alignItems='center'>
                <Box mr='5px'>
                  <IoPersonAdd />{" "}
                </Box>
                Propose a new job
              </Flex>
            }
          />
        </>
      )}
    </Flex>
  );
}

import React from "react";
import { Button, useColorMode } from "@chakra-ui/react";


export default function ButtonColor({ title, click, bg, color, size="md", mt="20px"}) {
  const { colorMode } = useColorMode()
  return (
    <Button mt={mt} 
      border="1px solid"
      borderColor={colorMode ==="light" ?"blackAlpha.200" : "whiteAlpha.300"}
      rounded="lg"
      bg={colorMode==="light" ? "#F8FAFC" : "#1C1C1C"}
    // bg={bg ?  bg : colorMode === "light" ? "blackAlpha.200" : "whiteAlpha.600"}
      color={color ?  color : colorMode === "light" ? "primary" : "white"}
      onClick={click} size={size}>
      {title}
    </Button>
  );
}

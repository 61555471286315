import React, { useState, createContext, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

export const UserContext = createContext();

export const useUser = () => useContext(UserContext);

// export const FetchCurrentUser = () => {
//   const { user, setUser } = useUser();
//   let history = useHistory();

//   useEffect(() => {
//     getUserData();
//   }, []);

//   async function getUserData() {
//     try {
//       if (!user) {
//         // const userData = await API.get("kronik", "/user/me", {});
//         const userData = await Auth.currentAuthenticatedUser();

//         setUser(userData);
//         console.log("userData", userData);
//         return userData;
//       }
//     } catch (e) {
//       history.push("/login");
//     }
//   }
// };

export const UserContextProvider = (props) => {
  const [user, setUser] = useState();
  const [admin, setAdmin] = useState(false);
  const [freelance, setFreelance] = useState(false);
  const [client, setClient] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [sidebarMini, setSidebarMini] = useState(true);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [render, setRender] = React.useState(false);

  let history = useHistory();
  let currentUrl = useLocation();

  useEffect(() => {
    getUserData();
    setRender(true);
  }, []);

  useEffect(() => {
    setRender(true);
  }, [admin, freelance, client]);

  async function getUserData() {
    try {
      if (!user) {
        // const userData = await API.get("kronik", "/user/me", {});
        const userData = await Auth.currentAuthenticatedUser();
        // console.log("userData", userData);
        if (userData.attributes["custom:user_role"] === "admin") {
          setAdmin(true);
        } else {
          setAdmin(false);
        }

        if (userData.attributes["custom:user_role"] === "freelance") {
          // console.log("freelance", true);

          setFreelance(true);
        } else {
          setFreelance(false);
        }

        if (userData.attributes["custom:user_role"] === "client") {
          // console.log("client", true);
          setClient(true);
        } else {
          setClient(false);
        }

        setUser(userData);
        // console.log("hello user", userData);
        window.$crisp.push(["set", "user:email", [userData.username]]);
        window.$crisp.push([
          "set",
          "user:nickname",
          [
            userData.attributes.given_name +
              " " +
              userData.attributes.family_name,
          ],
        ]);
        // console.log("userData", userData);

        return userData;
      }
    } catch (e) {
      // console.log("currentUrl", currentUrl)
      // console.log("slice", currentUrl.pathname.slice(0,6))
      if (currentUrl.pathname === "/login/freelance") {
        history.push("/login/freelance");
      } else if (currentUrl.pathname === "/login/client") {
        history.push("/login/client");
      } else if (currentUrl.pathname.slice(0, 7) === "/review") {
        // console.log ("test")
        return;
      } else {
        history.push("/login/general");
      }
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        admin,
        freelance,
        client,
        sidebarVisible,
        setSidebarVisible,
        sidebarMini,
        setSidebarMini,
        uploadFiles,
        setUploadFiles,
        filesList,
        setFilesList,
      }}>
      {props.children}
    </UserContext.Provider>
  );
};

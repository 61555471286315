import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Grid,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Textarea,
  useColorMode,
} from "@chakra-ui/react";
import Select from "react-select";
import { useUser } from "../../libs/UserContext";
import { customStyles, darkStyles } from "../../styles/customStyles";
import { roleOptions } from "../../data/adminData";
import { putCollab, putSuperCollab, removeCollab } from "./api";
export default function EditCollab(props) {
  const { colorMode } = useColorMode();
  const { admin } = useUser();
  const {
    projectId,
    projectName,
    videoId,
    currentAdmin,
    adminProjectId,
    reloadData,
    setReloadData,
    admins,
    isOpen,
    onClose,
    clientId,
  } = props;

  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [isLoadingOne, setIsLoadingOne] = useState(false);
  const [isLoadingTwo, setIsLoadingTwo] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [deleteButton, setDeleteButton] = useState(false);

  const adminsEmailList = admins && admins.filter((admin) => admin.email);

  // console.log("edit clientId", clientId);

  useEffect(() => {
    // console.log("currentAdmin", currentAdmin);
    // console.log("adminProjectId", adminProjectId);

    const simpleAdmin =
      currentAdmin.videos.length > 0 &&
      currentAdmin.videos.filter((item) => item.video === videoId);
    // console.log("simpleAdmin", simpleAdmin);
    setRole(
      currentAdmin.videos.length === 0
        ? { value: currentAdmin.role, label: currentAdmin.role }
        : {
            value: currentAdmin.videos[0].role,
            label: currentAdmin.videos[0].role,
          }
    );
  }, [currentAdmin]);

  function changeEmail(e) {
    setEmail(e.target.value);
  }

  async function updateSuperCollab() {
    setIsLoadingOne(true);
    // console.log("projectId", projectId);
    // console.log("admin", admin);
    // console.log("email", email);
    // console.log("role", role);
    // console.log("projectName", projectName);
    putSuperCollab(projectId, admin, role, currentAdmin, clientId).then(
      (response) => {
        setIsLoadingOne(false);
        onClose();
        setReloadData(!reloadData);
      }
    );
  }

  async function updateCollab() {
    setIsLoadingOne(true);
    // console.log("projectId", projectId);
    // console.log("admin", admin);
    // console.log("email", email);
    // console.log("role", role);
    // console.log("videoId", videoId);
    // console.log("projectName", projectName);
    putCollab(projectId, admin, role, videoId, currentAdmin, clientId).then(
      (response) => {
        setIsLoadingOne(false);
        onClose();
        setReloadData(!reloadData);
      }
    );
  }

  async function deleteCollab() {
    setIsLoadingTwo(true);
    removeCollab(projectId, currentAdmin, admin).then((response) => {
      setIsLoadingTwo(false);
      setDeleteButton(false);
      setConfirmMessage("");
      onClose();
      setReloadData(!reloadData);
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit people</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="1fr 1fr" gap="20px">
            {/* Name */}
            <Box my="4">
              <FormControl id="subtitle">
                <FormLabel>First name</FormLabel>
                <Input
                  variant="filled"
                  placeholder="Add a email"
                  value={currentAdmin.user && currentAdmin.user.given_name}
                  isDisabled
                />
              </FormControl>
            </Box>

            {/* Name */}
            <Box my="4">
              <FormControl id="subtitle">
                <FormLabel>Family name</FormLabel>
                <Input
                  variant="filled"
                  placeholder="Add a email"
                  value={currentAdmin.user && currentAdmin.user.family_name}
                  isDisabled
                />
              </FormControl>
            </Box>
          </Grid>

          {/* email */}
          <Box my="4">
            <FormControl id="subtitle">
              <FormLabel>email</FormLabel>
              <Input
                variant="filled"
                placeholder="Add a email"
                value={currentAdmin.user && currentAdmin.user.email}
                type="email"
                isDisabled
              />
            </FormControl>
          </Box>

          {/* Role */}
          <Box my="4">
            <FormLabel>Role</FormLabel>
            <Select
              styles={colorMode === "light" ? customStyles : darkStyles}
              isClearable
              placeholder="Select a role "
              value={role}
              onChange={setRole}
              options={roleOptions}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          {/* <Button
            // isDisabled={!state.currentClient || !state.name}
            // colorScheme="blue"
            mr={3}
            onClick={() => deleteCollab()}
            isLoading={isLoadingTwo}
            loadingText="Loading"
          >
            Remove
          </Button>
          <Button
            // isDisabled={!state.currentClient || !state.name}
            colorScheme="blue"
            mr={3}
            onClick={() =>
              // saveSuperCollab()
              adminProjectId ? updateCollab() : updateSuperCollab()
            }
            isLoading={isLoadingOne}
            loadingText="Loading"
          >
            Save
          </Button> */}

          {admin && !deleteButton && (
            <Button mr={3} onClick={() => setDeleteButton(true)}>
              Remove
            </Button>
          )}
          {!deleteButton && (
            <Button
              bgColor="primary"
              color="white"
              mr={3}
              onClick={() =>
                adminProjectId ? updateCollab() : updateSuperCollab()
              }
              isLoading={isLoadingOne}
              loadingText="Loading"
            >
              Save
            </Button>
          )}
          {deleteButton && (
            <Box mr="20px">
              <FormControl id="confirmation">
                <Input
                  variant="filled"
                  placeholder="Enter REMOVE to confirm"
                  value={confirmMessage}
                  onChange={(e) => setConfirmMessage(e.target.value)}
                />
              </FormControl>
            </Box>
          )}
          {deleteButton && (
            <Button mr={3} onClick={() => setDeleteButton(false)}>
              Cancel
            </Button>
          )}
          {confirmMessage === "REMOVE" && deleteButton && (
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => deleteCollab()}
              isLoading={isLoadingTwo}
              loadingText="Loading"
            >
              Remove
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import React, { useState } from "react";
import {
  Box,
  Button,
  Heading,
  useColorMode,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Link,
  useDisclosure,
  Image,
  Avatar,
} from "@chakra-ui/react";
import NewCollab from "../../components/collab/NewCollab";
import EditCollab from "../../components/collab/EditCollab";
import CollabLists from "../../components/collab/CollabLists";
import { displayRole } from "../../data/adminData";
import Card from "../../components/card";
import ButtonColor from "../../components/button/ButtonColor";
import { IoPersonAdd } from "react-icons/io5";
export default function Team(props) {
  const {
    team,
    projectId,
    projectName,
    reloadData,
    setReloadData,
    admin,
    isLoading,
    clientId,
  } = props;
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenView,
    onOpen: onOpenView,
    onClose: onCloseView,
  } = useDisclosure();
  const [currentAdmin, setCurrentAdmin] = useState("");
  const [displayMore, setDisplayMore] = useState(false);

  function viewCollab(people) {
    setCurrentAdmin(people);
    onOpenEdit();
  }
  const teamArray = team && !displayMore ? team.slice(0, 10) : team;

  // console.log("team", team);
  return (
    <Flex padding='20px' flexDirection='column'>
      <Box
      // p='20px'
      // bg={colorMode === "light" ? "white" : "#232323"}
      // padding='20px'
      // rounded='xl'
      // borderWidth={colorMode === "light" ? "1px" : "0px"}
      // borderColor='#F0F0F0'
      // boxShadow='sm'
      >
        {teamArray &&
          teamArray.map((people, index) => {
            return (
              <Box
                key={index}
                onClick={() => admin && viewCollab(people)}
                color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                <Flex
                  direction='column'
                  pb='10px'
                  onClick={() => onOpenEdit()}
                  cursor={admin ? "pointer" : ""}>
                  <Flex>
                    {people?.user?.profile_picture && (
                      <Image
                        mr='10px'
                        borderRadius='full'
                        boxSize='32px'
                        src={`https://aaliz317.twic.pics/${people?.user?.profile_picture}`}
                        alt={` Picture of {item.taskLeader.given_name}`}
                      />
                    )}

                    {!people?.user?.profile_picture && (
                      <Avatar
                        mr='10px'
                        size='sm'
                        name={
                          people.user.given_name + " " + people.user.family_name
                        }
                      />
                    )}

                    <Flex flexDirection='column'>
                      <Text
                        color={colorMode === "light" ? "gray.600" : "white"}>
                        {people.user &&
                          (people.user.given_name && people.user.given_name) +
                            " " +
                            (people.user.family_name &&
                              people.user.family_name)}
                      </Text>
                      <Text fontSize='sm'>{displayRole(people)} </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            );
          })}
        {team.length > 10 && (
          <Button
            variant='link'
            size='xs'
            onClick={() => setDisplayMore(!displayMore)}>
            {displayMore ? "Show less" : "show more"}
          </Button>
        )}
      </Box>

      <Box>
        <NewCollab
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          projectId={projectId}
          projectName={projectName}
          reloadData={reloadData}
          setReloadData={setReloadData}
          clientId={clientId}
        />
        {currentAdmin && (
          <EditCollab
            isOpen={isOpenEdit}
            onOpen={onOpenEdit}
            onClose={onCloseEdit}
            projectId={projectId}
            projectName={projectName}
            reloadData={reloadData}
            setReloadData={setReloadData}
            currentAdmin={currentAdmin}
            clientId={clientId}
          />
        )}

        <CollabLists
          isOpen={isOpenView}
          onOpen={onOpenView}
          onClose={onCloseView}
          isOpenEdit={isOpenEdit}
          onOpenEdit={onOpenEdit}
          onCloseEdit={onCloseEdit}
          team={team}
          setCurrentAdmin={setCurrentAdmin}
          onOpenNew={onOpen}
          displayRole={displayRole}
        />
      </Box>
      {admin && !isLoading && (
        <ButtonColor
          title={
            <Flex alignItems='center'>
              <Box mr='5px'>
                <IoPersonAdd />{" "}
              </Box>
              Add a people
            </Flex>
          }
          click={onOpen}
        />
      )}
      {/* {admin && <Button onClick={() => onOpen()}>Add a person</Button>} */}
    </Flex>
  );
}

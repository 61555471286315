import React from "react";
import { Box, Flex, useColorMode } from "@chakra-ui/react";
import SideSteps from "../../components/SideSteps";
import SideBarItem from "../../components/SideBarItem.js";
import CardTitle from "../../components/card/CardTitle";
import { MiniHeader } from "../../components/header/MiniHeader";
import { LeftColumn } from "../../components/column/LeftColumn";
export default function SideBar({
  projectName,
  clientName,
  videoName,
  clientLink,
  projectLink,
  videoLink,
  isLoading,
  admin,
  client,
  freelance,
  videoStatus,
  projectStatus,
  clientLogo,
}) {
  const { colorMode } = useColorMode();

  return (
    <LeftColumn>
      {/* <MiniHeader> */}
      {/* <CardTitle title='Folders' isActive='false' /> */}
      {/* </MiniHeader> */}
      <Box overflowY='auto' mt='10px'>
        <SideBarItem
          title={admin || freelance ? "Client" : "Organisation"}
          text={clientName}
          link={!isLoading && (admin || freelance) && clientLink}
          // status='client'
          clientLogo={clientLogo}
        />

        {(admin || client) && (
          <SideBarItem
            title='Quote'
            text={projectName}
            link={!isLoading && projectLink}
            status={projectStatus}
          />
        )}

        <SideBarItem
          title='Project'
          text={videoName}
          link={!isLoading && videoLink}
          isActive='true'
          status={videoStatus}
        />
      </Box>
    </LeftColumn>
  );
}

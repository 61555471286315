import React from "react";
import {
  Link,
  Flex,
  Box,
  Circle,
  Collapse,
  Heading,
  Text,
  HStack,
  Icon,
  useColorMode,
  IconButton,
  Badge,
  Grid,
  Image,
  Avatar,
} from "@chakra-ui/react";
import { Link as RouteLink } from "react-router-dom";
import {
  MdMovieFilter,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";
import {
  BsArrowRepeat,
  BsCameraVideo,
  BsCheck,
  BsFillInboxFill,
  BsPerson,
  BsQuestionCircle,
} from "react-icons/bs";
import { GiScooter } from "react-icons/gi";
import { AiFillCheckCircle, AiFillVideoCamera } from "react-icons/ai";
import { FaCut, FaRegBuilding } from "react-icons/fa";
import { IoBuildSharp } from "react-icons/io5";
import { ImBlocked } from "react-icons/im";

export default function SideBarItem({
  title,
  text,
  link,
  isActive,
  isLast,
  status,
  clientLogo,
}) {
  const { colorMode } = useColorMode();

  const pictoStatus = (status) => {
    switch (status) {
      case "client":
        return <Box bgColor='#377D50' w='20px' h='20px' rounded='md' />;
      // <FaRegBuilding/>;
      case "preparation":
        return (
          <Flex
            // bgColor='#6799F5'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            🕔
            {/* <BsArrowRepeat /> */}
          </Flex>
        );
      // <MdOutlineCheckBoxOutlineBlank/>;
      case "interview":
        return (
          <Flex
            // bgColor='#6799F5'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            {/* <BsArrowRepeat /> */}
            🚧
          </Flex>
        );
        <BsCameraVideo />;
      case "preProduction":
        return (
          <Flex
            // bgColor='#6799F5'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            {/* <BsArrowRepeat /> */}
            🚧
          </Flex>
        );
      // <BsCameraVideo/>;
      case "production":
        return (
          <Flex
            // bgColor='#6799F5'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            {/* <BsArrowRepeat /> */}
            🚧
          </Flex>
        );
      // <BsCameraVideo/>;
      case "blocked":
        return (
          <Flex
            // bgColor='#6799F5'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            {/* <BsArrowRepeat /> */}
            🚫
          </Flex>
        );
      // <BsCameraVideo/>;
      case "approve":
        return (
          <Flex
            // bgColor='#377D50'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            {/* <BsCheck /> */}✅
          </Flex>
        );
      // <MdOutlineCheckBox/>;
      case "delivered":
        return (
          <Flex
            // bgColor='#377D50'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            {/* <BsCheck /> */}✅
          </Flex>
        );
      // <MdOutlineCheckBox/>;
      case "finished":
        return (
          <Flex
            // bgColor='#377D50'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            {/* <BsCheck /> */}✅
          </Flex>
        );
      // <MdOutlineCheckBox/>;
      case "freelance":
        return (
          <Box
            // bgColor='#377D50'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
          />
        );
      // <BsPerson/>;
      case "accepted":
        return (
          <Flex
            // bgColor='#377D50'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            {/* <BsCheck /> */}✅{}
          </Flex>
        );

      // <MdOutlineCheckBox/>;
      case "refused":
        return (
          <Box
            // bgColor='#D8707F'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
          />
        );
      // <ImBlocked/>;

      default:
        return (
          <Flex
            // bgColor='#B6B6B6'
            bgColor='whiteAlpha.100'
            w='20px'
            h='20px'
            rounded='md'
            justifyContent='center'
            alignItems='center'>
            ▫️
          </Flex>
        );
      // <BsQuestionCircle/>;
    }
  };

  // console.log("status", status)
  return (
    <Box mb='10px'>
      <Flex
        flexDirection='column'
        // bg={colorMode === "light" ? "white" : "#232323"}
        paddingX='20px'
        paddingY='10px'
        rounded='xl'
        // borderWidth={colorMode === "light" ? "1px" : "0px"}
        // borderColor='#F0F0F0'
        // boxShadow='sm'
      >
        <Link
          as={RouteLink}
          to={link ? link : "#"}
          cursor={link ? "pointer" : "auto"}
          _hover={
            link
              ? {
                  // opacity: "0.5",
                  textDecor: "none",
                }
              : ""
          }>
          <Heading
            fontWeight='semibold'
            color={colorMode === "light" ? "gray.500" : "white"}
            fontSize='md'
            mb='10px'>
            {title === "Client" ||
            title === "Organisation" ||
            title === "Freelance"
              ? ""
              : title}
          </Heading>
          {text && (
            <Flex
              // justifyContent={clientLogo ? "center" : "inherit"}
              fontSize='md'
              color={
                isActive
                  ? colorMode === "light"
                    ? "primary"
                    : "white"
                  : colorMode === "light"
                  ? "black"
                  : "white"
              }
              bgColor={
                colorMode === "light"
                  ? isActive
                    ? "blackAlpha.50"
                    : null
                  : isActive
                  ? "whiteAlpha.50"
                  : null
              }
              _hover={{
                bgColor:
                  colorMode === "light" ? "blackAlpha.50" : "whiteAlpha.50",
              }}
              mr='0px'
              borderRadius='sm'
              p='5px'>
              <Flex>
                {status && (
                  <IconButton
                    variant='ghost'
                    mr='5px'
                    icon={pictoStatus(status ? status : "")}
                    size='xs'
                  />
                )}
                {!status &&
                  title !== "Client" &&
                  title !== "Organisation" &&
                  title !== "Freelance" &&
                  "◽️   "}

                <Flex
                  alignItems='center'
                  // flexDirection='column'
                  // justifyContent='center'
                >
                  {!clientLogo &&
                    (title === "Client" ||
                      title === "Organisation" ||
                      title === "Freelance") && (
                      <Avatar
                        mr='10px'
                        size='sm'
                        name={text}
                        bgColor='blackAlpha.500'
                        color='white'
                      />
                    )}
                  {clientLogo && (
                    <Image
                      mr='10px'
                      borderRadius={
                        title === "Client" || title === "Organisation"
                          ? "lg"
                          : "full"
                      }
                      boxSize='40px'
                      src={`https://aaliz317.twic.pics/${clientLogo}`}
                      alt={` Image of ${text}`}
                    />
                  )}
                  {text}
                </Flex>
              </Flex>
            </Flex>
          )}
        </Link>
      </Flex>
    </Box>
  );
}

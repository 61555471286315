import React, { useEffect, useState } from "react";
import {
  Link,
  Box,
  Text,
  Grid,
  useColorMode,
  Flex,
  Button,
  useDisclosure,
  Image,
  AvatarGroup,
  Avatar,
  Badge,
} from "@chakra-ui/react";
// import { MdAddBox } from "react-icons/md";
import TableRow from "../../components/table/TableRow";
import { Link as RouteLink } from "react-router-dom";

import { useUser } from "../../libs/UserContext";
import fetchFreelances from "./api";
import { Updating } from "../../components/notification/Updating";
import { a } from "aws-amplify";
import MainColumn from "../../components/column/MainColumn";
import { MdAddBox } from "react-icons/md";
import NewFreelance from "../../components/freelance/NewFreelance";

export function FreelancesList(props) {
  const { colorMode } = useColorMode();

  const { setIsLoading, reloadData, freelances } = props;
  console.log("freelances", freelances);
  return (
    <Box>
      <Flex justifyContent='space-between'></Flex>
      <Box mb='40px' pb='0px'>
        <Grid templateColumns='1fr 1fr 1fr 1fr ' gap='20px' mt='20px'>
          {freelances &&
            freelances
              // .filter((freelance) => freelance?.status?.value !== "test")
              .sort((a, b) => {
                if (!a.jobs) {
                  return 1;
                }
                if (!b.jobs) {
                  return -1;
                }

                return b?.jobs?.length - a?.jobs?.length;
              })
              .map((freelance, index) => {
                const jobsAccepted = freelance.jobs?.filter(
                  (job) => job.status?.value !== "refused"
                );

                // console.log("jobsAccepted", jobsAccepted);

                const totalPricing =
                  freelance.jobs &&
                  jobsAccepted.reduce((acc, currenVal) => {
                    return acc + currenVal.pricing;
                  }, 0);
                return (
                  <Link
                    key={index}
                    as={RouteLink}
                    to={`/freelances/${freelance._id}`}
                    _hover={{ textDecor: "none" }}>
                    <Flex
                      flexDirection='column'
                      boxShadow='sm'
                      rounded='xl'
                      minH='280px'
                      m='5px'
                      p='20px'
                      _hover={{
                        cursor: "pointer",
                        bgColor:
                          colorMode === "light" ? "gray.50" : "whiteAlpha.100",
                        shadow: "lg",
                      }}
                      bg={colorMode === "light" ? "white" : "#232323"}>
                      <Flex justifyContent='space-between'>
                        <Box
                          fontSize='sm'
                          color={colorMode === "light" ? "gray.500" : "white"}>
                          {freelance?.status?.label}
                        </Box>
                        <Flex gap='5px' fontSize='sm'>
                          {freelance?.jobs?.length > 0 && (
                            <Box>{jobsAccepted.length} jobs</Box>
                          )}
                          {freelance?.jobs?.length > 0 && totalPricing > 0 && (
                            <Box>/</Box>
                          )}
                          <Box>{totalPricing > 0 && `${totalPricing}€`}</Box>
                        </Flex>
                      </Flex>
                      <Flex
                        direction='column'
                        alignItems='center'
                        minH='220px'
                        justifyContent='center'
                        textAlign='center'>
                        <Flex
                          flexDirection='column'
                          justifyContent='space-between'>
                          <Flex flexDirection='column'>
                            {freelance?.profile_picture && (
                              <Flex alignItems='center' justifyContent='center'>
                                <Image
                                  mr='10px'
                                  borderRadius='full'
                                  boxSize='82px'
                                  alt={`Profile picture of ${freelance?.family_name}`}
                                  src={`https://aaliz317.twic.pics/${freelance?.profile_picture}`}
                                />
                              </Flex>
                            )}
                            {!freelance?.profile_picture && (
                              <Flex justifyContent='center'>
                                <Avatar
                                  boxSize='82px'
                                  bg='blackAlpha.300'
                                  color='white'
                                  key={index}
                                  name={
                                    freelance.given_name +
                                    " " +
                                    freelance.family_name
                                  }
                                />
                              </Flex>
                            )}

                            <Box
                              mt='10px'
                              color={
                                colorMode === "light" ? "gray.500" : "white"
                              }>
                              {freelance?.given_name || freelance?.family_name
                                ? freelance?.given_name +
                                  " " +
                                  freelance?.family_name
                                : freelance?.email}
                            </Box>
                          </Flex>
                          <Flex mt='10px' gap='10px' justifyContent='center'>
                            <Box>
                              {freelance?.skills?.map((skill, index) => {
                                return (
                                  <Badge
                                    mt='5px'
                                    fontSize='xs'
                                    variant='solid'
                                    borderRadius='full'
                                    px='10px'
                                    mx='5px'
                                    py='5px'
                                    // mr='10px'
                                  >
                                    <Box>{skill.label}</Box>
                                  </Badge>
                                );
                              })}
                            </Box>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Link>
                );
              })}
        </Grid>
      </Box>
    </Box>
  );
}

import React, { useState } from "react";
import { API } from "aws-amplify";
import { getHours } from "date-fns";
import dayjs from "dayjs";
import "dayjs/locale/fr";

export default async function fetchVideos(dateSelected, display) {
  const columns = [
    { status: "warmUp", title: "Preparation", count: 0 },
    { status: "interview", title: "Interview", count: 0 },
    { status: "preProduction", title: "Pre-production", count: 0 },
    { status: "production", title: "Production", count: 0 },
    { status: "approval", title: "Validated", count: 0 },
    { status: "broadcast", title: "Delivered", count: 0 },
    { status: "noStatus", title: "No status", count: 0 },
  ];

  return API.get(
    "kronik",
    `/video/get/videos/?year=${dateSelected}&display=${display}`
  ).then((response) => {
    // console.log("API video begin");
    // console.log("videos", response);
    // response.map((item) => {
    //   columns.map((it) => {
    //     if (item?.status?.value === it?.status) {
    //       it.count = it.count + 1;
    //     }
    //   });
    // });
    // console.log("end API video");
    return response;
  });
}

export async function fetchProjects(display) {
  return API.get("kronik", `/project/get/?display=${display}`).then(
    (response) => {
      // console.log("projects", response);
      const projectArray = response
        .filter((item) => item.project.wonDate)
        .map((item) => {
          return {
            project: item.project,
            pricing: item.project.pricing,
            client: item.client,
            videoCount: item.totalVideos,
            clientName: item.client.name,
            status: item.project.status
              ? item.project.status.label
              : "no status",
            wonDate: item.project.wonDate
              ? dayjs(item.project.wonDate).format("MMMM YYYY")
              : "",
            sortDate: item.project.wonDate ? dayjs(item.project.wonDate) : null,
          };
        })

        .sort(function (a, b) {
          return new Date(a.sortDate) - new Date(b.sortDate);
        });

      // console.log("end API project");

      return projectArray;
    }
  );
}

export async function fetchTasks(display) {
  return API.get("kronik", `/task/get/tasks?display=${display}`).then(
    (response) => {
      // console.log("response 1", response);
      // response.filter(it => it.taskStatus).map((item)=> console.log("item", item))

      const taskArray = response.map((item) => {
        // account Manager array
        const array = [];
        item.admins &&
          item.admins.map((admin) => {
            if (admin.role === "accountManager") {
              array.push(admin.given_name + " " + admin.family_name);
            }
            return array;
          });

        // const date with or without hour
        const dateHours =
          getHours(Date.parse(item.dueDate)) == 0
            ? dayjs(item.dueDate).format("DD MMMM YYYY")
            : dayjs(item.dueDate).format("DD MMMM YYYY HH:mm");

        return {
          title: item.title && item.title,
          taskLeader: item.taskLead,
          accountManager: array,
          people:
            item.taskLead &&
            item.taskLead.given_name + " " + item.taskLead.family_name,
          // date: dayjs(item.dueDate).format("DD MMMM YYYY"),
          date: dateHours,
          dateJs: item.dueDate,
          status: item.status,
          validation: item.validation,
          brief: item.brief,
          link: item.link,
          subtitle: item.subtitle,
          projectName: item.projectName,
          projectId: item.project,
          videoName: item.videoName,
          videoId: item.video,
          dateStatus: item.taskStatus,
          clientName: item.clientName,
          category: item.category,
          // admins: item.admins,
          id: item._id,
          private: item.private,
        };
      });

      return taskArray;
    }
  );
}

export async function getFreelancetasks() {
  const url = `/task/get/leader`;
  return await API.get("kronik", url, {}).then((response) => {
    const taskArray = response.map((item) => {
      // account Manager array
      const array = [];
      item.admins &&
        item.admins.map((admin) => {
          if (admin.role === "accountManager") {
            array.push(admin.given_name + " " + admin.family_name);
          }
          return array;
        });

      // const date with or without hour
      const dateHours =
        getHours(Date.parse(item.dueDate)) == 0
          ? dayjs(item.dueDate).format("DD MMMM YYYY")
          : dayjs(item.dueDate).format("DD MMMM YYYY HH:mm");

      return {
        title: item.title && item.title,
        taskLeader: item.taskLead,
        accountManager: array,
        people:
          item.taskLead &&
          item.taskLead.given_name + " " + item.taskLead.family_name,
        // date: dayjs(item.dueDate).format("DD MMMM YYYY"),
        date: dateHours,
        dateJs: item.dueDate,
        dueDate: item.dueDate,
        status: item.status,
        validation: item.validation,
        brief: item.brief,
        link: item.link,
        subtitle: item.subtitle,
        projectName: item.projectName,
        projectId: item.project,
        videoName: item.videoName,
        videoId: item.video,
        dateStatus: item.taskStatus,
        clientName: item.clientName,
        category: item.category,
        statusDate: item.statusDate,
        validationDate: item.validationDate,
        // admins: item.admins,
        id: item._id,
        private: item.private,
      };
    });

    return taskArray;
  });
}

import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useUser } from "../../libs/UserContext";
import axios from "axios";
import { postAsset, postDataFiles, uploadMultipartFile } from "./api";

export default function MultiPartUpload({
  taskData,
  admin,
  setReloadData,
  reloadData,
  projectId,
  hiddenFileInput,
}) {
  const { uploadFiles, setUploadFiles, filesList, setFilesList } = useUser();
  const [newFile, setNewFile] = useState([]);
  const [newProgressFile, setNewProgressFile] = useState("");
  const [progressOnly, setProgressOnly] = useState("");
  const [confirm, setConfirm] = useState("");
  const [confirmPost, setConfirmPost] = useState(0);

  const handleChange = (event) => {
    const newUploadArray = [...Array.from(event.target.files)];
    newUploadArray.map((file, index) => {
      // console.log("file map", index)
      file.id = uuidv4();

      return file;
    });
    setFilesList((filesList) => [...filesList, ...newUploadArray]);
    setNewFile(newUploadArray);
  };

  function formatName(originalName) {
    let fileName = originalName.replace(/\s+/g, "_");

    // console.log("fileName", fileName)
    var fileExtension = "." + fileName.split(".")[1];
    // console.log('fileExtension', fileExtension)
    var prefix = fileName.split(".")[0];
    // console.log('prefix', prefix)
    var name =
      prefix +
      convertDate() +
      Math.random().toString(36).substring(7) +
      new Date().getTime() +
      fileExtension;
    // console.log("name", name)
    return name;
  }

  function convertDate() {
    let date = new Date(),
      day = date.getDate(),
      month = date.getMonth();
    month = month + 1;
    if (String(day).length == 1) day = "0" + day;
    if (String(month).length == 1) month = "0" + month;
    // console.log("convertDate", date.getFullYear()+""+month+""+ day)
    return date.getFullYear() + "" + month + "" + day;
  }

  useEffect(() => {
    if (filesList.length === 0) {
      setReloadData(!reloadData);
    }
  }, [filesList]);

  useEffect(() => {
    Promise.all(
      Array.from(newFile).map(async (item, index) => {
        const file = item;
        const fileName = formatName(file.name);
        const fileSize = file.size;
        const url = `https://api.kronik.com/file`;

        try {
          await uploadMultipartFile(
            file,
            url,
            fileName,
            fileSize,
            uploadFiles,
            setUploadFiles,
            filesList,
            setFilesList,
            setNewProgressFile,
            setProgressOnly
          ).then(async (response) => {
            // console.log("response", response)
            if (!response) {
              throw new Error("Upload failed");
            }
            if (response?.status !== 499) {
              await postDataFiles(
                fileName,
                file,
                taskData,
                admin,
                setUploadFiles
              ).then(async (response) => {
                // console.log("postData", response);
                const fileId = response._id;
                // setConfirmPost(Math.random());
                // setConfirm(postData);
                postAsset("www", fileId, fileName, projectId).then(
                  (response) => {
                    // console.log("postAsset", response);
                  }
                );
              });
            }
          });

          // console.log(completeUpload.data, 'complete upload response');
        } catch (err) {
          console.log(err, err.stack);
        }
      })
    )
      .then((response) => {
        // if (filesList.length === null || filesList.length === undefined) {
        // setUploadFiles([]);
        // setFilesList([]);
        setUploadFiles([
          ...uploadFiles.filter((item) => item.percentage !== "100.00"),
        ]);
        setFilesList([
          ...filesList.filter((item) => item.percentage !== "100.00"),
        ]);
        // setReloadData(!reloadData);
        // setTotalProgress("");
        // setFile("");
        // setProgressStatus("");
        // }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [newFile]);

  useEffect(() => {
    const newArray = filesList.map((item) => {
      if (item.id === newProgressFile.id) {
        return newProgressFile;
      } else {
        return item;
      }
    });
    setFilesList(newArray);
    //   setProgressStatus(Array.from(newArray));
    setUploadFiles(Array.from(newArray));
  }, [newProgressFile, progressOnly]);

  return (
    <Flex direction='column' pt={{ base: "80px", sm: "30px" }}>
      <input
        type='file'
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        multiple
      />
    </Flex>
  );
}

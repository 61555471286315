import React, { useEffect, useState } from "react";
import { fetchTask } from "./api";
import { useParams } from "react-router-dom";
import Files from "./Files";
import { Box, Heading } from "@chakra-ui/react";
import { Updating } from "../../components/notification/Updating";

export function Review() {
  const { projectId, taskId } = useParams();
  const [taskData, setTaskData] = useState("");
  const [timecode, setTimecode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchTask(projectId, taskId).then((response) => {
      // console.log("response", response)
      setIsLoading(false);
      setTaskData(response);
    });
  }, []);

  return (
    <Box
      as='main'
      // py={{ base: "80px", sm: "30px" }}
      // px={{ base: "20px", sm: "40px" }}
      maxW='980px'
      m='auto'>
      <Heading as='h1' fontSize='2xl' mb='1' mt='5'>
        {taskData.title}
      </Heading>
      <Files
        //  link={taskData?.link}
        //  taskData={taskData}
        //  user={user}
        //  admin={admin}
        files={taskData?.files}
        //  projectId={taskData?.project?._id}
        //  setReloadData={setReloadData}
        //  reloadData={reloadData}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        clientId={taskData?.project?.client?._id}
        //  setComments={setComments}
        //  setReloadComments={setReloadComments}
        //  reloadComments={reloadComments}
        timecode={timecode}
        setTimecode={setTimecode}
        //  setLargePlayer={setLargePlayer}
        //  largePlayer={largePlayer}
        // updateDownload={updateDownload}
        // setDownloadData={setDownloadData}
        //  handleUpload={handleUpload}
        //  hiddenFileInput={hiddenFileInput}
        //  setActive={setActive}
      />

      {isLoading && <Updating />}
    </Box>
  );
}

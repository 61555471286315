import React, { useState, useEffect } from "react";

import {
  Box,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormLabel,
} from "@chakra-ui/react";
import ButtonIcon from "../../components/button/ButtonIcon";

import { updateNewStatus } from "./api";
import { newStatusOptionsWithIcon } from "../../data/videoData";

export default function UpdateStatus(props) {
  const {
    isOpen,
    onClose,
    videoData,
    admin,
    projectId,
    videoId,
    projectName,
    videoName,
    setReloadData,
    clientId,
  } = props;
  const [newStatus, setNewStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setNewStatus(videoData?.newStatus);
  }, [videoData]);

  // console.log("clientId", clientId);
  function update() {
    setReloadData(false);
    setIsLoading(true);
    updateNewStatus(
      admin,
      videoData,
      projectId,
      videoId,
      projectName,
      videoName,
      newStatus,
      clientId
    ).then((response) => {
      onClose();
      setReloadData(true);
      setIsLoading(false);
    });
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior='outside'
      size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize='3xl'>Update</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box marginBottom='40px'>
            <FormLabel>What is the status ?</FormLabel>
            {newStatusOptionsWithIcon.map((item, id) => {
              return (
                <ButtonIcon
                  title={item.label}
                  click={() =>
                    setNewStatus({ value: item.value, label: item.label })
                  }
                  checked={newStatus?.value === item.value ? true : false}
                  icon={item.icon}
                />
              );
            })}
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            bgColor='primary'
            color='white'
            mr={3}
            onClick={() => update()}
            isLoading={isLoading}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

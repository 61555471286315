import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/fr";

import {
  Box,
  Button,
  Grid,
  Heading,
  useColorMode,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Link,
  Flex,
  IconButton,
  useDisclosure,
  isLoading,
} from "@chakra-ui/react";

import { Link as RouteLink } from "react-router-dom";
import NewVideo from "../../components/video/NewVideo";
import { HiPencilAlt } from "react-icons/hi";
import { IoFlash } from "react-icons/io5";
import { HiPlusCircle } from "react-icons/hi";

import { newStatusSwitch } from "../../data/videoData";
import CardTitle from "../../components/card/CardTitle";
import TableRow from "../../components/table/TableRow";
import EditVideo from "../../components/video/EditVideo";
import UpdateStatus from "../../components/video/UpdateStatus";

export default function JobsList(props) {
  const { colorMode } = useColorMode();
  const {
    jobs,
    freelanceId,
    reloadData,
    setReloadData,
    projectData,
    projectId,
    projectName,
    project,
    admin,
    isLoading,
  } = props;
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenStatus,
    onOpen: onOpenStatus,
    onClose: onCloseStatus,
  } = useDisclosure();
  const [currentVideo, setCurrentVideo] = useState("");

  // console.log("videos", videos);
  // console.log("projectData", projectData);

  function OpenVideo(e, link) {
    if (e.metaKey) {
      var win = window.open(link, "_blank");
      win.focus();
    } else {
      history.push(link);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function clickStatus(e, video) {
    e.preventDefault();

    firstRenderStatus.current = true;
    firstRenderValidation.current = true;
    // console.log("video", video);
    setCurrentVideo(video);
    onOpenStatus();
    e.stopPropagation();
  }

  // console.log("projectData", projectData);
  // console.log("project avec tout", project);
  function openTask(e, video) {
    e.preventDefault();

    firstRenderStatus.current = true;
    firstRenderValidation.current = true;

    setCurrentVideo(video);
    onOpenEdit();
    e.stopPropagation();
  }

  const firstRenderStatus = useRef(true);
  const firstRenderValidation = useRef(true);
  return (
    <Box
      // borderWidth={colorMode === "light" ? "1px" : "0px"}
      // boxShadow="sm"
      // rounded="md"
      // bg={colorMode === "light" ? "white" : "gray.900"}
      // mb="6"
      mt='40px'>
      <CardTitle title='Jobs' isActive={true} />
      {jobs &&
        jobs
          .sort((a, b) => {
            if (a.createdAt < b.createdAt) {
              return 1;
            }
            if (a.createdAt > b.createdAt) {
              return -1;
            } else return 0;
          })

          .map((item, index) => {
            const Link = `/freelances/${freelanceId}/jobs/${item._id}`;
            return (
              // <Box key={index}>
              <TableRow
                key={index}
                click={(e) => OpenVideo(e, Link)}
                gridCol={"6fr  2fr 2fr 2fr"}>
                <Box color={colorMode === "light" ? "gray.500" : "white"}>
                  {`${item?.title} - ${item?.clientName}`}{" "}
                </Box>
                {/* <Box color={colorMode === "light" ? "gray.500" : "white"}> {item?.category?.label} </Box> */}
                <Box>
                  <Badge
                    fontSize='xs'
                    variant='solid'
                    colorScheme={newStatusSwitch(
                      projectData && item.newStatus && item.newStatus.value
                    )}>
                    {item.newStatus && item.newStatus.label}
                  </Badge>
                </Box>
                <Box color={colorMode === "light" ? "gray.500" : "white"}>
                  {" "}
                  {item?.pricing && item?.pricing + "€"}{" "}
                </Box>
                <Box color={colorMode === "light" ? "gray.500" : "white"}>
                  {" "}
                  {item?.createdAt &&
                    dayjs(item?.createdAt).format("DD/MM/YYYY")}
                </Box>
              </TableRow>
              // </Box>
            );
          })}

      {/* <Box py="10px">
        {admin && !isLoading && (
          <Button w="full" onClick={() => onOpen()}>
            <Flex alignItems="center">
              <Box mr="5px">
                <HiPlusCircle />
              </Box>{" "}
              Add a video
            </Flex>
          </Button>
        )}
      </Box> */}
      {/* <NewVideo
        isOpen={isOpen}
        onClose={onClose}
        reloadData={reloadData}
        setReloadData={setReloadData}
        // projectData={projectData}
        projectName={projectName}
        projectId={projectId}
        clientId={projectData?.client?._id}
      /> */}

      {/* <EditVideo
        isOpen={isOpenEdit}
        onClose={onCloseEdit}
        reloadData={reloadData}
        setReloadData={setReloadData}
        // projectData={videoData.project}
        projectName={projectName}
        projectId={projectId}
        videoId={currentVideo._id}
        videoData={currentVideo}
        clientId={projectData?.client?._id}
      /> */}

      {/* <UpdateStatus
        isOpen={isOpenStatus}
        onClose={onCloseStatus}
        videoData={currentVideo}
        admin={admin}
        projectId={currentVideo?.project}
        videoId={currentVideo?._id}
        projectName={currentVideo?.project?.name}
        videoName={currentVideo?.title}
        setReloadData={setReloadData}
        clientId={projectData?.client?._id}
      /> */}
    </Box>
  );
}

import React, { useReducer, useEffect, useState } from "react";
import { initialState, reducer } from "./reducer";
import { useUser } from "../../libs/UserContext";
import Select from "react-select";
import { customStyles, darkStyles } from "../../styles/customStyles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale } from "react-datepicker";
import {
  newStatusOptions,
  peopleOptions,
  categoryOptions,
  durationOptions,
  subtitleOptions,
  formatOptions,
  placesOptions,
  speakerLanguageOptions,
} from "../../data/videoData";
import {
  Button,
  Grid,
  Text,
  Box,
  Checkbox,
  Flex,
  Input,
  Switch,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  useColorMode,
  NumberInputField,
  NumberInput,
  cssVar,
} from "@chakra-ui/react";
import { createVideo, deleteVideo, updateVideo } from "./api";

export default function EditVideo(props) {
  const { colorMode } = useColorMode();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { user, admin } = useUser();
  const [dataDeleted, setDataDeleted] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [deleteButton, setDeleteButton] = useState(false);
  const [speakersArray, setSpeakersArray] = useState("");

  // console.log("user", user.username);
  const {
    isOpen,
    onClose,
    projectName,
    projectId,
    reloadData,
    setReloadData,
    videoId,
    videoData,
    clientId,
  } = props;

  useEffect(() => {
    dispatch({
      type: "previousData",
      payload: {
        name: videoData ? videoData.title : "",
        brief: videoData ? videoData.brief : "",
        status: videoData ? videoData.status : "",
        newStatus: videoData ? videoData.newStatus : "",
        date: videoData ? Date.parse(videoData.dueDate) : "",
        category: videoData ? videoData.category : "",
        format: videoData ? videoData.format : "",
        subtitle: videoData ? videoData.subtitle : "",
        duration: videoData ? videoData.duration : "",
        people: videoData ? videoData.people : "",
        places: videoData ? videoData.places : "",
        cancel: videoData ? videoData.cancel : "",
        pricing: videoData ? videoData.pricing : "",
        interviews: videoData ? videoData.interviews : "",
        shootings: videoData ? videoData.shootings : "",
        bRoll: videoData ? videoData.bRoll : "",
        speakerLanguage: videoData ? videoData.speakerLanguage : "",
        speakers: videoData && videoData.speakers ? videoData.speakers : [],
        month: videoData && videoData.month,
        client: videoData ? videoData.client : "",
        totalJobs: videoData ? videoData.totalJobs : "",
      },
    });
  }, [videoData]);

  // console.log("clientId", clientId);
  const DateForm = () => {
    return (
      <DatePicker
        className={colorMode === "light" ? "date-picker" : "date-picker-dark"}
        dateFormat='MMMM yyyy'
        placeholderText='Pick a date'
        isClearable
        calendarStartDay={1}
        selected={state.date}
        onChange={(date) => {
          dispatch({
            type: "field",
            payload: {
              fieldName: "date",
              value: date,
            },
          });
        }}
        // locale="fr"
      />
    );
  };
  // console.log("state", state);

  function save() {
    dispatch({ type: "save" });
    // console.log("admin", admin);
    // console.log("projectName", projectName);
    // console.log("projectId", projectId);
    // console.log("videoId", videoId);
    updateVideo(admin, projectName, projectId, state, videoId, clientId).then(
      (response) => {
        onClose();
        setReloadData(!reloadData);
        dispatch({ type: "success" });
      }
    );
  }

  function remove() {
    dispatch({ type: "delete" });

    deleteVideo(projectId, videoId).then((response) => {
      // console.log("dataDeleted", response);
      setDataDeleted(response);
      setReloadData(!reloadData);
      setDeleteButton(false);
      setConfirmMessage("");
      onClose();
      dispatch({ type: "success" });
    });
  }

  function randomId() {
    return Math.random();
  }

  useEffect(() => {
    const intw = state && parseInt(state.interviews);

    const speakersCount = intw - state.speakers.length;
    var arr = [];

    const arrayPush = () => {
      for (let i = 0; i < speakersCount; i++) {
        arr.push({
          id: randomId(),
          name: "",
          function: "",
          company: "",
        });
      }
    };
    arrayPush();

    if (speakersCount >= 0) {
      const fullArr = [...state.speakers, ...arr];
      dispatch({
        type: "field",
        payload: {
          fieldName: "speakers",
          value: fullArr,
        },
      });
    } else {
      const fullArr = [...state.speakers];
      for (let i = 0; i < speakersCount * -1; i++) {
        fullArr.pop();
      }

      dispatch({
        type: "field",
        payload: {
          fieldName: "speakers",
          value: fullArr,
        },
      });
    }
  }, [state.interviews]);

  function updateName(e, item) {
    let newArray = [...state.speakers];
    newArray.find((a) => a.id === item.id).name = e;

    dispatch({
      type: "field",
      payload: {
        fieldName: "speakers",
        value: newArray,
      },
    });
  }

  function updateFunction(e, item) {
    let newArray = [...state.speakers];
    newArray.find((a) => a.id === item.id).function = e;

    dispatch({
      type: "field",
      payload: {
        fieldName: "speakers",
        value: newArray,
      },
    });
  }

  function updateCompany(e, item) {
    let newArray = [...state.speakers];
    newArray.find((a) => a.id === item.id).company = e;

    dispatch({
      type: "field",
      payload: {
        fieldName: "speakers",
        value: newArray,
      },
    });
  }

  // function deleteSpeaker(item) {
  //   const arrayClean = speakersArray.filter((it) => it.id != item.id);
  //   setSpeakersArray(arrayClean);
  //   dispatch({
  //     type: "field",
  //     payload: {
  //       fieldName: "speakers",
  //       value: arrayClean,
  //     },
  //   });
  // }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior='outside'
      size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit video</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Title */}
          <Box my='4'>
            <FormControl id='subtitle'>
              <FormLabel>Title</FormLabel>
              <Input
                variant='filled'
                placeholder='Add a title'
                value={state.name}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "name",
                      value: e.target.value,
                    },
                  })
                }
              />
            </FormControl>
          </Box>
          {/* Brief*/}
          <Box my='4'>
            <FormLabel>Brief</FormLabel>
            <Textarea
              variant='filled'
              placeholder='Write a quick brief'
              size='md'
              resize='vertical'
              value={state.brief}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "brief",
                    value: e.target.value,
                  },
                });
              }}
            />
          </Box>
          {/* Pricing */}
          <Box my='4'>
            <FormControl id='pricing'>
              <FormLabel>Total HT</FormLabel>
              <NumberInput
                max={100000}
                min={10}
                variant='filled'
                value={
                  state && typeof state.pricing !== "number"
                    ? undefined
                    : state.pricing
                }>
                <NumberInputField
                  placeholder='Add a number'
                  onChange={(e) =>
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "pricing",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </NumberInput>
            </FormControl>
          </Box>

          {/* Status */}
          <Box my='4'>
            <FormLabel>Status</FormLabel>

            <Select
              styles={colorMode === "light" ? customStyles : darkStyles}
              isClearable
              placeholder='Select a status'
              value={state.newStatus}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "newStatus",
                    value: e,
                  },
                });
              }}
              options={newStatusOptions}
            />
          </Box>

          {/* Date */}
          <Box my='4'>
            <FormLabel>Date</FormLabel>
            <Flex>
              <Box maxW='200'>
                <DateForm />
              </Box>
            </Flex>
          </Box>

          {/* Casting */}
          <Box my='4'>
            <FormLabel>Casting</FormLabel>

            <Select
              styles={colorMode === "light" ? customStyles : darkStyles}
              isMulti
              isClearable
              placeholder='Select the casting'
              value={state.people}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "people",
                    value: e,
                  },
                });
              }}
              options={peopleOptions}
            />
          </Box>

          {/* Speaker Language */}
          <Box my='4'>
            <FormLabel>Speaker Language</FormLabel>

            <Select
              styles={colorMode === "light" ? customStyles : darkStyles}
              isMulti
              isClearable
              placeholder='Select the language'
              value={state.speakerLanguage}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "speakerLanguage",
                    value: e,
                  },
                });
              }}
              options={speakerLanguageOptions}
            />
          </Box>

          <Grid templateColumns='4fr 4fr 1fr' maxW='300px'>
            {/* Interviews */}
            <Box my='4' mr='20px'>
              <FormControl id='interviews'>
                <FormLabel>Interviews</FormLabel>
                <NumberInput
                  max={100}
                  min={0}
                  variant='filled'
                  value={
                    state && typeof state.interviews !== "number"
                      ? undefined
                      : state.interviews
                  }>
                  <NumberInputField
                    placeholder='Qty'
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "interviews",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </NumberInput>
              </FormControl>
            </Box>

            {/* Shooting */}
            <Box my='4'>
              <FormControl id='shooting'>
                <FormLabel>Shooting</FormLabel>
                <NumberInput
                  max={100}
                  min={0}
                  variant='filled'
                  value={
                    state && typeof state.shootings !== "number"
                      ? undefined
                      : state.shootings
                  }>
                  <NumberInputField
                    placeholder='Qty'
                    onChange={(e) =>
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "shootings",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </NumberInput>
              </FormControl>
            </Box>

            {/* bRoll */}
            <Box my='4' ml='20px'>
              <FormLabel>B roll</FormLabel>
              <Flex>
                <Box maxW='200'>
                  <Switch
                    size='lg'
                    // colorScheme="red"
                    isChecked={state.bRoll}
                    onChange={(e) => {
                      dispatch({
                        type: "field",
                        payload: {
                          fieldName: "bRoll",
                          value: e.currentTarget.checked,
                        },
                      });
                    }}
                  />
                </Box>
              </Flex>
            </Box>
          </Grid>

          {/* Speakers */}
          <FormLabel>Speakers names</FormLabel>
          {state.speakers &&
            state.speakers.map((item) => {
              return (
                <Grid templateColumns='1fr 1fr 1fr'>
                  <Box>
                    <FormControl id='name'>
                      {/* <FormLabel>Name</FormLabel> */}
                      <Input
                        variant='filled'
                        placeholder='Name'
                        value={item.name}
                        onChange={(e) => updateName(e.target.value, item)}
                      />
                    </FormControl>
                  </Box>

                  <Box mx='10px' my='5px'>
                    <FormControl id='function'>
                      {/* <FormLabel>Name</FormLabel> */}
                      <Input
                        variant='filled'
                        placeholder='Function'
                        value={item.function}
                        onChange={(e) => updateFunction(e.target.value, item)}
                      />
                    </FormControl>
                  </Box>

                  <Box>
                    <FormControl id='company'>
                      {/* <FormLabel>Name</FormLabel> */}
                      <Input
                        variant='filled'
                        placeholder='Company'
                        value={item.company}
                        onChange={(e) => updateCompany(e.target.value, item)}
                      />
                    </FormControl>
                  </Box>
                  {/* <Box>
                    <Button onClick={() => deleteSpeaker(item)}>X</Button>
                  </Box> */}
                </Grid>
              );
            })}

          {/* Category */}
          <Box my='4'>
            <FormLabel>Category</FormLabel>

            <Select
              styles={colorMode === "light" ? customStyles : darkStyles}
              isClearable
              placeholder='Select the category'
              value={state.category}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "category",
                    value: e,
                  },
                });
              }}
              options={categoryOptions}
            />
          </Box>

          {/* Time */}
          <Box my='4'>
            <FormLabel>Duration</FormLabel>

            <Select
              styles={colorMode === "light" ? customStyles : darkStyles}
              isClearable
              placeholder='Select the duration'
              value={state.duration}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "duration",
                    value: e,
                  },
                });
              }}
              options={durationOptions}
            />
          </Box>

          {/* Places */}
          <Box my='4'>
            <FormLabel>Places</FormLabel>

            <Select
              styles={colorMode === "light" ? customStyles : darkStyles}
              isClearable
              placeholder='Select the places'
              value={state.places}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "places",
                    value: e,
                  },
                });
              }}
              options={placesOptions}
            />
          </Box>

          {/* Subtitles */}
          <Box my='4'>
            <FormLabel>Subtitles</FormLabel>

            <Select
              styles={colorMode === "light" ? customStyles : darkStyles}
              isMulti
              isClearable
              placeholder='Select the subtitles'
              value={state.subtitle}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "subtitle",
                    value: e,
                  },
                });
              }}
              options={subtitleOptions}
            />
          </Box>

          {/* Formats */}
          <Box my='4'>
            <FormLabel>Formats</FormLabel>

            <Select
              styles={colorMode === "light" ? customStyles : darkStyles}
              isMulti
              isClearable
              placeholder='Select the formats'
              value={state.format}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: {
                    fieldName: "format",
                    value: e,
                  },
                });
              }}
              options={formatOptions}
            />
          </Box>

          {/* Cancel switch */}
          <Box my='4'>
            <FormLabel>Cancel</FormLabel>
            <Flex>
              <Box maxW='200'>
                <Switch
                  size='lg'
                  colorScheme='red'
                  isChecked={state.cancel}
                  onChange={(e) => {
                    dispatch({
                      type: "field",
                      payload: {
                        fieldName: "cancel",
                        value: e.currentTarget.checked,
                      },
                    });
                  }}
                />
              </Box>
            </Flex>
          </Box>
        </ModalBody>

        <ModalFooter>
          {(user.username === "florian@kronik.com" ||
            user.username === "matt@kronik.com") &&
            !deleteButton && (
              <Button
                // colorScheme="blue"
                mr={3}
                onClick={() => setDeleteButton(true)}>
                Delete
              </Button>
            )}
          {!deleteButton && (
            <Button
              colorScheme='blue'
              mr={3}
              onClick={() => save()}
              isLoading={state.isLoadingOne}
              loadingText='Loading'>
              Save
            </Button>
          )}
          {deleteButton && (
            <Box mr='20px'>
              <FormControl id='confirmation'>
                {/* <FormLabel>
                  You will delete this video and all the linked tasks
                </FormLabel> */}
                <Input
                  variant='filled'
                  placeholder='Enter DELETE to confirm'
                  value={confirmMessage}
                  onChange={(e) => setConfirmMessage(e.target.value)}
                />
              </FormControl>
            </Box>
          )}
          {deleteButton && (
            <Button
              // colorScheme="blue"
              mr={3}
              onClick={() => setDeleteButton(false)}>
              Cancel
            </Button>
          )}
          {confirmMessage === "DELETE" && deleteButton && (
            <Button
              bgColor='primary'
              color='white'
              mr={3}
              onClick={() => remove()}
              isLoading={state.isLoadingTwo}
              loadingText='Loading'>
              Delete
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

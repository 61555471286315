import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Heading,
  useColorMode,
  Link,
  Flex,
  Badge,
  Text,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  Button,
  Image,
} from "@chakra-ui/react";
import {
  Link as RouteLink,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";
import fetchVideos from "./api";

import { useUser } from "../../libs/UserContext";
import Card from "../../components/card";
import { newStatusEmoji, newStatusSwitch } from "../../data/videoData";
import { Updating } from "../../components/notification/Updating";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function VideosList(props) {
  const history = useHistory();
  const { videoStatus, filteredVideos } = props;
  const { admin, freelance } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const { colorMode } = useColorMode();

  const [dateSelected, setDateSelected] = useState("all");
  const [display, setDisplay] = useState("");
  const [videos, setVideos] = useState("");
  // const [filteredVideos, setFilteredVideos] = useState("");
  const [notStarted, setNotStarted] = useState("");
  const [production, setProduction] = useState("");
  const [delivered, setDelivered] = useState("");
  const [deliveredQty, setDeliveredQty] = useState(0);
  const [productionQty, setProductionQty] = useState(0);
  const [preparationQty, setPreparationQty] = useState(0);
  const [filteredVideosQty, setFilteredVideosQty] = useState("");

  // console.log("filterging clientVideos", filteredVideos);
  return (
    <>
      <Box as='main'>
        <Grid templateColumns='1fr 1fr 1fr 1fr ' gap='20px' mt='20px'>
          {filteredVideos &&
            filteredVideos
              .sort((a, b) => {
                if (!b.revenueMonth) {
                  return -1;
                }
                return new Date(b.revenueMonth) - new Date(a.revenueMonth);
              })
              .map((video, index) => {
                return (
                  <Link
                    key={index}
                    as={RouteLink}
                    to={`/projects/${video.id}/quotes/${video.projectId}`}
                    _hover={{ textDecor: "none" }}>
                    <Flex
                      justifyContent='space-between'
                      boxShadow='sm'
                      rounded='xl'
                      minH='220px'
                      m='5px'
                      p='20px'
                      direction='column'
                      _hover={{
                        bgColor:
                          colorMode === "light" ? "gray.50" : "whiteAlpha.100",
                        shadow: "lg",
                      }}
                      bg={colorMode === "light" ? "white" : "#232323"}>
                      <Flex flexDirection='column'>
                        <Flex
                          alignItems='center'
                          pb='15px'
                          color={
                            colorMode === "light" ? "primary" : "whiteAlpha.600"
                          }
                          fontSize='md'>
                          {video?.clientLogo && (
                            <Image
                              mr='15px'
                              borderRadius='md'
                              boxSize='32px'
                              alt={`Logo of ${video?.client}`}
                              src={`https://aaliz317.twic.pics/${video?.clientLogo}`}
                            />
                          )}
                          {admin || freelance ? video.client : video.project}
                        </Flex>
                        <Text fontSize='lg'>{video.title}</Text>
                      </Flex>

                      <Flex justifyContent='space-between'>
                        <Text>
                          {video?.newStatus && (
                            <Badge
                              mt='5px'
                              fontSize='xs'
                              variant='solid'
                              borderRadius='full'
                              px='10px'
                              py='5px'
                              // bgColor={newStatusSwitch(video?.newStatus?.value)}
                              bgColor='whiteAlpha.100'
                              // colorScheme={newStatusSwitch(
                              //   video?.newStatus?.value
                              // )}
                            >
                              {newStatusEmoji(video?.newStatus?.value)} {"  "}
                              {video?.newStatus ? video?.newStatus?.label : ""}
                            </Badge>
                          )}
                        </Text>
                      </Flex>
                    </Flex>
                  </Link>
                );
              })}
        </Grid>
      </Box>
      {isLoading && <Updating />}
    </>
  );
}

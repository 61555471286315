import React, { useState, useRef, useEffect } from "react";

import {
  Flex,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Progress,
  Button,
  Text,
} from "@chakra-ui/react";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  iniateUpload,
  UploadFiles,
  postDataFiles,
} from "../../../src/pages/task/api";
import { IoCloudUpload } from "react-icons/io5";

export default function Uploader({ uploadFiles }) {
  const [totalProgress, setTotalProgress] = useState(0);
  const [currentUpload, setCurrentUpload] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const arrayClean =
      uploadFiles.length > 0 &&
      uploadFiles
        .filter((file) => file.cancel !== true)
        .map((file) =>
          file?.percentage !== null && file?.percentage !== undefined
            ? file?.percentage
            : 0
        );
    setTotalProgress(
      arrayClean &&
        Math.round(
          arrayClean.reduce(
            (partial_sum, a) => parseInt(partial_sum) + parseInt(a),
            0,
            0
          ) / arrayClean.length
        )
    );
    setCurrentUpload(
      uploadFiles.filter(
        (file) => file.cancel !== true || file.percentage !== "100.00"
      ).length
    );
  }, [uploadFiles]);

  const cancelUpload = (data) => {
    // console.log('cancel upload', data)
    // const controller = data.controller
    data.controller?.abort();
    // data.cancelTokenSource.cancel();
  };

  return (
    <>
      <Flex
        direction='column'
        p='20px'
        bg='primary'
        minH='60px'
        borderRadius='lg'
        width='300px'
        color='white'
        position='fixed'
        overflow='visible'
        bottom='20px'
        left='auto'
        right='24px'
        display='block'
        zIndex={10000}
        onClick={onOpen}
        _hover={{ cursor: "pointer", opacity: "0.9" }}>
        Uploading {currentUpload} {currentUpload > 1 ? "files" : "file"}{" "}
        {totalProgress ? totalProgress + "%" : ""}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Uploading {currentUpload} {currentUpload > 1 ? "files" : "file"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {uploadFiles &&
              uploadFiles
                .filter(
                  (file) => file.cancel !== true || file.percentage !== "100.00"
                )
                .map((data, index) => {
                  return (
                    <Box key={index} mb='10px'>
                      <Progress
                        hasStripe
                        value={data?.percentage ? data?.percentage : 0}
                      />
                      {data && data?.percentage
                        ? data?.percentage + "% " + data?.name
                        : 0}
                      <Text
                        ml='5px'
                        as='span'
                        _hover={{ cursor: "pointer" }}
                        onClick={() => cancelUpload(data)}>
                        Cancel
                      </Text>
                    </Box>
                  );
                })}
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

import React, { useState } from "react";
import dayjs from "dayjs";

import {
  Box,
  Avatar,
  Grid,
  Button,
  Heading,
  useColorMode,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Link,
  useDisclosure,
  IconButton,
  Stack,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  Image,
} from "@chakra-ui/react";
import { useUser } from "../../libs/UserContext";
import { SearchIcon } from "@chakra-ui/icons";
import { AiFillFolder } from "react-icons/ai";
import { FaMicrophone, FaCalendar } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import { GoDeviceCameraVideo } from "react-icons/go";
import {
  IoTime,
  IoPeople,
  IoChatboxEllipsesSharp,
  IoLanguageSharp,
} from "react-icons/io5";
import {
  MdPhotoSizeSelectLarge,
  MdSubtitles,
  MdPlace,
  MdVideoLibrary,
} from "react-icons/md";
import { Link as RouteLink } from "react-router-dom";
import Card from "../../components/card";
import {
  statusEmoji,
  statusSwitch,
  validationEmoji,
  validationSwitch,
} from "../../data/taskData";
import ButtonColor from "../../components/button/ButtonColor";
import UpdateStatus from "../../components/task/UpdateStatus";
import { IoFlash } from "react-icons/io5";
import { newStatusEmoji } from "../../data/videoData";

export default function OrderSummary({
  taskData,
  projectId,
  videoId,
  projectName,
  videoName,
  setReloadData,
  firstRenderStatus,
  firstRenderValidation,
  isLoading,
}) {
  const { admin, freelance } = useUser();
  const { colorMode } = useColorMode();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        // bg={colorMode === "light" ? "white" : "#232323"}
        // padding='20px'
        rounded='xl'
        // borderWidth={colorMode === "light" ? "1px" : "0px"}
        // borderColor='#F0F0F0'
        // boxShadow='sm'
      >
        {/* Brief*/}
        <Grid direction='column' gap='20px'>
          {/* Status*/}
          <Flex justifyContent='space-between'>
            <Text
              fontSize='sm'
              color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
              Status
            </Text>
            <Text>
              {taskData?.status && (
                <Badge
                  fontSize='xs'
                  variant='solid'
                  borderRadius='full'
                  px='10px'
                  py='5px'
                  // bgColor={statusSwitch(
                  //   taskData && taskData.status && taskData.status.value
                  // )}
                  bgColor='whiteAlpha.100'>
                  {statusEmoji(taskData?.status?.value)}{" "}
                  {taskData && taskData.status ? taskData.status.label : ""}
                </Badge>
              )}
            </Text>
          </Flex>

          {/* Status*/}
          <Flex justifyContent='space-between'>
            <Text
              fontSize='sm'
              color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
              Validation
            </Text>
            <Text>
              {taskData?.validation && (
                <Badge
                  fontSize='xs'
                  variant='solid'
                  borderRadius='full'
                  px='10px'
                  py='5px'
                  // bgColor={validationSwitch(
                  //   taskData && taskData.validation && taskData.validation.value
                  // )}
                  bgColor='whiteAlpha.100'>
                  {validationEmoji(taskData?.validation?.value)}{" "}
                  {taskData && taskData.validation
                    ? taskData.validation.label
                    : ""}
                </Badge>
              )}
            </Text>
          </Flex>
          {/* Date*/}
          <Flex justifyContent='space-between'>
            <Text
              fontSize='sm'
              color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
              Date
            </Text>
            <Box as='h4' mx='10px'>
              {taskData && dayjs(taskData?.dueDate).format("DD/MM/YYYY")}
            </Box>
          </Flex>
          {/* People*/}
          <Flex justifyContent='space-between' alignItems='center'>
            <Text
              fontSize='sm'
              color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
              Leader
            </Text>
            <Flex alignItems='center'>
              {taskData?.taskLeader?.profile_picture && (
                <Image
                  // mr='10px'
                  borderRadius='full'
                  boxSize='32px'
                  src={`https://aaliz317.twic.pics/${taskData.taskLeader.profile_picture}`}
                  alt={` Picture of {item.taskLeader.given_name}`}
                />
              )}
              <Box as='h4' mx='10px'>
                {taskData?.taskLeader?.given_name}{" "}
                {taskData?.taskLeader?.family_name}
              </Box>
            </Flex>
          </Flex>
          {/* Category*/}
          <Flex justifyContent='space-between'>
            <Text
              fontSize='sm'
              color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
              Category
            </Text>
            <Box as='h4' mx='10px'>
              {taskData?.category?.label}
            </Box>
          </Flex>

          {/* Brief*/}
          <Flex justifyContent='space-between'>
            <Text
              fontSize='sm'
              color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
              Brief
            </Text>
            <Box mx='10px' overflowWrap='anywhere'>
              {taskData?.brief}
            </Box>
          </Flex>
        </Grid>
      </Box>
      {(admin || freelance) && !isLoading && (
        <ButtonColor
          title={
            <Flex alignItems='center'>
              <Box mr='5px'>
                <IoFlash />{" "}
              </Box>
              Update status
            </Flex>
          }
          click={() => onOpen()}
        />
      )}

      <UpdateStatus
        isOpen={isOpen}
        onClose={onClose}
        taskData={taskData}
        admin={admin}
        projectId={projectId}
        videoId={videoId}
        projectName={projectName}
        videoName={videoName}
        setReloadData={setReloadData}
        taskId={taskData._id}
        firstRenderStatus={firstRenderStatus}
        firstRenderValidation={firstRenderValidation}
      />
    </>
  );
}

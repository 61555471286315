import { API } from "aws-amplify";
import axios from "axios";

export async function updateUser(userId, state, admin) {
  const url = `/people/update/${userId}?admin=${admin}`;
  return await API.put("kronik", url, {
    body: {
      given_name: state.given_name,
      family_name: state.family_name,
      profile_picture: state.profile_picture ? state.profile_picture : null,
    },
  }).then((response) => {
    // console.log("response user", response);
    // console.log("api done");

    return response;
  });
}

export async function updatePicture(file) {
  const fileName = file.name;
  const reader = new FileReader();
  const url = `https://api.kronik.com/file/putObject`;

  const config = {
    onUploadProgress: (progressEvent) =>
      console.log(
        parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
      ),
  };

  reader.onloadend = () => {
    const base64String = reader.result.split(`base64,`)[1];
    const dataInfo = {
      fileName: fileName,
      base64String: base64String,
    };

    axios
      .post(url, dataInfo, config)
      .then((r) => {
        console.log(r);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  reader.readAsDataURL(file);
}
//   return await API.put("kronik", url, {})
//     .then((response) => {
//       console.log("response upload", response);
//       return response;
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// }

export const initialState = {
  brief:"",
  product:"",
  creator:"",
  pricing	:"",
  date: "",
  firstName:"",
  lastName:"",
  job:"",
  email:"",
  company:"",
  source:{ label: "", value: ""},
  status: { label: "À faire", value: "todo" },
  location:"",
  quantity:"",
  dueDate:"",
  client:"",
  currentClient: { label: "", value: "" },


};

export function reducer(state, action) {
  switch (action.type) {
    case "previousData": {
      return {
        ...state,
        brief:action.payload.brief,
        product:action.payload.product,
        creator:action.payload.creator,
        pricing:action.payload.pricing,
        date:action.payload.date,
        firstName:action.payload.firstName,
        lastName:action.payload.lastName,
        job:action.payload.job,
        email:action.payload.email,
        company:action.payload.company,
        source:action.payload.source,
        status:action.payload.status,
        location:action.payload.location,
        quantity:action.payload.quantity,
        dueDate:action.payload.dueDate,
        client:action.payload.client,
        currentClient: action.payload.currentClient,

      };
    }
    case "field": {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      };
    }
    case "save": {
      return {
        ...state,
        isLoadingOne: true,
      };
    }
    case "delete": {
      return {
        ...state,
        isLoadingTwo: true,
      };
    }
    case "error": {
      return { ...state, error: "there is an error" };
    }
    case "success": {
      return initialState;
    }

    default:
      return state;
  }
}

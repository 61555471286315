import React from "react";

import {
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";

export default function NavBar(props) {
  const history = useHistory();

  const {
    clients,
    admin,
    freelance,
    // filteredVideos,
    queryStatus,
    queryClient,
    queryDisplay,
    queryManager,
  } = props;
  return (
    <HStack my='5'>
      {/* Display filter */}
      {admin && (
        <Menu>
          <MenuButton
            as={Button}
            variant='outline'
            rounded='full'
            rightIcon={<ChevronDownIcon />}>
            {queryDisplay === "all" && queryManager === "all"
              ? "All projects"
              : queryDisplay === "all" && queryManager === "géraldinemillion"
              ? "Géraldine Million"
              : queryDisplay === "all" && queryManager === "maxencelebreux"
              ? "Maxence Lebreux"
              : queryDisplay === "all" && queryManager === "geoffroylasne"
              ? "Geoffroy Lasne"
              : queryDisplay === "all" && queryManager === "mariongaufroy"
              ? "Marion Gaufroy"
              : queryDisplay === "all" && queryManager === "claracolleoni"
              ? "Clara Colleoni"
              : "My projects"}
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                history.push(
                  `?client=${queryClient?.toLowerCase()}&display=all&manager=all`
                );
              }}>
              All projects
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?client=${queryClient?.toLowerCase()}&display=me&manager=all`
                );
              }}>
              My projects
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?client=${queryClient?.toLowerCase()}&display=all&manager=géraldinemillion`
                );
              }}>
              Géraldine Million
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?client=${queryClient?.toLowerCase()}&display=all&manager=maxencelebreux`
                );
              }}>
              Maxence Lebreux
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?client=${queryClient?.toLowerCase()}&display=all&manager=geoffroylasne`
                );
              }}>
              Geoffroy Lasne
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?client=${queryClient?.toLowerCase()}&display=all&manager=mariongaufroy`
                );
              }}>
              Marion Gaufroy
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(
                  `?client=${queryClient?.toLowerCase()}&display=all&manager=claracolleoni`
                );
              }}>
              Clara Colleoni
            </MenuItem>
          </MenuList>
        </Menu>
      )}

      {/* Client filter */}
      {admin && (
        <Menu>
          <MenuButton
            as={Button}
            variant='outline'
            rounded='full'
            rightIcon={<ChevronDownIcon />}
            textTransform='capitalize'>
            {queryClient !== "all" ? queryClient : "All clients"}
          </MenuButton>
          <MenuList maxH='300px' overflow='auto'>
            <MenuItem
              onClick={() => {
                history.push(
                  `?client=all&display=${queryDisplay}&manager=${queryManager}`
                );
              }}>
              All clients
            </MenuItem>

            {clients &&
              clients.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      history.push(
                        `?client=${item?.toLowerCase()}&display=${queryDisplay}&manager=${queryManager}`
                      );
                    }}>
                    {item}
                  </MenuItem>
                );
              })}
          </MenuList>
        </Menu>
      )}

      {/* Display filter
      {admin && (
        <Menu>
          <MenuButton
            as={Button}
            variant="link"
            rightIcon={<ChevronDownIcon />}
          >
            {display === "all" && accountManagerSelected === "all"
              ? "All projects"
              : display === "all" &&
                accountManagerSelected === "Géraldine Million"
              ? "Géraldine Million"
              : display === "all" && accountManagerSelected === "Geoffroy Lasne"
              ? "Geoffroy Lasne"
              : display === "all" && accountManagerSelected === "Marion Gaufroy"
              ? "Marion Gaufroy"
              : display === "all" && accountManagerSelected === "Clara Colleoni"
              ? "Clara Colleoni"
              : "My projects"}
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                setDisplay("all");
                setAccountManagerSelected("all");
              }}
            >
              All projects
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDisplay("me");
                setAccountManagerSelected("all");
              }}
            >
              My projects
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDisplay("all");
                setAccountManagerSelected("Géraldine Million");
              }}
            >
              Géraldine Million
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDisplay("all");
                setAccountManagerSelected("Geoffroy Lasne");
              }}
            >
              Geoffroy Lasne
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDisplay("all");
                setAccountManagerSelected("Marion Gaufroy");
              }}
            >
              Marion Gaufroy
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDisplay("all");
                setAccountManagerSelected("Clara Colleoni");
              }}
            >
              Clara Colleoni
            </MenuItem>
          </MenuList>
        </Menu>
      )} */}

      {/* Client filter
      <Menu>
        <MenuButton as={Button} variant="link" rightIcon={<ChevronDownIcon />}>
          {clientSelected !== "all" ? clientSelected : "Organisation"}
        </MenuButton>
        <MenuList maxH="300px" overflow="auto">
          <MenuItem
            onClick={() => {
              setClientSelected("all");
            }}
          >
            All
          </MenuItem>

          {clients &&
            clients.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setClientSelected(item);
                  }}
                >
                  {item}
                </MenuItem>
              );
            })}
        </MenuList>
      </Menu> */}
    </HStack>
  );
}

import React, { useState, useEffect } from "react";
import { Grid, Box, Heading, useColorMode, Link, Flex } from "@chakra-ui/react";
import { Link as RouteLink, NavLink, useLocation } from "react-router-dom";

import { useUser } from "../../libs/UserContext";
import Charts from "./Charts";
import CurrentVideos from "./CurrentVideos";
import { FreelanceDashboard } from "./FreelanceDashboard";
import { ClientDashboard } from "./ClientDashboard";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";
import MainColumn from "../../components/column/MainColumn";
import DashboardHeader from "../../components/header/DashboardHeader";

export default function Dashboard() {
  const { admin, freelance, client } = useUser();

  return (
    <Box width='100%'>
      <DashboardHeader />
      <MainColumn>
        <Box mx='20px'>
          {freelance && (
            <>
              <FreelanceDashboard />
              {/* <CurrentVideos/> */}
            </>
          )}
          {admin && (
            <Charts />
            //  <FreelanceDashboard />
            // <ClientDashboard />
          )}
          {client && <ClientDashboard />}
        </Box>
      </MainColumn>
    </Box>
  );
}

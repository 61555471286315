import React, {
  useState,
  Fragment,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../libs/UserContext";
import { useParams } from "react-router-dom";

import {
  Flex,
  Stack,
  Button,
  Box,
  Heading,
  Link,
  Text,
  Container,
  useColorMode,
  useColorModeValue,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Spinner,
} from "@chakra-ui/react";

function reducer(state, action) {
  switch (action.type) {
    case "loading": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "field": {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      };
    }
    case "confirmationCode": {
      return {
        ...state,
        confirmationCode: action.payload.value,
      };
    }
    case "emailSuccess": {
      return {
        ...state,
        emailLoggedIn: true,
        cognitoUser: action.payload.cognitoUser,
        isLoading: false,
      };
    }
    case "codeSuccess": {
      return {
        ...state,
        codeLogin: true,
        isLoading: false,
      };
    }
    case "createUser": {
      return {
        ...state,
        signUp: true,
        isLoading: false,
      };
    }
    case "createCognitoUser": {
      return {
        ...state,
        cognitoUser: action.payload.cognitoUser,
      };
    }
    default: {
      return state;
    }
  }
}

const initialState = {
  email: "",
  emailLoggedIn: false,
  confirmationCode: "",
  codeLogin: false,
  cognitoUser: "",
  isLoading: false,
  signUp: false,
  firstName: "",
  lastName: "",
  adress: "",
};

export default function Login() {
  let history = useHistory();
  const { colorMode, toggleColorMode } = useColorMode();

  const [state, dispatch] = useReducer(reducer, initialState);

  let { category } = useParams();

  const { user, setUser } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [cognitoUser, setCognitoUser] = useState("");
  // const [validated, setValidated] = useState(false);

  useEffect(() => {
    getUserData();
  }, []);

  async function getUserData() {
    if (user) {
      history.push("/dashboard");
      history.go(0);
      // console.log("user data", user);
    } else if (!user) {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        console.log("user data", user);

        window.$crisp.push(["set", "user:email", [userData.username]]);
        window.$crisp.push([
          "set",
          "user:nickname",
          [
            userData.attributes.given_name +
              " " +
              userData.attributes.family_name,
          ],
        ]);
        setUser(userData);
        history.push("/dashboard");
        history.go(0);
      } catch (e) {
        setLoading(false);
      }
    }
  }

  async function emailSubmit(e) {
    e.preventDefault();
    dispatch({ type: "loading" });

    try {
      const cognitoUser = await Auth.signIn(state.email);
      // console.log("cognitoUser", cognitoUser);
      dispatch({
        type: "emailSuccess",
        payload: {
          cognitoUser: cognitoUser,
        },
      });
    } catch (e) {
      console.log(e.message);
      dispatch({
        type: "createUser",
      });
    }
  }

  async function signUpSubmit(e) {
    e.preventDefault();

    // console.log("category", category)

    const params = {
      username: state.email,
      password: getRandomString(30),
      attributes: {
        // name: `${state.firstName} ${state.lastName}`,
        family_name: state.lastName,
        given_name: state.firstName,
        "custom:user_role": category ? category : null,
      },
    };
    try {
      // console.log("Auth.signUp")
      // console.log("params", params)
      await Auth.signUp(params);
      emailSubmit(e);
    } catch (e) {
      alert(e.message);
      console.log("error signUpSubmit", e);
    }
  }

  async function confirmSubmit(e) {
    e.preventDefault();
    dispatch({ type: "loading" });
    try {
      const userData = await Auth.sendCustomChallengeAnswer(
        state.cognitoUser,
        state.confirmationCode
      );

      if (state.signUp) {
        createUser();
        dispatch({
          type: "createCognitoUser",
          payload: {
            cognitoUser: cognitoUser,
          },
        });
        // setCognitoUser(userData);

        // history.push("/dashboard");
      } else {
        setUser(userData);
        dispatch({
          type: "codeSuccess",
          payload: {
            codeLogin: true,
          },
        });
        window.$crisp.push(["set", "user:email", [userData.username]]);
        window.$crisp.push([
          "set",
          "user:nickname",
          [
            userData.attributes.given_name +
              " " +
              userData.attributes.family_name,
          ],
        ]);

        history.push("/dashboard");
        history.go(0);
      }
    } catch (e) {
      alert(e.message);
      console.log("error confirmSubmit", e);
    }
  }

  async function createUser() {
    try {
      // await API.post("kronik", "/user/create", {
      await API.post("kronik", "/people/create", {
        body: {
          email: state.email,
          family_name: state.lastName,
          given_name: state.firstName,
          from:
            category === "freelance"
              ? "freelance"
              : category === "client"
              ? "client"
              : null,
        },
      });
      setUser(state.cognitoUser);
      dispatch({
        type: "codeSuccess",
        payload: {
          codeLogin: true,
        },
      });
      window.$crisp.push(["set", "user:email", [state.email]]);
      window.$crisp.push([
        "set",
        "user:nickname",
        [state.firstName + " " + state.lastName],
      ]);
      history.push("/dashboard");
      history.go(0);
    } catch (e) {
      alert(e.message);
      console.log("error createUser", e);
    }
  }

  function getRandomString(bytes) {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join("");
  }

  function intToHex(nr) {
    return nr.toString(16).padStart(2, "0");
  }

  return (
    <Flex minH='100vh' align='center' justify='center'>
      <Stack spacing={8} minW='lg' maxW='lg' py='12' px='6'>
        <Stack align='center'>
          <Heading fontSize='4xl'>Welcome kroniker ✌️</Heading>
          <Text
            fontSize={"lg"}
            color={colorMode === "light" ? "gray.600" : "whiteAlpha.600"}>
            Please sign in to your account
          </Text>
        </Stack>
        <Box
          rounded='lg'
          bg={useColorModeValue("white", "#232323")}
          boxShadow='lg'
          p='8'>
          {/* Step 1  : email*/}
          <Stack spacing='4'>
            <FormControl
              id='email'
              value={state.email}
              isRequired
              isDisabled={state.emailLoggedIn}
              onChange={(e) => {
                dispatch({
                  type: "field",
                  payload: { fieldName: "email", value: e.target.value },
                });
              }}>
              <FormLabel>Email</FormLabel>
              <Input type='email' placeholder='Enter your email adress' />
              {!state.emailLoggedIn && (
                <FormHelperText>We'll never share your email.</FormHelperText>
              )}
            </FormControl>

            {!state.emailLoggedIn && !state.signUp && (
              <Button
                bg='primary'
                color='white'
                isDisabled={!state.email || state.isLoading}
                type='submit'
                onClick={(e) => emailSubmit(e)}
                // onClick={(e) =>console.log("category", category)}
              >
                {state.isLoading ? <Spinner /> : "Sign in"}
              </Button>
            )}

            {/* Step 2  : confirmation code*/}
            {state.emailLoggedIn && (
              <Fragment>
                <FormControl
                  type='password'
                  value={state.confirmationCode}
                  onChange={(e) =>
                    dispatch({
                      type: "confirmationCode",
                      payload: { value: e.target.value },
                    })
                  }>
                  <FormLabel>Login code</FormLabel>
                  <Input type='password' placeholder='Paste login code' />
                  <FormHelperText>
                    We just sent you a temporary login code by email.
                  </FormHelperText>
                </FormControl>
                <Button
                  isDisabled={!state.confirmationCode || state.isLoading}
                  type='submit'
                  onClick={confirmSubmit}
                  bg='primary'
                  color='white'>
                  {state.isLoading ? <Spinner /> : "Confirm your login code"}
                </Button>
              </Fragment>
            )}

            {/* Step 2 bis : create account */}

            {state.signUp && !state.emailLoggedIn && (
              <FormControl
                type='text'
                isRequired
                isDisabled={state.emailLoggedIn}
                placeholder='Ajouter votre prénom'
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "firstName",
                      value: e.target.value,
                    },
                  })
                }
                value={state.firstName}>
                <FormLabel>Prénom</FormLabel>
                <Input type='text' placeholder='Enter your first name' />
              </FormControl>
            )}

            {state.signUp && !state.emailLoggedIn && (
              <FormControl
                type='text'
                isRequired
                isDisabled={state.emailLoggedIn}
                placeholder='Ajouter votre nom'
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "lastName",
                      value: e.target.value,
                    },
                  })
                }
                value={state.lastName}>
                <FormLabel>Nom</FormLabel>
                <Input type='text' placeholder='Enter your last name' />
              </FormControl>
            )}

            {state.signUp && !state.emailLoggedIn && category === "freelance" && (
              <FormControl
                type='text'
                isRequired
                // isDisabled={state.adress}
                placeholder='Ajouter votre adresse'
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    payload: {
                      fieldName: "adress",
                      value: e.target.value,
                    },
                  })
                }
                value={state.adress}>
                <FormLabel>Adress</FormLabel>
                <Input type='text' placeholder='Enter your adress' />
              </FormControl>
            )}

            {state.signUp && !state.emailLoggedIn && (
              <Button
                isDisabled={
                  !state.email ||
                  !state.firstName ||
                  !state.lastName ||
                  state.isLoading
                }
                type='submit'
                onClick={(e) => signUpSubmit(e)}
                bg='primary'
                color='white'>
                {state.isLoading ? <Spinner /> : "Sign up"}
              </Button>
            )}
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  IconButton,
  Grid,
  Button,
  Heading,
  useColorMode,
  Text,
  useDisclosure,
  Avatar,
  AvatarGroup,
  Link,
} from "@chakra-ui/react";
import {
  HiLocationMarker,
  HiPencilAlt,
  HiTrash,
  HiOutlineOfficeBuilding,
} from "react-icons/hi";
import { FaClock } from "react-icons/fa";

import { AiTwotoneFolderOpen } from "react-icons/ai";
import { Link as RouteLink } from "react-router-dom";
import { differenceInBusinessDays } from "date-fns";

import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";

import dayjs from "dayjs";
import "dayjs/locale/fr";
import { fetchTasks } from "./api";
import TasksList from "./TasksList";
import NavBar from "./NavBar";
import NewTask from "../../components/task/NewTask";
import EditVideo from "../../components/video/EditVideo";
import Team from "./Team";
import ProgressSteps from "./ProgressSteps";
import OrderSummary from "./OrderSummary";
import SideBar from "./SideBar";
import Brief from "./Brief";
import RightBar from "./RightBar";
import { Updating } from "../../components/notification/Updating";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";
import MainColumn from "../../components/column/MainColumn";
dayjs.locale("fr");

export default function Main({ videoData, reloadData, setReloadData }) {
  const [isLoading, setIsLoading] = useState(false);

  const { colorMode } = useColorMode();
  let { projectId, videoId } = useParams();
  const { admin, user, freelance } = useUser();
  //   const [videoData, setVideoData] = useState("");
  const [adminProjectId, setAdminProjectId] = useState("");
  const [monthTask, setMonthTask] = useState([]);
  const [month, setMonth] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const {
  //   isOpen: isOpenEdit,
  //   onOpen: onOpenEdit,
  //   onClose: onCloseEdit,
  // } = useDisclosure();

  useEffect(() => {
    const monthArray =
      videoData &&
      videoData.tasks.map((task) => dayjs(task.dueDate).format("MM/YYYY"));

    const mode = (myArray) =>
      myArray.reduce(
        (a, b, i, arr) =>
          arr.filter((v) => v === a).length >= arr.filter((v) => v === b).length
            ? a
            : b,
        null
      );

    monthArray.length > 1 && setMonth(mode(monthArray));
  }, [videoData]);

  const lastTask = videoData?.tasks?.filter(
    (task) => task.dueDate !== null
  ).length;

  const date1 =
    videoData &&
    new Date(
      videoData?.tasks.filter((task) => task.dueDate !== null)[
        lastTask - 1
      ]?.dueDate
    );

  const date2 =
    videoData &&
    new Date(
      videoData?.tasks.filter((task) => task.dueDate !== null)[0]?.dueDate
    );

  const stageTime = differenceInBusinessDays(date1, date2) + 1;
  // console.log("date1", date1);
  // console.log("date2", date2);
  return (
    <MainColumn>
      {/* <MiniHeader> */}
      {/* <Flex alignItems='center'>
        <CardTitle title={videoData?.video?.title} isActive='false' />

        {stageTime > 0 && (
          <Flex
            color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}
            fontSize='xs'
            alignItems='center'
            ml='10px'>
            <FaClock />
            <Box ml='5px'>{stageTime}J</Box>
          </Flex>
        )}
        {admin && !isLoading && (
          <Button
            aria-label='Edit'
            variant='outline'
            rounded='full'
            size='sm'
            onClick={() => onOpenEdit()}
            mx='10px'>
            <HiPencilAlt />
          </Button>
        )}
      </Flex> */}
      {/* </MiniHeader> */}
      <MainColumn>
        <Box px='20px' overflowY='auto' pt='20px'>
          <Brief videoData={videoData} />

          <TasksList
            videoData={videoData}
            projectId={projectId}
            videoId={videoId}
            projectName={videoData && videoData.project.name}
            videoName={videoData?.video?.title}
            admins={videoData && videoData.admins}
            leaderOptions={videoData && videoData.leaderOptions}
            reloadData={reloadData}
            setReloadData={setReloadData}
            admin={admin}
            isLoading={isLoading}
          />
        </Box>

        <NewTask
          projectId={projectId}
          videoId={videoId}
          projectName={videoData && videoData.project.name}
          videoName={videoData?.video?.title}
          admins={videoData && videoData.admins}
          leaderOptions={videoData && videoData.leaderOptions}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          reloadData={reloadData}
          setReloadData={setReloadData}
        />
      </MainColumn>
      {isLoading && <Updating />}
    </MainColumn>
  );
}

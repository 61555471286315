import React, { useState } from "react";
import {
  Box,
  Avatar,
  Grid,
  Button,
  Heading,
  useColorMode,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Link,
  useDisclosure,
  IconButton,
  Stack,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { useUser } from "../../libs/UserContext";

import { IoFlash } from "react-icons/io5";

import { Link as RouteLink } from "react-router-dom";

import Card from "../../components/card";
import ButtonColor from "../../components/button/ButtonColor";
import { newStatusEmoji, statusSwitch } from "../../data/videoData";
import { newStatusSwitch } from "../../data/videoData";
import UpdateStatus from "../../components/video/UpdateStatus";

export default function OrderSummary(props) {
  const { admin } = useUser();
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { videoData, setReloadData, isLoading, clientId } = props;
  const [displayMore, setDisplayMore] = useState(false);
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenView,
    onOpen: onOpenView,
    onClose: onCloseView,
  } = useDisclosure();
  const [currentAdmin, setCurrentAdmin] = useState("");

  function viewCollab(people) {
    setCurrentAdmin(people);
    onOpenEdit();
  }

  // console.log("videoData", videoData);
  return (
    <Flex padding='20px' flexDirection='column'>
      <Box
        // bgColor="tertiary"
        // bg={colorMode === "light" ? "white" : "#232323"}

        rounded='xl'
        // borderWidth={colorMode === "light" ? "1px" : "0px"}
        // borderColor='#F0F0F0'
        // boxShadow='sm'
      >
        <Grid direction='column' gap='20px'>
          {/* Status*/}
          <Flex justifyContent='space-between'>
            <Text
              fontSize='sm'
              color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
              Status
            </Text>
            <Text>
              {videoData?.video?.newStatus && (
                <Badge
                  fontSize='xs'
                  variant='solid'
                  borderRadius='full'
                  px='10px'
                  py='5px'
                  // bgColor={newStatusSwitch(videoData?.video?.newStatus?.value)}
                  bgColor='whiteAlpha.100'>
                  {newStatusEmoji(videoData?.video?.newStatus?.value)}{" "}
                  {videoData && videoData?.video?.newStatus
                    ? videoData?.video?.newStatus?.label
                    : ""}
                </Badge>
              )}
            </Text>
          </Flex>
          {/* OldStatus*/}
          {/* <Flex justifyContent="space-between">
            <Text fontSize="sm" color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
              Old status
            </Text>
            <Text>
              <Badge
                fontSize="xs"
                variant='solid' 
                colorScheme={statusSwitch(
             
                   
                    videoData?.video?.status?.value
                )}
              >
                { videoData?.video?.status
                  ? videoData?.video?.status?.label
                  : ""}
              </Badge>
            </Text>
          </Flex> */}
          {/* Brief*/}
          <Flex justifyContent='space-between'>
            <Text
              fontSize='sm'
              color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
              Category
            </Text>
            <Box as='h4' mx='10px'>
              {" "}
              {videoData?.video?.category?.label}
            </Box>
          </Flex>
          {/* Duration */}
          <Flex justifyContent='space-between'>
            <Text
              fontSize='sm'
              color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
              Duration
            </Text>
            <Box as='h4' mx='10px'>
              {videoData?.video?.duration?.label}
            </Box>
          </Flex>

          {/* Subtitles */}
          <Flex justifyContent='space-between'>
            <Text
              fontSize='sm'
              color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
              Subtitle
            </Text>

            <Box align='end'>
              {videoData?.video?.subtitle?.map((item, index) => {
                return (
                  <Text key={index} mx='10px' textAlign='right'>
                    {item.label}
                  </Text>
                );
              })}
            </Box>
          </Flex>

          {/* Formats */}
          <Flex justifyContent='space-between'>
            <Text
              fontSize='sm'
              color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
              Formats
            </Text>
            <Box>
              {videoData &&
                videoData.video &&
                videoData.video.format.map((item, index) => {
                  return (
                    <Text key={index} mx='10px' textAlign='right'>
                      {" "}
                      {item.label}
                    </Text>
                  );
                })}
            </Box>
          </Flex>
          {displayMore && (
            <>
              {/* Interviews */}
              <Flex justifyContent='space-between'>
                <Text
                  fontSize='sm'
                  color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                  Interview(s)
                </Text>
                <Box as='h4' mx='10px'>
                  {videoData && videoData.video.interviews
                    ? `${videoData.video.interviews} interview${
                        videoData.video && videoData.video.interviews > 1
                          ? "s"
                          : ""
                      }`
                    : " "}
                </Box>
              </Flex>

              {/* Places */}
              <Flex justifyContent='space-between'>
                <Text
                  fontSize='sm'
                  color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                  Place(s)
                </Text>

                <Box as='h4' mx='10px'>
                  {videoData &&
                    videoData.video &&
                    videoData.video.places &&
                    videoData.video.places.label}
                </Box>
              </Flex>
              {/* Casting */}
              <Flex justifyContent='space-between'>
                <Text
                  fontSize='sm'
                  color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                  People
                </Text>
                <Box>
                  {videoData.video &&
                    videoData.video.people &&
                    videoData.video.people.map((item, index) => {
                      return (
                        <Text key={index} mx='10px' textAlign='right'>
                          {" "}
                          {item.label}
                        </Text>
                      );
                    })}
                </Box>
              </Flex>
              {/* Shootings */}
              <Flex justifyContent='space-between'>
                <Text
                  fontSize='sm'
                  color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                  Shooting
                </Text>
                <Box as='h4' mx='10px'>
                  {videoData && videoData.video.shootings
                    ? `${videoData.video.shootings} shooting${
                        videoData.video && videoData.video.shootings > 1
                          ? "s"
                          : ""
                      }`
                    : " "}
                </Box>
              </Flex>

              {/* B roll */}
              <Flex justifyContent='space-between'>
                <Text
                  fontSize='sm'
                  color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                  B roll
                </Text>
                <Box as='h4' mx='10px'>
                  {videoData && (videoData.video.bRoll ? "Yes" : "No")}
                </Box>
              </Flex>
              {/* Speaker Language */}

              <Flex justifyContent='space-between'>
                <Text
                  fontSize='sm'
                  color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                  Language
                </Text>
                <Box>
                  {videoData.video &&
                    videoData.video.speakerLanguage &&
                    videoData.video.speakerLanguage.map((item, index) => {
                      return (
                        <Text key={index} mx='10px' textAlign='right'>
                          {" "}
                          {item.label}
                        </Text>
                      );
                    })}
                </Box>
              </Flex>
            </>
          )}

          {/* Total jobs */}
          {admin && (
            <Flex justifyContent='space-between'>
              <Text
                fontSize='xs'
                color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                Total costs
              </Text>
              <Text>
                {videoData?.video?.totalJobs
                  ? `${videoData?.video?.totalJobs?.toLocaleString("fr-FR")}€`
                  : " "}

                {/* {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(
                  videoData && videoData.video.pricing
                    ? `${videoData.video.pricing}`
                    : " "
                )} */}
              </Text>
            </Flex>
          )}

          {/* Pricing */}
          {admin && (
            <Flex justifyContent='space-between'>
              <Text
                fontSize='sm'
                color={colorMode === "light" ? "gray.500" : "whiteAlpha.600"}>
                Total HT
              </Text>
              <Heading>
                {videoData?.video?.pricing
                  ? `${videoData?.video?.pricing?.toLocaleString("fr-FR")}€`
                  : " "}

                {/* {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(
                  videoData && videoData.video.pricing
                    ? `${videoData.video.pricing}`
                    : " "
                )} */}
              </Heading>
            </Flex>
          )}

          {
            <Button
              variant='link'
              size='xs'
              onClick={() => setDisplayMore(!displayMore)}>
              {displayMore ? "Show less" : "show more"}
            </Button>
          }

          {/* Cancel */}
          {videoData?.video?.cancel && (
            <Alert status='error' variant='solid'>
              <AlertIcon />
              <AlertTitle mr={2}>Video canceled</AlertTitle>
            </Alert>
          )}
        </Grid>
      </Box>
      {admin && !isLoading && (
        <ButtonColor
          title={
            <Flex alignItems='center'>
              <Box mr='5px'>
                <IoFlash />{" "}
              </Box>
              Update status
            </Flex>
          }
          click={() => onOpen()}
        />
      )}
      <UpdateStatus
        isOpen={isOpen}
        onClose={onClose}
        videoData={videoData.video}
        admin={admin}
        projectId={videoData?.project?._id}
        videoId={videoData?.video?._id}
        projectName={videoData?.project?.name}
        videoName={videoData?.video?.title}
        setReloadData={setReloadData}
        clientId={clientId}
      />
    </Flex>
  );
}

import React from "react";
import { API } from "aws-amplify";

export async function fetchClientsAll() {
  // console.log("fetchClientsAll");
  return API.get("kronik", "/client/all", {});
}

export async function fetchClientsMe() {
  // console.log("fetchClientsMe");
  return API.get("kronik", "/client/get/list", {});
}

import React, { useState } from "react";
import Day from "./Day";
import { Grid, useColorMode } from "@chakra-ui/react";

export default function Month(props) {
  const { month, tasks } = props;
  const { colorMode } = useColorMode();
  return (
    <Grid
      templateColumns="repeat(7, 1fr)"
      templateRows="repeat(5, 1fr)"
      //   gap="20px"
      
      borderColor={colorMode === "light" ? "#F0F0F0" : "gray.900"}
      borderWidth={colorMode === "light" ? "1px" : "0px"}

      // backgroundColor="white"
      boxShadow="sm"
      rounded="xl"
      gridGap="1px"
      backgroundColor={colorMode === "light" ? "#F0F0F0" : "black.50"}
      // _even={{ borderTopLeftRadius: "md" }}
      // _first={{
      //   borderTopLeftRadius: "md",
      // }}
    >
      {month.map((row, i) => (
        <React.Fragment key={i}>
          {row.map((day, idx) => (
            <Day day={day} tasks={tasks} key={idx} rowIdx={i} i={idx} />
          ))}
        </React.Fragment>
      ))}
    </Grid>
  );
}

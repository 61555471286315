import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../libs/UserContext";
import {
  Flex,
  Button,
  Box,
  Grid,
  Heading,
  useDisclosure,
  HStack,
  IconButton,
  Text,
} from "@chakra-ui/react";
// import VideosList from "./VideosList";
// import ShootingList from "./ShootingL  ist";
import { EditProject } from "../../components/project/EditProject";
import NewVideo from "../../components/video/NewVideo";
// import Team from "./Team";
import About from "./About";
// import OrderSummary from "./OrderSummary";
import { HiPencilAlt, HiOutlineOfficeBuilding } from "react-icons/hi";
import { AiTwotoneFolderOpen } from "react-icons/ai";
import SideBar from "./SideBar";
import RightBar from "./RightBar";
import { Updating } from "../../components/notification/Updating";
import { fetchClient, fetchProjects } from "./api";
import ProjectsList from "./ProjectsList";
import VideosList from "./VideosList";
import Main from "./Main";
import { MiniHeader } from "../../components/header/MiniHeader";
import CardTitle from "../../components/card/CardTitle";
import EditClient from "../../components/client/EditClient";

export default function Client() {
  let { clientId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { admin } = useUser();
  const [project, setProject] = useState("");
  const [client, setClient] = useState("");
  const [clientLogo, setClientLogo] = useState("");
  const [videos, setVideos] = useState("");
  const [shooting, setShooting] = useState("");
  const [reloadData, setReloadData] = useState(false);
  const [projects, setProjects] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const {
  //   isOpen: isOpenEdit,
  //   onOpen: onOpenEdit,
  //   onClose: onCloseEdit,
  // } = useDisclosure();

  // const { isOpen, onOpen, onClose } = useDisclosure();

  // useEffect(() => {
  //   setIsLoading(true);
  //   fetchProjects(clientId).then((response) => {
  //     setProject(response);
  //     setIsLoading(false);
  //   });
  // }, [reloadData]);

  // useEffect(() => {
  //   console.log("projectclient", project)
  //   setVideos(project && project.videos);
  //   // setShooting(
  //   //   project &&
  //   //     project.videos.filter(
  //   //       (video) =>
  //   //         video.category &&
  //   //         video.category &&
  //   //         video.category.value === "shooting"
  //   //     )
  //   // );
  // }, [project]);

  return (
    <Box w='100%'>
      <MiniHeader>
        <CardTitle
          title={admin ? client && client[0]?.name : client && client}
          isActive={true}
        />
        {admin && !isLoading && (
          <Button
            aria-label='Edit'
            variant='outline'
            rounded='full'
            size='sm'
            onClick={() => onOpen()}
            mx='10px'>
            <HiPencilAlt />
          </Button>
        )}
      </MiniHeader>
      <Flex>
        <SideBar
          projectName={project?.project?.name}
          clientName={admin ? client && client[0]?.name : client && client}
          clientLink={``}
          isLoading={isLoading}
          clientLogo={admin ? client[0]?.logo : clientLogo}
        />

        <Main
          projects={projects}
          videos={videos}
          project={project}
          setProjects={setProjects}
          setVideos={setVideos}
          client={client[0]}
          setClient={setClient}
          setClientLogo={setClientLogo}
        />

        <RightBar
          projectData={project.project}
          team={projects?.admins}
          projectName={project?.project?.name}
          // projectId={projectId}
          reloadData={reloadData}
          setReloadData={setReloadData}
          admin={admin}
          isLoading={isLoading}
          clientId={clientId}
          client={client[0]}
        />

        {isLoading && <Updating />}
        {client && (
          <EditClient
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            reloadData={reloadData}
            setReloadData={setReloadData}
            clientData={client[0]}
          />
        )}
      </Flex>
    </Box>
  );
}

import {
  AiFillCalculator,
  AiFillDashboard,
  AiFillDollarCircle,
  AiFillMessage,
} from "react-icons/ai";
import {
  BsBank2,
  BsCalendarCheckFill,
  BsCheckLg,
  BsCollectionPlayFill,
  BsFillBarChartFill,
  BsFillBriefcaseFill,
  BsFillCalculatorFill,
  BsFillCalendar3WeekFill,
  BsFillCalendarDateFill,
  BsFillPiggyBankFill,
  BsListTask,
} from "react-icons/bs";
import {
  FaBuilding,
  FaPaperPlane,
  FaThList,
  FaUserCircle,
  FaVideo,
} from "react-icons/fa";
import { IoBusinessSharp } from "react-icons/io5";
import { MdOutlineTask, MdTask } from "react-icons/md";
import {
  RiBarChartBoxFill,
  RiBuilding4Fill,
  RiContactsFill,
  RiFolderUserFill,
  RiMoneyEuroBoxFill,
} from "react-icons/ri";

export const navItem = [
  { label: "Dashboard", link: "/dashboard" },
  { label: "Clients", link: "/clients" },
  { label: "Freelances", link: "/freelances" },
  { label: "Quotes", link: "/projects" },
  { label: "Revenues", link: "/revenues" },
  { label: "Shooting", link: "/shooting" },
  { label: "Projects", link: "/projects" },
  { label: "Tasks", link: "/tasks" },
];

export const navItemAdmin = [
  {
    label: "Dashboard",
    link: "/dashboard",
    picto: <BsFillBarChartFill />,
    nav: ["dashboard"],
  },
  {
    label: "Clients",
    link: "/clients",
    picto: <BsFillBriefcaseFill />,
    nav: [
      "lead",
      "clients",
      "quotes",
      "revenues",
      "invoices",
      "payments",
      "expenses",
    ],
  },
  // { label: "Briefs", link: "/brief", picto: <FaPaperPlane /> },
  {
    label: "Library",
    link: "/library",
    picto: <BsCollectionPlayFill />,
    nav: ["library"],
  },
  {
    label: "Projects",
    link: "/projects?status=production",
    picto: <FaVideo />,
    nav: ["projects"],
  },
  {
    label: "Tasks",
    link: "/tasks?list=todo",
    picto: <BsCalendarCheckFill />,
    nav: [, "shooting", "tasks"],
  },
  {
    label: "Freelances",
    link: "/freelances",
    picto: <RiContactsFill />,
    nav: ["freelances"],
  },
  // { label: "Quotes", link: "/projects", picto: <BsFillCalculatorFill /> },
  // { label: "Revenues", link: "/revenues", picto: <BsBank2 /> },
  // { label: "Shooting", link: "/shooting", picto: <BsFillCalendarDateFill /> },
  // { label: "Videos", link: "/videos", picto: <BsCollectionPlayFill /> },
  // { label: "Tasks", link: "/tasks", picto: <BsCheckLg /> },
];

export const navItemFreelance = [
  {
    label: "Dashboard",
    link: "/dashboard",
    picto: <BsFillBarChartFill />,
    nav: ["dashboard"],
  },

  {
    label: "Clients",
    link: "/clients",
    picto: <BsFillBriefcaseFill />,
    nav: ["lead", "clients", "jobs"],
  },

  {
    label: "Library",
    link: "/library",
    picto: <BsCollectionPlayFill />,
    nav: ["library"],
  },
  // {
  //   label: "Clients",
  //   link: "/clients",
  //   picto: <RiBuilding4Fill />,
  //   nav: ["clients"],
  // },

  {
    label: "Projects",
    link: "/projects?status=production",
    picto: <FaVideo />,
    nav: ["projects"],
  },
  {
    label: "Tasks",
    link: "/tasks?list=todo",
    picto: <BsCalendarCheckFill />,
    nav: ["tasks"],
  },

  // {label:"Tasks",link:"/tasks"},
];

export const navItemClient = [
  {
    label: "Dashboard",
    link: "/dashboard",
    picto: <BsFillBarChartFill />,
    nav: ["dashboard"],
  },

  {
    label: "Quotes",
    link: "/quotes",
    picto: <BsFillCalculatorFill />,
    nav: ["quotes"],
  },
  {
    label: "Library",
    link: "/library",
    picto: <BsCollectionPlayFill />,
    nav: ["library"],
  },
  {
    label: "Projects",
    link: "/projects",
    picto: <FaVideo />,
    nav: ["projects"],
  },
  { label: "Tasks", link: "/tasks", picto: <BsCheckLg />, nav: ["tasks"] },
];

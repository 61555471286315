export const skillOptions = [
  { value: "filmmaker", label: "Filmmaker" },
  { value: "interviewer", label: "Interviewer" },
  { value: "preEditor", label: "Pre Editor" },
  { value: "editor", label: "Editor" },
  { value: "director", label: "Director" },
  { value: "motion", label: "Motion Designer" },
  { value: "dronist", label: "Dronist" },
  { value: "liveProducer", label: "Live Producer" },
  { value: "storyboarder", label: "Storyboarder" },
  { value: "voiceOver", label: "Voice Over" },
  { value: "photographer", label: "Photographer" },
  { value: "actor", label: "Actor" },
];

export const statusOptions = [
  { value: "applied", label: "Applied" },
  { value: "sourced", label: "Sourced" },
  { value: "onboarding", label: "Onboarding" },
  { value: "rookie", label: "Rookie" },
  { value: "confirmed", label: "Confirmed" },
  { value: "rejected", label: "Rejected" },
  { value: "fired", label: "Fired" },
  { value: "test", label: "Test" },
  // { value: "selected", label: "Selected" },
  // { value: "validated", label: "Validated" },
  // { value: "blocked", label: "Blocked" },
];

export const cameraOptions = [
  { value: "sonyA7S3", label: "Sony A7S3" },
  { value: "sonyA73", label: "Sony A7 3" },
  { value: "sonyA74", label: "Sony A7 4" },
  { value: "sonyFX30", label: "Sony FX30" },
  { value: "sonyFX3", label: "Sony FX3" },
  { value: "sonyFX6", label: "Sony FX6" },
  { value: "sonyFX9", label: "Sony FX9" },
  { value: "sonyC70", label: "Sony C70" },
  { value: "canonC300", label: "Canon C300" },
  { value: "canonC300", label: "Canon C300" },
  { value: "panasonicGH5", label: "Panasonic GH5" },
  { value: "panasonicGH6", label: "Panasonic GH6" },
  { value: "panasonicS1", label: "Panasonic S1" },
  { value: "panasonicS5", label: "Panasonic S5" },
  { value: "panasonicS5", label: "Panasonic S5" },
  { value: "blackmagicPocketCinema", label: "Blackmagic Pocket Cinema" },
  { value: "blackmagic6K", label: "Blackmagic 6K" },
  { value: "redKomodo6K", label: "Red Komodo 6K" },
];
export const lenseOptions = [
  { value: "focal<35mm", label: "Focal Length < 35mm" },
  { value: "focal35mm", label: "Focal Length 35mm" },
  { value: "focal50mm", label: "Focal Length 50mm" },
  { value: "focal85mm", label: "Focal Length 85mm" },
  { value: "focal>85mm", label: "Focal Length > 85mm" },
  { value: "focal≤70mm≥f4", label: "Focal Length ≤70mm ≥f4" },
  { value: "focal≤70mm≤f2.8", label: "Focal Length ≤70mm ≤f2.8" },
  { value: "focal ≥70mm≥f4", label: "Focal Length  ≥70mm ≥f4" },
  { value: "focal ≥70mm≤f2.8", label: "Focal Length  ≥70mm ≤f2.8" },
];

export const microOptions = [
  { value: "hf", label: "HF" },
  { value: "canon", label: "Canon" },
  { value: "cygne", label: "Cygne" },
  { value: "main", label: "Main" },
];

export const lightOptions = [
  { value: "cobLight", label: "COB light" },
  { value: "ledLight", label: "LED light" },
];

export const supportOptions = [
  { value: "tripod", label: "Tripod" },
  { value: "slider", label: "Slider" },
  { value: "stab", label: "Stab" },
];
export const droneOptions = [
  { value: "djiPhantom", label: "DJI Phantom" },
  { value: "djiMatis", label: "DJI Matis" },
  { value: "djiSpark", label: "DJI Spark" },
];

// export const setupOptions = [{ value: "micro", label: "Micro" }];

export const setupOptions = [
  {
    label: "Cameras",
    options: cameraOptions,
  },
  {
    label: "Lenses",
    options: lenseOptions,
  },
  {
    label: "Microphones",
    options: microOptions,
  },
  {
    label: "Lighting",
    options: lightOptions,
  },
  {
    label: "Supports",
    options: supportOptions,
  },
  {
    label: "Drones",
    options: droneOptions,
  },
];

// export const freelancePrices = [
//     {job:"shootingBRool", unit:"minute", price:3},
//     {job:"shootingInterview", unit:"interview", price:100},
//     {job:"preparationInterview", unit:"day", price:600},
//     {job:"interview", unit:"interview", price:250},
//     {job:"preEditing", unit:"interview", price:160},
//     {job:"editing", unit:"minute", price:220},
//     {job:"feedback", unit:"session", price:80},
//     {job:"export", unit:"format", price:50},
//     {job:"subtitles", unit:"unit", price:60},
// ]

import { AiOutlineMobile } from "react-icons/ai";
import { BsAspectRatio, BsFillInboxFill } from "react-icons/bs";
import { FiSquare } from "react-icons/fi";
import { IoSquareOutline, IoStopOutline, IoTabletLandscapeOutline, IoTabletPortraitOutline } from "react-icons/io5";
import { MdCrop169, MdCrop54, MdCropDin, MdCropPortrait, MdOutlineAspectRatio } from "react-icons/md";

export const freelancePrices = {
    shootingBRoll:  {unit:"minute", price:3},
    shootingInterview: {unit:"interview", price:100},
    preparationInterview:{unit:"forfait", price:150},
    interview: { unit:"interview", price:100},
    preEditing:{unit:"interview", price:160},
    editing:{unit:"minute", price:180},
    feedback:{unit:"session", price:80},
    exportSimple:{ unit:"format", price:50},
    exportWithSubtitles:{ unit:"format", price:60},
    exportOtherLanguage:{ unit:"format", price:120},
    subtitles:{ unit:"unit", price:60},
    vectorMockup:{ unit:"unit", price:300},
    designMockup:{ unit:"unit", price:500},
    travelCosts:{unit:"km", price:3}
}


export const locationOptions = [
    {name:"Paris", country:"France"},
    {name:"Marseille", country:"France"},
    {name:"Lyon", country:"France"},
    {name:"Toulouse", country:"France"},
    {name:"Nice", country:"France"},
    {name:"Nantes", country:"France"},
    {name:"Montpellier", country:"France"},
    {name:"Strasbourg", country:"France"},
    {name:"Bordeaux", country:"France"},
    {name:"Lille", country:"France"},
    {name:"Rennes", country:"France"},
    {name:"Reims", country:"France"},
    {name:"Toulon", country:"France"},
    {name:"Grenoble", country:"France"},
    {name:"?", country:"France"},
    {name:"Barcelone", country:"Spain"},
    {name:"Madrid", country:"Spain"},
    {name:"Berlin", country:"Germany"},
    {name:"London", country:"England"},
    {name:"Milan", country:"Italy"},
    {name:"Lisbon", country:"Portugal"},
    {name:"Amsterdam", country:"Portugal"},
    {name:"Dublin", country:"Ireland"},
    {name:"Bruxelle", country:"Belgium"},
    {name:"?", country:"Europe"},
]
   

export const marginRates = {
    projectManager : {rate:0.25},
    director : {rate:0.20},
    kronik : {rate:0.15}
}

export const formatOptions = [
    { value: "16/9", label: "16/9", icon: <MdCrop169 size={52} /> },
    { value: "9/16", label: "9/16", icon: <AiOutlineMobile size={44} /> },
    { value: "1/1", label: "1/1", icon: <MdCropDin size={44} /> },
    { value: "4/5", label: "4/5", icon: <MdCrop54 size={44} /> },
    { value: "5/4", label: "5/4", icon: <MdCropPortrait size={44} /> },
  ];

  export const goalOptions = [
    {name: "Recrutement", category:"Marque employeur", icon: <BsFillInboxFill size={32} /> },
    {name: "Onboarding",category:"Marque employeur", icon: <BsFillInboxFill size={32} /> },
    {name: "Team building",category:"Marque employeur", icon: <BsFillInboxFill size={32} /> },
    {name: "Réassurance",category:"Sales",icon: <BsFillInboxFill size={32} /> },
    {name: "Conversion",category:"Sales",icon: <BsFillInboxFill size={32} /> },
    {name: "Lead", category:"Sales",icon: <BsFillInboxFill size={32} /> },
    {name: "Fidélisation", category:"Sales",icon: <BsFillInboxFill size={32} /> },
    {name: "Branding", category:"Communication",icon: <BsFillInboxFill size={32} /> },
    {name: "Notoriété", category:"Communication",icon: <BsFillInboxFill size={32} /> },
    {name: "RP", category:"Communication",icon: <BsFillInboxFill size={32} /> },
]

export const styleOptions = [
    {name: "Corporate", icon: <BsFillInboxFill size={32} /> },
    {name: "Pop", icon: <BsFillInboxFill size={32} /> },
    {name: "Intemporel", icon: <BsFillInboxFill size={32} /> },
    {name: "Fun", icon: <BsFillInboxFill size={32} /> },
    {name: "Rythmé", icon: <BsFillInboxFill size={32} /> },
]

export const briefOptions = [
    {name:"interview",text: "",  icon: <BsFillInboxFill size={32} /> },
    {name:"shooting",  text: "", icon: <BsFillInboxFill size={32} /> },
    {name:"time", text: "", icon: <BsFillInboxFill size={32} /> },
    {name:"B-Roll", text: "", icon: <BsFillInboxFill size={32} /> },
    {name:"Habillage",text: "", icon: <BsFillInboxFill size={32} /> },
    // {name:"Reviews",text: "", icon: <BsFillInboxFill size={32} /> },
]

export const speakerOptions = [
    {name: "Client",icon: <BsFillInboxFill size={32} /> },
    {name: "Prospect",icon: <BsFillInboxFill size={32} /> },
    {name: "Utilisateur",icon: <BsFillInboxFill size={32} /> },
    {name: "Collaborateur",icon: <BsFillInboxFill size={32} /> },
    {name: "Manager",icon: <BsFillInboxFill size={32} /> },
    {name: "Dirigeant",icon: <BsFillInboxFill size={32} /> },
    {name: "Expert",icon: <BsFillInboxFill size={32} /> },
    {name: "Ambassadeur",icon: <BsFillInboxFill size={32} /> },
    {name: "Partenaire",icon: <BsFillInboxFill size={32} /> },
    {name: "Acteur",icon: <BsFillInboxFill size={32} /> },
]


export const distributionOptions = [
    {name: "Linkedin",icon: <BsFillInboxFill size={32} /> },
    {name: "Youtube",icon: <BsFillInboxFill size={32} /> },
    {name: "Instagram",icon: <BsFillInboxFill size={32} /> },
    {name: "Ticktok",icon: <BsFillInboxFill size={32} /> },
    {name: "Snapchat",icon: <BsFillInboxFill size={32} /> },
    {name: "Facebook",icon: <BsFillInboxFill size={32} /> },
    {name: "Newsletter",icon: <BsFillInboxFill size={32} /> },
    {name: "Conférence",icon: <BsFillInboxFill size={32} /> },
    {name: "Vitrine",icon: <BsFillInboxFill size={32} /> },
    {name: "Messagerie",icon: <BsFillInboxFill size={32} /> },
    {name: "Application",icon: <BsFillInboxFill size={32} /> },
    {name: "Site web",icon: <BsFillInboxFill size={32} /> },
    {name: "Intranet",icon: <BsFillInboxFill size={32} /> },
]

  export const team = [
   
      {name: "filmmaker",price:600, qty:1, icon: <BsFillInboxFill size={32} /> },
      {name: "journalist",price:350, qty:1, icon: <BsFillInboxFill size={32} /> },
      {name: "storyteller",price:350, qty:1, icon: <BsFillInboxFill size={32} /> },
      {name: "editor",price:350, qty:1, icon: <BsFillInboxFill size={32} /> },
      {name: "project manager",price:350, qty:1, icon: <BsFillInboxFill size={32} /> },
    {name: "sound operator",price:350, qty:1, icon: <BsFillInboxFill size={32} /> },
    {name: "lighting operator",price:350, qty:1, icon: <BsFillInboxFill size={32} /> },
    {name: "makeup",price:350, qty:1, icon: <BsFillInboxFill size={32} /> },
    {name: "voice over",price:350, qty:1, icon: <BsFillInboxFill size={32} /> },
    {name: "script editor",price:350, qty:1, icon: <BsFillInboxFill size={32} /> },
    {name: "actor",price:350, qty:1, icon: <BsFillInboxFill size={32} /> },
    {name: "motion designer",price:350, qty:1, icon: <BsFillInboxFill size={32} /> },
]


  export const equipment = [
    {name: "extra camera",price:200, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "prompteur",price:100, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "drone intérieur",price:150, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "drone extérieur",price:100, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "liverecording",price:100, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "livestreaming",price:100, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "light",price:100, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "gimbals",price:100, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "slider",price:100, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "studio",price:100, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "micro",price:100, qty:1,icon: <BsFillInboxFill size={32} /> },
]


export const addOn = [
    {name: "reviews",price:100, qty:1,step:1,icon: <BsFillInboxFill size={32} /> },
    {name: "deplacement",price:3, qty:10, step:10,icon: <BsFillInboxFill size={32} /> },
    {name: "achat d'image",price:100, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "décor",price:200, qty:1,icon: <BsFillInboxFill size={32} /> },
]

export const exportSimple = [
    {name: "16/9",price:50, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "9/16",price:50, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "1/1",price:50, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "4/5",price:50, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "5/4",price:50, qty:1,icon: <BsFillInboxFill size={32} /> },
]

export const exportWithSubtitles = [
    {name: "16/9",price:80, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "9/16",price:80, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "1/1",price:80, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "4/5",price:80, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "5/4",price:80, qty:1,icon: <BsFillInboxFill size={32} /> },
]

export const exportOtherLanguage = [
    {name: "16/9",price:150, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "9/16",price:150, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "1/1",price:150, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "4/5",price:150, qty:1,icon: <BsFillInboxFill size={32} /> },
    {name: "5/4",price:150, qty:1,icon: <BsFillInboxFill size={32} /> },
]


export const templateOptions = [
    "basic", "standard", "premium", "custom"
];

// export const motionOptions = [
//     "basic", "standard", "premium",
// ]


export const qualityOptions = [
    {
      name: "basic",
      icon: <BsFillInboxFill size={32} />,
    },
    {
      name: "standard",
      icon: <BsFillInboxFill size={32}/>,
    },
    {
      name: "premium",
      icon: <BsFillInboxFill size={32}/>,
    },
  ];




export const motionOptions = [
    {
      name: "basic",
      price:150,
      icon: <BsFillInboxFill size={32} />,
    },
    {
      name: "standard",
      price:600,
      icon: <BsFillInboxFill size={32}/>,
    },
    {
      name: "premium",
      price:1200,
      icon: <BsFillInboxFill size={32}/>,
    },
  ];

export const testimonialTemplate = {
   basic : {
        template:"testimonial",
        name: "basic",
        time:60 ,
        isShooting:true,
        isEditing:true,
        isBRoll:false,
        isDirector:false,
        interview:1,
        export:2,
        revisions:2,
        subtitles:0,
        motion:{name:"basic", price: motionOptions.find(item =>item.name ==="basic").price},
        // exportSimple:[ { value: "16/9", label: "16/9" }]
        team:[
            {name:"filmmaker", price:team.find(item => item.name ==="filmmaker").price, qty:1},
            {name:"journalist", price:team.find(item => item.name ==="journalist").price, qty:1},
            {name:"storyteller", price:team.find(item => item.name ==="storyteller").price, qty:1},
        ],
        equipment: [
            {name: "drone intérieur", price:equipment.find(item => item.name==="drone intérieur").price,qty:1  },
            // {name: "drone extérieur", price:equipment.find(item => item.name==="drone extérieur").price,qty:1  },
            // {name: "liverecording", price:equipment.find(item => item.name==="liverecording").price, qty:1 }
        ],
        exportSimple: [
            {name: "16/9", price:exportSimple.find(item => item.name==="16/9").price,qty:1,  include: 0  },
            {name: "9/16", price:exportSimple.find(item => item.name==="9/16").price,qty:1  },
        ],
        exportWithSubtitles: [
            {name: "16/9", price:exportWithSubtitles.find(item => item.name==="16/9").price,qty:1  },
            {name: "9/16", price:exportWithSubtitles.find(item => item.name==="9/16").price,qty:1  },
        ],
        exportOtherLanguage: [
        ],
    }, 
         
    standard : {
        template:"testimonial",
        name: "standard",
        time:90,
        preparationInterview:200,
        preEditing: 180,
        isShooting:true,
        isEditing:true,
        isBRoll:true,
        isDirector:false,
        interview:2,
        export:2,
        revisions:2,
        subtitles:1,
        motion:{name:"standard", price: motionOptions.find(item =>item.name ==="standard").price},
        // exportSimple:[ { value: "16/9", label: "16/9" }],
        // exportWithSubtitles:[ { value: "9/16", label: "9/16" }],
        // exportOtherLanguage:[],
        // equipment:equipment.filter(item => (item.name==="prompteur" || item.name==="drone extérieur")),
        team:[
            {name:"filmmaker", price:team.find(item => item.name ==="filmmaker").price, qty:1},
            {name:"journalist", price:team.find(item => item.name ==="journalist").price, qty:1},
            {name:"storyteller", price:team.find(item => item.name ==="storyteller").price, qty:1},
        ],
        equipment: [
            {name: "drone intérieur", price:equipment.find(item => item.name==="drone intérieur").price,qty:1  },
            {name: "drone extérieur", price:equipment.find(item => item.name==="drone extérieur").price,qty:1  },
            // {name: "liverecording", price:equipment.find(item => item.name==="liverecording").price, qty:1 }
        ],
        exportSimple: [
            {name: "16/9", price:exportSimple.find(item => item.name==="16/9").price,qty:1  },
            {name: "9/16", price:exportSimple.find(item => item.name==="9/16").price,qty:1  },
        ],
        exportWithSubtitles: [
            {name: "16/9", price:exportWithSubtitles.find(item => item.name==="16/9").price,qty:1  },
            {name: "9/16", price:exportWithSubtitles.find(item => item.name==="9/16").price,qty:1  },
        ],
        exportOtherLanguage: [
        ],
    }, 
       
    premium : {
        template:"testimonial",
        name: "premium",
        time:150,
        preparationInterview:400,
        preEditing: 180,
        isShooting:true,
        isEditing:true,
        isBRoll:true,
        isDirector:true,
        interview:3,
        export:4,
        revisions:4,
        subtitles:2,
        motion:{name:"premium", price: motionOptions.find(item =>item.name ==="premium").price},
        speaker:[
            {name:"Client"},
        ],
        brief:[
            {name:"interview", text:"2-3 max"},
            {name:"shooting", text:"4h" },
            {name:"time", text:"1-2 min"},
            {name:"B-Roll", text:"Oui" },
            {name:"Habillage", text:"inclus"},
            // {name:"Reviews", text:"2 inclus"}
        ],
        goal:[
            {name:"Recrutement"},
            {name:"Branding"}
        ],
        distribution:[
            {name:"Linkedin"},
            {name:"Site web"}
        ],
        style:[
          {name:"Pop"},
          {name:"Corporate"},
          
        ],
        team:[
            {name:"filmmaker", price:team.find(item => item.name ==="filmmaker").price, qty:1},
            {name:"journalist", price:team.find(item => item.name ==="journalist").price, qty:1},
            {name:"storyteller", price:team.find(item => item.name ==="storyteller").price, qty:1},
            {name:"editor", price:team.find(item => item.name ==="editor").price, qty:1},
            {name:"project manager", price:team.find(item => item.name ==="project manager").price, qty:1},
        ],
        addOn:[
            {name:"reviews", price:addOn.find(item => item.name ==="reviews").price, qty:2, include:2},
            {name:"deplacement", price:addOn.find(item => item.name ==="deplacement").price, qty:50}

        ],
        equipment: [
            {name: "drone intérieur", price:equipment.find(item => item.name==="drone intérieur").price,qty:2  },
            {name: "drone extérieur", price:equipment.find(item => item.name==="drone extérieur").price,qty:3  },
            {name: "liverecording", price:equipment.find(item => item.name==="liverecording").price, qty:1 }
        ],
        exportSimple: [
            {name: "16/9", price:exportSimple.find(item => item.name==="16/9").price,qty:2, include: 2  },
            {name: "9/16", price:exportSimple.find(item => item.name==="9/16").price,qty:1 , include: 1  },
            {name: "1/1", price:exportSimple.find(item => item.name==="1/1").price,qty:1  },
        ],
        exportWithSubtitles: [
            {name: "16/9", price:exportWithSubtitles.find(item => item.name==="16/9").price,qty:1  },
            {name: "9/16", price:exportWithSubtitles.find(item => item.name==="9/16").price,qty:1  },
            {name: "1/1", price:exportWithSubtitles.find(item => item.name==="1/1").price,qty:1  },
        ],
        exportOtherLanguage: [
        ],
        location:[
            {name:"Paris"}
        ]
      
        // exportSimple:[ { value: "16/9", label: "16/9" }],
        // exportWithSubtitles:[ { value: "9/16", label: "9/16" }],
        // exportOtherLanguage:[]
    }, 
}